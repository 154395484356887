
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "DepositStrategy": [
      "ByBooking",
      "ByPartySize"
    ],
    "RestaurantPageContent": [
      "CateringFormPage",
      "EcommercePage",
      "EmailMarketingPage",
      "EmptySection",
      "FilePage",
      "FormPage",
      "GiftCardPurchasePage",
      "LoyaltySignUpPage",
      "MenuPage",
      "TemplatePage",
      "UntappdMenuPage"
    ],
    "SiteSection": [
      "CalendarSection",
      "CardSection",
      "DynamicSection",
      "FullScreenSection",
      "GallerySection",
      "HeroSection",
      "LoyaltySignupSection",
      "MapWithScheduleSection",
      "PhotoSection",
      "ReservationSection",
      "ReviewSection",
      "TagSection"
    ]
  }
};
      export default result;
    