import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { createScriptTagsFromString } from 'public/components/default_template/meta/PixelsMeta';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import { sift } from 'config';

import { useSift } from './SiftContext';

declare global {
  interface Window {
    _sift: any
  }
}

export const SiftMeta = () => {
  const { customer } = useCustomer();
  const { sessionId } = useSift();
  const siftLoaded = useRef(false);
  // Load when component is mounted. If not mounted during SSR, the load listeners in the snippet won't fire during CSR.
  useEffect(() => {
    if(typeof window != 'undefined' && typeof window._sift === 'undefined' && !siftLoaded.current) {
      const e = document.createElement('script');
      e.src = 'https://cdn.sift.com/s.js';
      document.body.appendChild(e);
      siftLoaded.current = true;
    }
  }, []);
  const header = createScriptTagsFromString(`<script type="text/javascript">
  var _user_id = '${customer ? customer.email : ''}';
  var _session_id = '${sessionId}'; // Set to a unique session ID for the visitor's current browsing session.

  var _sift = window._sift = window._sift || [];
  _sift.push(['_setAccount', '${sift.beaconKey}']);
  _sift.push(['_setUserId', _user_id]);
  _sift.push(['_setSessionId', _session_id]);
  _sift.push(['_trackPageview']);

 (function() {
   function ls() {
     var e = document.createElement('script');
     e.src = 'https://cdn.sift.com/s.js';
     document.body.appendChild(e);
   }
   if (window.attachEvent) {
     window.attachEvent('onload', ls);
   } else {
     window.addEventListener('load', ls, false);
   }
 })();
</script>`)[0];
  if(!header) return null;
  return <Helmet>{header}</Helmet>;
};
