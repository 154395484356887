import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { _div, _heading, useEditor, FieldType, ContainsEditableProps, ModuleField } from '@toasttab/sites-components';
import classnames from 'classnames';

import { SectionImagePosition, ThemeTypeEnum, Link as LinkType, ButtonType, Image as ImageObject, PaddingEnum, BackgroundImage } from 'src/apollo/sites';
import { _Image } from 'src/shared/components/common/editable_image/EditableImage';
import ImageScroller from 'src/shared/components/common/image_scroller/ImageScroller';

import { EditableButton } from 'shared/components/common/button';
import { getThemeModule, getSectionPaddingModule, getImageModules, getBackgroundColorOrImageModule } from 'shared/components/common/editor_context/editableUtils';

import HeroWrapper from './HeroWrapper';

type Props = HeroBoxProps & {
  images?: ImageObject[] | null;
  imagesPath?: string | null;
  imageDescription?: string | null;
  imgPosition?: SectionImagePosition | null;
  textWithBackground?: boolean | null;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

const InsetHero = (props: Props) => {
  const { useEditableRef, isEditor } = useEditor();
  const carouselButtonRef = useRef<HTMLDivElement>(null);
  const {
    editPath, theme, images, imagesPath, imageDescription, header, title,
    body, button, imgPosition, textWithBackground, backgroundColor, backgroundImage, padding = PaddingEnum.Medium
  } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const image = useMemo(() => images?.[0] || null, [images]);
  const imagePath = useMemo(() => `${imagesPath}[${currentImageIndex}]`, [imagesPath, currentImageIndex]);
  const pathToHeroImgSrc = useMemo(() => `${imagePath}.src`, [imagePath]);

  const [mouseHover, toggleMouseHover] = useReducer(active => !active, false);
  const [focused, toggleFocus] = useReducer(active => !active, false);
  const autoScrollActive = useMemo(() => !isEditor && !mouseHover && !focused, [isEditor, mouseHover, focused]);

  const { editableRef, pushSchema } = useEditableRef<HTMLDivElement>({
    name: 'inset hero section',
    displayName: 'Hero Section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    skipRef: carouselButtonRef,
    schema: {
      fields:
        getEditableFields(editPath, backgroundColor, backgroundImage, theme, button, padding, images, imagesPath, currentImageIndex)
    }
  });

  useEffect(() => {
    pushSchema();
  }, [pushSchema, images?.length, currentImageIndex]);

  const heroBox = useMemo(() => {
    if(!image || !images) return null;

    return <HeroBox
      theme={theme}
      header={header}
      title={title}
      body={body}
      button={button}
      editPath={editPath}
      className={images?.length > 1 ? 'overImageScroller' : ''} />;
  }, [body, button, editPath, header, image, images, theme, title]);

  const heroContent = useMemo(() => {
    if(!image || !images) return null;

    if(images?.length > 1) {
      return (
        <div className={classnames('heroWrapper', theme)}
          // Pause auto-scrolling on mouseover or focus. See https://toasttab.atlassian.net/browse/WOO-1106 for details.
          onFocus={toggleFocus} onBlur={toggleFocus} onMouseOver={toggleMouseHover} onMouseOut={toggleMouseHover}>
          <ImageScroller
            arrowPosition="bottom"
            skipRef={carouselButtonRef}
            images={images}
            autoScrollMS={3_500}
            onScrollIndexChange={setCurrentImageIndex}
            className={classnames('image themeElement md-compactButtons', theme)}
            autoScrollActive={autoScrollActive} />
          {heroBox}
        </div>);
    }

    return (
      <div className={classnames('heroWrapper', theme)}>
        <_Image
          imageObject={image}
          imageObjectPath={`${imagesPath}[0]`}
          src={image.src}
          className={classnames('image standalone themeElement', theme)}
          alt={image.altText || imageDescription || 'User-uploaded image'}
          fit={image.fit}
          fitEditPath={`${imagePath}.fit`}
          link={image.link}
          linkEditPath={`${imagePath}.link`}
          editPath={pathToHeroImgSrc}
          eagerLoad
          deletable={false}
          deletableParent={true} />
        {heroBox}
      </div>
    );
  }, [autoScrollActive, heroBox, image, imageDescription, imagePath, images, imagesPath, pathToHeroImgSrc, theme]);

  if(!image || !images) return null;

  return (
    <HeroWrapper theme={theme} imgPosition={imgPosition} textWithBackground={textWithBackground} editableRef={editableRef}>
      {heroContent}
    </HeroWrapper>
  );
};

type HeroBoxProps = {
  theme?: ThemeTypeEnum | null;
  header?: string | null;
  title?: string | null;
  body?: string | null;
  button?: LinkType | null;
  editPath?: string | null;
  className?: string;
}

const HeroBox = ({ theme, header, title, body, button, editPath, className }: HeroBoxProps) => {
  const pathToHeader = `${editPath}.heroHeader`;
  const pathToTitle = `${editPath}.heroTitle`;
  const pathToBody = `${editPath}.heroBody`;
  const pathToButton = `${editPath}.heroButton`;

  return (
    <div className={classnames('heroBoxWrapper', theme, className)}>
      <div>
        <div className={classnames('heroBox themeElement', theme, { paddedSection: theme === ThemeTypeEnum.Wide })}>
          <div className="heroTextWrapper">
            <div className="heroText">
              {header && <_div className="heroHeader" html={header} editPath={pathToHeader} />}
              {title && <_heading styleLevel={1} html={title} editPath={pathToTitle} />}
              {body && <_div className="heroBody" html={body} editPath={pathToBody} />}
            </div>
            {button &&
              <EditableButton
                classNames={'heroButton'}
                editPath={pathToButton}
                link={button.link}
                type={button?.type || ButtonType.Inverted}
                primaryColor={button?.primaryColor}
                textColor={button?.textColor}
                alignment={button?.alignment}
                text={button.text} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const getEditableFields = (
  editPath: string,
  backgroundColor: string | null | undefined,
  backgroundImage: BackgroundImage | null | undefined,
  theme: ThemeTypeEnum | null | undefined,
  button: LinkType | null | undefined,
  padding: PaddingEnum | undefined | null,
  images: ImageObject[] | null | undefined,
  imagesPath: string | null | undefined,
  currentImageIndex?: number | null
) => {
  const fields: ModuleField[] = [
    getSectionPaddingModule(editPath, padding ?? undefined),
    getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage),
    getThemeModule(editPath, theme),
    {
      displayName: 'Button',
      type: FieldType.Boolean,
      path: `${editPath}.heroButton`,
      value: !!button,
      activeValue: { text: 'Button', type: 'primary', link: '/' }
    },
    ...(images || []).length > 1 && currentImageIndex != null ?
      getImageModules(
        `${imagesPath}[${currentImageIndex}]`,
        images?.[currentImageIndex],
        { adminLocation: 'action panel', deletable: false }
      )
      : []
  ];

  return fields;
};

export default InsetHero;
