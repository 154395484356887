import { useCallback, useMemo, useRef, useState } from 'react';

import parseISO from 'date-fns/parseISO';

import {
  MenuApiType,
  // eslint-disable-next-line camelcase
  Menus_VisibilityEnum,
  RestaurantQuery,
  DateTimeRange,
  usePaginatedMenuItemsQuery,
  usePaginatedMenuItemsWithPopularItemsQuery,
  PaginatedMenuItemsWithPopularItemsQuery,
  PaginatedMenusInput,
  PaginatedMenuItemsQuery
} from 'src/apollo/onlineOrdering';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { getItemsFlat } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';

import { useOptionalFulfillment } from './FulfillmentContext';

export type Restaurant = RestaurantQuery['restaurantV2'] & { __typename: 'Restaurant' };
export type MenusResponse = PaginatedMenuItemsQuery['paginatedMenuItems']
export type Menus = NonNullable<MenusResponse['menus']>;
export type MenuGroups = Menus[0]['groups'] & { __typename: 'MenuGroup' };

const PAGE_SIZE = 100;

type Props = {
  respectAvailability?: boolean;
  channelGuid?: string | null;
  skipPopularItems?: boolean;
  skip?: boolean;
  requirePagination?: boolean;
};

export const useOOMenus = ({ respectAvailability = true, channelGuid, skipPopularItems = false, skip = false, requirePagination = false }: Props) => {
  const fulfillmentContext = useOptionalFulfillment();
  const fulfillmentData = fulfillmentContext?.fulfillmentData;
  const { selectedLocation, restaurant, ooRestaurant } = useRestaurant();
  const hasMore = useRef(true);
  const hasNewFilters = useRef(false);
  const [filters, _setFilters] = useState<Set<string>>(new Set());
  const shortUrl = selectedLocation.shortUrl;
  const restaurantGuid = selectedLocation.externalId;
  const ooOnly = restaurant.config.isOnlineOrderingOnly ?? true;
  const hasOOModule = ooRestaurant?.hasOnlineOrderingModule ?? true;
  const shouldSkip = skip || ooOnly && !hasOOModule;

  const setFilters: typeof _setFilters = useCallback(arg => {
    hasMore.current = true;
    hasNewFilters.current = true;
    _setFilters(arg);
  }, []);

  const baseInput = useMemo(() => ({
    restaurantGuid,
    menuApi: MenuApiType.Do,
    // eslint-disable-next-line camelcase
    visibility: Menus_VisibilityEnum.ToastOnlineOrdering,
    respectAvailability,
    hideOutOfStockItems: restaurant.config.ooConfig?.hideOutOfStockItems ?? false
  }), [restaurantGuid, respectAvailability, restaurant]);

  const menusInput: PaginatedMenusInput = useMemo(() => ({
    ...baseInput,
    dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime,
    channelGuid,
    offset: 0,
    filters: filters.size > 0 ? Array.from(filters).map(filter => filter.split(':')) : null,
    requirePagination
  }), [baseInput, filters, fulfillmentData, channelGuid, requirePagination]);

  const {
    data: menuDataResp,
    error: menuErr,
    loading: menuLoading,
    fetchMore: menuFetchMore,
    previousData: menuPreviousData,
    networkStatus: menuNetworkStatus
  } = usePaginatedMenuItemsQuery({
    variables: { input: menusInput },
    skip: !restaurantGuid || !shortUrl || shouldSkip || !skipPopularItems,
    fetchPolicy: typeof window === 'undefined' ? 'network-only' : 'cache-and-network',
    onCompleted() {
      hasNewFilters.current = false;
    }
  });

  const {
    data: popularItemsData,
    loading: popularItemsLoading,
    fetchMore: popularItemsFetchMore,
    previousData: popularItemsPreviousData,
    networkStatus: popularItemsNetworkStatus
  } = usePaginatedMenuItemsWithPopularItemsQuery({
    variables: {
      input: menusInput,
      popularItemsInput: {
        menusInput: baseInput,
        restaurantGuid: restaurantGuid
      }
    },
    skip: !restaurantGuid || !shortUrl || skipPopularItems || shouldSkip,
    fetchPolicy: typeof window === 'undefined' ? 'network-only' : 'cache-and-network',
    onCompleted() {
      hasNewFilters.current = false;
    }
  });

  // Leverage the previous data from each query so we can continue to render data even while parameters are changing
  const menuData = menuDataResp || popularItemsData || menuPreviousData || popularItemsPreviousData;

  const _fetchMore = menuFetchMore || popularItemsFetchMore;
  const fetchMore = useCallback((offset: number) => {
    if(!hasMore.current) {
      return;
    }

    _fetchMore({
      variables: { input: { ...menusInput, offset } },
      updateQuery: (prev, { fetchMoreResult }) => {
        if(!fetchMoreResult) return prev;

        if(!fetchMoreResult.paginatedMenuItems.items || fetchMoreResult.paginatedMenuItems.items.length < PAGE_SIZE) {
          hasMore.current = false;
        }

        return Object.assign({}, prev, {
          paginatedMenuItems: {
            items: [...prev.paginatedMenuItems.items || [], ...fetchMoreResult.paginatedMenuItems.items || []],
            groupings: prev.paginatedMenuItems.groupings,
            totalCount: menuData?.paginatedMenuItems.totalCount,
            menus: null,
            count: fetchMoreResult.paginatedMenuItems.count
          }
        });
      }
    });
  }, [_fetchMore,
    menusInput,
    menuData?.paginatedMenuItems?.totalCount]);


  if(shouldSkip) {
    return { menus: [], popularItems: [] };
  }

  const loading = menuLoading && (!menuData && !menuPreviousData) || popularItemsLoading && (!popularItemsData && !popularItemsPreviousData);
  if(loading) {
    return { loading: true };
  }

  if(menuErr) {
    return { error: menuErr };
  }

  const menuWrapper: MenusResponse | undefined = menuData?.paginatedMenuItems as MenusResponse | undefined;
  const menus = menuWrapper?.menus
    ?.filter(menu => menu.groups?.length
      && menu.groups.some(group => group?.items?.length));

  const shouldPreorderItemBeVisible = (item: MenuItem | null) => {
    const preorderRule = item?.timeBasedRules?.preorderRule;
    if(!preorderRule) {
      return true;
    }
    const { start, end } = preorderRule.orderDateRange as DateTimeRange;
    const now = new Date(Date());
    return parseISO(start) <= now && (!end || parseISO(end) >= now);
  };

  // **TEMPORARY**
  // Filter out any items with preorder rules whose orderDateRanges are in the past or the future
  let mappedMenus: Menus | undefined = menus?.map(menu => ({
    ...menu,
    groups: menu.groups?.map(group => {
      if(!group?.items) {
        return group;
      }
      return {
        ...group,
        items: group.items.filter(item => shouldPreorderItemBeVisible(item))
      };
    })
  }));

  if(mappedMenus?.every(menu => menu.groups?.length === 1)) {
    const menuGroups: MenuGroups = mappedMenus
      .flatMap(menu => menu.groups)
      .filter(Boolean) as MenuGroups;

    mappedMenus = [{
      name: 'Menu',
      guid: 'Menu',
      groups: menuGroups
    }];
  }

  // network status 2 indicates that variables have changed; loadingNewFilterData should only be true if the queries wrapped in this hook
  // are returning new data because filters have changed
  const loadingNewFilterData = (menuLoading && menuNetworkStatus === 2 || popularItemsLoading && popularItemsNetworkStatus === 2) && hasNewFilters.current;

  if(skipPopularItems) return {
    menus: mappedMenus,
    menuItems: menuData?.paginatedMenuItems.items,
    groupings: menuData?.paginatedMenuItems.groupings,
    fetchMore,
    filters,
    setFilters,
    loading,
    loadingNewFilterData
  };

  const popularItemsQuery: PaginatedMenuItemsWithPopularItemsQuery | undefined = popularItemsData;
  let popularItems: Array<MenuItem> = [];
  if(popularItemsQuery?.popularItems && 'items' in popularItemsQuery.popularItems) {
    popularItems = popularItemsQuery.popularItems.items;
  }

  if(selectedLocation.featuredItems?.enabled && !selectedLocation.featuredItems?.useDefaults && mappedMenus) {
    const menuItemMasterIds = new Set(selectedLocation.featuredItems.menuItemMasterIds);
    const items = getItemsFlat(mappedMenus);

    popularItems = [...items.filter(item => menuItemMasterIds.has(item.masterId)), ...popularItems.filter(item => !menuItemMasterIds.has(item.masterId || ''))].slice(0, 3);
  }

  // **TEMPORARY**
  // Filter out any popular items with preorder rules whose orderDateRanges are in the past or the future
  popularItems = popularItems.filter(item => shouldPreorderItemBeVisible(item));

  return {
    menus: mappedMenus,
    popularItems,
    menuItems: menuData?.paginatedMenuItems.items,
    groupings: menuData?.paginatedMenuItems.groupings,
    fetchMore,
    filters,
    setFilters,
    loading,
    loadingNewFilterData
  };
};
