import React, { useCallback } from 'react';

import useTracker from 'src/lib/js/hooks/useTracker';

import { SubmitButton } from 'public/components/default_template/online_ordering/checkout/SubmitOrderButton';
import { useCart } from 'public/components/online_ordering/CartContext';

const ContinuePaymentButton = ({ enableIntlPayment }: {enableIntlPayment: () => void}) => {
  const { cartGuid } = useCart();
  const tracker = useTracker();

  const buttonOnClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    tracker.track('International continue to payment clicked', { cartGuid });
    enableIntlPayment();
  }, [cartGuid, tracker, enableIntlPayment]);

  return <SubmitButton dataTestid="continueButtonPayment" label="Continue to payment" onClick={buttonOnClick} />;
};

export default ContinuePaymentButton;
