import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ContainsEditableProps, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { BackgroundImage, Image, PaddingEnum } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { LocationSelector } from 'src/shared/components/common/form_input/LocationInput';
import LoadingSpinnerOverlay from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { getBackgroundColorOrImageModule, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';
import { ModalCloseButton } from 'shared/components/common/modal';
import { SiteRestaurantLocation } from 'shared/js/types';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { CustomerContextProvider } from 'public/components/online_ordering/CustomerContext';
// eslint-disable-next-line no-restricted-imports
import { useReservationContext } from 'public/components/online_ordering/ReservationContext';

// eslint-disable-next-line no-restricted-imports
import { EncryptedCreditCardData } from '../online_ordering/checkout/payment/CreditCardForm';
import CancellationConfirmation from './CancellationConfirmation';
import DepositPayment from './DepositPayment';
import GuestSelector from './GuestSelector';
import ReservationConfirmation from './ReservationConfirmation';
import ReservationError from './ReservationError';
import ReservationForm from './ReservationForm';
import DateTimeSelector from './TimeSelector';
import {
  Deposit,
  formatNumericDateString,
  formatTimeString,
  getDateOptions,
  getHoursListFromReservationHours,
  getSeatingOptionsFromAvailabilities,
  getSelectedDateTime,
  isBookingInAdvance,
  SeatingLocation,
  SeatingOption,
  type SelectReservation
} from './reservationUtils';

const MIN_LOADING_TIME = 300;

type Props = {
  image?: string | null;
  // If imageObject is passed, the image will be ingored.
  imageObject?: Image | null;
  imageObjectPath?: string;
  backgroundColor?: string | null;
  backgroundImage?: BackgroundImage | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

type ReservationProps = {
  isModal?: boolean;
};

export type BasicDetailsProps = {
  locations?: SiteRestaurantLocation[] | null;
  selectedResLocation: SiteRestaurantLocation;
  setSelectedResLocation: (arg0: string) => void;
  maxPartySize: number;
  minPartySize: number;
  numGuests: number;
  setNumGuests: (arg0: number) => void;
  selectedDate: Date | null;
  setSelectedDate: (arg0: Date) => void;
  timeOptions: Date[] | null;
  selectedTime: Date | null;
  setSelectedTime: (arg0: Date) => void;
  timezone: string;
  datesLoading?: boolean;
};

export type SelectSeatingProps = {
  options: SeatingOption[];
  selectReservation: SelectReservation;
  selectedLocation: SeatingLocation | null;
  selectedTime: Date | null;
  timezone: string;
};

export const BasicDetails = ({
  locations, selectedResLocation, setSelectedResLocation, maxPartySize, minPartySize, timezone,
  numGuests, setNumGuests, selectedDate, setSelectedDate, timeOptions, selectedTime, setSelectedTime
}: BasicDetailsProps) => {
  const dateOptions = useMemo(() => {
    if(timezone) {
      return getDateOptions(timezone);
    } else {
      return [];
    }
  }, [timezone]);

  return (
    <div data-testid="reservation-basic-details">
      {(locations?.length || 0) > 1 ?
        <div className="locationSection">
          <h3 className="sectionTitle">Which location?</h3>
          <div className="resLocationSelector">
            <div className="dropDownWrapper">
              <LocationSelector
                id="reservation-location-selector"
                locations={locations}
                selectedLocation={selectedResLocation}
                setSelectedLocation={setSelectedResLocation}
                withBorder
                leftAligned />
            </div>
          </div>
        </div>
        : null}
      <div className="guestSection">
        <h3 className="sectionTitle">How many guests?</h3>
        <GuestSelector
          numGuests={numGuests}
          setNumGuests={setNumGuests}
          maxPartySize={maxPartySize}
          minPartySize={minPartySize} />
      </div>
      <DateTimeSelector
        dateOptions={dateOptions}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        timezone={timezone} />
    </div>
  );
};

export const SelectSeating = ({ options, selectReservation, selectedLocation, selectedTime, timezone }: SelectSeatingProps) => {
  if(options.length < 1) {
    return (
      <div data-testid="reservation-select-seating">
        <h3 className="titleSection">Sorry!</h3>
        <div className="description">Looks like there are no reservations available near this time. Try selecting a different time.</div>
      </div>
    );
  }

  return (
    <div data-testid="select-seating">
      {options.map((locationOption, index) => {
        return (
          <div className={classnames('seatingLocationWrapper', { withoutBorder: index === options.length - 1 })} key={locationOption.location.guid}>
            <h3 className="seatingLocation">{locationOption.location.name}</h3>
            <div className="timeOptions" role="group" aria-label={ `Reservation times for ${locationOption.location.name}` }>
              {locationOption.times.map(timeWithDeposit => {
                const time = timeWithDeposit.time;
                return (
                  <div className="timePillContainer" key={formatTimeString(time, timezone)}>
                    <button type="button"
                      className={classnames('timePill', { selected: locationOption.location.guid === selectedLocation?.guid && time === selectedTime })}
                      onClick={() => {
                        selectReservation(locationOption.location, time, timeWithDeposit.deposit);
                      }}>
                      {formatTimeString(time, timezone)}
                    </button>
                    {timeWithDeposit.deposit?.actualAmount &&
                      <span>${timeWithDeposit.deposit.actualAmount % 1 !== 0 ? timeWithDeposit.deposit.actualAmount.toFixed(2) : timeWithDeposit.deposit.actualAmount} deposit</span>}
                  </div>
                );
              })}
            </div>
            <hr />
          </div>
        );
      })}
    </div>
  );
};

const Reservation = ({ isModal }: ReservationProps) => {
  const {
    availHours,
    availabilitiesLoading,
    bookingMinHoursInAdvance,
    datesLoading,
    getReservationHours,
    getReservationAvailabilitiesHours,
    getReservationRestaurant,
    hours,
    locations,
    loyaltySignupUrl,
    minPartySize,
    maxPartySize,
    reservationPolicy,
    reservationRestaurantLoading,
    selectedLocation,
    timezone
  } = useReservationContext();
  const [step, setStep] = useState(0);
  const [reservationLoading, setReservationLoading] = useState<boolean>(false);
  const [selectedResLocation, setSelectedResLocation] = useState(selectedLocation);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [dropdownTimeOptions, setDropdownTimeOptions] = useState<Date[] | null>(null);
  const [selectedDropdownTime, setSelectedDropdownTime] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedSeatingLocation, setSelectedSeatingLocation] = useState<SeatingLocation | null>(null);
  const [seatingOptions, setSeatingOptions] = useState<SeatingOption[]>([]);
  const [deposit, setDeposit] = useState<Deposit | null>(null);
  const [reservationGuid, setReservationGuid] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [cancelMessage, setCancelMessage] = useState<string>('Your reservation has been canceled.');
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const [submittedOccasion, setSubmittedOccasion] = useState<string>('');
  const [creditCardData, setCreditCardData] = useState<EncryptedCreditCardData | null>(null);
  const [numGuests, setNumGuests] = useState(minPartySize <= 2 && maxPartySize >= 2 ? 2 : minPartySize);

  const timeoutKey = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Clean up the timeout call on dismount, if needed.
  useEffect(() => {
    return () => {
      if(timeoutKey.current) {
        clearTimeout(timeoutKey.current);
      }
    };
  }, []);

  useEffect(() => {
    if(selectedResLocation) {
      getReservationRestaurant({ variables: { restaurantGuid: selectedResLocation.externalId } });
    }
  }, [selectedResLocation, getReservationRestaurant]);

  useEffect(() => {
    if(!selectedDate) return;
    const hoursResult = hours;
    const hoursOptions: Date[] = getHoursListFromReservationHours(hoursResult?.reservationHours || [], timezone, datesLoading);
    timeoutKey.current = setTimeout(() => {
      timeoutKey.current = null;
      setDropdownTimeOptions(hoursOptions);
    }, MIN_LOADING_TIME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, timezone]);

  useEffect(() => {
    setSeatingOptions(getSeatingOptionsFromAvailabilities(availHours?.reservationAvailabilities || []));
    if(selectedDate && selectedDropdownTime) {
      timeoutKey.current = setTimeout(() => {
        timeoutKey.current = null;
        setStep(1);
      }, MIN_LOADING_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availHours]);

  useEffect(() => {
    if(!selectedDate || !selectedDropdownTime) {
      return;
    }
    // Get updated availability hours.
    getReservationAvailabilitiesHours({
      variables: {
        restaurantGuid: selectedResLocation.externalId,
        dateTime: getSelectedDateTime(selectedDate, selectedDropdownTime).toISOString(),
        partySize: numGuests
      }
    });
    // Leave selectedDate off the list of deps since changing selectedDate will always lead to updaing selectedDropdownTime, and we don't want to update this twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropdownTime, numGuests, selectedResLocation.externalId]);

  const changeSelection = useCallback(() => {
    if(step === 2) {
      setStep(step - 1);
    } else {
      setStep(2);
    }
  }, [step]);

  const handleSelectDateClick = useCallback((date: Date) => {
    setStep(0);
    setSelectedDate(date);
    setSelectedDropdownTime(null);
    getReservationHours({
      variables: {
        restaurantGuid: selectedResLocation.externalId,
        date: formatNumericDateString(date, timezone)
      }
    });
  }, [getReservationHours, timezone, selectedResLocation.externalId]);

  const handleSelectTimeDropdownClick = useCallback((time: Date) => {
    setSelectedDropdownTime(time);
  }, [setSelectedDropdownTime]);

  const onResError = useCallback((err: string) => {
    if(step == 1) {
      if(err.includes('in advance')) {
        setErrorMessage(
          `Online reservations must be made at least ${bookingMinHoursInAdvance} hours before the reservation start time.`
        );
      }
      timeoutKey.current = setTimeout(() => {
        timeoutKey.current = null;
        setReservationLoading(false);
        setStep(4);
      }, MIN_LOADING_TIME);
    } else if(step == 2) {
      if(err.includes('overlapping')) {
        setErrorMessage(
          'It looks like you’re trying to make a reservation that overlaps with an existing reservation. You can cancel your existing reservation or select a time that does not overlap.'
        );
      } else {
        setErrorMessage('Something went wrong. We couldn’t save your info.');
      }
      timeoutKey.current = setTimeout(() => {
        timeoutKey.current = null;
        setReservationLoading(false);
        setStep(4);
      }, MIN_LOADING_TIME);
    }
  }, [step, bookingMinHoursInAdvance]);

  const selectReservation: SelectReservation = useCallback((location, dateTime, deposit) => {
    setSelectedTime(dateTime);
    setSelectedSeatingLocation(location);
    setDeposit(deposit || null);

    if(bookingMinHoursInAdvance && !isBookingInAdvance(dateTime, bookingMinHoursInAdvance)) {
      onResError('in advance');
    }
  }, [setSelectedTime, setSelectedSeatingLocation, bookingMinHoursInAdvance, onResError]);

  useEffect(() => {
    if(selectedSeatingLocation && selectedTime && step < 2) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeatingLocation, selectedTime]);

  const selectReservationLocation = useCallback((guid: string) => {
    locations?.forEach(loc => {
      if(loc.externalId === guid) {
        setSelectedResLocation(loc);
      }
    });
  }, [locations, setSelectedResLocation]);

  const onResCreated = useCallback((detailsHaveChanged: boolean) => {
    if(detailsHaveChanged) {
      // Details shouldn't change in the making of a reservation, report this error so we can handle it if it starts happening.
      reportError('Details changed while making a reservation.');
    }

    timeoutKey.current = setTimeout(() => {
      timeoutKey.current = null;
      setReservationLoading(false);
    }, MIN_LOADING_TIME);
    if(deposit) {
      setStep(6);
    } else {
      setStep(3);
    }
  }, [setStep, deposit]);

  const onDepositPaid = useCallback(() => {
    timeoutKey.current = setTimeout(() => {
      timeoutKey.current = null;
      setReservationLoading(false);
    }, MIN_LOADING_TIME);
    setStep(3);
  }, [setStep]);

  const onCancellation = useCallback((isSuccessful: boolean, isDepositExpired?: boolean | null) => {
    if(step == 3 && isSuccessful && isDepositExpired === null) {
      setStep(5);
    } else if(step == 3 && isDepositExpired === null) {
      setErrorMessage('Unfortunately, we\'re experiencing technical difficulties and are unable to process your cancellation request. '
        + 'Please contact the restaurant to cancel your reservation or try again later.');
      setStep(4);
    } else if(step == 6 && isDepositExpired) {
      setCancelMessage('Your booking has been canceled as the time to pay the deposit has expired.');
      setStep(5);
    }
  }, [step, setStep]);

  const restart = useCallback(() => {
    setStep(0);
    setErrorMessage('');
  }, [setStep, setErrorMessage]);

  const getTitle = useMemo((): string => {
    switch(step) {
      case 3: return 'All set!';
      case 4: return 'There’s an issue with your selection';
      case 5: return 'Canceled';
      default: return 'Book a reservation';
    }
  }, [step]);

  if(reservationRestaurantLoading) {
    return <LoadingSpinnerOverlay />;
  }

  return (
    <div className={classnames('cardSection', { isModal: isModal })} data-testid="reservation-component" aria-live="polite">
      {reservationLoading || datesLoading || availabilitiesLoading ? <LoadingSpinnerOverlay /> : null}
      {isModal && <ModalCloseButton className="reservationModalCloseButton" />}
      <div className="titleSection" data-testid="titleSection">
        <h3 id={'reservation-modal-title'}>{getTitle}</h3>
      </div>
      <AnimatedSection expanded={step < 2}>
        <div className="formSection">
          <BasicDetails locations={locations} selectedResLocation={selectedResLocation} setSelectedResLocation={selectReservationLocation}
            numGuests={numGuests} setNumGuests={setNumGuests} timezone={timezone}
            selectedDate={selectedDate} setSelectedDate={handleSelectDateClick}
            timeOptions={dropdownTimeOptions} selectedTime={selectedDropdownTime} setSelectedTime={handleSelectTimeDropdownClick}
            maxPartySize={maxPartySize} minPartySize={minPartySize} datesLoading={datesLoading} />
        </div>
      </AnimatedSection>
      <AnimatedSection className="selectSeating" expanded={step === 1}>
        <SelectSeating options={seatingOptions} selectReservation={selectReservation} selectedLocation={selectedSeatingLocation} selectedTime={selectedTime} timezone={timezone} />
      </AnimatedSection>
      <AnimatedSection className="customerInfo" expanded={step === 2}>
        <CustomerContextProvider>
          <ReservationForm restaurantGuid={selectedResLocation.externalId}
            numGuests={numGuests}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            deposit={deposit}
            changeSelection={changeSelection}
            serviceArea={selectedSeatingLocation}
            cancellationPolicy={reservationPolicy}
            timezone={timezone}
            setNumGuests={setNumGuests}
            setSelectedDate={handleSelectDateClick}
            setSelectedTime={setSelectedTime}
            setSelectedSeatingLocation={setSelectedSeatingLocation}
            setReservationGuid={setReservationGuid}
            onResCreated={onResCreated}
            onResError={onResError}
            setIsLoading={setReservationLoading}
            setSubmittedOccasion={setSubmittedOccasion}
            setSubmittedEmail={setSubmittedEmail}
            restaurantLocationState={selectedResLocation.state} />
        </CustomerContextProvider>
      </AnimatedSection>
      <AnimatedSection className="confirmation" expanded={step === 3}>
        <ReservationConfirmation restaurantGuid={selectedResLocation.externalId} reservationGuid={reservationGuid} numGuests={numGuests} selectedDate={selectedDate} selectedTime={selectedTime}
          serviceArea={selectedSeatingLocation} cancellationPolicy={reservationPolicy} deposit={deposit}
          loyaltySignupUrl={loyaltySignupUrl} onCancellation={onCancellation} timezone={timezone} occasion={submittedOccasion}
          creditCardData={creditCardData} />
      </AnimatedSection>
      <AnimatedSection className="error" expanded={step === 4}>
        <ReservationError errorMessage={errorMessage} restart={restart} />
      </AnimatedSection>
      <AnimatedSection className="cancelled" expanded={step === 5}>
        <CancellationConfirmation cancelMessage={cancelMessage} loyaltySignupUrl={loyaltySignupUrl} />
      </AnimatedSection>
      <AnimatedSection className="payment" expanded={step === 6}>
        <DepositPayment restaurantGuid={selectedResLocation.externalId} reservationGuid={reservationGuid} numGuests={numGuests} selectedDate={selectedDate} selectedTime={selectedTime}
          changeSelection={changeSelection} deposit={deposit} serviceArea={selectedSeatingLocation} timezone={timezone} onCancellation={onCancellation} occasion={submittedOccasion} step={step}
          creditCardData={creditCardData} setCreditCardData={setCreditCardData} onDepositPaid={onDepositPaid} email={submittedEmail} />
      </AnimatedSection>
    </div>
  );
};

export default Reservation;

export const ReservationWidget = ({ editPath, image, imageObject, imageObjectPath, backgroundColor, backgroundImage, padding = PaddingEnum.Medium }: Props) => {
  const { useEditableRef } = useEditor();
  const pathToImg = `${editPath}.img`;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'reservation section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorOrImageModule(editPath, backgroundColor, backgroundImage)
      ]
    },
    path: editPath
  });
  const backgroundColorStyle = backgroundColor ? { backgroundColor: backgroundColor, zIndex: 0 } : {};

  return (
    <div ref={editableRef} className="reservation" style={backgroundColorStyle} data-testid="reservation-module">
      {image ?
        <div className="backgroundImage">
          <_Image alt={imageObject?.altText ?? ''} imageObject={imageObject} imageObjectPath={imageObjectPath} src={image} editPath={pathToImg} />
        </div>
        : null}
      <Reservation />
    </div>
  );
};
