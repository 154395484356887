import { RestaurantConfig } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';
import { isToastOrderRequest } from 'src/public/js/siteUtilities';

import ToastProduct from 'public/components/online_ordering/ToastProduct';

export const isPreviewSite = (staticContext: RequestContext | undefined, restaurantConfig?: RestaurantConfig, product?: ToastProduct): boolean => {
  return restaurantConfig?.previewMode === true &&
  // If we are on an OO path, we don't want to show the preview mode because the rx site might be in preview but they are actively
  // using their online ordering site
  (product === ToastProduct.Sites || product === ToastProduct.OOPro) && !isToastOrderRequest(staticContext);
};
