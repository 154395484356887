import React from 'react';

import { MenuTemplate } from 'src/apollo/sites';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { PlaceHolderMenuImage } from './PlaceHolderMenuImage';
import { TemplateOverrides } from './menuStylingOverrides';

interface Props {
  className?: string;
  imageSize?: number;
  templateOverrides?: TemplateOverrides;
}

export const DEFAULT_IMAGE_SIZE = 200;

export const DefaultPlaceHolderMenuImage: React.FC<Props> = props => {
  const { restaurant: { name, meta: { fontFamily } } } = useRestaurant();
  const { className, templateOverrides } = props;
  const menuTemplateConfig = templateOverrides?.menuTemplateOverride;

  const imageSize = props.imageSize ?? menuTemplateConfig === MenuTemplate.TopImageV2 ? 420 : DEFAULT_IMAGE_SIZE;
  const text = templateOverrides?.placeholderImageConfig.placeholderImageText || name;

  return (
    <PlaceHolderMenuImage
      className={className}
      text={text}
      textColor={'rgba(0, 0, 0, .15)'}
      fontFamily={fontFamily?.name}
      fontSize={'16px'}
      rotation={-29}
      height={imageSize}
      width={imageSize}
      alpha={0.2} />
  );
};
