import React, { useEffect, useMemo, useRef } from 'react';

import { ContainsEditableProps, useEditor, FieldType, ModuleField } from '@toasttab/sites-components';

import { PaddingEnum, Image as ImageObject, VideoConfig } from 'src/apollo/sites';
import { getSectionPaddingModule } from 'src/shared/components/common/editor_context/editableUtils';

import { getImageUrl } from 'shared/components/common/Image';

import { resources } from 'config';

type Props = {
  imageObject?: ImageObject | null;
  imageObjectPath?: string | null;
  videoSrc: string;
  videoConfig?: VideoConfig | null;
  padding?: PaddingEnum | null;
  videoDescription?: string | null;
} & ContainsEditableProps;

const DO_PREFIX_REGEX = new RegExp(/^https:\/\/toast-sites(-prod)?.nyc3.cdn.digitaloceanspaces.com\//);

const VideoHero = ({ editPath, videoSrc, videoConfig, videoDescription, padding = PaddingEnum.Medium, imageObject, imageObjectPath }: Props) => {
  const { useEditableRef } = useEditor();
  const maxAriaLen = 400;
  const videoRef = useRef<HTMLVideoElement>(null);


  const { editableRef, pushSchema } = useEditableRef<HTMLDivElement>({
    name: 'hero section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        {
          path: `${editPath}.video`,
          type: FieldType.Video,
          displayName: 'Video',
          value: videoSrc
        },
        {
          path: `${editPath}.videoConfig.captionFile`,
          type: FieldType.CaptionFile,
          displayName: 'Video Caption Upload',
          value: videoConfig?.captionFile
        },
        {
          path: imageObjectPath || `${editPath}.images[0]`,
          type: FieldType.Image,
          displayName: 'Poster Image',
          value: imageObject?.src
        },
        {
          path: `${editPath}.ariaText`,
          displayName: 'Aria Text',
          type: FieldType.Text,
          value: videoDescription || '',
          validation: { maxLen: maxAriaLen }
        },
        {
          path: `${editPath}.videoConfig.autoplay`,
          type: FieldType.Boolean,
          displayName: 'Play Automatically',
          value: videoConfig?.autoplay || true
        },
        ...videoConfig?.autoplay === false
          ? [{
            path: `${editPath}.videoConfig.muted`,
            type: FieldType.Boolean,
            displayName: 'Muted',
            value: videoConfig?.muted || true
          } as ModuleField]
          : []
      ]
    }
  });
  const isMuted = useMemo(() => videoConfig?.autoplay !== false || videoConfig?.muted !== false, [videoConfig?.autoplay, videoConfig?.muted]);

  useEffect(() => {
    pushSchema();
  }, [pushSchema, videoConfig?.autoplay]);

  useEffect(() => {
    // We must assign the `muted` attribute via DOM primatives.
    // Toggling muted on and off via JSX fails in Chrome.
    const video = videoRef.current;
    if(video) {
      video.muted = isMuted;
    }
  }, [isMuted, editPath]);

  const videoDisplaySrc = useMemo(() => videoSrc?.replace(DO_PREFIX_REGEX, resources.publicAssetURL), [videoSrc]);

  useEffect(() => {
    const video = videoRef.current;
    if(video) {
      video.setAttribute('src', getImageUrl(videoDisplaySrc) as string);
      video.load();
    }
    return () => {
      /** We must carefully dismount the video element when dismounting the component
       *  (particularly in the editor), otherwise sound can play even after
       *  the video is removed from the DOM and the video will continue to take up memory.
       **/
      if(video) {
        video.pause();
        video.removeAttribute('src');
        video.load();
      }
    };
  }, [videoDisplaySrc]);

  const trackSrc = getImageUrl(videoConfig?.captionFile);

  return (
    <div ref={editableRef} className="hero" data-testid="video-hero-wrapper">
      <video ref={videoRef} src={getImageUrl(videoDisplaySrc)} id={`video-${editPath}`} crossOrigin="anonymous"
        poster={getImageUrl(imageObject?.displaySrc || imageObject?.src, imageObject?.displaySrc ? resources.imagePrefix : null)}
        muted={isMuted} autoPlay={videoConfig?.autoplay ?? true} loop playsInline controls className="video" aria-label={videoDescription || 'User-uploaded video'}
        data-testid="video-hero" >
        {trackSrc !== undefined ? <track label="English" srcLang="en" kind="subtitles" src={trackSrc} default /> : null }
      </video>
    </div>
  );
};

export default VideoHero;
