import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import useTracker from 'src/lib/js/hooks/useTracker';
import { NearbyRestaurantSearchResultRestaurantType } from 'src/public/components/default_template/restaurant_profile/profileUtils';

import Image from 'shared/components/common/Image';

import { resources } from 'config';

import RXConfig from './RXConfig';

export const formatRXName = (name: string): string => {
  return name.replace(/\\r\\n/g, ' - ');
};

const genericRXImage = {
  src: 'toastLocal/common/generic-restaurant-1102.png',
  srcSet: [
    'toastLocal/common/generic-restaurant-276.png 276w',
    'toastLocal/common/generic-restaurant-551.png 551w',
    'toastLocal/common/generic-restaurant-1102.png 1102w'
  ],
  alt: 'Restaurant banner'
};

const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean; wrapper: (n: React.ReactNode) => JSX.Element; children: JSX.Element}): JSX.Element =>
  condition ? wrapper(children) : children;

export const hasOORXProfile = (rx: NearbyRestaurantSearchResultRestaurantType) => rx.hasOnlineOrderingModule && rx.shortUrl;

type Props = {
  restaurant: NearbyRestaurantSearchResultRestaurantType;
  utmContent?: string;
  className?: string;
}

const RXCard = ({ restaurant, className }: Props) => {
  const tracker = useTracker();

  const imageData = useMemo(() => {
    if(restaurant.imageUrl) {
      return { src: restaurant.imageUrl, cdnHost: resources.tlAssetUrl };
    }
    return { ...genericRXImage };
  }, [restaurant.imageUrl]);

  const useOrderPage = useMemo(() => hasOORXProfile(restaurant), [restaurant]);

  const ooURLTracking = useMemo(
    () => useOrderPage
      ? `/local/order/${restaurant.shortUrl}/r-${restaurant.guid}`
      : '',
    [useOrderPage, restaurant]
  );

  return (
    <div className={classnames('rx-card-container', !ooURLTracking && 'disabled', className)}>
      <ConditionalWrapper condition={!!ooURLTracking} wrapper={children =>
        <Link to={{ pathname: ooURLTracking, state: { fromSearch: true } }} className="oo-link" onClick={() => tracker.page(ooURLTracking)}>
          {children}
        </Link> } >
        <div className="rx-card-wrapper">
          <div className="rx-card">
            <div className="rx-image-wrapper">
              {imageData && <Image alt="" {...imageData} className="rx-image" />}
            </div>

            <div className="rx-details">
              <div className="rx-header">
                <h3 className="rx-name">{formatRXName(restaurant.name || '')}</h3>
                <RXConfig config={restaurant} />
              </div>
            </div>
          </div>
        </div>
      </ConditionalWrapper>
      {useOrderPage ?
        <Link to={{ pathname: ooURLTracking, state: { fromSearch: true } }} className="link-to-rx-profile"> More about {restaurant.name ? formatRXName(restaurant.name) : 'this restaurant'}</Link> :
        null}
    </div>
  );
};


export default RXCard;
