import React from 'react';

import cx from 'classnames';

import { NearbyRestaurantSearchResultRestaurantType } from 'src/public/components/default_template/restaurant_profile/profileUtils';

import Image from 'shared/components/common/Image';

import { TagIcon } from 'public/components/default_template/offers/OffersIcons';
import { MenuItemTag } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';

type Props = {
  config?: NearbyRestaurantSearchResultRestaurantType
}

const RXConfig = ({ config }: Props) => {
  const hasMenuTag = config?.promoBanners?.__typename === 'PromoBannerResponse' && config?.promoBanners?.promoCodeBanners.length > 0;
  return (
    <div className={cx('rx-config', { 'has-menu-tag': hasMenuTag })}>
      {hasMenuTag && <MenuItemTag tagColor="#FF4B00" textColor="#ffffff" Icon={TagIcon}>OFFERS</MenuItemTag>}
      <div>
        {config?.hasOnlineOrderingModule ? <Image src="toastLocal/icons/takeout-gray.svg" alt="Takeout" /> : null}
        {config?.deliveryServiceAvailability.available ? <Image src="toastLocal/icons/delivery-gray.svg" alt="Delivery" /> : null}
      </div>
    </div>
  );
};

export default RXConfig;
