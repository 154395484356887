import React from 'react';

import CssFilterConverter from 'css-filter-converter';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';

type Props = {
  url: string;
  color?: string;
};

// Warning, this uses partial string matches and it may cause unexpected
// problems if there are any conflicts in social domains.
const SocialLink = ({ url, color }: Props) => {
  let alt = null;
  let src = null;
  const formattedUrl = url.toLowerCase();
  if(formattedUrl.includes('instagram.com')) {
    alt = 'Instagram logo';
    src = 'icons/instagram-white.svg';
  } else if(formattedUrl.includes('twitter.com') || formattedUrl.includes('x.com')) {
    alt = 'X logo (formerly Twitter)';
    src = 'icons/x-white-1.svg';
  } else if(formattedUrl.includes('pinterest.com')) {
    alt = 'Pinterest logo';
    src = 'icons/pinterest-white.svg';
  } else if(formattedUrl.includes('facebook.com')) {
    alt = 'Facebook logo';
    src = 'icons/facebook-white.svg';
  } else if(formattedUrl.includes('yelp.com')) {
    alt = 'Yelp logo';
    src = 'icons/yelp-white.svg';
  } else if(formattedUrl.includes('tripadvisor.com')) {
    alt = 'Tripadvisor logo';
    src = 'icons/tripadvisor.svg';
  } else if(formattedUrl.includes('snapchat.com')) {
    alt = 'Snapchat logo';
    src = 'icons/snapchat-white.webp';
  } else if(formattedUrl.includes('foursquare.com')) {
    alt = 'Foursquare logo';
    src = 'icons/foursquare.webp';
  } else if(formattedUrl.includes('tiktok.com')) {
    alt = 'TikTok logo';
    src = 'icons/tiktok-white.svg';
  } else if(formattedUrl.includes('youtube.com')) {
    alt = 'YouTube logo';
    src = 'icons/youtube-white.svg';
  }
  // make logos (which are by default white) match color (if available) using css filters
  // note - this code will not work as well if logos are not all white
  // TODO: converting all logos to SVG and downloading them to this repo will allow us to apply a simple svg fill instead of a css filter
  // This will allow us to remove the CssFilterConverter and reduce load times
  let style: React.CSSProperties | undefined = undefined;
  if(color) {
    const cssFilter = CssFilterConverter.hexToFilter(color).color;
    if(cssFilter) {
      style = { filter: cssFilter };
    }
  }
  return alt && src
    ?
    <Link key={url} href={url} data-testid="social-link">
      <Image alt={alt} src={src} style={style} />
    </Link>
    : null;
};

export default SocialLink;
