import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useEditor } from '@toasttab/sites-components';
import isEmail from 'validator/es/lib/isEmail';

import { ButtonType } from 'src/apollo/sites';
import InputField from 'src/shared/components/common/form_input/InputField';

import Button from 'shared/components/common/button';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';

import { validatePhone } from 'public/components/pages/form_page/FormPage';
import { getSuccessHeader, LOYALTY_SIGNUP_CONTENT, useLoyaltyContext } from 'public/components/pages/loyalty_page/LoyaltyPage';


type Props = {
  header?: string;
  headerColor?: string,
  subheaderColor?: string,
  onClose: () => void,
  successTimeout: number
}
export const LoyaltySignupPopup = (props: Props) => {
  const {
    onSubmit,
    programDescription,
    useEmail,
    submitState,
    programName
  } = useLoyaltyContext();
  const { isEditor } = useEditor();
  const successTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  useEffect(() => {
    if(submitState.isDone()) {
      successTimer.current = setTimeout(() => {
        props.onClose();
      }, props.successTimeout);
    }
    return () => {
      if(successTimer.current) {
        clearTimeout(successTimer.current);
      }
    };
  }, [props, submitState]);

  const formMethods = useForm({ mode: 'onTouched' });

  return (
    <>
      {submitState.isDone() || submitState.isFailed()
        ?
        <div className="popupContent loyaltyPopupContent" data-testid="loyalty-signup-popup">
          <div className="heading successMessage">
            <h2 id="popup-heading" className="postSubmit">{submitState.isFailed() ? LOYALTY_SIGNUP_CONTENT.errorLine1 : getSuccessHeader(programName)}</h2>
            <p>{submitState.isFailed() ? LOYALTY_SIGNUP_CONTENT.errorLine2 : LOYALTY_SIGNUP_CONTENT.successLine2}</p>
          </div>
        </div>
        :
        <div className="popupContent loyaltyPopupContent" data-testid="loyalty-signup-popup">
          <div className="heading">
            <h2 id="popup-heading" style={{ color: props.headerColor }}>{props.header || LOYALTY_SIGNUP_CONTENT.defaultHeader}</h2>
            <p style={{ color: props.subheaderColor }}>{programDescription}</p>
          </div>
          <div className="popupBody">
            <div className="loyaltySignup">
              <FormProvider {...formMethods}>
                <div className="popupForm">
                  {useEmail
                    ?
                    <InputField
                      validate={(value: string) => !isEmail(value) ? 'Please enter a valid email address' : true}
                      type="email"
                      id="email"
                      label="Email"
                      disabled={isEditor}
                      required={true} />
                    :
                    <PhoneInput
                      validate={validatePhone}
                      id="phone"
                      label="Phone"
                      disabled={isEditor}
                      required={true} />}
                </div>
                <Button variant={ButtonType.Primary} onClick={formMethods.handleSubmit(onSubmit)} disabled={submitState.isInProgress() || isEditor} className="submit">Sign Up</Button>
                <div className="disclaimer">
                  {useEmail
                    ? LOYALTY_SIGNUP_CONTENT.emailDisclaimer
                    : LOYALTY_SIGNUP_CONTENT.phoneDisclaimer}
                </div>
              </FormProvider>
            </div>
          </div>
        </div>}
    </>
  );
};
