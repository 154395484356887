import React, { createContext, useContext, useEffect, useState } from 'react';

import { useMarketingSubscriptionMutation, useMarketingConfigQuery } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export const SUBSCRIPTION_SOURCE = 'ONLINE';
export const SUBSCRIPTION_CHANNEL = 'SMS';
export enum MarketingSubscriptionChannel {
  SMS = 'SMS',
  EMAIL = 'EMAIL'
}

export interface MarketingContextType {
  smsAccountEnabled: boolean;
  createMarketingSubscriptionRequest: (args: CreateMarketingSubscriptionInput) => void;
  subscribeToSmsMarketing: boolean;
  setSubscribeToSmsMarketing: React.Dispatch<React.SetStateAction<boolean>>;
  subscribeToEmailMarketing: boolean;
  setSubscribeToEmailMarketing: React.Dispatch<React.SetStateAction<boolean>>;
}

type CreateMarketingSubscriptionInput = {
  customerInput: string,
  firstName: string,
  lastName: string,
  channel: MarketingSubscriptionChannel
}

export const MarketingContext = createContext<MarketingContextType | undefined>(undefined);


export const MarketingContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant, restaurant } = useRestaurant();
  const [subscribeToSmsMarketing, setSubscribeToSmsMarketing] = useState(false);
  const [subscribeToEmailMarketing, setSubscribeToEmailMarketing] = useState(false);
  const [createMarketingSubscriptionRequest] = useMarketingSubscriptionMutation();
  const restaurantGuid = ooRestaurant?.guid || '';

  const { data, refetch, error } = useMarketingConfigQuery({
    variables: {
      input: {
        restaurantGuid,
        managementSetGuid: restaurant.externalId
      }
    },
    ssr: false
  });

  useState(() => {
    if(error) {
      refetch();
    }
  });

  const isIntlRestaurant = useIsIntlRestaurant();
  // If the restaurant is US, we default to subscribing to email marketing
  useEffect(() => {
    if(!isIntlRestaurant) {
      setSubscribeToEmailMarketing(true);
    }
  }, [isIntlRestaurant]);

  const smsAccountEnabled = data?.marketing.marketingConfig.__typename === 'MarketingConfigQuerySuccessResponse' ? data.marketing.marketingConfig.smsAccountEnabled : false;

  const subscribeCustomer = ({ customerInput, firstName, lastName, channel }: CreateMarketingSubscriptionInput) => {
    createMarketingSubscriptionRequest({
      variables: {
        input: {
          managementSetGuid: restaurant.externalId,
          restaurantGuid,
          subscriptionRequest: {
            source: SUBSCRIPTION_SOURCE,
            firstName: firstName,
            lastName: lastName,
            restaurantGuid,
            subscriptions: [{
              channel: channel,
              value: customerInput
            }]
          }
        }
      }
    });
  };

  const context = {
    smsAccountEnabled: smsAccountEnabled,
    createMarketingSubscriptionRequest: subscribeCustomer,
    subscribeToSmsMarketing,
    setSubscribeToSmsMarketing,
    subscribeToEmailMarketing,
    setSubscribeToEmailMarketing
  };

  return (
    <MarketingContext.Provider value={context}>
      {props.children}
    </MarketingContext.Provider>
  );
};

export const useMarketing = () => {
  const context = useContext(MarketingContext);
  if(!context) {
    throw new Error('useMarketing must be used within a MarketingContextProvider');
  }

  return context;
};
