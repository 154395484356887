import { utcToZonedTime, format as formatTz } from 'date-fns-tz';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

export const isToday = (dateObj: Date) => {
  const today = new Date();
  return dateObj.getMonth() === today.getMonth() && dateObj.getDate() === today.getDate();
};

export const isTomorrow = (dateObj: Date) => {
  const today = new Date();
  return dateObj.getMonth() === today.getMonth() && dateObj.getDate() === today.getDate() + 1;
};

export const dateToDayString = (dateObj: Date): string => {
  return isToday(dateObj) ? 'Today' : isTomorrow(dateObj) ? 'Tomorrow' : format(dateObj, 'EEE, M/d');
};

export const toDayString = (date: string): string => dateToDayString(parseISO(date));

export const toLocalTime = (date: string, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string => {
  const dateObj = parseISO(date);
  const zonedDate = utcToZonedTime(dateObj, tz);
  return formatTz(zonedDate, 'h:mm aa z', { timeZone: tz });
};

export const toLocalDateTime = (dateObj: Date, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone, showTimezone: boolean = true): string => {
  const zonedDate = utcToZonedTime(dateObj, tz);
  const formatStr = showTimezone ? 'h:mm aa z' : 'h:mm aa';
  return dateToDayString(zonedDate) + ', ' + formatTz(zonedDate, formatStr, { timeZone: tz });
};

export const toFutureString = (date: string, tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone): string => {
  const dateObj = parseISO(date);
  const zonedDate = utcToZonedTime(dateObj, tz);

  // eslint-disable-next-line quotes
  return formatTz(zonedDate, "EEE, M/d 'at' h:mm aa z", { timeZone: tz });
};

export const formatMDY = (date: Date) => format(date, 'MM/dd/yyyy');

export const getDiningBehaviorToggleLabel = (minimumTime: number): string => {
  if(minimumTime !== null) {
    const earliestFulfillmentDate = getMinimumFulfillmentTime(minimumTime);
    if(minimumTime > 60 && new Date().getDate() !== earliestFulfillmentDate.getDate()) {
      return `${toLocalDateTime(earliestFulfillmentDate, undefined, false)}`;
    }
  }
  return `${minimumTime} - ${minimumTime + 5} min`;
};

export const getMinimumFulfillmentTime = (minimumTime: number): Date => {
  const now = new Date();
  return new Date(new Date(now).setHours(now.getHours(), now.getMinutes() + minimumTime));
};
