import React, { useRef, useEffect } from 'react';

import classNames from 'classnames';
import padStart from 'lodash/padStart';

import { Image as ImageType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import CarouselElement from 'shared/components/common/carousel/CarouselElement';
import { CarouselAriaProps } from 'shared/components/common/carousel/types';
import { useCarousel } from 'shared/components/common/carousel/useCarousel';
import { _Image } from 'shared/components/common/editable_image/EditableImage';


type Props = {
  images: ImageType[]
  autoScrollMS?: number
  autoScrollActive?: boolean
  className?: string
  arrowPosition: 'bottom' | 'top'
  skipRef?: React.RefObject<HTMLDivElement>; // provide a skipRef to enable buttons in the admin.
  onScrollIndexChange?: (index: number) => void;
  testId?: string
} & CarouselAriaProps

const ImageScroller = ({ images, autoScrollMS, autoScrollActive = false, className, arrowPosition, skipRef, onScrollIndexChange, testId, ariaLabelledBy }: Props) => {
  const containerId = 'image-scroller';
  const carouselRef = useRef<HTMLUListElement>(null);

  const { itemNamesMap, scroll, currentItem } = useCarousel({
    containerRef: carouselRef, containerId: containerId,
    numItems: images.length, itemsPerPage: 1, autoScrollMS,
    autoScrollActive
  });

  useEffect(() => {
    onScrollIndexChange && onScrollIndexChange(currentItem);
  }, [currentItem, onScrollIndexChange]);

  const numSlides = itemNamesMap.size;
  const currSlideString = padStart((currentItem + 1).toString(), numSlides.toString().length, '0');

  return (
    <section
      role="region"
      aria-live="off"
      className={classNames('imageScroller', className)} aria-roledescription="carousel" aria-labelledby={ariaLabelledBy}
      data-testid={testId}>
      <ul className="images no-list-style" id={containerId} ref={carouselRef}>
        {images.map((image, i) =>
          <CarouselElement
            key={`scrollElement-${containerId}-${i}`} name={itemNamesMap.get(i)!}
            className={classNames('scrollerImageContainer', className)}
            role="group" aria-roledescription="slide" aria-hidden={i !== currentItem}>
            <_Image key={`scrollElement-${containerId}-${i}`} imageObject={image} alt={image.altText ?? 'alt text'} className="coverImage" eagerLoad={false} />
          </CarouselElement>)}
      </ul>
      {images.length > 1 &&
      <div className={classNames('arrows', arrowPosition)} ref={skipRef} data-testid="image-scroller-buttons">
        <Arrow ariaControls={containerId} direction="left" onClick={() => scroll('backwards')} ariaLabel="previous slide" />
        <div className="counter" data-testid="image-scroller-counter" aria-label={`Currently on slide ${currSlideString} of ${numSlides}`}>{currSlideString}/{numSlides}</div>
        <Arrow ariaControls={containerId} direction="right" onClick={() => scroll('forwards')} ariaLabel="next slide" />
      </div>}
    </section>
  );
};

type ArrowProps = {
  direction: 'left' | 'right'
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  ariaControls?: string
} & CarouselAriaProps

const Arrow = ({ direction, onClick, disabled = false, ariaLabel, ariaLabelledBy, ariaControls }: ArrowProps) => {
  return (
    <button role="button"
      aria-controls={ariaControls}
      disabled={disabled}
      onClick={onClick} className={classNames(`btn--${direction}`, { disabled: disabled })}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}>
      <Image src="icons/white-carousel-arrow.svg" alt={`${direction} arrow`} />
    </button>);
};

export default ImageScroller;
