import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { createClient } from 'src/apollo/createClient';
import {
  PopupLayout,
  useSubmitFormPopupMutation
} from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';

import { Modal, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { GRID_GAP } from 'public/components/default_template/dynamic_section/DynamicSectionUtils';
import { DYNAMIC_SECTION_CELL_SIZE } from 'public/components/default_template/popups/DynamicSectionPopup';
import { PopupContents } from 'public/components/default_template/popups/PopupContents';

import { resources } from 'config';

import { getHasShown, setHasShown, toRichTextField } from './utils';

export type formData = { [key: string]: string };

type Props = {
  isOrderPage?: boolean;
}

type WrappedProps = {
  route: string
} & Props

const WrappedPopups = ({ route, isOrderPage }: WrappedProps) => {
  const { isEditor, popupGuid, useEditableRef } = useEditor();
  const { restaurant } = useRestaurant();
  const { isOpen, onClose, onOpen } = useModal();
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const formMethods = useForm<formData>();
  const { handleSubmit } = formMethods;
  const [submitFormPopupMutation] = useSubmitFormPopupMutation();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Find the most applicable popup for the current page
  // 1. Is enabled
  // 2. Can be shown on the current page
  // Unless we're in the editor and we're viewing a specific popup
  const popupIndex = useMemo(() => restaurant.content?.popups?.findIndex(popup => {
    if(isEditor && popupGuid) {
      return popup.guid === popupGuid;
    }

    // If popup.applicablePages is null, it should be shown on all pages, if it is the empty list it should not be shown on any pages.
    return popup.enabled &&
      (!popup.applicablePages || popup.applicablePages.includes(route) ||
      isOrderPage && popup.applicablePages.includes('/order'));
  }), [restaurant, route, isEditor, popupGuid, isOrderPage]);
  const popup = typeof popupIndex !== 'undefined' && popupIndex >= 0 ? restaurant.content?.popups?.[popupIndex] : undefined;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'popup',
    path: `content.popups[${popupIndex}]`,
    actions: ['delete'],
    schema: { fields: [] }
  });

  const onSubmit = useCallback((data: formData) => {
    if(!popup) {
      // this should never happen
      return;
    }

    setSubmitting(true);
    try {
      submitFormPopupMutation({
        variables: {
          formId: popup.guid,
          data: data
        }
      });
      setSubmitted(true);
    } catch(e) {
      reportError('Failed to submit popup form', e);
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        onClose();
      }, (popup?.form?.successTimeout || 0) * 1000);
    }
  }, [onClose, popup, submitFormPopupMutation]);

  useEffect(() => {
    if(!isEditor && typeof window !== 'undefined' && popup?.enabled && !getHasShown(popup.guid)) {
      setTimeout(() => {
        onOpen();
        setHasShown(popup.guid);
      }, (popup?.displayDelay || 0) * 1000);
    }
  }, [popup, onOpen, onClose, isEditor]);

  // dont display EmbeddedCode popups without any content, unless we're in the editor
  const dynamicBlock = popup?.dynamicSection?.blocks?.[0];
  if(!popup || !isEditor && popup.layout === PopupLayout.EmbeddedCode && !dynamicBlock) {
    return null;
  }

  const header = toRichTextField(popup.header);
  const description = toRichTextField(popup.description);
  const isShelf = popup.layout === PopupLayout.FormSlideIn;

  // The width of a mobile popup is fixed
  let width = undefined;
  if(!isMobile && !!dynamicBlock) {
    const numCols = dynamicBlock.endX - dynamicBlock.startX + 1;
    width = numCols * DYNAMIC_SECTION_CELL_SIZE + (numCols - 1) * GRID_GAP;
  }

  const popupContentProps = {
    popup,
    description,
    header,
    onClose,
    popupIndex,
    dynamicBlock,
    onSubmit,
    handleSubmit,
    submitted,
    submitting,
    formMethods,
    width
  };

  if(isEditor) {
    return (
      <div className="editorPopup" data-testid="popup-modal-editor">
        <div className="editorPopupOverlay" style={{ opacity: popup.overlayOpacityPercentage ? popup.overlayOpacityPercentage / 100 : undefined, backgroundColor: popup.overlayColor || undefined }} />
        <div className={classnames('editorPopupWrapper', popup.placement, { popupShelf: isShelf })}>
          <div className="editorPopupContents" style={{ backgroundColor: popup.backgroundColor || undefined }} ref={editableRef}>
            <PopupContents {...popupContentProps} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal className="popupModal" isOpen={isOpen} onClose={onClose} testId="popup-modal" preventOverlayClose={popup.dismissible === false}>
      <ModalOverlay color={popup.overlayColor || undefined} opacity={popup.overlayOpacityPercentage || undefined} fadeIn={isShelf} fadeOut={isShelf} />
      <ModalContent style={{ backgroundColor: popup.backgroundColor || undefined }} ariaLabelledBy={'popup-heading'}
        wrapperClassName={classnames('popupWrapper', popup.placement, { popupShelf: isShelf })} contentClassName="content fullScreenMobile scrollable" slideIn={isShelf} slideOut={isShelf}>
        <PopupContents {...popupContentProps} />
      </ModalContent>
    </Modal>
  );
};

const EditorPopups = () => {
  const { url, popupMode } = useEditor();

  if(!popupMode) {
    return null;
  }

  return <WrappedPopups route={url} />;
};

const SitesPopups = (props: Props) => {
  const { pathname } = useLocation();

  return (
    <ApolloProvider client={createClient(resources.apiEndpoint, undefined, false, { enabled: true }, undefined, false, false, resources.clientQueryTimeoutMs)}>
      <WrappedPopups route={pathname} isOrderPage={props.isOrderPage} />
    </ApolloProvider>
  );
};

const Popups = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor ? <EditorPopups /> : <SitesPopups {...props} />;
};

export default Popups;
