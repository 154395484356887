import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ButtonType } from 'src/apollo/sites';

import Button from 'shared/components/common/button';
import AriaDatePicker from 'shared/components/common/form_input/DatePicker';

import { PopupContentProps } from 'public/components/default_template/popups/PopupContents';
import { onBirthdaySubmit } from 'public/components/default_template/popups/date_of_birth/utils';

export const onDateOfBirthPopupFailure = () => {
  window.location.href = 'https://www.google.com';
};

export const DateOfBirthPopup = (props: PopupContentProps) => {
  const formMethods = useForm();
  const dateOfBirth = formMethods.watch('dateOfBirth');
  const { header, description, onClose } = props;

  return (
    <div className="popupContent date-of-birth-popup" data-testid="date-of-birth-popup">
      <h2 style={{ color: header.color, fontFamily: header.fontFamily }}>
        {props.header.text}
      </h2>
      <p style={{
        color: description.color,
        fontFamily: description.fontFamily
      }}>
        {description.text}
      </p>
      <div className="formWrapper">
        <FormProvider {...formMethods}>
          <div className="datePickerWrapper" style={{
            color: description.color,
            fontFamily: description.fontFamily
          }}>
            <AriaDatePicker
              id="dateOfBirth"
              label="Date of birth"
              required
              futureDatesOnly={false}
              onThemedSurface={true}
              dateInputStyle={{
                color: description.color,
                fontFamily: description.fontFamily
              }} />
          </div>
          <Button
            type="submit"
            disabled={!dateOfBirth}
            onClick={formMethods.handleSubmit(onBirthdaySubmit(onClose, onDateOfBirthPopupFailure))}
            testId="date-of-birth-popup-confirm"
            variant={props.popup?.buttons?.[0]?.buttonType || ButtonType.Primary}
            className="flex flex-row justify-center px-0 confirm-button">Confirm
          </Button>
        </FormProvider>
      </div>
    </div>
  );
};
