import React from 'react';
import { withRouter } from 'react-router';

import { RequestContextProps } from 'src/lib/js/context';
import { siteHasIntlLocation } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useScrollToTopOnNewLocation from 'src/lib/js/hooks/useScrollToTopOnNewLocation';
import useTracker from 'src/lib/js/hooks/useTracker';
import TagManager from 'src/lib/js/react-gtm/TagManagerScript';
import { getHost } from 'src/lib/js/utilities';
import { StructuredDataProvider } from 'src/public/components/seo/context';
import { ExperimentContextProvider } from 'src/shared/components/common/ab_testing/ABTestContext';
import { DebugContextProvider } from 'src/shared/components/common/debug/DebugContext';

import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import RestaurantContextProvider, { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { RestaurantRoutesContextProvider } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';

import Meta from 'public/components/default_template/meta/Meta';
import { SiftMeta } from 'public/components/default_template/meta/sift/SiftMeta';
import Popups from 'public/components/default_template/popups/Popups';
import { SpotlightContextProvider } from 'public/components/default_template/spotlight/SpotlightContext';
import { ReservationContextProvider } from 'public/components/online_ordering/ReservationContext';

import { TrackingIFrame } from './tracking/TrackingIFrame';


type WrappedProps = RequestContextProps & {
  titleTag?: string;
  descriptionTag?: string;
  homePath?: string;
  orderPath?: string;
  ecommPath?: string;
  orderPathPattern?: string;
  checkoutPathPrefix?: string;
  confirmPathPrefix?: string;
  noPopups?: boolean;
};

type Props = WrappedProps & {
  ooUsage?: RxDataUsage;
  sitesUsage?: RxDataUsage;
};

const WrappedPageShell = (props: React.PropsWithChildren<WrappedProps>) => {
  useScrollToTopOnNewLocation();
  const {
    restaurant,
    restaurant: { meta, pixels, pixelsV2, content, theme },
    selectedLocation: { meta: locationMeta, shortUrl }
  } = useRestaurant();
  // The Cookie Consent banner doesn't know about cookies that GTM might add,
  // so we can't use GTM for Int'l websites currently.
  const hasIntlLocation = siteHasIntlLocation(restaurant);
  return (
    <RestaurantRoutesContextProvider
      shortUrl={shortUrl}
      homePath={props.homePath}
      orderPath={props.orderPath}
      ecommPath={props.ecommPath}
      orderPathPattern={props.orderPathPattern}
      checkoutPathPrefix={props.checkoutPathPrefix}
      confirmPathPrefix={props.confirmPathPrefix}>
      <StructuredDataProvider>
        <SiftMeta />
        {!hasIntlLocation && pixelsV2.google_tag_manager?.scriptArg
          ?
          <>
            <TagManager gtmId={pixelsV2.google_tag_manager.scriptArg} />
            <TrackingIFrame googleTagManagerId={pixelsV2.google_tag_manager.scriptArg} />
          </>
          : null}
        <Meta
          {...meta}
          {...locationMeta}
          siteTheme={theme}
          primaryColor={meta.primaryColor || locationMeta.primaryColor}
          titleTag={props.titleTag}
          description={props.descriptionTag}
          domain={getHost(props.staticContext)}
          pixels={pixels}
          pixelsV2={pixelsV2}
          externalModules={content?.externalModules} />
        {!props.noPopups ? <Popups /> : null}
        {props.children}
      </StructuredDataProvider>
    </RestaurantRoutesContextProvider>
  );
};

const ContextualPageShell = ({ ooUsage, sitesUsage, children, ...rest }: React.PropsWithChildren<Props>) => {
  const tracker = useTracker();

  return (
    <ExperimentContextProvider
      onVariantSelected={(experimentName, variantName) => {
        tracker.register({ [experimentName]: variantName });
      }}>
      <SpotlightContextProvider>
        <AttributionContextProvider>
          <DebugContextProvider>
            <RestaurantContextProvider ooUsage={ooUsage} sitesUsage={sitesUsage} isEditor={false}>
              <ReservationContextProvider>
                <WrappedPageShell {...rest}>{children}</WrappedPageShell>
              </ReservationContextProvider>
            </RestaurantContextProvider>
          </DebugContextProvider>
        </AttributionContextProvider>
      </SpotlightContextProvider>
    </ExperimentContextProvider>
  );
};
export default withRouter<React.PropsWithChildren<Props>, React.ComponentType<Props>>(ContextualPageShell);
