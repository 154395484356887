/**
 * Returns the referrer and referring domain for the current browser session.
 * @param overrideReferrer An optional URL to use to generate referrer and referring domain.
 * @returns {[string | undefined, string | undefined]} A tuple containing the referrer and referring domain, either value undefined if unable to be resolved.
 */
export const getReferrerProperties = (overrideUrl?: string) => {
  try {
    const { href: referrer, hostname: referringDomain } = new URL(overrideUrl || document?.referrer || '');
    return [referrer, referringDomain];
  } catch(error) {
    return [undefined, undefined];
  }
};
