import React, { useMemo } from 'react';

import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

import BaseCard from './BaseCard';
import SeeMoreIcon from './SeeMoreIcon';

type Props = {
  onClick?: () => void;
  testId?: string;
}

const SeeMoreCard = (props: Props) => {
  const getColorFromTheme = useThemeColorScheme();

  const [iconColor] = useMemo(() => {
    return getColorFromTheme(theme => [
      theme.colorScheme.icon.default
    ], [
      '#252525'
    ]);
  }
  , [getColorFromTheme]);

  return (
    <BaseCard
      testId={props.testId}
      onClick={props.onClick}>
      <div className="seeMore">
        <SeeMoreIcon color={iconColor} />
        <span>See More</span>
      </div>
    </BaseCard>
  );
};

export default SeeMoreCard;
