import React, { useEffect } from 'react';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';

import { useReorder } from './ReorderContext';

const ItemModalWrapper: React.FC = () => {
  const { ooRestaurant } = useRestaurant();
  const { reorderItem, onHistoryClose, clearReorderItem, isReorderItemOpen, onReorderItemOpen, onReorderItemClose } = useReorder();

  useEffect(() => {
    // We want to close the history modal whenever we open the item modal.
    if(reorderItem) {
      onReorderItemOpen();
      onHistoryClose();
    }
  }, [reorderItem, onHistoryClose, onReorderItemOpen]);

  return (
    <ItemModal
      isOpen={isReorderItemOpen && !!reorderItem}
      onClose={() => {
        onReorderItemClose();
        clearReorderItem();
      }}
      restaurantGuid={ooRestaurant?.guid}
      specialRequestsEnabled={ooRestaurant?.specialRequestsConfig.enabled}
      specialRequestsPlaceholder={ooRestaurant?.specialRequestsConfig.placeholderMessage}
      orderGuid={reorderItem?.orderGuid}
      selectionGuid={reorderItem?.selection.guid}
      itemGuid={reorderItem?.selection.itemGuid}
      itemGroupGuid={reorderItem?.selection.itemGroupGuid} />
  );
};

export default ItemModalWrapper;
