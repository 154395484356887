import { reportError } from 'src/lib/js/clientError';

const SEPARATOR = '•';

export type PlaceholderImageProps = {
  width: number;
  height: number;
  text: string;
  alpha: number;
  fontSize: string;
  fontFamily: string;
  textColor: string;
  rotation: number;
};

export interface PlaceholderImageWindowCache {
  generatedImages: {[key: string]: string} | undefined;
}

export const generatePlaceholderImage = (props: PlaceholderImageProps): string => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if(!ctx) {
    // unlikely but ctx can be null
    reportError('Context could not be created');
    return '';
  }
  canvas.width = props.width;
  canvas.height = props.height;

  const textUpper = props.text.toUpperCase();

  // background
  ctx.fillStyle = `rgba(255, 255, 255, ${props.alpha})`;
  ctx.fillRect(0, 0, props.width, props.height);

  // text
  ctx.font = `${props.fontSize} ${props.fontFamily}`;
  ctx.fillStyle = props.textColor;

  const textMeasure = ctx.measureText(textUpper);
  const repeat = Math.ceil(props.width / textMeasure.width) * 2;

  const textLine1 = Array(repeat)
    .fill(textUpper)
    .join(` ${SEPARATOR} `);

  const textLine2 = Array(repeat - 1)
    .fill(textUpper)
    .join(` ${SEPARATOR} `);

  const textLineMeasure1 = ctx.measureText(textLine1);
  const textLineMeasure2 = ctx.measureText(textLine2);
  const textHeight = textMeasure.fontBoundingBoxAscent + textMeasure.fontBoundingBoxDescent;
  const diagonalLength = Math.sqrt(props.width * props.width + props.height * props.height);
  const spacing = textHeight + textHeight * .80; // Spacing between vertical lines

  const secondRowStagger = (textLineMeasure1.width - textLineMeasure2.width) / 2;

  // change the degree in which the text is drawn
  const angleRadians = props.rotation * Math.PI / 180;

  ctx.translate(props.width / 2, props.height / 2); // Move to center of canvas
  ctx.rotate(angleRadians); // Rotate to specified angle, remove negative to flip

  // Calculate start and end positions for full coverage
  const start = -diagonalLength / 2;
  const end = diagonalLength / 2;

  // Draw the text in diagonal pattern with specified angle
  for(let y = start; y < end; y += spacing * 2) {
    // 2 rows at a time to get the stagger
    ctx.fillText(textLine2, start, y);
    ctx.fillText(textLine1, start - secondRowStagger, y + spacing);
  }

  // Reset transformations
  ctx.rotate(-angleRadians);
  ctx.translate(-props.width / 2, -props.height / 2);

  return canvas.toDataURL('image/png', 0.72);
};
