import { useCallback } from 'react';
import useScript from 'react-script-hook';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { useTracker } from 'src/lib/js/hooks/useTracker';

import { resources } from 'config';

declare global {
  interface Window {
    Toast: any;
  }
}

export const useSpi = () => {
  const { ooSpiSurchargingFe } = useFlags();
  const isIntlRestaurant = useIsIntlRestaurant();

  return { spiSurchargingEnabled: !isIntlRestaurant && ooSpiSurchargingFe };
};

// only log the events if the FF is enabled
export const useTempEventTracker = () => {
  const { ooSpiTempEventsEnabled } = useFlags();
  const tracker = useTracker();

  const track = useCallback((name: any, data: any) => {
    if(ooSpiTempEventsEnabled) {
      tracker.track(name, data);
    }
  }, [tracker, ooSpiTempEventsEnabled]);

  return { track };
};

export const useSpiSdk = (skip?: boolean) => {
  const isIntlRestaurant = useIsIntlRestaurant();
  const { track } = useTempEventTracker();
  const [loading, error] = useScript({ src: !skip && !isIntlRestaurant ? resources.spiURL : null });
  if(error) {
    track('temp_SPI_sdk_failed', {});
  }
  return !loading && !error && typeof window !== 'undefined' ? window.Toast : undefined;
};
