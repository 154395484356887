import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';

import { saveFulfillmentTime } from 'public/components/default_template/online_ordering/checkout/fulfillmentTimeUtils';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CheckoutFormData, useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { usePayment, getCheckoutInfo, PaymentCompletedParams } from 'public/components/online_ordering/PaymentContext';

import { getCustomerInfo, useHandleCompletedOrderCallback, getPaymentOption, useHandleValidationError } from './checkoutUtils';
import { useIsCartValid } from './useIsCartValid';

class PaymentNeedsUserInfoError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeedsUserInfoError';
  }
}

class PaymentNeededError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PaymentNeededError';
  }
}

export const useSubmitOrder = () => {
  const isIntlRestaurant = useIsIntlRestaurant();
  const tracker = useTracker();
  const { customer } = useCustomer();
  const { savedAddressUsed } = useDelivery();
  const { placeOrder, setOrderError, giftCardAppliedAmount, toastCashAppliedAmount, orderTotal, saveNewAddress } = useCheckout();
  const { cartGuid, cart, refetchCart } = useCart();
  const { completePayment, setUserInfoRequired, createPaymentIntent, confirmedPayment, billingDetails, tipAmount, paymentType, paymentOption } = usePayment();
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: getCustomerInfo(customer)
  });

  const { setValue } = formMethods;

  const handleCompletedOrder = useHandleCompletedOrderCallback();

  const onValidationError = useHandleValidationError();
  const { isCartValid } = useIsCartValid(onValidationError, refetchCart);

  const onPaymentCompleted = useCallback(
    (checkoutFormData: CheckoutFormData, resolve: (arg: any) => void) =>
      async (paymentId?: string, firstName?: string, lastName?: string, phone?: string, email?: string, phoneCountryCode?: string, paymentCompletedParams?: PaymentCompletedParams) => {
        // when paying with a digital wallet, user info may not be populated in the form
        await placeOrder(
          cartGuid!,
          {
            ...checkoutFormData,
            yourInfoFirstName: checkoutFormData.yourInfoFirstName || firstName || '',
            yourInfoLastName: checkoutFormData.yourInfoLastName || lastName || '',
            yourInfoPhone: checkoutFormData.yourInfoPhone || phone || '',
            yourInfoEmail: checkoutFormData.yourInfoEmail || email || '',
            yourInfoCountryCode: phoneCountryCode
          },
          handleCompletedOrder,
          paymentId,
          paymentCompletedParams
        );
        resolve(true);
      },
    [placeOrder, cartGuid, handleCompletedOrder]
  );

  const onPaymentCancelled = useCallback(
    (resolve: (val: boolean) => void, reject: (err: Error) => void) => (needsInput: boolean, firstName?: string, lastName?: string, phone?: string, email?: string) => {
      if(needsInput) {
      // populate form fields if some of the user info is available
        if(firstName) setValue('yourInfoFirstName', firstName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(lastName) setValue('yourInfoLastName', lastName, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(phone) setValue('yourInfoPhone', phone, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        if(email) setValue('yourInfoEmail', email, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        reject(new PaymentNeedsUserInfoError('We couldn\'t get your information from your digital wallet. Please enter it above.'));
      } else {
        resolve(true);
      }
    }, [setValue]
  );

  const onSubmitHelper = useCallback(async (checkoutFormData: CheckoutFormData) => {
    // payment intent logic not needed (and cannot be used) if there is no amount to charge
    if(orderTotal > 0) {
      return new Promise((resolve, reject) => {
        if(confirmedPayment === null) {
          // we haven't confirmed a payment, so complete the payment
          completePayment(
            checkoutFormData,
            giftCardAppliedAmount,
            toastCashAppliedAmount,
            () => isCartValid(
              cartGuid!!,
              {
                firstName: checkoutFormData.yourInfoFirstName,
                lastName: checkoutFormData.yourInfoLastName,
                email: checkoutFormData.yourInfoEmail,
                phone: checkoutFormData.yourInfoPhone
              }
            ),
            onPaymentCompleted(checkoutFormData, resolve),
            onPaymentCancelled(resolve, reject),
            err => reject(err)
          );
        } else if(confirmedPayment.amount === orderTotal * 100) {
          // we have already confirmed a payment and the order total hasn't changed, so we can place the order without
          // updating the payment
          const { firstName, lastName, phoneNumber, email, phoneCountryCode } = getCheckoutInfo(checkoutFormData, billingDetails);
          onPaymentCompleted(checkoutFormData, resolve)(confirmedPayment.externalReferenceId, firstName, lastName, phoneNumber, email, phoneCountryCode);
        } else {
          // the order total changed, so the confirmed payment is no longer valid and we need to collect payment again
          createPaymentIntent().then(() => reject(new PaymentNeededError('Your order has changed. Please re-enter your payment information above.')));
        }
      });
    } else {
      if(!(checkoutFormData.yourInfoFirstName || checkoutFormData.yourInfoLastName || checkoutFormData.yourInfoPhone || checkoutFormData.yourInfoEmail)) {
        // the user info fields may have been hidden if a digital wallet is selected and a gift card is used to cover the entire amount
        setUserInfoRequired(true);
        throw new PaymentNeedsUserInfoError('Please enter your name, phone number, and email address.');
      } else {
        return await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      }
    }
  },
  [
    orderTotal,
    completePayment,
    giftCardAppliedAmount,
    toastCashAppliedAmount,
    onPaymentCompleted,
    onPaymentCancelled,
    setUserInfoRequired,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    billingDetails,
    confirmedPayment,
    createPaymentIntent,
    isCartValid
  ]);

  const submitOrder = useCallback(async (checkoutFormData: CheckoutFormData) => {
    tracker.track('Place Order clicked', {
      cartSource: cart?.cartSource,
      restaurantGuid: cart?.restaurant?.guid,
      diningOption: cart?.diningOptionBehavior,
      deliveryProvider: cart?.deliveryProviderInfo?.provider,
      saveNewAddress,
      savedAddressUsed: cart?.diningOptionBehavior === DiningOptionBehavior.Delivery ? savedAddressUsed : undefined,
      fulfillmentTime: cart?.fulfillmentType,
      numItems: cart?.order?.numberOfSelections,
      subtotal: cart?.order?.preDiscountItemsSubtotal,
      tax: cart?.order?.taxV2,
      deliveryChargeTotal: cart?.order?.deliveryServiceCharges,
      gratuityServiceCharges: cart?.order?.gratuityServiceCharges,
      processingServiceCharges: cart?.order?.processingServiceCharges,
      nonDeliveryNonGratuityNonUbpServiceCharges: cart?.order?.nonDeliveryNonGratuityNonUbpServiceCharges,
      discounts: cart?.order?.discountsTotal,
      total: cart?.order?.totalV2,
      paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal, isIntlRestaurant),
      giftCardApplied: giftCardAppliedAmount > 0,
      tipAmount
    });
    // We save the fulfillment time to local storage here so that it is available for order failures
    saveFulfillmentTime(cart, cartGuid!);

    if(!isIntlRestaurant) {
      try {
        await onSubmitHelper(checkoutFormData);
      } catch(error) {
        if(error instanceof PaymentNeedsUserInfoError || error instanceof PaymentNeededError) {
          setOrderError({
            type: 'CUSTOM_ERROR_MESSAGE',
            message: error.message
          });
        } else if(error) {
          setOrderError(error);
        }
        // if the onSubmitHelper promise is rejected without an error, it is assumed that orderError has already been set
      }
    } else {
      try {
        await placeOrder(cartGuid!, checkoutFormData, handleCompletedOrder);
      } catch(error) {
        setOrderError(error);
      }
    }
  }, [
    tracker,
    cart,
    tipAmount,
    paymentType,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    isIntlRestaurant,
    onSubmitHelper,
    placeOrder,
    cartGuid,
    handleCompletedOrder,
    setOrderError,
    saveNewAddress,
    savedAddressUsed
  ]);

  return submitOrder;
};
