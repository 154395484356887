import React, { createContext, useContext, useMemo } from 'react';

// eslint-disable-next-line camelcase
import { DoMenus_GetMenuGroupsGroup, DoMenus_GetMenuItemsItem, Menus_VisibilityEnum, useDoGetMenuGroupsQuery, useDoGetMenuItemsQuery } from 'src/apollo/onlineOrdering';
import { reportError } from 'src/lib/js/clientError';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

type BulkCallsContextType = {
  // eslint-disable-next-line camelcase
  menuItems: DoMenus_GetMenuItemsItem[]
  // eslint-disable-next-line camelcase
  menuGroups: DoMenus_GetMenuGroupsGroup[]
}

type BulkCallsContextProps = {
  bulkMenuItemIds?: any[] | null
  bulkMenuGroupIds?: any[] | null
}

export const BulkCallsContext = createContext<BulkCallsContextType | undefined>(undefined);

// Provides the menu item and/or group details for the menu items and/or groups
// listed in the bulk calls section for this page.
export const BulkCallsContextProvider = ({
  bulkMenuItemIds,
  bulkMenuGroupIds,
  children
}: React.PropsWithChildren<BulkCallsContextProps>) => {
  const itemsToRequestList = useMemo(() => {
    return bulkMenuItemIds?.map(item => {
      return {
        menuGroupGuid: item?.itemGroupGuid,
        menuItemGuid: item?.itemGuid,
        restaurantGuid: item?.rxGuid,
        respectAvailability: false,
        // eslint-disable-next-line camelcase
        visibility: Menus_VisibilityEnum.ToastOnlineOrdering
      };
    }) || [];
  }, [bulkMenuItemIds]);

  const groupsToRequest = useMemo(() => {
    return {
      groups: bulkMenuGroupIds?.map(({
        groupGuid,
        rxGuid
      }) => ({
        menuGroupGuid: groupGuid,
        restaurantGuid: rxGuid
      })) || [],
      respectAvailability: false,
      // eslint-disable-next-line camelcase
      visibility: Menus_VisibilityEnum.ToastOnlineOrdering
    };
  }, [bulkMenuGroupIds]);

  const ooClient = useOOClient();

  const {
    data,
    error
  } = useDoGetMenuItemsQuery({
    variables: { input: { items: itemsToRequestList } },
    skip: itemsToRequestList.length === 0,
    client: ooClient
  });

  const menuItemsData: any[] = useMemo(() => {
    if(error) {
      reportError('Error fetching bulk menu items: ', error);
    }
    if(data && data.doMenus_getMenuItems.__typename === 'DoMenus_GetMenuItemsResponse') {
      return data.doMenus_getMenuItems.items;
    }
    return [];
  }, [error, data]);

  const {
    data: groupsData,
    error: groupsError
  } = useDoGetMenuGroupsQuery({
    variables: { input: groupsToRequest },
    skip: groupsToRequest.groups.length === 0,
    client: ooClient
  });

  const menuGroupsData: any[] = useMemo(() => {
    if(groupsError) {
      reportError('Error fetching bulk menu groups: ', groupsError);
    }
    if(groupsData && groupsData.doMenus_getMenuGroups.__typename === 'DoMenus_GetMenuGroupsResponse') {
      return groupsData.doMenus_getMenuGroups.groups;
    }
    return [];
  }, [groupsData, groupsError]);

  const context = useMemo(() => {
    return {
      menuItems: menuItemsData,
      menuGroups: menuGroupsData
    };
  }, [menuGroupsData, menuItemsData]);

  return (
    <BulkCallsContext.Provider value={context}>
      {children}
    </BulkCallsContext.Provider>);
};

export const useBulkCalls = () => {
  const context = useContext(BulkCallsContext);
  if(!context) {
    throw new Error('useBulkCalls must be used within a BulkCallsContextProvider');
  }

  return context;
};
