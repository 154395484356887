import { useCallback } from 'react';

import { ReorderableItemsQuery, useReorderableItemsQuery as query } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';

/**
 * We can only control how many orders are retrieved at once, not the number of items,
 * which will vary and in some cases could be filtered out entirely.
 */
const DEFAULT_ORDERS_PER_PAGE = 10;

export type PaginatedQueryResponse = ReturnType<typeof usePaginatedReorderableItemsQuery>;
export type QueryResponse = ReturnType<typeof useReorderableItemsQuery>;
export type QueryData = ReorderableItemsQuery['reorderableItems'];
export type QueryItems = QueryData['items'];

export const useReorderableItemsQuery = () => {
  const { fulfillmentData } = useFulfillment();
  const { selectedLocation } = useRestaurant();
  const { data, loading, error } = query({
    variables: {
      input: {
        restaurantGuid: selectedLocation.externalId,
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime,
        pageSize: DEFAULT_ORDERS_PER_PAGE
      }
    },
    errorPolicy: 'ignore',
    ssr: false
  });

  return { data, loading, error };
};

export const usePaginatedReorderableItemsQuery = ({ skip }: { skip?: boolean }) => {
  const { fulfillmentData } = useFulfillment();
  const { selectedLocation } = useRestaurant();
  const { data, loading, error, fetchMore } = query({
    variables: {
      input: {
        restaurantGuid: selectedLocation.externalId,
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime,
        pageSize: DEFAULT_ORDERS_PER_PAGE
      }
    },
    errorPolicy: 'ignore',
    ssr: false,
    skip,
    notifyOnNetworkStatusChange: true
  });

  const paginate = useCallback(() => fetchMore({
    variables: {
      input: {
        restaurantGuid: selectedLocation.externalId,
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime,
        pageSize: DEFAULT_ORDERS_PER_PAGE,
        pageToken: data?.reorderableItems?.nextPageToken
      }
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if(!fetchMoreResult) return prev;
      return {
        reorderableItems: {
          currentPageToken: fetchMoreResult.reorderableItems.currentPageToken || null,
          nextPageToken: fetchMoreResult.reorderableItems.nextPageToken || null,
          items: [...prev.reorderableItems.items, ...fetchMoreResult.reorderableItems.items],
          __typename: prev.reorderableItems.__typename
        }
      };
    }
  }), [selectedLocation, fetchMore, fulfillmentData, data]);

  return { data, loading, error, paginate };
};
