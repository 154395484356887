import React from 'react';
import { withRouter } from 'react-router';

import { RequestContextProps } from 'src/lib/js/context';
import RedirectWithStatus from 'src/shared/components/common/RedirectWithStatus';

import { useBrandedAppPromo } from 'public/components/default_template/app_promo/useAppPromo';

const AppRedirect = ({ staticContext }: RequestContextProps) => {
  const appData = useBrandedAppPromo({ userAgent: staticContext?.req.headers['user-agent'] });

  if(!appData?.link) {
    return <RedirectWithStatus status={307} to="/" />;
  }

  if(typeof window !== 'undefined') {
    // If somehow we get to the client, force a transition because the react-router-dom redirect component
    // behaves weirdly in these contexts
    window.location.href = appData.link;
    return <></>;
  }

  return <RedirectWithStatus to={appData.link} />;
};

export default withRouter(AppRedirect);
