import { useMemo } from 'react';

import { ImageRatio, MenuConfig, MenuTemplate } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';
import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';

export const useReorderTemplate = () => {
  const { restaurant: { config } } = useRestaurant();

  return useMemo(() => {
    const templateOverrides = {
      ...overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config),
      // Need to override image ratio to match default for classic if top is used
      // for the main menu, otherwise use whatever was configured for left/classic.
      ...config.ooConfig?.format?.template === MenuTemplate.TopImageV2
        ? { imageRatio: `imageRatio${ImageRatio.Smaller}` }
        : {}
    };
    const template = config.ooConfig?.format?.template === MenuTemplate.LeftImageV2 ? MenuTemplate.LeftImageV2 : MenuTemplate.Classic;
    const templateClass = getTemplateClass(template);

    return { templateOverrides, templateClass };
  }, [config]);
};
