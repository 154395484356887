import React, { useEffect } from 'react';

import { captureMessage } from '@sentry/react';
import qr from 'qrcode';

import Button from 'shared/components/common/button';
import { formatPhoneNumberForDisplay } from 'shared/js/phoneUtils';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { PopupContentProps } from 'public/components/default_template/popups/PopupContents';
import { SmsMarketingLegalDisclaimer } from 'public/components/default_template/popups/sms_marketing_signup/SmsMarketingLegalDisclaimer';


export const SmsMarketingSignupPopup = (props: PopupContentProps) => {
  const [url, setUrl] = React.useState('');
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const {
    description,
    header,
    popup: { buttons },
    submitting,
    submitted
  } = props;
  const smsMarketingAccountTollFreeNumber = buttons?.[0]?.action?.metadata;

  useEffect( () => {
    if(isMobile || !!url) {
      return;
    }

    if(!smsMarketingAccountTollFreeNumber) {
      captureMessage('Error: generating QR Code for SMS Marketing Signup. No Toll Free Number provided');
      props.onClose();
      return;
    }
    qr.toDataURL(smsMarketingAccountTollFreeNumber).then(res => {
      setUrl(res);
    });
  }, [url, isMobile, smsMarketingAccountTollFreeNumber, props]);

  if(!smsMarketingAccountTollFreeNumber) {
    return;
  }

  return (
    <div className="popupContent sms-marketing-signup-popup" data-testid="sms-marketing-signup-popup">
      <h2 style={{ color: header.color, fontFamily: header.fontFamily }}>
        {props.header.text}
      </h2>
      <p style={{
        color: description.color,
        fontFamily: description.fontFamily
      }}>
        {description.text}
      </p>
      {isMobile &&
          <Button
            key={'modal-action-sms-marketing-signup'}
            disabled={submitting || submitted}
            onClick={() => {
              if(smsMarketingAccountTollFreeNumber) {
                window.open(
                  smsMarketingAccountTollFreeNumber,
                  '_self',
                  'noopener noreferrer'
                );
              }
              props.onClose();
            }}
            variant={props.popup?.buttons?.[0]?.buttonType}>
              Click here to sign up
          </Button>}
      {url && !isMobile &&
          <div className="qr-code-container">
            <img className="qr-code" src={url} alt="QR Code for SMS Marketing" />
            <div className="scan-to-join-cta">
              <div className="top" />
              <div className="bottom">Scan to join</div>
            </div>
            <p
              style={{
                color: description.color,
                fontFamily: description.fontFamily
              }}
              className="alt-signup-method">
              or text JOIN to {formatPhoneNumberForDisplay(smsMarketingAccountTollFreeNumber)}
            </p>
          </div>}
      <SmsMarketingLegalDisclaimer style={{
        color: description.color,
        fontFamily: description.fontFamily
      }} />
    </div>
  );
};
