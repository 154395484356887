import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import { withApollo } from '@apollo/client/react/hoc';
import { FontContextProvider } from '@toasttab/sites-components';

import { isClient } from 'src/lib/js/detector';
import useQueryString from 'src/lib/js/hooks/useQueryString';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getReferrerProperties } from 'src/lib/js/trackerUtilities';

import { getFontFaceAndFamily } from 'public/components/default_template/meta/StyleMeta';

import { server, environment } from 'config';

import { StaticSiteContextProvider } from './StaticSiteContext';

const App = ({ staticContext, location, children }) => {
  // Enable tracking for analytics
  const tracker = useTracker({
    blacklisted: !isClient() || staticContext?.isBot || staticContext?.isEditor,
    debug: false
  });

  const { rwg_token: rwgToken } = useQueryString();

  /**
   * Capture or force 'reset' order-with-google user property for tracking to avoid stale owg attribution
   * across event data. Particularly important for authenticated user sessions with ID resolution, or Amplitude sessions
   * shared across restaurant websites.
   */
  useEffect(() => {
    if(typeof sessionStorage !== 'undefined') {
      const currentRwgToken = sessionStorage.getItem('rwg_token');

      if(rwgToken) {
        sessionStorage.setItem('rwg_token', rwgToken);
        tracker.register({ rwgToken });
      } else if(!currentRwgToken) {
        tracker.unregister(['rwgToken']);
      }
    }
  }, [rwgToken, tracker]);

  /**
   * Force 'reset' referrer user properties for tracking to avoid stale user referrer attribution properties
   * across event data. Without, sessions started from a different referrer will be attributed to the previous referrer.
   * Additionally, for authenticated users starting new sessions, the referrer will be the previous session's referrer.
   */
  useEffect(() => {
    if(typeof window !== 'undefined') {
      const [referrer, referringDomain] = getReferrerProperties();
      if(referrer && referringDomain) {
        tracker.register({ referrer, referring_domain: referringDomain });
      } else {
        // removing the property preserves the default (None) categorization in Amplitude when no referrer is present
        tracker.unregister(['referrer', 'referring_domain']);
      }
    }
  }, [tracker]);

  // Use react-helmet for head tag updates
  const defaultCanonical = `${server.protocol}://${server.host}${location.pathname != '/' ? location.pathname : ''}`;
  const helmet =
    <Helmet defaultTitle="Toast | The best food at your fingertips">
      <link rel="canonical" href={defaultCanonical} />
    </Helmet>;

  const skipToMainContent = useCallback(e => {
    if(e.type === 'keydown' && e.code !== 'Escape' && e.code !== 'Space' && e.code !== 'Enter') {
      return;
    }
    e.preventDefault();
    const mainEl = document.getElementById('main');
    mainEl.focus({ preventScroll: mainEl.getBoundingClientRect().top > 0 });
  }, []);

  return (
    <StaticSiteContextProvider value={staticContext}>
      <FontContextProvider
        renderGoogleFonts={fonts =>
          <Helmet>
            {fonts.map(font => <link key={font} href={`https://fonts.googleapis.com/css?family=${font}:wght@400;600;700&display=swap`} rel="stylesheet" />)}
          </Helmet>}
        renderFonts={styles => <Helmet><style>{styles}</style></Helmet>}
        toFontFace={font => {
          return getFontFaceAndFamily({ name: font.name, url: font.src }).fontFace;
        }}>
        <a href="#main" className="skip-link" onClick={e => skipToMainContent(e)} onKeyDown={e => skipToMainContent(e)}>Skip to Main content</a>
        {helmet}
        <div className="tsw-app-wrapper" id="tsw-app-wrapper">
          {children}
        </div>
      </FontContextProvider>
    </StaticSiteContextProvider>
  );
};

export default withRouter(withApollo(App));
