import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AnimatePresence, motion } from 'framer-motion';
import { MenuItem } from 'schema-dts';

import {
  DiningOptionBehavior,
  MenuItemFromCartQuery,
  // eslint-disable-next-line camelcase
  Menus_ContainsAlcohol, Menus_MenuItemTag, Menus_VisibilityEnum,
  RankedPromoOfferDiscount,
  useDoMenuItemQuery,
  useMenuItemDetailsQuery,
  useMenuItemFromCartLazyQuery,
  useReorderableMenuItemLazyQuery
} from 'src/apollo/onlineOrdering';
import { reportError } from 'src/lib/js/clientError';
import { I18nType } from 'src/lib/js/hooks/useFormatPrice';
import { formatImageURL } from 'src/lib/js/utilities';
import { getOfferPrice } from 'src/public/components/default_template/online_ordering/cart/offers/OfferUtils';
import SchemaThing from 'src/public/components/seo/SchemaThing';
import { Channel } from 'src/public/js/siteUtilities';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { useAlertModalContext } from 'shared/components/common/alert_modal/AlertModal';
import ErrorNotice from 'shared/components/common/error_notice';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModalContext } from 'shared/components/common/modal';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { REQUEST_FAILURE_MESSAGE } from 'shared/js/constants';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import QuantitySelector from 'public/components/default_template/online_ordering/item_modal/QuantitySelector';
import SelectionList from 'public/components/default_template/online_ordering/item_modal/SelectionList';
import { MenuItemTag, OfferBadge, TimeBasedRuleItemModalTag } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { useMenuSearchContext, SearchHighlightedText } from 'public/components/default_template/search';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { ModifierContextProvider, useModifierContext } from 'public/components/online_ordering/ModifierContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { ItemWithTimeBasedRule, useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';
import { Action, collapseModifier, collapseModifierGroups, ModifierGroup, SelectionModifierGroup, WrappedModifier } from 'public/components/online_ordering/types';

type Props = {
  restaurantGuid?: string;
  /**
   * @deprecated this property is ignored as it is no longer required by any menu queries
   */
  shortUrl?: string; // TODO Clean up callers that still pass this in
  itemGuid?: string | null;
  itemGroupGuid?: string | null;
  itemMenuGuid?: string | null;
  onClose: () => void;
  isOpen: boolean;
  specialRequestsEnabled?: boolean | null;
  specialRequestsPlaceholder?: string | null;
  selectionGuid?: string | null;
  cartGuid?: string;
  /**
   * If present with `selectionGuid`, will populate the modal to facilitate reordering
   * that item from a past order.
   */
  orderGuid?: string;
  withTransitions?: boolean;
  shouldShowHighlights?: boolean;
  // eslint-disable-next-line camelcase
  itemTags?: Menus_MenuItemTag[];
  offer?: RankedPromoOfferDiscount;
  channelGuid?: string
};

type BodyProps = {
  name?: string | null;
  itemGuid?: string | null;
  itemGroupGuid?: string | null;
  itemMenuGuid?: string | null;
  basePrice?: number | null;
  groups?: (ModifierGroup | SelectionModifierGroup)[] | null;
  specialRequestsEnabled?: boolean | null;
  specialRequestsPlaceholder?: string | null;
  specialRequestsContent?: string | null;
  description?: string | null;
  image?: string;
  loading?: boolean;
  shouldShowHighlights?: boolean;
  // eslint-disable-next-line camelcase
  itemTags?: Menus_MenuItemTag[];
}

type ActionsProps = {
  loading?: boolean;
  isReorder?: boolean;
  selectionGuid?: string | null;
  itemGuid?: string | null;
  offer?: RankedPromoOfferDiscount;
  channelGuid?: string
}

const headerId = 'menu-item-modal-header';

const ModalActions = ({ loading, isReorder, selectionGuid, offer, channelGuid }: ActionsProps) => {
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const { displayedModifier, commitModifier, price, quantity, setQuantity, validate, invalidRequiredGroups } = useModifierContext();
  const { orderingDisabled } = useRestaurant();
  const { addToCart, editCartItem, loadingCart, applyPromoCode, channel } = useCart();
  const { timeBasedRulesMap, verifyNoConflictingItemsInCart, verifyItemIsFulfillable } = useTimeBasedRules();
  const { fulfillmentData, guestSelectedFulfillmentTime, selectedDiningOptionBehavior, diningOptionBehaviorAvailability, setFulfillmentModalOpen, closeFulfillmentTooltip } = useFulfillment();
  const { onClose } = useModalContext();
  const { openAlertModal } = useAlertModalContext();
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const isDisabled = loading || !quantity || loadingCart;
  const { searchString, canUseSearch } = useMenuSearchContext();
  const scheduledOrdersOnly = useMemo(() => {
    const behavior = diningOptionBehaviorAvailability[selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'takeout'];
    return behavior?.scheduledAvailable && !behavior.asapAvailable;
  }, [diningOptionBehaviorAvailability, selectedDiningOptionBehavior]);
  const promptFulfillmentTimeUpdate = scheduledOrdersOnly && !guestSelectedFulfillmentTime;
  const priceAfterOffer = useMemo(() => getOfferPrice(offer, price), [offer, price]);
  const [isInvalid, setIsInvalid] = useState(false);

  const submitModifier = useCallback(() => {
    if(displayedModifier?.isRoot || !validate()) {
      return;
    }

    commitModifier();
  }, [commitModifier, validate, displayedModifier]);

  const onSubmit = useCallback(async () => {
    setIsInvalid(false);
    if(!displayedModifier?.isRoot) {
      return;
    }

    if(!validate()) {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 5000);
      return;
    }

    let errorType = null;
    if(selectionGuid && !isReorder) {
      try {
        errorType = await editCartItem(displayedModifier, quantity, selectionGuid);
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error editing cart item.', error);
      }
    } else {
      const timeBasedRules = timeBasedRulesMap[displayedModifier.modifier.itemGuid];
      try {
        const item = {
          itemGuid: displayedModifier.modifier.itemGuid,
          preorderRule: timeBasedRules?.preorderRule,
          leadTimeRule: timeBasedRules?.leadTimeRule,
          pickupWindowRule: timeBasedRules?.pickupWindowRule,
          name: displayedModifier.name,
          modifier: displayedModifier,
          quantity
        } as ItemWithTimeBasedRule;
        if(!verifyNoConflictingItemsInCart(item)) {
          onClose();
          return;
        }

        const trackData = {
          quantity,
          price: priceAfterOffer,
          unitOfMeasure: displayedModifier.unitOfMeasure,
          timeBasedRule: timeBasedRules?.leadTimeRule ? 'Minimum lead time' : timeBasedRules?.preorderRule ? 'Preorder' : timeBasedRules?.pickupWindowRule ? 'Pickup window' : null,
          canUseSearch,
          searchString: canUseSearch && searchString ? searchString : null,
          isReorder
        };

        errorType = await addToCart(displayedModifier, quantity, fulfillmentData?.cartFulfillmentData, trackData, channelGuid);
        // If this item is being added from a BOGO, add the promo code first
        if(offer && errorType !== 'CartModificationError') {
          await applyPromoCode(offer.promoCode);
        }
      } catch(error) {
        openAlertModal(REQUEST_FAILURE_MESSAGE);
        reportError('Error adding cart item.', error);
        errorType = 'CartModificationError';
      }
    }
    if(errorType !== 'CartModificationError') {
      onClose();
      //only open the cart modal for desktop
      if(!isMobile || selectionGuid) {
        context?.open();
      }
    }
    closeFulfillmentTooltip();
  }, [
    selectionGuid,
    isReorder,
    editCartItem,
    displayedModifier,
    quantity,
    addToCart,
    onClose,
    context,
    validate,
    fulfillmentData,
    openAlertModal,
    timeBasedRulesMap,
    verifyNoConflictingItemsInCart,
    canUseSearch,
    searchString,
    isMobile,
    applyPromoCode,
    offer,
    priceAfterOffer,
    closeFulfillmentTooltip,
    channelGuid
  ]);

  const totalItemPrice = useMemo(() => quantity * price, [quantity, price]);
  const formattedPrice = useMemo(() => {
    if(offer?.estimatedSavings !== undefined) {
      return <><span className="discountedPrice"><FormattedPrice value={totalItemPrice} /></span> {priceAfterOffer === 0 ? 'Free' : <FormattedPrice value={priceAfterOffer} />}</>;
    }
    return <FormattedPrice value={totalItemPrice} />;
  }, [offer?.estimatedSavings, totalItemPrice, priceAfterOffer]);

  const isOrderingDisabled = useMemo(() => channel !== Channel.ECOMM && orderingDisabled, [channel, orderingDisabled]);

  if(isOrderingDisabled) {
    return (
      <button type="button" disabled className="modalButton flush">
        Currently not accepting orders
      </button>
    );
  }

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const futureSchedules = fulfillmentData?.scheduleData.futureScheduleDates;
  if(displayedModifier?.isRoot && fulfillmentType && !verifyItemIsFulfillable(displayedModifier.modifier.itemGuid, fulfillmentType, futureSchedules ?? [])) {
    return (
      <button type="button" disabled className="modalButton flush">
        This item is not currently available
      </button>
    );
  }

  return (
    displayedModifier?.isRoot && !isOrderingDisabled ?
      <>
        <div className="addToCart">
          {!displayedModifier.usesFractionalQuantity ?
            <div className="actionWrapper">
              <QuantitySelector min={1} canIncrease={true} disabled={isDisabled} defaultValue={quantity} onQuantityChange={setQuantity} totalItemPrice={totalItemPrice} />
            </div>
            : null}
          <div className="actionWrapper primary">
            <button
              type="button"
              className="modalButton"
              disabled={isDisabled}
              onClick={async () => {
                await onSubmit();
                if(promptFulfillmentTimeUpdate) {
                  await setFulfillmentModalOpen(true);
                }
              }}
              data-testid="menu-item-cart-cta">
              <div>{selectionGuid && !isReorder ? 'Update Cart' : 'Add to Cart' }</div>
              {!loading && <div>{isNaN(quantity) ? '0.00' : formattedPrice}</div>}
            </button>
          </div>
        </div>
        {isInvalid && invalidRequiredGroups?.length > 0 &&
        <div role="alert" className="ariaAlert">
          <div className="error">The following required modifiers need to be corrected: </div>
          <ul>
            {invalidRequiredGroups.map(group =>
              <li key={group.guid}>{group.name}</li>)}
          </ul>
        </div>}
      </> :
      <button type="button" className="modalButton flush"
        onClick={displayedModifier?.isRoot ? onClose : submitModifier}
        data-testid="modifier-item-continue-cta">
                Continue
      </button>
  );
};

const WrappedModalBody = (props: BodyProps, ref: React.RefObject<HTMLDivElement>) => {
  const {
    groups,
    image,
    loading,
    name,
    basePrice,
    itemGuid,
    itemGroupGuid,
    itemMenuGuid,
    description,
    itemTags,
    specialRequestsPlaceholder, specialRequestsEnabled, specialRequestsContent,
    shouldShowHighlights
  } = props;
  const {
    displayedModifier,
    modifiers,
    dropModifier,
    addSpecialRequests,
    quantity,
    setQuantity,
    deselect
  } = useModifierContext();
  const { error } = useCart();
  const { ooRestaurant, restaurant: { meta: { primaryColor, theme } } } = useRestaurant();
  const { validateItemFulfillmentTime, timeBasedRulesMap } = useTimeBasedRules();
  const { getOffersTextForEntity } = useOffers();
  const offersText = getOffersTextForEntity(itemGuid, itemGroupGuid, itemMenuGuid);
  const fulfillmentTimeValid = validateItemFulfillmentTime(itemGuid);
  const timeBasedRules = itemGuid && timeBasedRulesMap[itemGuid];
  const [prevGuid, setPrevGuid] = useState<string | null>(null);
  const [wasPrev, setWasPrev] = useState(false);
  const restaurantI18n: I18nType = ooRestaurant?.i18n as I18nType;
  const fallbackColor = primaryColor ?? DEFAULT_COLORS.primary ?? '#000000';
  const tagColor = theme?.enabled ? theme.colorScheme.border.default : fallbackColor;

  const isSelected = modifiers.length > 1 && displayedModifier?.groupGuid
        && !!modifiers[modifiers.length - 2]?.modifierGroups[displayedModifier?.groupGuid]?.[displayedModifier.modifier.itemGuid];
  const previousModifier = modifiers.length > 1 ? modifiers[modifiers.length - 2] : null;

  useEffect(() => {
    // Determine if the modifier that's about to be displayed was already on the stack.
    // If it was, we don't want to animate its entrance
    if(previousModifier) {
      setWasPrev(prevGuid === displayedModifier?.modifier.itemGuid);
      setPrevGuid(previousModifier.modifier.itemGuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousModifier, displayedModifier]);

  const schema = useMemo(() => {
    if(name) {
      return (
        <SchemaThing<MenuItem> json={routes => ({
          '@context': 'https://schema.org',
          '@type': 'MenuItem',
          '@id': routes.location,
          name: name,
          ...description ? { description: description } : {},
          image: image ? formatImageURL(image) : undefined,
          ...ooRestaurant?.guid
            ? {
              subjectOf: {
                '@type': 'Menu',
                '@id': routes.orderUrl(ooRestaurant.guid)
              }
            }
            : {},
          identifier: itemGuid ?? undefined,
          offers: basePrice
            ? {
              '@type': 'OfferForPurchase',
              priceCurrency: restaurantI18n.currency,
              price: basePrice
            }
            : undefined
        })} />
      );
    }
    return null;
  }, [name, description, image, itemGuid, restaurantI18n, ooRestaurant?.guid, basePrice]);

  const getDisplay = useCallback((modifier: WrappedModifier | null, shouldEnter: boolean, shouldExit: boolean) => {
    const nameForDisplay = shouldShowHighlights && name
      ? <SearchHighlightedText text={name} />
      : name;

    const descriptionForDisplay = shouldShowHighlights && description
      ? <SearchHighlightedText text={description} />
      : description;
    return (
    // Force showing the root modifier if we're SSRing
      modifier && !modifier?.isRoot && typeof window !== 'undefined' ?
        <motion.div
          key={modifier.modifier.itemGuid}
          initial={shouldEnter ? { marginLeft: '100%' } : false}
          animate={{ marginLeft: '0%' }}
          exit={shouldExit ? { marginLeft: '100%' } : undefined}
          transition={{ duration: 0.5 }}
          className="secondaryContent"
          ref={ref}>
          <div className="header secondary">
            <ModalCloseButton className="itemModalCloseButton" />
            <div className="header-row">
              <div className="backButton" onClick={dropModifier}>
                <Image alt="Back button" src="/icons/caret-left.svg" eagerLoad />
              </div>
              <h2 className="modalTitle" id={headerId}>{nameForDisplay}</h2>
            </div>
          </div>
          <div className="paddedContent">
            <p className="subTitle">{modifier.modifier.name}</p>
            {isSelected ?
              <button type="button" className="modalButton marginBottom" onClick={() => {
                // Order is important here: first we disregard any current selections, then we
                // remove the modifier from the parent modifier group
                dropModifier();
                deselect(displayedModifier.groupGuid, displayedModifier.modifier.itemGuid);
              }}>
                Remove
              </button>
              : null}
            <div className="modifierGroups" role="list">
              {modifier.modifier.modifierGroups.map((group: ModifierGroup, index) => <SelectionList
                key={group.guid} group={group} firstModifierGroup={index === 0} />)}
            </div>
          </div>
        </motion.div> :
        <div className="content" ref={ref}>
          <ModalCloseButton className="itemModalCloseButton" />
          {modifier && modifier?.isRoot || (image || loading) ?
            image || loading ?
              <div className="imageWrapper">
                {image ?
                  <Image alt="" src={formatImageURL(image)} className="itemModalImage" eagerLoad />
                  : null}
              </div>
              : null
            : null}
          {timeBasedRules && <TimeBasedRuleItemModalTag timeBasedRules={timeBasedRules} fulfillmentTimeValid={fulfillmentTimeValid} testId={'time-based-ordering-item-tag-' + itemGuid} />}
          <div className="header">
            <div className="header-row">
              {modifier && !modifier?.isRoot ?
                <div className="backButton" onClick={dropModifier}>
                  <Image alt="Back button" src="/icons/caret-left.svg" eagerLoad />
                </div>
                : null}
              {nameForDisplay && <h2 className="modalTitle" id={headerId}>{loading ? <Skeleton /> : nameForDisplay}</h2>}
            </div>
          </div>
          <div className="paddedContent">
            <h3 className="subHeader">
              {error?.kind === 'CartModificationError' ?
                <div className="description"><ErrorNotice>{error.message}</ErrorNotice></div>
                : null}
              {descriptionForDisplay && <p className="description">{loading ? <Skeleton width="100%" /> : descriptionForDisplay}</p>}
              {(itemTags && itemTags.length > 0 || offersText.length > 0) &&
                <div className="itemTagsContainer wrap" data-testid="menu-item-tags">
                  {offersText.map(offer => <OfferBadge key={offer} text={offer.toUpperCase()} />)}
                  {itemTags?.map(tag => <MenuItemTag key={tag.guid} subtle tagColor={tagColor} testId="item-tag">{tag.name?.toUpperCase()}</MenuItemTag>)}
                </div>}
            </h3>
            <div className="modifierGroups" role="list">
              {groups
                ? groups.map((group: ModifierGroup) => <SelectionList key={group.guid} group={group} />)
                : <SelectionList />}
            </div>
            {specialRequestsEnabled ?
              <div className="specialRequests modifierGroup">
                <label htmlFor="items-modal-special-request" className="title">Special Instructions</label>
                <div className="row textarea">
                  <textarea
                    id="items-modal-special-request"
                    onChange={e => {
                      addSpecialRequests(e.target.value);
                    }}
                    disabled={loading}
                    placeholder={specialRequestsPlaceholder || undefined}
                    aria-placeholder={specialRequestsPlaceholder || undefined}
                    maxLength={200}
                    defaultValue={specialRequestsContent || undefined}
                    rows={4} />
                </div>
              </div>
              : null}
            {modifier?.usesFractionalQuantity ?
              <div className="fractionalQuantity modifierGroup">
                <div className="title">Quantity</div>
                <div className="quantity">
                  <input
                    type="number"
                    inputMode="decimal"
                    disabled={loading}
                    name="fractionalQuantityInput"
                    placeholder="0.00"
                    defaultValue={quantity.toFixed(2)}
                    onChange={e => setQuantity(parseFloat(e.target.value))} />
                  <label
                    htmlFor="fractionalQuantityInput">{modifier.unitOfMeasure?.toLowerCase()}
                  </label>
                </div>
                <i>{modifier.price}/{modifier.unitOfMeasure?.toLowerCase()}</i>
              </div>
              : null}
          </div>
        </div>
    );
  }, [addSpecialRequests,
    ref,
    description,
    itemTags,
    tagColor,
    dropModifier,
    error?.kind,
    error?.message,
    groups,
    image,
    itemGuid,
    loading,
    name,
    quantity,
    setQuantity,
    specialRequestsContent,
    specialRequestsEnabled,
    specialRequestsPlaceholder,
    isSelected,
    deselect,
    displayedModifier?.groupGuid,
    displayedModifier?.modifier.itemGuid,
    timeBasedRules,
    offersText,
    fulfillmentTimeValid,
    shouldShowHighlights]);

  return (
    <>
      {modifiers.length > 1 && displayedModifier ?
        <div key={displayedModifier.modifier.itemGuid} className="background" aria-hidden>
          {getDisplay(displayedModifier, false, false)}
        </div>
        : null}
      <AnimatePresence exitBeforeEnter>
        <React.Fragment key={`schema-${displayedModifier?.modifier.itemGuid}`}>
          {schema}
        </React.Fragment>
        {previousModifier ?
          <div key={`animate-${previousModifier.modifier.itemGuid}`} className="background" aria-hidden>
            {getDisplay(previousModifier, false, false)}
          </div>
          : null}
        <React.Fragment key={`animate-${displayedModifier?.modifier.itemGuid}`}>
          {getDisplay(displayedModifier, !wasPrev, true)}
        </React.Fragment>
      </AnimatePresence>
    </>
  );
};

const ModalBody = React.forwardRef(WrappedModalBody);

// eslint-disable-next-line camelcase
const ALCOHOL_ITEM_TAG: Menus_MenuItemTag = { name: 'ID REQUIRED' };

const WrappedItemModal = (props: Props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { loadingCart: loadingCartState } = useCart();
  const { fulfillmentData } = useFulfillment();
  const { isOpen, itemGuid, itemGroupGuid, restaurantGuid } = props;

  const { openAlertModal } = useAlertModalContext();

  const shouldSkip = !restaurantGuid || !itemGuid || !itemGroupGuid || !isOpen;
  const hasCartSelection = Boolean(props.cartGuid && props.selectionGuid);
  const hasReorderSelection = Boolean(props.orderGuid && props.selectionGuid);
  const hasSelection = hasCartSelection || hasReorderSelection;

  const { data, error, loading: loadingDetails, called: calledDetails } = useMenuItemDetailsQuery({
    variables: {
      input: {
        itemGuid: itemGuid || '',
        itemGroupGuid: itemGroupGuid || '',
        restaurantGuid: restaurantGuid || '',
        dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime,
        channelGuid: props.channelGuid
      },
      nestingLevel: 10
    },
    skip: shouldSkip || !isOpen || !!hasSelection
  });

  const [loadCartSelection, {
    data: cartSelection,
    error: errorCart,
    loading: loadingCart,
    called: calledCart
  }] = useMenuItemFromCartLazyQuery({
    variables: {
      input: {
        itemGuid: itemGuid || '',
        itemGroupGuid: itemGroupGuid || '',
        restaurantGuid: restaurantGuid || '',
        selectionGuid: props.selectionGuid || '',
        cartGuid: props.cartGuid || '',
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime
      },
      nestingLevel: 10
    },
    fetchPolicy: 'no-cache'
  });

  const [loadReorderSelection, {
    data: reorderSelection,
    error: errorReorder,
    loading: loadingReorder,
    called: calledReorder
  }] = useReorderableMenuItemLazyQuery({
    variables: {
      input: {
        restaurantGuid: restaurantGuid || '',
        orderGuid: props.orderGuid || '',
        selectionGuid: props.selectionGuid || '',
        dateTime: fulfillmentData?.cartFulfillmentData?.fulfillmentDateTime
      },
      nestingLevel: 10
    },
    fetchPolicy: 'no-cache'
  });

  const { data: doMenuItemData, error: errorMenuItem } = useDoMenuItemQuery({
    variables: {
      input: {
        menuGroupGuid: itemGroupGuid || '',
        menuItemGuid: itemGuid || '',
        restaurantGuid: restaurantGuid || '',
        // eslint-disable-next-line camelcase
        visibility: Menus_VisibilityEnum.ToastOnlineOrdering,
        dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime,
        channelGuid: props.channelGuid
      }
    },
    skip: shouldSkip || !isOpen
  });

  const errorSelection = errorCart || errorReorder;
  const loadingSelection = loadingCart || loadingReorder;
  const calledSelection = calledCart || calledReorder;
  const selectionItemDetails = cartSelection?.selectionItemDetails || reorderSelection?.reorderableSelectionItemDetails;

  useEffect(() => {
    if(error || errorSelection || errorMenuItem) {
      openAlertModal(REQUEST_FAILURE_MESSAGE);
      reportError('Error loading menu item.', error || errorSelection || errorMenuItem);
    }
  }, [error, errorSelection, errorMenuItem, openAlertModal]);

  const modifierMap = doMenuItemData?.doMenus_findMenuItem?.__typename === 'DoMenus_FindMenuItemResponse'
    ? doMenuItemData?.doMenus_findMenuItem?.menuResponse?.modifierGroupReferences?.reduce((map, ref) => ({
      ...map,
      [ref.guid]: ref
    }), {})
    : {};

  const loading = calledDetails && loadingDetails || calledSelection && loadingSelection;

  useEffect(() => {
    if(!shouldSkip && isOpen) {
      if(hasReorderSelection) {
        loadReorderSelection();
      } else if(hasCartSelection) {
        loadCartSelection();
      }
    }
  }, [shouldSkip, isOpen, hasCartSelection, hasReorderSelection, loadCartSelection, loadReorderSelection]);

  const modifierGroups = useMemo(() => data?.menuItemDetails.modifierGroups ? collapseModifierGroups(data?.menuItemDetails.modifierGroups) : {}, [data]);

  const rootModifier = useMemo(() => {
    const getModifiersFromSelectionQuery = (selectionItemDetails: MenuItemFromCartQuery['selectionItemDetails']) => {
      return {
        ...collapseModifier({
          ...selectionItemDetails,
          price: selectionItemDetails.menuItemPrice
        }),
        specialInstructions: selectionItemDetails?.specialRequest,
        usesFractionalQuantity: selectionItemDetails?.usesFractionalQuantity,
        unitOfMeasure: selectionItemDetails?.fractionalQuantity?.unitOfMeasure,
        name: data?.menuItemDetails.name
      };
    };

    const getModifiersFromMenuQuery = () => {
      return {
        groupGuid: itemGroupGuid || 'root',
        modifier: {
          itemGuid: props.itemGuid!,
          itemGroupGuid,
          modifierGroups: data?.menuItemDetails.modifierGroups || [],
          price: data?.menuItemDetails.prices[0] || 0
        },
        modifierGroups,
        isRoot: true,
        masterId: data?.menuItemDetails?.masterId,
        action: 'replace' as Action,
        price: data?.menuItemDetails.prices[0] || 0,
        usesFractionalQuantity: data?.menuItemDetails?.usesFractionalQuantity,
        unitOfMeasure: data?.menuItemDetails?.unitOfMeasure,
        name: data?.menuItemDetails.name
      };
    };

    return !calledSelection || !selectionItemDetails
      ? getModifiersFromMenuQuery()
      : getModifiersFromSelectionQuery(selectionItemDetails);
  }, [calledSelection, selectionItemDetails, data, itemGroupGuid, modifierGroups, props.itemGuid]);
  const itemTags = useMemo(() => {
    // eslint-disable-next-line camelcase
    const tags: Menus_MenuItemTag[] = [];
    // eslint-disable-next-line camelcase
    if(data?.menuItemDetails?.contentAdvisories?.alcohol?.containsAlcohol === Menus_ContainsAlcohol.Yes) {
      tags.push(ALCOHOL_ITEM_TAG);
    }

    return [...tags, ...props.itemTags ?? []];
  }, [data?.menuItemDetails?.contentAdvisories?.alcohol?.containsAlcohol, props.itemTags]);

  if(shouldSkip) {
    return null;
  }

  return (
    <ModifierContextProvider modifier={rootModifier} rootRef={rootRef} modifierGroupData={modifierMap}
      quantity={selectionItemDetails?.fractionalQuantity?.quantity || selectionItemDetails?.quantity || 1}>
      <div className="itemModalContainer">
        <div className="itemModal">
          <ModalBody
            name={data?.menuItemDetails.name || selectionItemDetails?.name}
            itemGuid={props.itemGuid}
            itemGroupGuid={props.itemGroupGuid}
            itemMenuGuid={props.itemMenuGuid}
            basePrice={data?.menuItemDetails.prices[0] || 0}
            specialRequestsEnabled={props.specialRequestsEnabled}
            specialRequestsPlaceholder={props.specialRequestsPlaceholder}
            specialRequestsContent={selectionItemDetails?.specialRequest}
            description={data?.menuItemDetails.description || selectionItemDetails?.description}
            itemTags={itemTags}
            groups={!loading ? data?.menuItemDetails.modifierGroups || selectionItemDetails?.modifierGroups || [] : null}
            image={data?.menuItemDetails?.imageUrls?.medium || selectionItemDetails?.imageUrls?.medium}
            loading={loading}
            ref={rootRef}
            shouldShowHighlights={props.shouldShowHighlights} />
        </div>
        <ModalActions loading={loading} isReorder={hasReorderSelection} selectionGuid={props.selectionGuid} offer={props.offer} channelGuid={props.channelGuid} />
        {loadingCartState && loadingCart && <LoadingSpinnerOverlay withBorderRadius />}
      </div>
    </ModifierContextProvider>
  );
};

const ItemModal = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay fadeIn={props.withTransitions} fadeOut />
      <ModalContent wrapperClassName="itemModalWrapper" contentClassName="itemModalContent" ariaLabelledBy={headerId}>
        <WrappedItemModal key="modal" {...props} />
      </ModalContent>
    </Modal>
  );
};

export default ItemModal;
