import { AdsManagerConversionTagsConfig, AdsManagerRestaurantConversionsData } from 'src/apollo/onlineOrdering';
import { formatToFacebook, formatToGoogleAnalytics } from 'src/shared/components/common/ads_manager/adsManagerFormatters';

export const extractAdsPixelMeta = (adsManagerConversionTagsConfig?: AdsManagerConversionTagsConfig | null) => {
  if(!adsManagerConversionTagsConfig) {
    return [];
  }

  return adsManagerConversionTagsConfig.restaurantConversionsDataList?.map((conversionData: AdsManagerRestaurantConversionsData) => {
    const googleTag = formatToGoogleAnalytics(conversionData?.googleConversionsData?.tagId ?? '');
    const metaTag = formatToFacebook(conversionData?.metaConversionsData?.pixelId ?? '');
    return [googleTag, metaTag];
  }).flat()
    .filter(tag => tag !== '');
};
