import React from 'react';

import { FieldType, ContainsEditableProps, useEditor, _heading, _p } from '@toasttab/sites-components';

import { Image, PaddingEnum } from 'src/apollo/sites';
import { getSectionPaddingModule } from 'src/shared/components/common/editor_context/editableUtils';

import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useOOLocations from 'shared/js/hooks/useOOLocations';

import CTAs, { CTAData } from 'public/components/default_template/ctas';

type Props = {
  orientation: 'left' | 'right';
  header: string;
  bodyText: string;
  withUnderline?: boolean | null;
  mainImgDescription?: string | null;
  mainImgSrc: string;
  featureImgSrc?: string | null;
  featureImgDescription?: string | null;
  mainImage?: Image | null;
  featureImage?: Image | null;
  primaryCta?: CTAData | null;
  nonPrimaryCtas?: CTAData[];
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

const PhotoSection = ({ padding = PaddingEnum.Medium, ...props }: Props) => {
  const { restaurant, orderingUrl, reservationsUrl, phoneNumber, locations, updateLocation } = useRestaurant();
  const ooLocations = useOOLocations();
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'photo section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: props.editPath,
    schema: {
      fields: [
        getSectionPaddingModule(props.editPath, padding),
        {
          path: `${props.editPath}.orientation`,
          displayName: 'Orientation',
          type: FieldType.Enum,
          value: props.orientation,
          enums: [{ displayName: 'Left', value: 'left' }, { displayName: 'Right', value: 'right' }]
        },
        {
          path: `${props.editPath}.withUnderline`,
          displayName: 'Underlined',
          type: FieldType.Boolean,
          value: props.withUnderline ?? false
        }
      ]
    }
  } );

  const pathToHeader = `${props.editPath}.header`;
  const pathToBody = `${props.editPath}.body`;

  const textContent =
    <div className={`textContent ${props.orientation}`}>
      <_heading styleLevel={2} className="title" html={props.header} editPath={pathToHeader} />
      <hr className={`separator ${props.withUnderline ? 'primaryColorBackground' : 'empty'}`} />
      <_p html={props.bodyText} className="body" editPath={pathToBody} />
      {props.primaryCta || props.nonPrimaryCtas ?
        <div className="ctas">
          <CTAs
            primaryCta={props.primaryCta}
            nonPrimaryCtas={props.nonPrimaryCtas}
            restaurantConfig={restaurant.config}
            orderingUrl={orderingUrl}
            reservationsUrl={reservationsUrl}
            phoneNumber={phoneNumber}
            locations={locations}
            ooLocations={ooLocations}
            updateLocation={updateLocation} />
        </div>
        : null}
    </div>;

  const pathToMainImg = `${props.editPath}.mainImg`;
  const pathToFeatureImg = `${props.editPath}.featureImg`;

  const photoContent =
    <div className={`photoContent ${props.orientation}`}>
      <div className="anchorPhoto">
        <_Image
          imageObject={props.mainImage}
          imageObjectPath={`${props.editPath}.mainImage`}
          src={props.mainImgSrc}
          className="mainImg"
          alt={props.mainImgDescription || 'Section main image'}
          editPath={pathToMainImg} />
        {props.featureImgSrc ?
          <_Image
            imageObject={props.featureImage}
            imageObjectPath={`${props.editPath}.featureImage`}
            src={props.featureImgSrc}
            className="featureImg"
            alt={props.featureImgDescription || 'Section feature image'}
            editPath={pathToFeatureImg} /> :
          null}
      </div>
    </div>;

  return (
    <div ref={editableRef} className={`photoSection ${props.orientation}`}>
      {props.orientation === 'left' ?
        <>{textContent}{photoContent}</>
        : <>{photoContent}{textContent}</>}
    </div>
  );
};

export default PhotoSection;
