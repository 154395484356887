import React from 'react';
import { useLocation } from 'react-router';

import { useEditor } from '@toasttab/sites-components';

import { getHostFromWindow } from 'src/lib/js/utilities';

import config from 'config';

type Props = {
  googleTagManagerId: string;
  testId?: string;
}

export const TrackingIFrame = ({ googleTagManagerId, testId }: Props) => {
  const { isEditor } = useEditor();
  if(isEditor) {
    return null;
  } else {
    return <LiveSiteTrackingIFrame googleTagManagerId={googleTagManagerId} testId={testId} />;
  }
};


const LiveSiteTrackingIFrame = ({ googleTagManagerId, testId }: Props) => {
  const location = useLocation();
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  // No server-side rendering. Just client-side rendering.
  if(!isClient) {
    return null;
  }
  const url = new URL('/tracking' + location.pathname, `${config.server.protocol}://${getHostFromWindow()}`);
  url.searchParams.set('gtmId', googleTagManagerId);
  url.searchParams.set('title', document.title);
  /*
   * Per SECR-796, never enable the following features in the iframe sandbox
   *  - allow-top-navigation
   *  - allow-top-navigation-by-user-activation
   *  - allow-popups-to-escape-sandbox
   *  - allow-pointer-lock
   *  - allow-orientation-lock
   *
   * Additionally, this iframe is for invisible pixel tracking, so we should never need allow-presentation or allow-popups.
   */
  return <iframe src={url.toString()} sandbox="allow-scripts allow-forms allow-same-origin" hidden={true} height={0} width={0} data-testid={testId}></iframe>;
};

