import React, { useRef, useState } from 'react';

import { PaginatedMenuItemsQuery } from 'src/apollo/onlineOrdering';
import { MenuConfig } from 'src/apollo/sites';
import useOnVisible from 'src/lib/js/hooks/useOnVisible';
import MenuSection from 'src/public/components/default_template/menu_section/MenuSection';
import { LoadingSpinner } from 'src/shared/components/common/loading_spinner/LoadingSpinner';
import LoadingSpinnerOverlayWrapper from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';


type Props = {
  menuItems: NonNullable<PaginatedMenuItemsQuery['paginatedMenuItems']['items']>
  loading: boolean;
  loadingNewFilterData: boolean;
  fetchMore: (offset: number) => void;
  menuConfig: MenuConfig | null | undefined;
  hideOutOfStockItems?: boolean | null;
  channelGuid?: string;
}

export const PaginatedMenu = ({ menuItems, loading, loadingNewFilterData, fetchMore, menuConfig, hideOutOfStockItems, channelGuid }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { restaurant: siteRestaurant, ooRestaurant: restaurant } = useRestaurant();
  const restaurantGuid = restaurant?.guid;
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  useOnVisible(ref, () => {
    if(loading) {
      return;
    }

    setShowLoadingSpinner(true);
    fetchMore(menuItems.length);

    // artificially delay removing the loading spinner to force the UI for fast menu loads
    setTimeout(() => {
      setShowLoadingSpinner(false);
    }, 2000);
  }, { rootMargin: '1px 1px 1px 1px' });

  return (
    <div className="paginatedMenu">
      {!loading && loadingNewFilterData && !!menuItems.length && !!ref.current && <LoadingSpinnerOverlayWrapper top />}
      <div className="paddedContentWrapper">
        <div className="paddedContent">
          <MenuSection
            shortUrl={restaurant?.shortUrl}
            channelGuid={channelGuid}
            specialRequestsEnabled={restaurant?.specialRequestsConfig?.enabled}
            specialRequestsPlaceholder={restaurant?.specialRequestsConfig?.placeholderMessage}
            restaurantGuid={restaurantGuid}
            orientation={siteRestaurant.config.ooConfig?.cardOrientation}
            menuConfig={{ format: menuConfig?.format, colors: menuConfig?.colors }}
            menuItems={hideOutOfStockItems ? menuItems.filter(item => !item.outOfStock) : menuItems} />
        </div>
      </div>
      <div ref={ref} className="loader">
        {showLoadingSpinner && !!ref.current && !!menuItems.length && <LoadingSpinner />}
      </div>
    </div>
  );
};
