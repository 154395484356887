import React from 'react';

import Image from 'shared/components/common/Image';

const TTOPromo = () => {
  return (
    <div className="promoContainer">
      <div className="promoImg">
        <Image alt="App on phone" src="toastLocal/splash/toast-takeout-native-app-362.jpg" />
      </div>
      <div className="promoContent">
        <h2>Order from your favorites on the app</h2>
        <h3>Download Toast Takeout to place orders for pickup or delivery from top restaurants near you</h3>

        <a href="https://toasttakeout.page.link/Ktwp"
          rel="nofollow"
          className="button-gray iosButton">
        iOS
        </a>
        <a
          href="https://toasttakeout.page.link/Ktwp"
          rel="nofollow"
          className="button-gray androidButton">
        Android
        </a>

        <div className="dinerSupport">
          Issue with your food order? Please contact the restaurant directly. <a href="mailto:dinersupport@toasttab.com" >Contact Us</a> for all other diner inquiries.
        </div>
      </div>
    </div>
  );
};

export default TTOPromo;
