import { useRef, useMemo, useEffect } from 'react';

import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';
import { useOffers } from 'src/public/components/online_ordering/OffersContext';
import { useTimeBasedRules } from 'src/public/components/online_ordering/TimeBasedRuleContext';
import { useOOMenus } from 'src/public/components/online_ordering/useOOMenu';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

/**
 * @param skip - additional flag to optionally skip tracking the order page view event.
 */
export const useTrackOrderPage = (skip: boolean = false) => {
  const { track } = useThrottledTracker();

  const { ooRestaurant: restaurant, diningOptions, ooPromoBanners } = useRestaurant();
  const { timeBasedRulesMap, loading: timeBasedRulesLoading } = useTimeBasedRules();
  const { rankedPromoOfferDiscounts, loading: offersLoading } = useOffers();
  const orderPageViewRef = useRef(false);
  const { hasLoyaltyAccount } = useLoyalty();
  const { deliveryEnabled, selectedDiningOptionBehavior } = useFulfillment();
  const { cart } = useCart();
  const { loading: menusLoading, menuItems } = useOOMenus({});

  const userId = useExperimentUserId();
  const { customer } = useCustomer();

  const loyaltyStatus = useMemo(() => {
    if(restaurant?.loyaltyConfig?.programName) {
      if(customer) {
        return hasLoyaltyAccount ? 'Authenticated and a rewards member' : 'Authenticated and not a rewards member';
      }
      return 'Guest';
    }
    return undefined;
  }, [customer, hasLoyaltyAccount, restaurant?.loyaltyConfig]);

  useEffect(() => {
    if(typeof window !== 'undefined' && !orderPageViewRef.current && !timeBasedRulesLoading && !offersLoading && !menusLoading && diningOptions && !skip) {
      track('Order page view', {
        isAsapAvailable: !!diningOptions.some(option => option.asapSchedule?.availableNow),
        isScheduledAvailable: !!diningOptions.some(option => option.futureSchedule?.dates?.length),
        leadTimeRuleCount: Object.values(timeBasedRulesMap).filter(rules => rules.leadTimeRule).length,
        preorderRuleCount: Object.values(timeBasedRulesMap).filter(rules => rules.preorderRule).length,
        userExperimentId: userId,
        hasOOEntitlement: restaurant?.hasOnlineOrderingModule ?? true,
        numOffersPresent: rankedPromoOfferDiscounts?.length ?? 0,
        isDeliveryThresholdOfferPresent: Boolean(ooPromoBanners?.deliveryBanners.length),
        isDeliveryAvailable: deliveryEnabled,
        loyaltyProgramStatus: loyaltyStatus,
        diningOption: selectedDiningOptionBehavior,
        deliveryProvider: cart?.deliveryProviderInfo?.provider,
        menuNavExperience: (menuItems ?? []).length > 1500 ? 'Paginated' : 'Non-paginated'
      });
      orderPageViewRef.current = true;
    }
    // diningOptions, timeBasedRulesMap, and rankedPromoOfferDiscounts get set when the data finishes loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    skip,
    diningOptions,
    timeBasedRulesMap,
    restaurant?.hasOnlineOrderingModule,
    rankedPromoOfferDiscounts,
    ooPromoBanners,
    timeBasedRulesLoading,
    offersLoading,
    deliveryEnabled
  ]);
};
