import React, { useMemo, useState } from 'react';

import classnames from 'classnames';

import { NearbyRestaurantSearchInput, RxSearchQueryVariables, useRxSearchQuery } from 'src/apollo/onlineOrdering';
import { useQueryString } from 'src/lib/js/hooks/useQueryString';
import { LoadingSpinner } from 'src/shared/components/common/loading_spinner/LoadingSpinner';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import UhOh from 'shared/components/uh_oh/UhOh';

import { View } from 'public/components/pages/location_selection_page/LocationSelection';
import { ListViewIcon, MapViewIcon } from 'public/components/pages/location_selection_page/LocationSelectionIcons';
import ToastLocalHelmet from 'public/components/toast_local/common/ToastLocalHelmet';
import RXCard from 'public/components/toast_local/rx_card/RXCard';
import { DiningOptionFilters } from 'public/components/toast_local/rx_search/RxSearch';
import { pilotAreaDefaultSearchLatLong } from 'public/components/toast_local/rx_search/toast-cash-accepted-helpers';

import { server } from 'config';

type Props = {
  title?: string;
  dish?: string;
  numPerPage?: number;
  utmContent?: string;
  className?: string;
  mapView?: boolean;
}

// 333 Summer St., Boston, MA
const INITIAL_SEARCH_LOCATION = {
  longitude: 42.3489128,
  latitude: -71.0507854
};

const PaginatedRXs = ({ title, numPerPage = 50, utmContent, className, mapView }: Props) => {
  const parsedQS = useQueryString(['filters'], ['filters']);
  const ooClient = useOOClient();
  const [view, setView] = useState<View>(View.List);

  const variables: RxSearchQueryVariables = useMemo(() => {
    let userLat: number | undefined = parsedQS.filters?.userLat;
    let userLong: number | undefined = parsedQS.filters?.userLong;

    // If no user-specified location, use the server-provided one, or the pilot area
    if(!userLat || !userLong) {
      if(parsedQS.filters?.acceptsToastCash === true) {
        userLat = pilotAreaDefaultSearchLatLong.latitude;
        userLong = pilotAreaDefaultSearchLatLong.longitude;
      } else {
        userLat = INITIAL_SEARCH_LOCATION.latitude;
        userLong = INITIAL_SEARCH_LOCATION.longitude;
      }
    }

    const vars: NearbyRestaurantSearchInput = {
      limit: numPerPage,
      coordinates: {
        latitude: userLat,
        longitude: userLong
      }
    };

    if(parsedQS.filters?.diningOption === DiningOptionFilters.DineIn) {
      vars.allowRestaurantsWithoutOnlineOrdering = true;
    }

    let query = '';
    if(parsedQS.filters?.cuisine) {
      query += parsedQS.filters.cuisine;
    }
    if(parsedQS.filters?.search) {
      query += ' ' + parsedQS.filters.search;
    }
    vars.searchQuery = query;

    return { input: vars };
  }, [numPerPage, parsedQS]);

  const { loading, error, data } = useRxSearchQuery({ variables, skip: !variables, client: ooClient });

  const restaurants = React.useMemo(() => {
    const clientSideFiltered = data?.nearbyRestaurantSearch.results.map(r => r.restaurant)
      .filter(r => {
        if(parsedQS.filters?.acceptsToastCash === true) {
          return r.guestCurrencyConfig.promoEnabled && r.guestCurrencyConfig.storedValueEnabled;
        }
        return true;
      })
      .filter(r => {
        if(parsedQS.filters?.diningOption === DiningOptionFilters.DineIn) {
          // for now, only list Dine In rxs which have Toast Pay enabled
          return r.toastPayConfig?.qrCodeOnReceiptEnabled ?? false;
        }
        return true;
      })
      .filter(r => {
        if(parsedQS.filters?.hasOffers) {
          return r.promoBanners?.__typename === 'PromoBannerResponse' && r?.promoBanners?.promoCodeBanners.length > 0;
        }
        return true;
      });
    return clientSideFiltered?.length ? clientSideFiltered : null;
  }, [data, parsedQS]);

  const mapGuids = useMemo(() => restaurants?.map(loc => `guids=${loc.guid}`).join('&'), [restaurants]);
  const firstRx = restaurants && restaurants[0] ? restaurants[0] : undefined;
  const mapShortUrl = firstRx?.shortUrl ? encodeURIComponent(firstRx.shortUrl) : undefined;
  const selectedGuid = firstRx?.guid;

  const mapSrc = useMemo(() =>
    `${server.protocol}://${server.fullHost}/sites-web/v1/map?shortUrl=${mapShortUrl}&sourceId=ToastLocalSearch&order=` +
    `&${mapGuids}&selectedGuid=${selectedGuid}&selectedDiningOption=&style=locationDetails`,
  [mapGuids, mapShortUrl, selectedGuid]);

  if(loading) return <LoadingSpinner />;
  if(error) return <UhOh />;

  return (
    <>
      <ToastLocalHelmet />
      <div className={classnames('paginated-rxs', className)}>
        <h2 className="rx-listing-title">{title}</h2>

        {mapView && view === View.Map && restaurants?.length ?
          <div className="restaurantsMap">
            <iframe title="Google Maps" width="100%" height="100%" loading="eager"
              className="locationMap"
              src={mapSrc}>
            </iframe>
          </div> :
          <div className="restaurants pure-g">
            {restaurants ?
              restaurants.map(rx => <RXCard
                restaurant={rx} utmContent={utmContent} className="pure-u-24-24 pure-u-md-12-24 pure-u-lg-8-24" key={rx.guid} />)
              : <div className="pure-u-24-24"><h2>0 restaurants available. Please broaden your search to have better results.</h2></div>}
          </div>}

        {mapView &&
          <button className="viewButton" onClick={() => setView(view === View.Map ? View.List : View.Map)}>
            {view === View.Map ? <ListViewIcon color="#FF4C00" /> : <MapViewIcon color="#FF4C00" /> }
            <span>{`Show ${view === View.Map ? 'list' : 'map'}`}</span>
          </button>}
      </div>
    </>
  );
};

export default PaginatedRXs;
