import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { _heading, _p, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import isEmail from 'validator/es/lib/isEmail';

import { ButtonType } from 'src/apollo/sites';
import InputField from 'src/shared/components/common/form_input/InputField';

import Button, { ButtonLink } from 'shared/components/common/button';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import { SubmitState } from 'shared/js/hooks/useSubmissionState';

import { validatePhone } from 'public/components/pages/form_page/FormPage';

type TextInfo = {
  /** Either a raw string, or serialized JSON representing the Rich text contents of the header */
  content: string;
  /** if editPath is not specified, then the content is assumed to not be editable */
  editPath?: string;
}

type Props = {
  errorLine1: string,
  errorLine2: string,
  successLine1: string,
  successLine2: string,
  header: TextInfo,
  subheader: TextInfo,
  inputType: 'phone' | 'email',
  disclaimer: any,
  onSubmit: (data: any) => void,
  isSection?: boolean,
  editableRef: React.RefObject<HTMLElement>,
  submitState: SubmitState
}

export const BetterTogetherSection = ({ isSection = false, editableRef, ...props }: Props) => {
  const { isEditor } = useEditor();

  const formMethods = useForm({ mode: 'onTouched' });

  return (
    <>
      {props.submitState.isDone() || props.submitState.isFailed() ?
        <div className="betterTogetherWrapper">
          <div className="betterTogether">
            <div className="heading" role={props.submitState.isFailed() ? 'alert' : ''}>
              <h1 className="postSubmit">{props.submitState.isFailed() ? props.errorLine1 : props.successLine1}</h1>
              <h2>{props.submitState.isFailed() ? props.errorLine2 : props.successLine2}</h2>
              {!isSection && <ButtonLink variant={ButtonType.Secondary} href="/" autoFocus>Back to homepage</ButtonLink>}
            </div>
          </div>
        </div> :
        <FormProvider {...formMethods}>
          <div className="betterTogetherWrapper" ref={editableRef as any} aria-live={'off'}>
            <div className="betterTogether">
              <div className="heading">
                <_heading styleLevel={isSection ? 2 : 1} html={props.header.content} editPath={props.header.editPath || ''} />
                {props.subheader.editPath
                  ? <_p html={props.subheader.content} editPath={props.subheader.editPath} />
                  : <p data-testid="readonly-subheader">{props.subheader.content}</p>}
              </div>
              <div className={classnames('fieldsWrapper', isEditor ? 'disableClicks' : '')}>
                {props.inputType === 'email' &&
                  <InputField
                    validate={(value: string) => !isEmail(value) ? 'Please enter a valid email address' : true}
                    type="email"
                    id="email"
                    label="Email"
                    required={true} />}
                {props.inputType === 'phone' &&
                  <PhoneInput
                    validate={validatePhone}
                    id="phone"
                    label="Phone"
                    required={true} />}
              </div>
              <Button variant={ButtonType.Primary} onClick={formMethods.handleSubmit(props.onSubmit)} disabled={props.submitState.isInProgress()} className="submit">Sign Up</Button>
              <div className="disclaimer">
                {props.disclaimer}
              </div>
            </div>
          </div>
        </FormProvider>}
    </>
  );
};
