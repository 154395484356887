import React, { useEffect, useRef } from 'react';

import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import DemoButton from 'public/components/toast_local/common/DemoButton';
import Footer from 'public/components/toast_local/common/Footer';
import ToastLocalHelmet from 'public/components/toast_local/common/ToastLocalHelmet';
import Nav from 'public/components/toast_local/common/nav/Nav';
import CateringPromo from 'public/components/toast_local/splash_page/CateringPromo';
import CuisineFarm from 'public/components/toast_local/splash_page/CuisineFarm';
import Hero from 'public/components/toast_local/splash_page/Hero';
import Mission from 'public/components/toast_local/splash_page/Mission';
import RXHotSpots from 'public/components/toast_local/splash_page/RXHotSpots';
import TTOPromo from 'public/components/toast_local/splash_page/TTOPromo';
import { MSAS } from 'public/components/toast_local/splash_page/msas.data';


const Splash = () => {
  const { track } = useThrottledTracker();
  const splashPageViewedRef = useRef(false);
  const { loadingCustomer } = useCustomer();

  useEffect(() => {
    if(typeof window !== 'undefined' && !splashPageViewedRef.current && !loadingCustomer) {
      track('Local home view', {});
      splashPageViewedRef.current = true;
    }
  }, [track, loadingCustomer]);


  return (
    <>
      <ToastLocalHelmet />
      <div className="toastLocalSplash">
        <div className="defaultTemplate">
          <Nav />
          <div className="paddedSection"><Hero /></div>
          <div className="paddedSection"><CuisineFarm numCuisines={12} /></div>
          <div className="paddedSection"><Mission /></div>
          <div className="paddedSection"><TTOPromo /></div>
          <div className="paddedSection"><CateringPromo /></div>
          <div className="paddedSection">
            <div className="posPromo">
              <div className="message">
                Represent a restaurant looking to learn more about the Toast platform?
              </div>
              <div>
                <DemoButton buttonLocation="splash" />
              </div>
            </div>
          </div>
          <div className="paddedSection">
            <RXHotSpots title="More to explore" iconProps={{ src: 'toastLocal/splash/flame-orange-blue.svg', height: '60' }} elements={MSAS} />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Splash;
