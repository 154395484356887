import React, { useRef, useState } from 'react';

import { ButtonType } from 'src/apollo/sites';
import Button from 'src/shared/components/common/button';
import DropdownArrow from 'src/shared/components/common/dropdown/DropdownArrow';
import Portal from 'src/shared/components/common/modal/Portal';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

type Option = {
  name: string;
  value: string;
  selected: boolean;
}

type Props = {
  name: string;
  options: (Option & { children: Option[]})[]
  onChange: (value: string | string[]) => void;
  onClear: () => void;
}

export const Filter = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const localFilters = useRef<Set<string>>(new Set());

  const Wrapper = isMobile ? Portal : React.Fragment;

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.key === 'Enter' || e.key === ' ') {
      toggle(e);
    }
  };

  const toggle = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(expanded => !expanded);
  };

  const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // ignore clicks inside of the menu
    if(!isMobile && !event.currentTarget.contains(event.relatedTarget as Node)) {
      setExpanded(false);
    }
  };

  return (
    <div
      className="filter"
      role="combobox"
      aria-haspopup
      aria-expanded={expanded}
      tabIndex={0}
      onKeyDown={handleKeyDown} onBlur={onBlur}>
      <div className="filterLabel" onClick={toggle}>
        {props.name}
      </div>
      <div onClick={toggle} className="arrow">
        <DropdownArrow />
      </div>
      {expanded ?
        <Wrapper>
          <div
            className="filterContent"
            role="listbox">
            <div className="filterOptionsContainer">
              {props.options.map(option => {
                const parentRef = React.createRef<HTMLInputElement>();
                const childRefs = option.children.map(() => React.createRef<HTMLInputElement>());
                const childValues = option.children.map(c => c.value);

                return (
                  <React.Fragment key={option.value}>
                    {childValues.length && childValues.length > 1 ?
                      <label htmlFor={option.value} className="filterOption bold">
                        <input ref={parentRef} type="checkbox" id={option.value} defaultChecked={option.selected} onChange={e => {
                          e.stopPropagation();
                          childRefs.forEach(c => { if(c.current) c.current.checked = e.target.checked; });

                          if(localFilters.current.has(option.value)) {
                            childValues.forEach(v => localFilters.current.delete(v));
                          } else {
                            childValues.forEach(v => localFilters.current.add(v));
                          }
                        }} />
                        {option.name}
                      </label>
                      : null}
                    {(option.children || [option]).map((childOption, index) =>
                      <label key={childOption.value} htmlFor={childOption.value} className="filterOption">
                        <input ref={childRefs[index]} type="checkbox" id={childOption.value} defaultChecked={childOption.selected} onChange={e => {
                          e.stopPropagation();


                          if(localFilters.current.has(childOption.value)) {
                            localFilters.current.delete(childOption.value);
                          } else {
                            localFilters.current.add(childOption.value);
                          }

                          if(childValues && parentRef.current && localFilters.current.size !== childValues.length) {
                            parentRef.current.checked = false;
                          } else if(childValues && parentRef.current && localFilters.current.size === childValues.length) {
                            parentRef.current.checked = true;
                          }
                        }} />
                        {childOption.name}
                      </label>)}
                  </React.Fragment>
                );
              })}
            </div>
            <div className="buttons">
              <Button className="button" variant={ButtonType.Secondary} onClick={(e: React.MouseEvent) => {
                props.onChange(Array.from(localFilters.current));
                localFilters.current = new Set();
                toggle(e);
              }}>
                Apply
              </Button>
              <Button className="button" variant={ButtonType.Secondary} onClick={(e: React.MouseEvent) => {
                props.onClear();
                toggle(e);
              }}>
                Reset
              </Button>
            </div>
          </div>
        </Wrapper>
        : null}
    </div>
  );
};
