import React, { useMemo } from 'react';

import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { MarkerStyle } from 'public/components/google_maps/Map';

import { server } from 'config';


type Props = {
  className?: string;
  selectedDiningOption?: DiningOptionBehavior;
  sourceId?: string;
  hideDefaultLocation?: boolean;
  markerStyle?: MarkerStyle;
  locationGuids?: string[]
}

const LocationMap = (props: Props) => {
  const { selectedLocation, host } = useRestaurant();
  const { orderPathPattern } = useRestaurantRoutes();
  const guids = useMemo(() => props.locationGuids?.map(guid => `guids=${guid}`).join('&'), [props.locationGuids]);
  const shortUrl = selectedLocation.shortUrl ? encodeURIComponent(selectedLocation.shortUrl) : undefined;

  const src = useMemo(() =>
    `${server.protocol}://${server.fullHost}/sites-web/v1/map?shortUrl=${shortUrl}&sourceId=${props.sourceId || ''}&hostName=${host}&orderPathPattern=${orderPathPattern}` +
    `&${guids}&selectedGuid=${!props.hideDefaultLocation ? selectedLocation.externalId : ''}&selectedDiningOption=${props.selectedDiningOption || ''}&style=${props.markerStyle || ''}`,
  [host, orderPathPattern, props, guids, selectedLocation, shortUrl]);

  return (
    <iframe title="Google Maps" width="100%" height="100%" loading="eager"
      className={classnames('locationMap', props.className)}
      src={src}>
    </iframe>
  );
};

export default LocationMap;
