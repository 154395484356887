import React, { useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';

import classnames from 'classnames';
import fromUnixTime from 'date-fns/fromUnixTime';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { getFulfillmentTimeNotificationText, hasFulfillmentTimeChanged } from 'src/public/components/default_template/online_ordering/checkout/fulfillmentTimeUtils';
import { CartContextProvider } from 'src/public/components/online_ordering/CartContext';
import { LoyaltyContextProvider } from 'src/public/components/online_ordering/LoyaltyContext';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';
import { usePageViewed } from 'src/shared/js/hooks/usePageViewed';
import { asValidPhoneCountryCode } from 'src/shared/js/phoneUtils';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import PhoneLink from 'shared/components/common/link/PhoneLink';
import { toLocalDateTime } from 'shared/js/timeUtils';

import { AppPromo } from 'public/components/default_template/app_promo/AppPromo';
import { useBrandedAppPromo } from 'public/components/default_template/app_promo/useAppPromo';
import { LoyaltyCard, LoyaltyCardType } from 'public/components/default_template/offers/LoyaltyCard';
import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import { calculateDiscountsTotal } from 'public/components/default_template/online_ordering/cart/cartUtils';
import OrderItems, { ECOMM_ITEM_NAME, OrderPrices } from 'public/components/default_template/online_ordering/order/OrderItems';
import { OrderTracker } from 'public/components/default_template/online_ordering/order_tracker/OrderTracker';
import { CreditCardSurchargeDisclaimerBase } from 'public/components/default_template/surcharges/CreditCardSurchargeDisclaimer';
import { useCompletedOrder } from 'public/components/online_ordering/OrderContext';
import { formatDeliveryAddress, formatLocationAddress } from 'public/components/online_ordering/addressUtils';
import { calculateSubtotal } from 'public/components/online_ordering/types';

import { PackagingSelections } from './PackagingSelections/PackagingSelections';
import TakeoutCTA from './TakeoutCTA/TakeoutCTA';

const OrderConfirmation = () => {
  const { completedOrder, loadingCompletedOrder } = useCompletedOrder();
  const { search } = useLocation();
  const orderTotal = completedOrder ? completedOrder.totalV2 - (completedOrder.giftCard?.appliedBalance || 0) : 0;
  const subtotal = useMemo(() => calculateSubtotal(completedOrder), [completedOrder]);
  const { restaurant, ooRestaurant, toastProduct } = useRestaurant();
  const i18nCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);
  const isTdsOrder = !!completedOrder?.deliveryServiceInfo;
  const useTdsOrderTracker = isTdsOrder;
  const isDeliveryOrder = completedOrder?.diningOptionBehavior === DiningOptionBehavior.Delivery;
  const resetReferrerOnPageLoad = (searchParams: URLSearchParams) => {
    const referrer = searchParams.get('source');
    if(referrer != '') {
      searchParams.set('source', '');
    }
  };

  const appPromoData = useBrandedAppPromo();

  const resetReferrer = useCallback(() => {
    if(completedOrder) {
      const searchParams = new URLSearchParams(search);
      // If we are referred via email, we don't want subsequent
      // refreshes of the page to track as email referrers
      resetReferrerOnPageLoad(searchParams);
    }
  }, [completedOrder, search]);

  usePageViewed(resetReferrer);

  if(loadingCompletedOrder) {
    return (
      <div className="orderConfirmation checkoutForm">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if(!completedOrder) {
    return <EmptyCart />;
  }

  const isOOBasic = restaurant.config.isOnlineOrderingOnly && restaurant.config.hasFullCustomization === false;
  const shouldShowCTA = isOOBasic && ooRestaurant?.consumerCTAEnabled;

  const destination = encodeURIComponent([
    completedOrder.restaurant.location.address1,
    completedOrder.restaurant.location.address2 || '',
    completedOrder.restaurant.location.city,
    completedOrder.restaurant.location.state,
    completedOrder.restaurant.location.zip
  ].join(' '));
  const directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

  const fulfillmentTimeChanged = hasFulfillmentTimeChanged(completedOrder);
  const fulfillmentTime = completedOrder.estimatedFulfillmentDate ? getFulfillmentTimeNotificationText(completedOrder.estimatedFulfillmentDate) : undefined;

  const getRxName = () => {
    if(toastProduct === ToastProduct.ToastLocal) {
      // completed order's rx name is the Local by Toast Restaurant List Display Name
      return completedOrder.restaurant.name;
    }

    // using the white label name keeps the confirmation page consistent with the checkout page
    return ooRestaurant?.whiteLabelName || completedOrder.restaurant.name;
  };

  return (
    <div className="orderConfirmation checkoutForm">
      <section className="checkoutSection currentSection flat">
        {fulfillmentTimeChanged && fulfillmentTime && <div className="fulfillmentUpdate">{ isDeliveryOrder ? 'Arrival' : 'Order ready'}{' '}time updated to {fulfillmentTime}</div>}
        <h2 className="checkoutSectionHeader">
          <div>Thanks for your order{completedOrder.customerV3?.firstName && `, ${completedOrder.customerV3?.firstName}`}!</div>
          <div className="checkNumber">Order #{completedOrder.checkNumber}</div>
        </h2>
        <div className="fixedSection">
          <div>An email receipt has been sent to {completedOrder.customerV3?.email || 'you'}</div>
          {/* The order tracker can handle pickup and curbside orders, but for now only render it for TDS orders */}
          {useTdsOrderTracker && <OrderTracker order={completedOrder} />}
          {completedOrder.restaurant.loyaltyConfig?.loyaltySignupEnabled ?
            <CartContextProvider>
              <LoyaltyContextProvider>
                <div className="loyaltySection" data-testid="loyalty-section">
                  <LoyaltyCard type={LoyaltyCardType.OrderConfirmationPage} completedOrderBehavior={completedOrder.diningOptionBehavior} />
                </div>
              </LoyaltyContextProvider>
            </CartContextProvider>
            : <hr />}
          <div className={classnames(useTdsOrderTracker ? 'orderSummary' : '')}>
            <h2 className="subHeader rxName">{getRxName()}</h2>
            <div className="address">{formatLocationAddress(completedOrder.restaurant.location)}</div>
            <PhoneLink className="linkText phone" phoneNumber={completedOrder.restaurant.location.phone} countryCode={i18nCountryCode} />
            <br />
            <br />
            {!useTdsOrderTracker &&
          <div className="sectionRows">
            {completedOrder.estimatedFulfillmentDate &&
              <div className="sectionRow">
                <div className="icon">
                  <Image alt="Order time" src="icons/clock-gray.svg" />
                </div>
                Your order will be {completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivered' : 'ready'}{' '}
                {toLocalDateTime(fromUnixTime(completedOrder.estimatedFulfillmentDate / 1000))}
              </div>}
            {completedOrder.diningOptionBehavior === DiningOptionBehavior.TakeOut &&
              <>
                <div className="sectionRow noMargin">
                  <div className="icon">
                    <Image alt="Location" src="icons/location.svg" />
                  </div>
                  {completedOrder.restaurant.location.address1}{completedOrder.restaurant.location.address2 ? `, ${completedOrder.restaurant.location.address2}` : ''}
                </div>
                <div className="sectionRow directions">
                  <div className="icon"></div>
                  <Link href={directionsLink}>
                    Get directions
                  </Link>
                </div>
              </>}
            <div className={classnames('sectionRow', completedOrder.guestCommunication && 'noMargin')}>
              <div className="icon">
                {completedOrder.curbsidePickup?.selected || completedOrder.curbsidePickupV2 ?
                  <Image alt="Curbside pickup details" src="icons/curbside-gray.svg" /> :
                  <Image alt="Order type" src={completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'icons/delivery.svg' : 'icons/pickup.svg'} />}
              </div>
              {completedOrder.curbsidePickup?.selected || completedOrder.curbsidePickupV2 ?
                'Curbside' :
                completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ?
                  `Delivery${completedOrder.deliveryInfo ? ` to ${formatDeliveryAddress(completedOrder.deliveryInfo)}` : ''}` :
                  'Pickup'}
            </div>
            {completedOrder.guestCommunication &&
              <div className="sectionRow instructions">
                <div className="icon"></div>{completedOrder.guestCommunication}
              </div>}
          </div>}
            <h2 className="subHeader">Order summary</h2>
            <div className="cartOrder">
              <div className="cartData">
                <OrderItems loading={loadingCompletedOrder} selections={completedOrder.selections} />
                {Boolean(completedOrder.packagingSelections.length) &&
                <PackagingSelections packaging={completedOrder.packagingSelections || []} />}
                <OrderPrices
                  loading={loadingCompletedOrder}
                  subtotal={subtotal}
                  discountsTotal={calculateDiscountsTotal(completedOrder?.discounts?.globalReward?.amount || 0, completedOrder.discountsTotal)}
                  diningOptionBehavior={completedOrder.diningOptionBehavior}
                  deliveryFee={completedOrder.deliveryServiceCharges}
                  gratuityServiceCharges={completedOrder.gratuityServiceCharges}
                  processingServiceCharges={completedOrder.processingServiceCharges}
                  nonDeliveryNonGratuityNonUbpServiceCharges={completedOrder.nonDeliveryNonGratuityNonUbpServiceCharges}
                  tax={completedOrder.taxV2}
                  tip={completedOrder.tip}
                  fundraisingAmount={completedOrder.fundraisingTotal}
                  giftCardAppliedAmount={completedOrder.giftCard?.appliedBalance}
                  surchargeAmount={completedOrder.surchargeServiceCharges.baseAmount}
                  surchargeTaxAmount={completedOrder.surchargeServiceCharges.taxAmount}
                  shipping={completedOrder.selections.find(selection => selection.name === ECOMM_ITEM_NAME)?.price}
                  enableTooltip={false} />
                <div className="prices">
                  <div className="cart-flex-row">
                    <div className="totalPrice">Total</div>
                    <div className="totalPrice"><FormattedPrice value={orderTotal} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            completedOrder.surchargeServiceCharges.baseAmount > 0 &&
              <CreditCardSurchargeDisclaimerBase
                className="confirmPageRule"
                surchargeAmount={completedOrder.surchargeServiceCharges.baseAmount + completedOrder.surchargeServiceCharges.taxAmount}
                surchargeRatePercent={completedOrder.surchargeServiceCharges.rate} />
          }
        </div>
        {appPromoData ? <AppPromo appPromoData={appPromoData} /> : null}
      </section>
      {shouldShowCTA && !appPromoData && <ShowForUS><TakeoutCTA orderGuid={completedOrder.guid} /></ShowForUS>}
    </div>
  );
};

export default OrderConfirmation;
