import React from 'react';

import { Currency } from '@toasttab/buffet-pui-number-utilities';
import { Restaurant } from 'schema-dts';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { I18nType } from 'src/lib/js/hooks/useFormatPrice';
import { mergeSchedules, schemaOrgOpeninghours } from 'src/lib/js/schedule';
import { formatImageURL } from 'src/lib/js/utilities';

import { getImageUrl } from 'shared/components/common/Image';
import { useBulkCalls } from 'shared/components/common/dynamic_menus/BulkCallsContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useLocationSchedules } from 'public/components/online_ordering/FulfillmentContext';
import SchemaThing from 'public/components/seo/SchemaThing';

type Props = {
  rxGuid?: string
}

const DynamicMenuPageSchema = ({ rxGuid }: Props) => {
  const { restaurant: site, locations, ooRestaurant } = useRestaurant();
  const locationSchedules = useLocationSchedules();
  const { menuGroups, menuItems } = useBulkCalls();

  const selectedLocation = locations?.find(location => location.externalId === rxGuid);
  const locationSchedule = locationSchedules && selectedLocation?.externalId ?
    locationSchedules[selectedLocation?.externalId]
    : undefined;
  const restaurantI18n: I18nType | undefined = ooRestaurant?.i18n as I18nType | undefined;

  if(!selectedLocation || !selectedLocation.name || !ooRestaurant) {
    return null;
  }
  return <SchemaThing<Restaurant> json={routes => ({
    '@context': 'https://schema.org',
    '@type': 'Restaurant',
    '@id': routes.location,
    sameAs: site.meta?.urls || undefined,
    name: selectedLocation?.name || '',
    servesCuisine: site.meta?.cuisine,
    paymentAccepted: site.meta?.acceptedPaymentTypes,
    priceRange: site.meta?.priceRange,
    image: site.meta.icon ? getImageUrl(site.meta.icon) : undefined,
    address: {
      '@type': 'PostalAddress',
      addressCountry: restaurantI18n?.country || undefined,
      postalCode: selectedLocation?.zipcode,
      addressRegion: selectedLocation?.state,
      addressLocality: selectedLocation?.city,
      streetAddress: selectedLocation?.address2 ?
        `${selectedLocation?.address1}, ${selectedLocation?.address2}`
        : selectedLocation?.address1
    },
    ...selectedLocation?.lat && selectedLocation?.long
      ? {
        geo: {
          '@type': 'GeoCoordinates',
          longitude: `${selectedLocation?.long}`,
          latitude: `${selectedLocation?.lat}`
        }
      }
      : {},
    ...selectedLocation.reservationsUrl ?
      { acceptsReservations: !!selectedLocation.reservationsUrl }
      : {},
    telephone: selectedLocation.phoneNumber || ooRestaurant?.location?.phone,
    openingHoursSpecification: schemaOrgOpeninghours(
      mergeSchedules(selectedLocation, locationSchedule, !!site.config.isOnlineOrderingOnly, DiningOptionBehavior.TakeOut)
    ),
    hasMenu: {
      '@type': 'Menu',
      '@id': `${routes.location}`,
      hasMenuSection: menuGroups?.map(menuGroup => ({
        '@type': 'MenuSection',
        '@id': menuGroup?.guid,
        name: menuGroup?.name,
        description: menuGroup?.description,
        hasMenuItem: menuGroup?.items?.map(menuItem => getMenuItemSchema(menuItem, restaurantI18n?.currency))
      })),
      hasMenuItem: menuItems?.map(menuItem => getMenuItemSchema(menuItem, restaurantI18n?.currency))
    }
  })} />;
};

const getMenuItemSchema = (menuItem: any, currency?: Currency) => {
  return {
    '@type': 'MenuItem',
    '@id': menuItem?.guid,
    identifier: menuItem?.guid,
    name: menuItem?.name,
    description: menuItem?.description,
    image: menuItem?.imageLink ? formatImageURL(menuItem?.imageLink) : undefined,
    offers: menuItem.price
      ? {
        '@type': 'OfferForPurchase',
        price: menuItem.price,
        priceCurrency: currency
      }
      : undefined
  };
};

export default DynamicMenuPageSchema;
