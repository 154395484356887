import React, { useCallback } from 'react';
import { generatePath } from 'react-router';

import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';

export enum CtaDestination {
  OrderPage = 'Order Page',
  MenuPage = 'Menu Page'
}

const LocationCta = ({
  shortUrl,
  ctaDestination,
  rxName,
  orderPathPattern,
  hostName,
  diningOptionBehavior,
  disabled,
  className
}: {
  shortUrl: string,
  ctaDestination: CtaDestination,
  rxName?: string | null,
  orderPathPattern: string,
  hostName: string,
  diningOptionBehavior?: DiningOptionBehavior | null,
  disabled?: boolean,
  className?: string
}) => {
  const linkText = ctaDestination === CtaDestination.OrderPage ? 'Order now' : 'View menu';
  const getHref = useCallback(() => {
    const params = new URLSearchParams();

    if(ctaDestination === CtaDestination.OrderPage) {
      params.set('diningOption', diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'takeout');
    }

    const url = ctaDestination === CtaDestination.OrderPage ?
      `${generatePath(orderPathPattern, { slug: shortUrl })}?${params.toString()}` :
      `/menu/${shortUrl}`;
    return `https://${hostName}${url}`;
  }, [diningOptionBehavior, ctaDestination, shortUrl, orderPathPattern, hostName]);

  return (
    <button disabled={disabled} className={classnames('orderButton', 'button', 'buttonLink', className, ButtonType.Primary)}>
      <a target="_parent" href={getHref()} aria-label={`${linkText} ${rxName}`}>
        {linkText}
      </a>
    </button>
  );
};

export default LocationCta;
