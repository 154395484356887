import React from 'react';

import { LoyaltyFeatures } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';
import Button from 'src/shared/components/common/button';
import LoadingSpinnerOverlay from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { ModalContent, ModalCloseButton } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { LoyaltyIcon } from 'public/components/default_template/offers/OffersIcons';

type LoyaltyProgramDetailsProps = {
  illustrationColor: string;
}

export const LoyaltyProgramDetails = ({ illustrationColor }: LoyaltyProgramDetailsProps) => {
  const { hasLoyaltyAccount, loyaltyFeatures } = useLoyalty();
  const { ooRestaurant } = useRestaurant();

  const programName = ooRestaurant?.loyaltyConfig?.programName || '';

  const featuresToDisplay = getApplicableLoyaltyFeatures(loyaltyFeatures, hasLoyaltyAccount);

  const description = featuresToDisplay && featuresToDisplay.length > 0 ?
    <ul className="loyaltyFeatures">
      {featuresToDisplay?.map(lf => lf && lf.message && <li key={lf.message}>{lf.message}</li>)}
      {hasLoyaltyAccount && <li>View and redeem rewards at checkout.</li>}
    </ul>
    : null;

  const heading = hasLoyaltyAccount ? programName : `Sign up for ${programName || 'loyalty'}`;

  return (
    <div className="body loyalty" style={{ background: `linear-gradient(0deg, white, ${illustrationColor}22)` }} data-testid="loyalty-signup-modal">
      <div className="headerContent centered">
        <LoyaltyIcon color={illustrationColor} />
        <div className="title">
          {heading}
        </div>
      </div>
      {description}
    </div>
  );
};

type LoyaltySignupProps = LoyaltyProgramDetailsProps & {
  openLegal: () => void;
  acceptedTerms: boolean;
  setAcceptedTerms: (value: boolean) => void;
  signupForLoyalty: () => void;
};

const getApplicableLoyaltyFeatures = (loyaltyFeatures: (LoyaltyFeatures | null)[] | undefined | null, hasLoyaltyAccount: boolean) => {
  return hasLoyaltyAccount ? loyaltyFeatures && loyaltyFeatures?.filter(f => !f?.tags?.includes('SIGNUP')) : loyaltyFeatures;
};

export const LoyaltySignupModal = ({
  illustrationColor,
  openLegal,
  acceptedTerms,
  setAcceptedTerms,
  signupForLoyalty
}: LoyaltySignupProps) => {
  const { loadingSignup, hasLoyaltyAccount } = useLoyalty();
  const { ooPromoBanners } = useRestaurant();
  const accrualBonus = ooPromoBanners?.loyaltyBanners?.[0];

  return (
    <ModalContent contentClassName="modalContentV2 loyalty fullScreenMobile">
      <div className="modalHeader">
        <ModalCloseButton />
      </div>
      {loadingSignup && <LoadingSpinnerOverlay /> }
      {accrualBonus && hasLoyaltyAccount && <div className="accrualBonusTag">{accrualBonus.bannerText} right now!</div>}
      <LoyaltyProgramDetails illustrationColor={illustrationColor} />
      {!hasLoyaltyAccount &&
        <div className="loyaltyFormContainer">
          <div className="signupForm">
            <div className="checkboxContainer">
              <input type="checkbox" id="terms" checked={acceptedTerms} onChange={e => setAcceptedTerms(e.target.checked)} />
              <label htmlFor="terms">I agree to the following{' '}
                <a
                  onClick={() => openLegal()}
                  tabIndex={0}
                  onKeyDown={
                    e => {
                      if(e.code !== 'Escape' && e.code !== 'Space' && e.code !== 'tab') {
                        return;
                      }
                      e.preventDefault();
                      openLegal();
                    }
                  }
                  aria-label="click to view terms and conditions">
                terms & conditions.
                </a>
              </label>
            </div>
            <Button
              variant={ButtonType.Primary}
              className="signupButton"
              disabled={!acceptedTerms}
              onClick={signupForLoyalty}
              data-testid="loyalty-signup-button">
              Sign up
            </Button>
          </div>
        </div>}
    </ModalContent>
  );
};
