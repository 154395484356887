import React from 'react';

export const SmsMarketingLegalDisclaimer = ({ style }: {style?: React.CSSProperties}) => {
  return (
    <div style={style} className="legal-disclaimer" data-testid="sms-marketing-legal-disclaimer">
      By texting the number provided, you give Toast and this restaurant/restaurant group permission to send you
      {' '}
      automated recurring marketing text messages at the number used at signup. Consent to marketing is not a condition
      {' '}
      of purchase. Message &amp; data rates may apply, msg frequency varies. Reply STOP to opt out. Subject to Toast&apos;s
      {' '}
      <a
        style={style}
        className="underline"
        rel="noopener noreferrer"
        target="_blank"
        href="https://pos.toasttab.com/terms-of-service">
        Terms of Service
      </a>
      ,
      {' '}
      <a
        style={style}
        className="underline"
        rel="noopener noreferrer"
        target="_blank"
        href="https://pos.toasttab.com/privacy">
        Privacy Statement
      </a>
      , and
      {' '}
      Merchant&apos;s Terms and Policies.
      {' '}
      Info for CA residents available
      {' '}
      <a
        style={style}
        className="underline"
        rel="noopener noreferrer"
        target="_blank"
        href="https://pos.toasttab.com/privacy#addendum-a">
        here.
      </a>
    </div>
  );
};
