import React from 'react';
import { UseFormHandleSubmit, UseFormReturn } from 'react-hook-form';

import { Popup } from 'src/apollo/sites';

import { ModalCloseButton } from 'shared/components/common/modal';

import { PopupLayoutSelector } from 'public/components/default_template/popups/PopupLayoutSelector';
import { RichTextField } from 'public/components/default_template/popups/utils';


type formData = { [key: string]: string };
export type PopupContentProps = {
  popup: Popup,
  description: RichTextField
  header: RichTextField
  onClose: () => void
  submitted: boolean
  submitting: boolean
  formMethods: UseFormReturn<formData, any>
  handleSubmit: UseFormHandleSubmit<formData>,
  onSubmit: (data: formData) => void
  popupIndex: any;
  dynamicBlock: any;
  width: any;
}
/**
 * Popup Content wrapper that renders:
 * 1. Modal Close Button if popup is dismissible
 * 2. Popup Layout Selector
 */
export const PopupContents = (props: PopupContentProps) => {
  const { popup, width, dynamicBlock } = props;
  return (
    <>
      <div className="popupHeader">
        {popup.dismissible !== false && <ModalCloseButton />}
      </div>
      <div
        className="popup"
        style={{ backgroundColor: popup.backgroundColor || undefined, width, maxWidth: dynamicBlock ? '100%' : undefined }}>
        <PopupLayoutSelector {...props} />
      </div>
    </>);
};
