import React, { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getPaymentOption } from 'src/public/components/default_template/online_ordering/checkout/checkoutUtils';
import { getReadyTimeChangeBucket } from 'src/public/components/default_template/online_ordering/checkout/fulfillmentTimeUtils';
import { usePayment } from 'src/public/components/online_ordering/PaymentContext';

import Link from 'shared/components/common/link';
import ContextualLoadingSpinner from 'shared/components/common/loading_spinner/LoadingSpinner';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { toLocalTime } from 'shared/js/timeUtils';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { OrderError } from 'public/components/online_ordering/CheckoutContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { getQuoteTime } from 'public/components/online_ordering/fulfillmentUtils';

const CheckoutError = ({ error } : { error?: OrderError | null }) => {
  const { cart } = useCart();
  const tracker = useTracker();

  if(!error || !cart) {
    return null;
  }

  switch(error.type) {
    case 'OUT_OF_STOCK':
    case 'oos-item-removed':
      return <>Sorry, one of the items in your order is out of stock. It has been removed from your order.</>;
    case 'ORDER_TIME_UNAVAILABLE':
      return <>Sorry, your order can no longer be completed at this time. Please pick a new order time.</>;
    case 'UNSUPPORTED_PROMO_CODE':
      return (
        <>
          <div>You have already redeemed this one-time promotion. Please remove the promo code and try again.</div>
          <div>You may see a temporary hold on your card, but it will be released in 24-48 hours and your card account will not be charged.</div>
        </>
      );
    case 'INVALID_CREDIT_CARD':
      return <>Your credit card was declined. You have not been charged, but may notice a temporary authorization on your statement. Please review card information and try again.</>;
    case 'OO_DISABLED':
    case 'online-ordering-disabled':
      return <>Sorry, we are not accepting online orders at this time.</>;
    case 'PLACE_ORDER_EMPTY_CART':
    case 'CART_CONTENT_NOT_FOUND':
    case 'empty-cart-place-order':
    case 'cart-content-not-found':
      return <>Please add some items to your order.</>;
    case 'PLACE_ORDER_INVALID_EMAIL':
      return <>Please enter a valid email address.</>;
    case 'UNSUPPORTED_PAYMENT_METHOD':
      return <>Sorry, we do not accept this type of credit card. Please try a different one.</>;
    case 'MISSING_CUSTOMER_INFO':
      return <>Please enter your name, email address, and phone number.</>;
    case 'MISSING_DELIVERY_INFO':
      return <>Please enter an address for delivery.</>;
    case 'DINING_OPTION_UNAVAILABLE':
      return <>Sorry, {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'take out'} is not available right now.</>;
    case 'NOT_IN_DELIVERY_AREA':
      return <>Sorry, we do not deliver to your address.</>;
    case 'ALCOHOL_UNSUPPORTED':
    case 'place-order:alcohol-unsupported':
      return <>Sorry, we cannot complete this order with alcoholic items. Please remove them and try again.</>;
    case 'AUTOFIRE_OFFLINE':
      return (
        <>
          We are experiencing issues processing online orders. This issue is typically resolved in less than 5 minutes. If the error persists, contact the restaurant directly to order.
        </>
      );
    case 'CRITICAL_ERROR':
    case 'PLACE_ORDER_FAILED':
    case 'critical-error':
    case 'cart-operation-failed':
    case 'cart-operation-invalid':
      return (
        <>
          We had trouble placing your order. Please try again, or contact the restaurant directly to place an order.
        </>
      );
    case 'CUSTOM_ERROR_MESSAGE':
      return <>{error.message}</>;
    default: {
      // Some error messages come through as non-customer-facing, stringified error objects formatted as "[<ErrorType>(reasonCode=<string>, reasonMsg=<string>)]"
      // In that case, pull out the contents of the reasonMsg field.  Otherwise, display the original message.
      const reasonMsg = error.message.split('reasonMsg=')[1]?.slice(0, -2) ?? error.message;
      tracker.track('Unknown checkout error type', {
        cartSource: cart.cartSource,
        type: error.type,
        message: error.message
      });
      return <>{reasonMsg}</>;
    }
  }
};

const CheckoutErrorModal = ({ onSubmit }: {onSubmit: () => Promise<void>}) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { orderError, setOrderError, giftCardAppliedAmount, orderTotal, saveNewAddress } = useCheckout();
  const { refetchCart, cart, cartGuid, loadingCart } = useCart();
  const tracker = useTracker();
  const { paymentOption, paymentType } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant();
  const error = useRef<OrderError | null>(null);
  const { savedAddressUsed } = useDelivery();

  const trackError = useCallback(() => {
    if(cart) {
      tracker.track('Order Placement Failed', {
        cartSource: cart.cartSource,
        readyTimeChangeBucket: getReadyTimeChangeBucket(cart, cartGuid, false),
        errorMsg: orderError?.message,
        errorType: orderError?.type,
        paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal, isIntlRestaurant),
        diningOption: cart?.diningOptionBehavior,
        deliveryProvider: cart?.deliveryProviderInfo?.provider,
        saveNewAddress,
        savedAddressUsed: cart?.diningOptionBehavior === DiningOptionBehavior.Delivery ? savedAddressUsed : undefined

      });
    }
  }, [cart, cartGuid, giftCardAppliedAmount, orderError?.message, orderError?.type, orderTotal, paymentOption, paymentType, tracker, isIntlRestaurant, saveNewAddress, savedAddressUsed]);

  useEffect(() => {
    if(orderError && !loadingCart) {
      // This check ensures we do not track the same error multiple times
      if(error.current === null ||
        (error.current.type !== orderError.type || orderError.message !== error.current.message)) {
        error.current = orderError;
        onOpen();
        trackError();
      }
    }
  }, [orderError, onOpen, trackError, cart, loadingCart]);

  const closeModal = useCallback(() => {
    setOrderError(null);
    error.current = null;
    onClose();
    if(orderError?.refetchCart) {
      refetchCart();
    }
  }, [orderError, refetchCart, setOrderError, onClose]);

  const orderTimeChangedErrors = ['place-order:order-time-changed', 'order-time', 'ORDER_TIME_CHANGED'];

  return (
    <>
      <Modal onClose={closeModal} isOpen={isOpen} preventOverlayClose>
        <ModalOverlay />
        <ModalContent>
          {orderError?.type && orderTimeChangedErrors.includes(orderError.type) ?
            <OrderTimeChangedErrorModalContent onSubmit={onSubmit} /> :
            <div className="checkoutErrorModalContent">
              <div className="header">
                <h3>There was an error placing your order!</h3>
                <ModalCloseButton />
              </div>
              {orderError && <div className="body"><CheckoutError error={orderError} /></div>}
            </div>}
        </ModalContent>
      </Modal>
    </>
  );
};

const OrderTimeChangedErrorModalContent = ({ onSubmit }: {onSubmit: () => Promise<void>}) => {
  const { cart, refetchCart } = useCart();
  const { ooRestaurant } = useRestaurant();
  const { orderPath } = useRestaurantRoutes();
  const { formState: { isSubmitting } } = useFormContext();

  useEffect(() => {
    refetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!cart || !ooRestaurant) {
    return null;
  }

  const newOrderTime =
    cart.fulfillmentType === 'ASAP' ?
      `${getQuoteTime(cart.diningOptionBehavior, ooRestaurant, cart)} minutes` :
      toLocalTime(cart.fulfillmentDateTime || '', ooRestaurant.timeZoneId ?? undefined);

  const diningBehavior = cart.diningOptionBehavior === DiningOptionBehavior.TakeOut ? 'pickup' : 'delivery';

  return (
    <div className="checkoutErrorModalContent">
      <div className="header">
        <h3>Order delayed - proceed to placing order?</h3>
        <ModalCloseButton />
      </div>
      <div className="body">
        <div>{`The selected ${diningBehavior} time is no longer available, so your order has not been processed. The new ready time is ${newOrderTime}.`}</div>
        <div className="buttonContainer">
          <button className="primaryCta proceed" type="submit" onClick={onSubmit}>{isSubmitting ? <ContextualLoadingSpinner size="24px" strokeWidth="3px" /> : 'Place order'}</button>
          <Link className="primaryCta cancel" href={orderPath}>Cancel order</Link>
        </div>
      </div>
    </div>
  );
};

export default CheckoutErrorModal;
