import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import { useEditor } from '@toasttab/sites-components';

import { RequestContextProps } from 'src/lib/js/context';

import BaseErrorComponent from 'shared/components/base_error/BaseErrorComponent';
import * as errorMessages from 'shared/js/constants';
import { SiteTheme } from 'shared/js/types';

import StyleMeta, { StyleMetaProps } from 'public/components/default_template/meta/StyleMeta';


type Props = {
  errorStatus?: number;
  title?: string;
  subtitle?: string;
  message?: string;
  meta?: StyleMetaProps;
  siteTheme?: SiteTheme
}

type WithRouterProps = RequestContextProps & Props & { metaTitle?: boolean };

export const getUhOhPropsForError = (error?: any) => {
  if(error?.networkError) {
    return {
      title: errorMessages.GENERIC_FAILURE_TITLE,
      subtitle: errorMessages.REQUEST_FAILURE_MESSAGE,
      message: undefined
    };
  }

  return {
    title: errorMessages.GENERIC_FAILURE_TITLE,
    subtitle: errorMessages.GENERIC_FAILURE_MESSAGE,
    message: undefined
  };
};

const UhOh = ({ staticContext, errorStatus, metaTitle = true, meta, siteTheme }: Partial<WithRouterProps>) => {
  // Update the static router's status prop so the server can send the proper response code (serverside only)
  if(staticContext) { staticContext.statusCode = errorStatus || 500; }

  return (
    <Fragment>
      <StyleMeta {...meta} siteTheme={siteTheme} />
      {metaTitle &&
      <Helmet>
        <title>Uh oh... Something went wrong | Error</title>
      </Helmet>}

      <div><BaseErrorComponent errorCode={'500'} errorMessage={'Something went wrong, please try again.'} /></div>
    </Fragment>
  );
};

const UhOhWithRouter = withRouter<WithRouterProps, React.ComponentType<WithRouterProps>>(UhOh);

const WrappedUhOh = (props: Props) => {
  const { isEditor } = useEditor();
  return isEditor ? <UhOh {...props} metaTitle={false} /> : <UhOhWithRouter {...props} />;
};

export default WrappedUhOh;
