import React, { cloneElement } from 'react';
import { Helmet } from 'react-helmet-async';

import parse from 'html-react-parser';

import { useStaticSiteContext } from 'public/components/app/StaticSiteContext';

export type PixelsMetaProps = {
  pixels?: string[];
  adsPixels?: string[];
}

declare global {
  interface Window {
    __COOKIE_BANNER_ENABLED__: boolean | undefined
  }
}

const PixelsMeta = ({ pixels, adsPixels }: PixelsMetaProps) => {
  const staticContext = useStaticSiteContext();
  const cookieBannerEnabled = typeof window === 'undefined' ? staticContext?.cookieBannerEnabled : window.__COOKIE_BANNER_ENABLED__;
  const pixelTags = (pixels || []).flatMap(pixel => createScriptTagsFromString(pixel, cookieBannerEnabled));
  const adsPixelTags = (adsPixels || []).flatMap(pixel => createScriptTagsFromString(pixel, cookieBannerEnabled));
  // render each tag into a react element with a key.
  const pixelComponents = pixelTags.map(pixel => cloneElement(pixel, { key: `${pixel}` }));
  const adsPixelComponents = adsPixelTags.map(pixel => cloneElement(pixel, { key: `${pixel}` }));

  return (
    <Helmet>
      {pixelComponents}
      {adsPixelComponents}
    </Helmet>
  );
};


export const createScriptTagsFromString = (pixelString: string, cookieBannerEnabled: boolean | null = null): JSX.Element[] => {
  const supportedHeadTags = ['script', 'link'];
  const parsed = parse(pixelString.trim());
  if(Array.isArray(parsed)) {
    return parsed.filter(el => typeof el !== 'string' && supportedHeadTags.includes(el.type)).map(el => forceHelmetCompatibilty(el, cookieBannerEnabled));
  } else if(typeof parsed === 'string') {
    return [];
  } else {
    // parsed is JSX.Element
    if(!supportedHeadTags.includes(parsed.type)) {
      return [];
    }
    return [forceHelmetCompatibilty(parsed, cookieBannerEnabled)];
  }
};

// If the OneTrust cookie banner is enabled (i.e. we load the script), allow pixels to define their own 'type' attribute for the
// script tag that loads them. This allows us to disable the pixel until cookie consent is given by the user.
const forceHelmetCompatibilty = (element: JSX.Element, cookieBannerEnabled: boolean | null = null) => {
  const innerhtml = element.props.dangerouslySetInnerHTML?.__html;
  const src = element.props.src;
  const { dangerouslySetInnerHTML, ...otherProps } = element.props;
  if(src) {
    if(cookieBannerEnabled) {
      return <script type="text/javascript" {...otherProps} src={src}>{innerhtml}</script>;
    } else {
      return <script {...otherProps} src={src} type="text/javascript" >{innerhtml}</script>;
    }
  } else if(!src && innerhtml) {
    // Helmet requires elements without a 'src' attribute to be constructed as a JSX.IntrinsicElement, which is why we must recreate the element here.
    // This is an undocumented behavior of Helmet, and is probably a bug.
    if(cookieBannerEnabled) {
      return <script type="text/javascript" {...otherProps}>{innerhtml}</script>;
    } else {
      return <script {...otherProps} type="text/javascript">{innerhtml}</script>;
    }
  }
  return element;
};


export default PixelsMeta;
