import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import { AsYouType, CountryCode, isSupportedCountry, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { useOptionalRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import InputMessage from 'shared/components/common/form_input/InputMessage';
import { asValidPhoneCountryCode } from 'shared/js/phoneUtils';


const MIN_PHONE_NUMBER_LENGTH = 10;
const MAX_PHONE_NUMBER_LENGTH = 11;

export type PhoneInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  prefix?: string;
  id: string;
  label?: string;
  required?: boolean;
  warning?: string;
  focusMessage?: string;
  filled?: boolean;
  validate?: (value: string) => boolean | string | undefined;
  onChangeNumber?: (rawPhoneNumber: string) => void;
  defaultValue?: string;
  button?: React.ReactElement;
  unregisterOnUnmount?: boolean;
  shouldUnFocus?: boolean;
  initialFocus?: boolean;
  isSingletonField?: boolean;
};

/**
 * @param phoneNumber user-inputted phone number
 * @param countryCode country to parse and format the phone number as
 * @returns international E.164 format phone number
 * @throws Error if the phone number is invalid
 */
export const formatPhoneNumber = (phoneNumber: string, countryCode: CountryCode = 'US'): string => {
  if(isSupportedCountry(countryCode) && isValidPhoneNumber(phoneNumber, countryCode)) {
    return parsePhoneNumber(phoneNumber, countryCode).number;
  }

  throw new Error('Invalid phone number');
};

const PhoneInput = (props: PhoneInputProps) => {
  const {
    prefix, onChangeNumber, defaultValue, id, label, required, warning, focusMessage, filled, validate, button,
    unregisterOnUnmount = true, shouldUnFocus = true, initialFocus = false, isSingletonField = false,
    ...inputProps
  } = props;
  const { register, formState: { errors }, setValue, unregister } = useFormContext();
  const [focus, setFocus] = useState(initialFocus);
  useEffect(() => {
    register(id, { required: required ? 'required' : false, validate });
    return unregisterOnUnmount ? () => unregister(id) : () => {};
  }, [register, validate, id, required, unregister, unregisterOnUnmount]);

  const ooRestaurant = useOptionalRestaurant()?.ooRestaurant;

  // For now this will use the country code of the rx, supporting only local numbers.
  // TODO: Add a country code selector to support the 'tourist' experience.
  const countryCode = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);

  const [phoneNumber, setPhoneNumber] = useState(defaultValue || '');
  useEffect(() => setPhoneNumber(defaultValue || ''), [defaultValue]);
  useEffect(() => onChangeNumber && onChangeNumber(phoneNumber), [phoneNumber, onChangeNumber]);

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    // The timeout is necessary to prevent the onBlur from firing and putting us into an error state prematurely
    setTimeout(() => {
      if(initialFocus && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 100);
  }, [initialFocus]);

  const formattedPhoneNumber = useMemo(() => new AsYouType(countryCode).input(phoneNumber), [countryCode, phoneNumber]);
  const onBlurInput = (id: string, phoneNumber: string) => () => {
    // This prevents us from showing an error state on blur when the form essentially wasn't touched,
    // e.g. in the case of the auth modal where the phone input is the only field and the guest clicks
    // the close button without attempting to enter phone number.
    if(isSingletonField && phoneNumber === '') {
      return;
    }
    setValue(id, phoneNumber, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    if(shouldUnFocus) {
      setFocus(false);
    }
  };

  return (
    <div className={classnames('formInputContainer inputField', errors[id] && 'error')}>
      <div className="inputFieldInner">
        <label data-testid="phone-input-label" htmlFor={id}>
          <input
            aria-required={required}
            id={id}
            aria-describedby={`${id}-input-message`}
            data-testid={`input-${id}`}
            {...inputProps}
            value={formattedPhoneNumber}
            type="tel"
            autoComplete="tel"
            ref={inputRef}
            onChange={e => {
              const event = e.nativeEvent as InputEvent;
              let newValue = e.target.value;

              // handle the three character delete case - remove the closing parenthesis
              if(event.inputType === 'deleteContentBackward'
              && formattedPhoneNumber.endsWith(')')
              && !newValue.endsWith(')')) {
                newValue = newValue.slice(0, newValue.length - 1);
              }

              // remove non-numeric characters
              const rawNewValue = newValue.replace(/[^0-9]/g, '');

              if(rawNewValue.length <= MAX_PHONE_NUMBER_LENGTH) {
                setPhoneNumber(rawNewValue);

                if(rawNewValue.length >= MIN_PHONE_NUMBER_LENGTH) {
                  setValue(id, rawNewValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                }
              }
            }}
            onFocus={() => setFocus(true)}
            placeholder=" "
            onBlur={onBlurInput(id, phoneNumber)} />
          <span>{label}</span>
        </label>
        {button}
      </div>
      <InputMessage id={id} warning={warning} focus={focus} focusMessage={focusMessage} />
    </div>
  );
};

export default PhoneInput;

