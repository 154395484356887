import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useDeleteCreditCardMutation, useMakeCreditCardPrimaryMutation } from 'src/apollo/onlineOrdering';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import Image from 'shared/components/common/Image';
import DropDown from 'shared/components/common/dropdown';
import { ToggleInput } from 'shared/components/common/forms';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CreditCardModal from 'public/components/default_template/online_ordering/checkout/payment/CreditCardModal';
import SaveCardCheckbox from 'public/components/default_template/online_ordering/checkout/payment/SaveCardCheckbox';
import { SavedCreditCard } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCard';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { usePayment, SelectedCreditCard, useOptionalPayment } from 'public/components/online_ordering/PaymentContext';
import { useApplePay } from 'public/components/online_ordering/applePayUtils';


export const getCreditCardImage = (cardType?: string) => {
  switch(cardType) {
    case 'VISA': return <Image className="cardLogo" alt="Visa icon" src="icons/visa-logo.svg" />;
    case 'MASTERCARD': return <Image className="cardLogo" alt="Mastercard icon" src="icons/mastercard-logo.svg" />;
    case 'AMEX': return <Image className="cardLogo" alt="American Express icon" src="icons/amex-logo.svg" />;
    case 'DISCOVER': return <Image className="cardLogo" alt="Discover icon" src="icons/discover-logo.svg" />;
    case 'JCB': return <Image className="cardLogo" alt="JCB icon" src="icons/jcb-logo.svg" />;
    case 'DINERS': return <Image className="cardLogo" alt="Diners icon" src="icons/diners-club-logo.svg" />;
    case 'MAESTRO': return <Image className="cardLogo" alt="Maestro icon" src="icons/maestro-logo.svg" />;
    default: return <Image className="cardLogo" alt="Credit card icon" src="icons/credit-card-gray.svg" />;
  }
};

type CreditCardRowProps = {
  guid?: string;
  encryptionKeyId?: string;
  cardType?: string;
  maskedPan: string;
  expirationMonth?: string | null;
  expirationYear?: string | null;
  isPrimary: boolean;
  onError: (err: string) => void;
  hideInputs?: boolean;
};

export const CreditCardRow = ({ guid, encryptionKeyId, cardType, maskedPan, expirationMonth, expirationYear, isPrimary, onError, hideInputs }: CreditCardRowProps) => {
  const { setNewCreditCard, selectedCreditCard, setSelectedCreditCard, paymentOption } = useOptionalPayment() || {};
  const [deleteCreditCardMutation] = useDeleteCreditCardMutation();
  const [makeCreditCardPrimaryMutation] = useMakeCreditCardPrimaryMutation();
  const { refetchCustomer } = useCustomer();
  const [cardLoading, setCardLoading] = useState(false);
  const ooClient = useOOClient();

  const removeCard = useCallback(async () => {
    setCardLoading(true);
    if(encryptionKeyId) {
      setNewCreditCard && setNewCreditCard(null);
    } else if(guid) {
      const { data } = await deleteCreditCardMutation({ variables: { input: { cardGuid: guid } }, client: ooClient });
      if(data?.deleteCreditCard.__typename === 'DeleteCreditCardResponse') {
        setSelectedCreditCard && setSelectedCreditCard((selectedCard: SelectedCreditCard) => ({
          ...selectedCard,
          savedCardGuid: selectedCard?.savedCardGuid === guid ? null : selectedCard?.savedCardGuid
        }));
        refetchCustomer();
        onError('');
      } else {
        onError('There was an error while updating your card. Please try again.');
      }
    }
    setCardLoading(false);
  }, [encryptionKeyId, guid, setNewCreditCard, setSelectedCreditCard, deleteCreditCardMutation, refetchCustomer, onError, ooClient]);

  const primaryCard = useCallback(async () => {
    setCardLoading(true);
    if(guid) {
      const { data } = await makeCreditCardPrimaryMutation({ variables: { input: { cardGuid: guid } }, client: ooClient });
      if(data?.makeCreditCardPrimary.__typename === 'MakeCreditCardPrimaryResponse') {
        await refetchCustomer();
        onError('');
      } else {
        onError('There was an error while updating your card. Please try again.');
      }
    }
    setCardLoading(false);
  }, [guid, makeCreditCardPrimaryMutation, refetchCustomer, onError, ooClient]);

  const isChecked = useMemo(() => {
    if(selectedCreditCard?.newCardSelected) {
      return Boolean(encryptionKeyId);
    } else if(selectedCreditCard?.savedCardGuid) {
      return selectedCreditCard.savedCardGuid === guid;
    } else {
      return isPrimary;
    }
  }, [selectedCreditCard, encryptionKeyId, guid, isPrimary]);

  useEffect(() => {
    if(isChecked) {
      setSelectedCreditCard && setSelectedCreditCard({ newCardSelected: Boolean(encryptionKeyId), savedCardGuid: guid || null });
    }
  }, [isChecked, encryptionKeyId, guid, setSelectedCreditCard, paymentOption]);

  if(!guid && !encryptionKeyId) {
    // when a new card is removed, show a skeleton until the animation completes
    return (
      <div className="savedCreditCard">
        <Skeleton width="100%" height="35px" />
      </div>
    );
  }

  return (
    <div className="savedCreditCard" role="listitem" tabIndex={0}>
      {cardLoading && <LoadingSpinnerOverlay withBorderRadius />}
      <div className="savedCardRadio">
        {hideInputs ?
          <SavedCreditCard
            maskedPan={maskedPan}
            expirationMonth={expirationMonth}
            expirationYear={expirationYear}
            cardType={cardType} /> :
          <ToggleInput
            checked={isChecked}
            onChange={() => {
              if(encryptionKeyId) {
                setSelectedCreditCard && setSelectedCreditCard({ newCardSelected: true, savedCardGuid: null });
              } else {
                setSelectedCreditCard && setSelectedCreditCard({ newCardSelected: false, savedCardGuid: guid || null });
              }
            }}
            name="savedCreditCard"
            id={`card-${guid || encryptionKeyId}`}
            type="radio">
            <SavedCreditCard
              maskedPan={maskedPan}
              expirationMonth={expirationMonth}
              expirationYear={expirationYear}
              cardType={cardType} />
          </ToggleInput>}
      </div>
      <div>
        <DropDown hideArrow={true} label={<Image className="editCard" alt="Edit card" src="icons/kebab-menu.svg" />}>
          {({ close }) =>
            <>
              {guid && !isPrimary &&
                <DropDownOption
                  onSelect={() => {
                    primaryCard();
                    close();
                  }}>
                  Make primary
                </DropDownOption>}
              <DropDownOption
                onSelect={() => {
                  removeCard();
                  close();
                }}>
                Delete card
              </DropDownOption>
            </>}
        </DropDown>
      </div>
    </div>
  );
};

const SavedCreditCards = () => {
  const { customer } = useCustomer();
  const { newCreditCard } = usePayment();
  const [errorMsg, setErrorMsg] = useState('');

  if(!customer?.creditCards && !newCreditCard) {
    return null;
  }

  return (
    <div className="savedCreditCards">
      <hr />
      {[...customer?.creditCards || []].sort((a, b) => {
        if(a.isPrimary) return -1;
        if(b.isPrimary) return 1;
        return a.guid > b.guid ? 1 : -1;
      }).map(card =>
        <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} />)}
      <AnimatedSection expanded={Boolean(newCreditCard)}>
        <CreditCardRow
          encryptionKeyId={newCreditCard?.encryptionKeyId}
          maskedPan={`XXXX-${newCreditCard?.cardLast4}`}
          expirationMonth={newCreditCard?.expMonth}
          expirationYear={newCreditCard?.expYear}
          isPrimary={false}
          onError={setErrorMsg} />
        {customer && <SaveCardCheckbox />}
      </AnimatedSection>
      <AnimatedSection expanded={!newCreditCard}>
        <div className="addCard">
          <CreditCardModal>
            <div className="plusSign"><Image alt="New card" src="icons/plus.svg" /></div>
            <span>Use a different card</span>
          </CreditCardModal>
        </div>
      </AnimatedSection>
      {errorMsg && <div className="submitError">{errorMsg}</div>}
    </div>
  );
};


/** Visual representation of accepted payment methods */
// TODO: This should either be removed or pull the options from SPI/Adyen to be more accurate.
export const AcceptedPaymentMethods = () => {
  const { canUseApplePay } = useApplePay();
  const { ooRestaurant } = useRestaurant();
  const showAmex = ooRestaurant?.creditCardConfig?.amexAccepted;
  return (
    <div className="acceptedPaymentMethods">
      <Image alt="Visa icon" src="icons/visa-logo-minimal.svg" />
      {showAmex && <Image alt="American Express icon" src="icons/amex-logo-minimal.svg" data-testid="amex-logo" />}
      <Image alt="Diners icon" src="icons/diners-logo-minimal.svg" />
      <Image alt="Mastercard icon" src="icons/mastercard-logo-minimal.svg" />
      {canUseApplePay && <Image alt="Apple Pay icon" src="/icons/apple-pay.svg" data-testid="apple-pay-icon" />}
    </div>
  );
};


export default SavedCreditCards;
