import { useRestaurantLocationsQuery, Restaurant, GeneralError } from 'src/apollo/onlineOrdering';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export const useOOLocations = () => {
  const { locations } = useRestaurant();
  const client = useOOClient();
  const { data } = useRestaurantLocationsQuery({
    variables: { restaurantGuids: locations?.map(loc => loc.externalId) ?? [] },
    skip: !locations?.length,
    client
  });

  const locationsDataByGuid = data?.restaurants?.reduce(
    (acc: { [key: string]: Restaurant }, loc: Restaurant | GeneralError) => {
      if(loc.__typename === 'Restaurant') {
        acc[loc.guid] = loc;
      }
      return acc;
    },
    {}
  );

  return locations?.filter(loc => locationsDataByGuid?.[loc.externalId]?.hasOnlineOrderingModule);
};

export default useOOLocations;
