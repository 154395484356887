import React from 'react';

import { useEditor, _heading, ContainsEditableProps } from '@toasttab/sites-components';
import classnames from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { BackgroundImage, CalendarEvent, PaddingEnum, ThemeTypeEnum } from 'src/apollo/sites';
import EditorBuildOnly from 'src/editor/build-tools/EditorBuildOnly';
import { reportError } from 'src/lib/js/clientError';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { getBackgroundColorOrImageModule, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';

export type CalendarEventsProps = {
  header?: string | null;
  theme?: ThemeTypeEnum | null;
  backgroundColor?: string | null;
  separatorColor?: string | null;
  padding?: PaddingEnum | null;
  backgroundImage?: BackgroundImage | null;
} & ContainsEditableProps;

// Hotfixing for really long URLs. This should probably be a CTA instead of a URL.
// const prettyUrl = (url: string) => url.replace(/^(https?:\/\/)?(www\.)?/, '');
const prettyUrl = (_: string) => 'More Info';

const CalendarEvents = ({ padding = PaddingEnum.Medium, ...props }: CalendarEventsProps) => {
  const { useEditableRef, isEditor } = useEditor();
  const { restaurant } = useRestaurant();

  const events: CalendarEvent[] = (restaurant?.calendarEvents || []) as CalendarEvent[];

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'calendar section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: props.editPath,
    // Note: this schema is overriden by CalendarEventsForm.tsx in toast-sites-admin-spa
    schema: {
      fields: [
        getSectionPaddingModule(props.editPath, padding),
        getBackgroundColorOrImageModule(props.editPath, props.backgroundColor, props?.backgroundImage)
      ]
    }
  });

  const pathToHeader = `${props.editPath}.calendarHeader`;

  // public: don't show if there are no events
  // admin: show prompt to add event
  if(!isEditor && !events.length) return null;

  const separatorStyle = props.separatorColor ? { borderColor: props.separatorColor } : {};

  return (
    <div ref={editableRef} className={classnames('calendarEvents', props.theme)} data-testid="calendarSection">
      <div className="paddedSection">
        <_heading styleLevel={2} className="sectionHeader" html={props.header || ''} editPath={pathToHeader} />
      </div>
      {events.length !== 0
        &&
        <div className="paddedSection eventsList" data-testid="eventsList">
          {events.map(evt => {
            if(!evt) return null;
            let eventDate = 'Call for details';
            try {
              eventDate = evt?.displayTime ? format(parseISO(evt?.eventDateTime), 'MM.dd / h:mm aa') : format(parseISO(evt?.eventDateTime), 'MM.dd');
            } catch(e) {
              reportError(`Error parsing date: ${evt.eventDateTime}`, e);
            }
            return (
              <div key={evt?.eventGuid} className="event" style={separatorStyle} data-testid="calendarEvent">
                <div className="eventDate">{eventDate}</div>
                <div className="eventContent">
                  <div className="eventName">{evt.name}</div>
                  <div className="eventDescription">{evt.description}</div>
                </div>
                <div className="eventLink">
                  {evt.url
                    ?
                    <a href={evt.url} target="_blank" rel="noopener noreferrer">
                      {prettyUrl(evt.url)}
                    </a>
                    :
                    ' '}
                </div>
              </div>
            );
          })}
        </div>}
      <EditorBuildOnly>
        {events.length === 0 &&
        <div data-testid="editor-no-events-disclaimer">
          <h4 className="text-center no-events-disclaimer">You have no events. This section will not show on your live site until you add your first event. Edit this section to add events.</h4>
        </div>}
      </EditorBuildOnly>
    </div>
  );
};

export default CalendarEvents;
