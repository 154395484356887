import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ContainsEditableProps, FieldType, ModuleField, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { ButtonType, CardImage, Link as LinkType, RelativePosition } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import { getLinkEnums } from 'shared/components/common/link/util';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';


type TestProps = { testId?: string }

type Props = {
  as?: 'button' | 'div';
  ariaLabel?: string;
  variant?: ButtonType;
  primaryColor?: string | null;
  textColor?: string | null;
  iconRight?: JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
  testId?: string;
  hasButtonRole?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<any>, any>;

type EditableProps = {
  image?: CardImage | null;
  classNames?: string;
  parentEditPath?: string;
} & TestProps & LinkType & ContainsEditableProps;

export const EditableButton = ({ image, type, text, link, editPath, classNames, primaryColor, textColor, alignment, testId, parentEditPath }: EditableProps) => {
  const { useEditableRef } = useEditor();
  const { restaurant: { config, name: restaurantName }, sitePages } = useRestaurant();

  const linkEnums = useMemo(() => getLinkEnums( { config, sitePages }), [config, sitePages]);

  const schemaFields: ModuleField[] = useMemo(() => {
    const isInBlock = editPath.indexOf('.blocks') > -1;
    return [
      {
        path: `${editPath}.type`,
        displayName: 'Type',
        type: FieldType.Enum,
        value: type,
        enums: [
          { displayName: 'Filled', value: ButtonType.Primary },
          { displayName: 'Outline', value: ButtonType.Secondary },
          { displayName: 'Text', value: ButtonType.Text }
        ]
      },
      {
        path: `${editPath}.text`,
        displayName: 'Text',
        type: FieldType.Text,
        value: text
      },
      {
        path: `${editPath}.link`,
        displayName: 'Links to',
        type: FieldType.EnumOrOther,
        value: link,
        enums: linkEnums
      },
      ...type === ButtonType.Primary ?
        [{
          path: `${editPath}.primaryColor`,
          displayName: 'Button Color',
          type: FieldType.Color,
          value: primaryColor
        }] as ModuleField[]
        : [],
      {
        path: `${editPath}.textColor`,
        displayName: 'Text Color',
        type: FieldType.Color,
        value: textColor
      } as ModuleField,
      ...isInBlock
        ? [{
          path: editPath,
          displayName: 'Delete Button',
          type: FieldType.DeleteBlock,
          parentEditPath
        }] as ModuleField[]
        : [{
          path: `${editPath}.alignment`,
          displayName: 'Button Alignment',
          type: FieldType.Enum,
          enums: [
            { displayName: 'Left', value: RelativePosition.Left }, { displayName: 'Center', value: RelativePosition.Center }, { displayName: 'Right', value: RelativePosition.Right }
          ],
          value: alignment
        }]
    ];
  }, [editPath, link, linkEnums, primaryColor, text, textColor, type, alignment, parentEditPath]);

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'button',
    displayName: 'Button',
    actions: [],
    path: editPath,
    schema: { fields: schemaFields }
  });

  const colorStyles = useMemo(() => {
    const colorStyles: any = {};
    if(type == ButtonType.Primary && primaryColor) {
      colorStyles.backgroundColor = primaryColor;
    }
    if(textColor) {
      colorStyles.color = textColor;
    }
    return colorStyles;
  }, [primaryColor, textColor, type]);

  const ariaLabel = useMemo(() => {
    switch(link) {
      case '/order':
        return `Order from ${restaurantName} now`;
      case '/menu':
        return `View ${restaurantName} menu`;
      default:
        return undefined;
    }
  }, [link, restaurantName]);

  const button = useMemo(() => {
    if(type === ButtonType.Text) {
      return image?.textInset ?
        <Link ariaLabel={ariaLabel} data-testid="text-inset-link" href={link} style={colorStyles}>{text} <Image src="icons/caret-right-white.svg" alt="" eagerLoad /></Link> :
        <Link ariaLabel={ariaLabel} href={link} data-testid="text-link" className="textOnly" style={colorStyles}>{text}</Link>;
    }
    return (
      <Link href={link}>
        <Button
          ariaLabel={ariaLabel}
          as="div"
          variant={type ?? ButtonType.Primary}
          primaryColor={primaryColor}
          textColor={textColor}
          style={colorStyles}
          hasButtonRole={false}>
          {text}
        </Button>
      </Link>);
  }, [type, link, text, image, primaryColor, textColor, colorStyles, ariaLabel]);


  return (
    <div
      data-testid={testId || 'editable-button'}
      className={classnames(classNames, 'button-wrapper', { [`align-button--${alignment}`]: !!alignment })}
      ref={editableRef}>
      {button}
    </div>
  );
};

export const Button = (props: React.PropsWithChildren<Props>) => {
  const { as = 'button', variant, ariaLabel, className, children, iconRight, icon: iconLeft, primaryColor, textColor, disabled, testId = 'button', hasButtonRole, ...buttonProps } = props;
  // a Button can either be a 'button' or a 'div' to prevent nested Buttons and Links which cause accessibility issues
  const Tag: 'button' | 'div' | undefined = as;
  const hasIcon = !!(iconLeft || iconRight);
  if(Tag === 'button') {
    return (
      <button
        data-testid={testId}
        type="button"
        role={hasButtonRole !== false ? 'button' : undefined}
        className={classnames('button', className, variant, { disabled: disabled })}
        disabled={disabled}
        {...buttonProps}>
        {hasIcon &&
          <div className="buttonChildrenWrapper">
            {iconLeft}
            {children}
            {iconRight && <span>{iconRight}</span>}
          </div>}
        {!hasIcon && children}
      </button>);
  } else {
    const { onClick, ...divProps } = { ...buttonProps };
    return (
      <div
        data-testid={testId}
        role={hasButtonRole !== false ? 'button' : undefined}
        className={classnames('button', className, variant, { disabled: disabled })}
        onClick={disabled ? () => {} : onClick}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        {...divProps}>
        {hasIcon &&
              <div className="buttonChildrenWrapper">
                {iconLeft}
                {children}
                {iconRight && <span>{iconRight}</span>}
              </div>}
        {!hasIcon && children}
      </div>
    );
  }
};


type ButtonLinkProps = {
  href: string
} & Props;

// Note: this only works for internal links (e.g. /menu)
export const ButtonLink = (props: ButtonLinkProps) => {
  const history = useHistory();
  const { href, children, onClick, ...buttonProps } = props;

  return (
    <Button {...buttonProps} onClick={e => { history.push(href); onClick?.(e); }}>{children}</Button>
  );
};

export default Button;
