import React from 'react';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import Warning from 'src/shared/components/common/warning/Warning';

import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import useCanSubmitOrder from './useCanSubmitOrder';

const PlaceOrderTipWarning = () => {
  const { giftCardAppliedAmount } = useCheckout();
  const { tipAmount } = usePayment();
  const isIntlRestaurant = useIsIntlRestaurant();
  const disable = !useCanSubmitOrder();

  // if a GC is applied and a non-zero tip is selected, present a warning to the guest stating they need to provide another payment method.
  // OO does not support allowing GC's to cover tip amounts
  if(!((disable || isIntlRestaurant) && giftCardAppliedAmount > 0 && tipAmount > 0)) {
    return null;
  }

  return <Warning dataTestId="place-order-tip-warning" message="Please add another payment method for the tip" />;
};

export default PlaceOrderTipWarning;
