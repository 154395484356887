import React, { useCallback, useState } from 'react';
import { OtpInput } from 'reactjs-otp-input';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'shared/components/common/modal';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

const CONFIRMATION_CODE_LENGTH = 6;

export type onCompleteProps = {
  identity: string;
  code: string;
  setError: (err: string) => void
}

type ConfirmCodeProps = {
  identity: string;
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>;
  sendCode?: () => void;
};

export const ConfirmCodeForm = ({ identity, onComplete, sendCode }: ConfirmCodeProps) => {
  const { loadingCustomer } = useCustomer();

  return (
    <div className="codeForm" role="form">
      {loadingCustomer && <LoadingSpinnerOverlay />}
      <ConfirmationCodeInputs identity={identity} onComplete={onComplete} />
      {sendCode && <button className="resendCodeButton" onClick={sendCode}>Resend code</button>}
    </div>
  );
};

type ModalProps = {
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>;
  identity: string;
  sendCode?: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmationCodeModal = ({ onComplete, identity, sendCode, isOpen, onClose }: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent contentClassName={'modalContentV2 fullScreenMobile'}
        data-testid="CodeConfirmationModal"
        ariaLabelledBy="code-confirmation-modal">
        <div className={'modalHeader'} data-testid="CodeConfirmationModalHeader">
          <ModalCloseButton />
        </div>

        <div className="body">
          <div className="headerContent">
            <h2 className="title" id="code-confirmation-modal-title">Enter verification</h2>
            <p className="subtitle">
              {`Code sent to ${identity}`}
            </p>
          </div>
          <div className="form">
            <ConfirmCodeForm identity={identity} onComplete={onComplete} sendCode={sendCode} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

interface ConfirmationCodeInputsProps {
  identity: string;
  onComplete: ({ identity, code, setError }: onCompleteProps) => Promise<any>
}

export function ConfirmationCodeInputs({
  identity,
  onComplete
}: ConfirmationCodeInputsProps) {
  const { loadingCustomer } = useCustomer();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  // Reset key to force re-render of OTP input when code is incorrect (resets focus)
  const [reset, setReset] = useState(0);

  const onChangeCode = useCallback(async (code: string) => {
    setCode(code);

    if(code.length === CONFIRMATION_CODE_LENGTH) {
      try {
        setLoading(true);
        await onComplete({ identity, code, setError });
      } catch(e) {
        setError('An error occurred. Please try again.');
      } finally {
        setCode('');
        setLoading(false);
        setReset(key => key + 1);
      }
    }
  }, [identity, onComplete, setCode]);
  return (
    <>
      <OtpInput
        key={reset}
        shouldAutoFocus={true}
        isInputNum={true}
        separator={<span className="separator" />}
        isDisabled={loadingCustomer || loading}
        containerStyle="inputField inputFieldOtp"
        inputStyle="inputContainer"
        value={code}
        numInputs={CONFIRMATION_CODE_LENGTH}
        onChange={onChangeCode} />
      {error && <span className="formError">{error}</span>}
      {loading && <LoadingSpinnerOverlay />}

    </>
  );
}
