import React, { useEffect } from 'react';

import classnames from 'classnames';

import { useCart } from 'src/public/components/online_ordering/CartContext';
import { Channel } from 'src/public/js/siteUtilities';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'shared/components/common/modal';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';

import Cart from './Cart';
import CartIcon from './CartIcon';

export const CART_POPOVER_CONTEXT_KEY = 'cartPopover';

const CartModal = ({ disabled }: {disabled?: boolean}) => {
  const { cart, channel } = useCart();
  const { ooRestaurant, orderingDisabled } = useRestaurant();
  const cartDisabled = channel !== Channel.ECOMM && orderingDisabled || !ooRestaurant || disabled;
  const numberOfItems = cart?.order?.numberOfSelections || 0;
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);

  useEffect(() => {
    // Freeze background behind cart when modal is open
    document.body.style.overflowY = context?.isOpen ? 'hidden' : 'unset';
    return () => { document.body.style.overflowY = 'unset'; };
  }, [context?.isOpen]);

  if(!context) {
    return null;
  }

  return (
    <>
      <button
        className="targetAction"
        aria-label={`Cart with ${numberOfItems} items`}
        aria-controls="cart-control"
        disabled={cartDisabled}
        aria-expanded={context.isOpen}
        onClick={context.open}>
        <div aria-hidden="true" className={classnames('cartPopoverTarget', { disabled: cartDisabled })}>
          <CartIcon />
          {numberOfItems}
        </div>
      </button>
      <Modal isOpen={context.isOpen} onClose={context.close} className="cartModal">
        <ModalOverlay fadeIn fadeOut />
        <ModalContent wrapperClassName="wrapper" contentClassName="content" slideIn slideOut ariaLabelledBy="cart-label">
          <div className="header">
            <div id="cart-label">
              Your Order
            </div>
            <ModalCloseButton className="closeButton" />
          </div>
          <Cart />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CartModal;
