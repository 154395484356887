import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useSubmitOrder } from 'public/components/default_template/online_ordering/checkout/useSubmitOrder';
import { CheckoutFormData } from 'public/components/online_ordering/CheckoutContext';

const useAdyenPayments = () => {
  const submitOrder = useSubmitOrder();
  const { getValues } = useFormContext<CheckoutFormData>();

  const placeAdyenOrder = useCallback(async (paymentIntentID: string | undefined) => {
    const checkoutFormData = getValues();
    const formData = {
      ...checkoutFormData,
      semiPaymentIntentId: paymentIntentID
    };

    await submitOrder(formData);
  }, [getValues, submitOrder]);

  return { placeAdyenOrder };
};

export default useAdyenPayments;
