import React, { useCallback } from 'react';

import { ConfirmGuestIdentityMutation, GuestDocument, IdentityType, useConfirmGuestIdentityMutation } from 'src/apollo/onlineOrdering';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';
import { alertError } from 'src/shared/js/alertUtils';

import { useModalContext } from 'shared/components/common/modal';

import { ConfirmationCodeModal, onCompleteProps } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';


interface HandleResponseProps {
  response: ConfirmGuestIdentityMutation | null | undefined,
  setError: (err: string) => void
}


interface VerifyEmailModalProps {
  email: string;
  onCloseModal: (didUpdateEmail: boolean) => Promise<void>;
  resendCode: (email: string) => void;
}

const VerifyEmailModal = (
  { email, onCloseModal, resendCode }: VerifyEmailModalProps
) => {
  const { onClose } = useModalContext();
  const ooClient = useOOClient();
  const combinedOnClose = useCallback((didUpdateEmail: boolean) => {
    onCloseModal(didUpdateEmail);
    onClose();
  }, [onCloseModal, onClose]);


  const handleResponse = useCallback(async ({ response, setError }: HandleResponseProps) => {
    const confirmGuestIdentityResult = response?.confirmGuestIdentity;
    switch(confirmGuestIdentityResult?.__typename) {
      case 'ConfirmGuestIdentitySuccess':
        setError('');
        combinedOnClose(true);
        break;
      case 'IncorrectCodeError':
      case 'CodeExpiredError':
      case 'CodeMaxAttemptsReachedError':
        setError(confirmGuestIdentityResult?.message);
        break;
      case 'GuestIdentityAlreadyExistsForAnotherGuestError':
      case 'CodeAuthenticationError':
      case 'UnexpectedConfirmCodeError':
        alertError(confirmGuestIdentityResult?.message);
        combinedOnClose(false);
        break;
    }
  }, [combinedOnClose]);

  const [confirmGuestIdentity] = useConfirmGuestIdentityMutation({
    refetchQueries: [{ query: GuestDocument }],
    awaitRefetchQueries: true
  });

  const onComplete = async ({ code, setError }: onCompleteProps) => {
    const { data } = await confirmGuestIdentity({
      variables: {
        input: {
          code,
          identityType: IdentityType.Email,
          identityString: email
        }
      },
      client: ooClient
    });
    return handleResponse({ response: data, setError });
  };

  return <ConfirmationCodeModal
    onComplete={onComplete}
    identity={email}
    isOpen={true}
    onClose={() => combinedOnClose(false)}
    sendCode={() => resendCode(email)} />;
};

export default VerifyEmailModal;
