import React, { PropsWithChildren, StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import { NormalizedCacheObject } from '@apollo/client';

import { createClient } from 'src/apollo/createClient';
import { DebugContextProvider } from 'src/shared/components/common/debug/DebugContext';
import { NavHistoryProvider } from 'src/shared/components/common/history_context/NavHistoryContext';
import { useGiaAuth } from 'src/shared/js/hooks/useGiaAuth';
import { AuthProvider } from 'src/vendor/do-secundo-guest-authentication';

import AlertModalProvider from 'shared/components/common/alert_modal/AlertModal';
import { OOClientProvider } from 'shared/components/common/oo_client_provider/OOClientProvider';

import App from 'public/components/app/App';
import Routes from 'public/js/routes';

import config from 'config';

declare global {
  interface Window {
    __SSR_STATUS__: string | undefined | null;
    __SSR_ERR_MSG__: object | undefined | null;
    __OO_STATE__: NormalizedCacheObject | undefined;
    __APOLLO_STATE__: NormalizedCacheObject | undefined;
    generatedImages: {[k: string]: string}
  }
}

const AuthProviderWrapper = ({ children, gatewayOrigin }: PropsWithChildren<{gatewayOrigin: string}>) => {
  const giaAuth = useGiaAuth();
  return giaAuth
    ? <AuthProvider gatewayOrigin={gatewayOrigin}>{children}</AuthProvider>
    : <>{children}</>;
};

const ClientApp = () => {
  const giaAuth = useGiaAuth();
  return (
    <StrictMode>
      <CookiesProvider>
        <AuthProviderWrapper gatewayOrigin={config.resources.apiHost}>
          <BrowserRouter>
            <NavHistoryProvider>
              <DebugContextProvider>
                <OOClientProvider client={host => createClient(host, window.__OO_STATE__, true, { enabled: true }, undefined, true, giaAuth, config.resources.clientQueryTimeoutMs)}>
                  <AlertModalProvider>
                    <App><Routes /></App>
                  </AlertModalProvider>
                </OOClientProvider>
              </DebugContextProvider>
            </NavHistoryProvider>
          </BrowserRouter>
        </AuthProviderWrapper>
      </CookiesProvider>
    </StrictMode>
  );
};

export default ClientApp;
