import React from 'react';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import PrimaryCTA from 'shared/components/primary_cta/PrimaryCTA';

import { useCart } from 'public/components/online_ordering/CartContext';
import { Channel } from 'public/js/siteUtilities';

const EmptyCart = () => {
  const { channel } = useCart();
  const { orderPath, ecommPath } = useRestaurantRoutes();

  return (
    <div className="checkoutForm emptyCart">
      <section className="checkoutSection currentSection">
        <h2 className="subHeader">Your cart is empty.</h2>
        <div className="fixedSection">
          <div>Add items to start your order.</div>
        </div>
        <div className="fixedSection">
          <PrimaryCTA
            href={channel === Channel.ECOMM ? ecommPath : orderPath}
            text={channel === Channel.ECOMM ? 'Keep shopping' : 'Order Online'} />
        </div>
      </section>
    </div>
  );
};

export default EmptyCart;
