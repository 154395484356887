import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { DiningOptionBehavior, OrderIdentifier, OrderStatusType, useGetOrderStatusesQuery } from 'src/apollo/onlineOrdering';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { CompletedOrder } from 'src/public/components/online_ordering/types';

import { OrderTrackerMap } from 'public/components/default_template/online_ordering/order_tracker/order_tracker_map/OrderTrackerMap';

import DeliveryDriverInfo from './delivery_driver_info/DeliveryDriverInfo';
import OrderStatusBar from './order_status_bar/OrderStatusBar';
import getUnifiedOrderStatus, { UnifiedOrderStatus } from './order_status_bar/statusUtil';
import { TrackerFulfillmentInfo } from './tracker_fulfillment_info/TrackerFulfillmentInfo';


export type OrderTrackerProps = {
  order: CompletedOrder
}

export const OrderTracker = ({ order } : OrderTrackerProps) => {
  const orderIdentifier : OrderIdentifier = {
    orderGuid: order.guid,
    restaurantGuid: order.restaurant.guid,
    diningOptionBehavior: order.diningOptionBehavior,
    isThirdPartyDeliveryServiceOrder: !!order.deliveryServiceInfo
  };

  const { customer } = useCustomer();

  const orderBelongsToUser = customer!! && customer.email === order.customerV3?.email;

  const { data, loading: orderStatusInfoLoading } = useGetOrderStatusesQuery({ variables: { input: { orderIdentifiers: [orderIdentifier] } }, fetchPolicy: 'network-only', ssr: false });

  const orderStatusInfo = data?.orderStatuses.orderStatusesResponse?.at(0);

  const unifiedStatus = getUnifiedOrderStatus(orderStatusInfo?.guestOrderStatus ?? OrderStatusType.Received, orderStatusInfo?.deliveryStatus ?? null);

  const curbside = order.curbsidePickup?.selected || order.curbsidePickupV2 !== undefined;

  const deliveryDriver = orderStatusInfo?.trackingDetails?.deliveryDriver;
  const driverInfo = deliveryDriver
    ? {
      driverName: deliveryDriver.name,
      driverNumber: deliveryDriver.phoneNumber,
      driverPhotoUrl: deliveryDriver.profileImageUrl
    }
    : undefined;

  const showDeliveryComponents = order.diningOptionBehavior === DiningOptionBehavior.Delivery;

  const showDriverInfo = showDeliveryComponents && unifiedStatus !== UnifiedOrderStatus.CANCELLED && unifiedStatus !== UnifiedOrderStatus.DELIVERY_FAILED;

  const showFulfillmentInfo = ![UnifiedOrderStatus.CANCELLED, UnifiedOrderStatus.DELIVERY_FAILED].includes(unifiedStatus);

  const showMap = showDeliveryComponents && showDriverInfo && orderBelongsToUser && showFulfillmentInfo;

  let body =
  <div data-testid="tracker-skeleton" >
    <Skeleton width="100%" height={420} className="trackerSkeleton" />
  </div>;

  if(!orderStatusInfoLoading) {
    const orderCompletedDate = orderStatusInfo?.lastUpdated ? new Date(Date.parse(orderStatusInfo.lastUpdated)) : undefined;

    body =
    <div>
      <div className="sectionBlock">
        <OrderStatusBar
          orderStatus={unifiedStatus}
          diningOptionBehavior={order.diningOptionBehavior}
          curbside={curbside}
          restaurantName={order.restaurant.name}
          restaurantNumber={order.restaurant.location.phone}
          pickupInstructions={order.guestCommunication}
          completedDate={orderCompletedDate}
          driverName={driverInfo?.driverName} />
        {showMap && <div data-testid="tracker-map" className="mapWrapper"><OrderTrackerMap order={order} orderStatusData={orderStatusInfo} /></div>}
        {showFulfillmentInfo && <TrackerFulfillmentInfo order={order} orderStatus={unifiedStatus} orderStatusData={orderStatusInfo} loggedIn={orderBelongsToUser} />}
      </div>
      {showDriverInfo &&
      <div className="sectionBlock">
        <DeliveryDriverInfo driverInfo={driverInfo} />
      </div>}
    </div>;
  }


  return (
    <div data-testid="order-tracker">
      {body}
    </div>
  );
};
