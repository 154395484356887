import React, { useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { LinkIcon, CloseIcon, ChevronLeftIcon, ChevronRightIcon } from '@toasttab/buffet-pui-icons';
import { DEFAULT_COLORS, FieldType, GOOGLE_FONTS, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import classNames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { SpotlightConfig } from 'src/apollo/onlineOrdering';
import { ButtonType, SpotlightBanner as SpotlightBannerType, SpotlightBannerContent } from 'src/apollo/sites';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import Link from 'src/shared/components/common/link';

import Button from 'shared/components/common/button';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { ScreenWidth, useIsMobile, useScreenWidth } from 'shared/js/utils/WindowContext';

import { useBrandedAppPromo } from 'public/components/default_template/app_promo/useAppPromo';
import { getFontFaceAndFamily } from 'public/components/default_template/meta/StyleMeta';
import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

import { useSpotlightContext } from './SpotlightContext';


const EIGHT_SECONDS_MS = 8000;

type Props = {
  fixed?: boolean;
  style?: React.CSSProperties;
}

type WrappedProps = Props & {
  route: string
}

type AppSpotlight = {
  description: string;
  link?: string | null;
}

const onlineOrderingPaths = ['/order', '/online'];

// Don't show if
// 1. Is not enabled or has no contents
// 2. Is on a page that is not supported
export const showSpotlight = (
  spotlightInstance: Props,
  configuredSpotlight: SpotlightBannerType | undefined | null,
  configuredLegacySpotlight: SpotlightConfig | undefined | null,
  consolidatedContents: SpotlightBannerContent[],
  currentRootPath: string,
  toastProduct: ToastProduct,
  isOnlyLegacyContent?: boolean,
  appSpotlight?: AppSpotlight
) => {
  const showSpotlight = configuredSpotlight
    && !!configuredSpotlight?.enabled
    && configuredSpotlight.contents?.length
    // Skip applicablePages check if product is not Sites
    && (toastProduct !== ToastProduct.Sites || isOnApplicablePage(currentRootPath, configuredSpotlight.applicablePages || []));
  const showLegacySpotlight = !!configuredLegacySpotlight &&
   !!configuredLegacySpotlight.headerText &&
    configuredLegacySpotlight.headerText.length > 0 &&
    onlineOrderingPaths.includes(currentRootPath);
  // If there is no configuration, we do not show the fixed spotlight if there is only legacy content
  const sameFixed = configuredSpotlight ? spotlightInstance.fixed === configuredSpotlight?.fixed : isOnlyLegacyContent && spotlightInstance.fixed === false;
  return (
    consolidatedContents.length !== 0 && sameFixed && (showSpotlight || showLegacySpotlight || !!appSpotlight)
  );
};

const isOnApplicablePage = (currentRootPath: string, applicablePages: string[]) => {
  // accounts for sites that do not have a custom domain page and use /online for their online ordering page instead of /order
  return (applicablePages.includes('/order') ? [...applicablePages, ...onlineOrderingPaths] : applicablePages).includes(currentRootPath);
};

export const getConsolidatedBanners = (configuredSpotlight: SpotlightBannerType | undefined | null, legacySpotlight: SpotlightConfig | undefined | null, appSpotlight?: AppSpotlight) => {
  const result = [];
  if(legacySpotlight?.headerText) {
    result.push({
      description: legacySpotlight.headerText,
      additionalDetails: legacySpotlight.bodyText
    });
  }
  if(configuredSpotlight?.enabled && configuredSpotlight.contents) {
    result.push(...configuredSpotlight.contents);
  }
  if(appSpotlight?.link) {
    result.push({
      description: appSpotlight.description,
      link: appSpotlight.link
    });
  }
  return result;
};

const SpotlightBannerContents = (
  { description, additionalDetails, link, color, fontFamily, onModalOpen, onModalClose }:
  SpotlightBannerContent & { color: string; fontFamily: string | undefined; onModalOpen: () => void; onModalClose: () => void }
) => {
  const { isOpen, onOpen, onClose } = useModal();
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const width = useScreenWidth();

  const truncatedDescription = useMemo(
    () => descriptionRef.current ? descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight : false,
    // recalculate when the window width changes to check if the description has been truncated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptionRef?.current?.scrollHeight, width]
  );

  return (
    <>
      {link && <span className="icon"><LinkIcon size="xs" /></span>}
      <span className="description" style={{ color }} ref={descriptionRef}>{link ? <Link style={{ color }} data-testid="spotlightLink" href={link}>{description}</Link> : description}</span>
      {truncatedDescription || additionalDetails ?
        <Button
          variant={ButtonType.Text}
          className={classNames('seeMoreButton', !truncatedDescription ? 'leftPadding' : '')}
          onClick={() => { onOpen(); onModalOpen(); }}
          style={{ color, fontFamily }}>See More
        </Button>
        : null}
      <Modal isOpen={isOpen} onClose={() => {
        onClose();
        onModalClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <div className="spotlightModal">
            <div className="header">
              <ModalCloseButton />
            </div>
            <div className="description">
              {description}
            </div>
            <div className="additionalDetails">
              {additionalDetails}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const WrappedSpotlightBanner = (props: WrappedProps) => {
  const { useEditableRef } = useEditor();
  const { restaurant, toastProduct, ooRestaurant } = useRestaurant();
  const { isHidden, hide } = useSpotlightContext();
  const getColorFromTheme = useThemeColorScheme();
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const appPromoData = useBrandedAppPromo({ skip: !!restaurant.content?.navConfig?.disableAppPromo });

  const spotlight = restaurant.content?.spotlightBanner;
  const legacySpotlight = ooRestaurant?.spotlightConfig;
  const currentRootPath = `/${props.route.split('/')[1]}`;

  const appSpotlight = useMemo(() => {
    if(!appPromoData) {
      return undefined;
    }

    return {
      description: `Download the ${appPromoData.appName} app now!`,
      link: appPromoData.link
    };
  }, [appPromoData]);

  const consolidatedContents = useMemo(() => {
    return getConsolidatedBanners(spotlight, legacySpotlight, appSpotlight);
  }, [spotlight, legacySpotlight, appSpotlight]);

  const moreThanOneBanner = consolidatedContents.length > 1;
  const isOnlyLegacyContent = !moreThanOneBanner && !!legacySpotlight?.headerText && legacySpotlight?.headerText?.length > 0;
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'spotlight banner',
    displayName: 'Banner',
    path: 'content.spotlightBanner',
    actions: ['delete'],
    schema: { fields: [{ displayName: 'isOnlyLegacyContent', value: isOnlyLegacyContent, type: FieldType.Text, path: '' }] }
  });


  if(!showSpotlight({ ...props }, spotlight, legacySpotlight, consolidatedContents, currentRootPath, toastProduct, isOnlyLegacyContent, appSpotlight)) {
    return null;
  }

  const contrastChecker = new ColorContrastChecker();

  const maybeBackgroundColor = toastProduct === ToastProduct.OOBasic ? restaurant.meta.primaryColor : spotlight?.backgroundColor;
  const backgroundColor = getColorFromTheme(theme => {
    return theme.colorOverrides?.spotlightBannerColorOverrides?.background ?? theme.colorScheme.background.primary;
    // If either the backgroundColor or the primaryColor is not defined, use the default background color
  }, maybeBackgroundColor || restaurant.meta.primaryColor || DEFAULT_COLORS.background);

  const themeTextColor = getColorFromTheme( theme => theme.colorScheme.text.onBgPrimary, '#000000');
  // Text color will be whatever color passes the contrast check
  const textColor = [themeTextColor, '#FFFFFF', '#000000'].filter(color => contrastChecker.isLevelAA(backgroundColor, color))[0] || '#FFFFFF';

  const font = spotlight?.font ? getFontFaceAndFamily(spotlight.font) : undefined;
  const fontLink = font && typeof font !== 'string' && font.fontFamily && GOOGLE_FONTS.includes(font.fontFamily)
    ? <link href={`https://fonts.googleapis.com/css?family=${font.fontFamily}:wght@400;600;700&display=swap`} rel="stylesheet" />
    : null;

  const button = spotlight?.fixed ?
    <Button
      aria-label="Hide banner"
      data-testid="spotlightHideButton"
      variant={ButtonType.Text}
      className={classNames('hideButton', !moreThanOneBanner ? 'singlePage' : '')}
      style={{ color: textColor }} onClick={hide}><CloseIcon />
    </Button>
    : null;

  return (
    <>
      {font ?
        <Helmet>
          {fontLink}
          <style type="text/css">
            {`${font.fontFace}`}
          </style>
        </Helmet>
        : null}
      <h5
        ref={editableRef}
        hidden={isHidden}
        data-testid="spotlightBanner"
        className={classnames(
          'spotlightBanner',
          {
            fixedTop: spotlight?.fixed,
            isHidden
          }
        )}
        style={{
          ...props.style,
          backgroundColor,
          color: textColor,
          fontFamily: font?.fontFamily,
          '--spotlightBannerBgColor': backgroundColor,
          '--spotlightPaginationColor': textColor
        } as React.CSSProperties }>
        <div className="carouselWrapper">
          <Swiper
            slidesPerGroupAuto={false}
            navigation={{
              nextEl: '.carouselArrowNext',
              prevEl: '.carouselArrowPrev'
            }}
            pagination={{
              clickable: true,
              el: '.carouselPagination'
            }}
            loop={true}
            autoplay={{ delay: EIGHT_SECONDS_MS }}
            modules={[Navigation, Pagination, Autoplay]}
            className="spotlightCarousel"
            style={{ '--swiper-navigation-size': '16px' } as React.CSSProperties}>
            {button}
            {consolidatedContents.map((content, i) =>
              <SwiperSlide key={`spotlight-carousel-${i}`}>
                <div className="contentWrapper">
                  <SpotlightBannerContents {...content} color={textColor} onModalOpen={() => {}} onModalClose={() => {}} fontFamily={font?.fontFamily} />
                </div>
              </SwiperSlide>)}
            {moreThanOneBanner &&
            <>
              <div className="carouselPagination" />
              {!isMobile &&
              <div className="carouselArrows" data-testid="carousel-arrows">
                <div className="carouselArrowPrev" ><ChevronLeftIcon /></div>
                <div className="carouselArrowNext" ><ChevronRightIcon /></div>
              </div>}
            </>}
          </Swiper>
        </div>
      </h5>
    </>
  );
};

const EditorSpotlightBanner = (props: Props) => {
  const { url } = useEditor();

  return <WrappedSpotlightBanner {...props} route={url} />;
};

const SitesSpotlightBanner = (props: Props) => {
  const { pathname } = useLocation();

  return <WrappedSpotlightBanner {...props} route={pathname} />;
};

const SpotlightBanner = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor ? <EditorSpotlightBanner {...props} /> : <SitesSpotlightBanner {...props} />;
};

export default SpotlightBanner;
