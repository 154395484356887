import { SeoMeta } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { SiteData } from 'shared/js/types';

export const generateMetaFields = (meta: SeoMeta, restaurant: NonNullable<ReturnType<typeof useRestaurant>['restaurant']>) => {
  return { title: generateTitle(meta, restaurant), description: generateDescription(meta, restaurant) };
};

export const generateTitle = (meta: SeoMeta, restaurant: NonNullable<ReturnType<typeof useRestaurant>['restaurant']>) => {
  let { title } = meta;
  if(title) {
    return `${restaurant.name} - ${title}`;
  }
  return restaurant.name;
};

export const generateDescription = (meta: SeoMeta, restaurant: NonNullable<ReturnType<typeof useRestaurant>['restaurant']>) => {
  let { description } = meta;
  if(!description) {
    const locations = restaurant.locations as NonNullable<typeof restaurant.locations>;
    const locationsInCitiesStr = locations?.length > 0
      ? `Locations in ${locations.slice(0, 3)
        .map(loc => `${loc.city}, ${loc.state}`)
        .join(', ')}`
      : '';
    const backupDescription = `See our latest menu, find our hours, and order online directly from us. ${locationsInCitiesStr || ''}`;
    description = backupDescription;
  }

  return description;
};

export const generateAddlPageMetaFields = (meta: SeoMeta, pageRoute: string, restaurant: SiteData) => {
  return { title: generateAddlPageTitle(meta, pageRoute, restaurant), description: generateAddlPageDescription(meta) };
};

const generateAddlPageTitle = (meta: SeoMeta, pageRoute: string, restaurant: SiteData) => meta.title ?? `${restaurant.name} - ${pageRoute.replace('/', '')}`;

const generateAddlPageDescription = (meta: SeoMeta) => meta.description ?? undefined;

export default generateMetaFields;
