import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useDeleteAccountMutation } from 'src/apollo/onlineOrdering';
import { DeleteAccountModal } from 'src/public/components/default_template/online_ordering/account/profile_settings/DeleteAccountModal';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { TOAST_LOCAL_PATH } from 'src/public/js/siteUtilities';
import Button from 'src/shared/components/common/button';
import { Modal, ModalContent, ModalOverlay, useModal } from 'src/shared/components/common/modal';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import { alertError } from 'shared/js/alertUtils';

import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';

const toastFail = () => {
  alertError('There was an error while saving. Please refresh the page.');
};

export const ProfileSettings = ({ isLoggedOut }: {isLoggedOut?: boolean}) => {
  const { pwlessLogout } = useCustomer();
  const { isOpen, onOpen, onClose } = useModal();
  const [deleteAccount] = useDeleteAccountMutation();
  const history = useHistory();
  const { orderPath, homePath } = useRestaurantRoutes();
  const ooClient = useOOClient();

  const openModal = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onOpen();
  }, [onOpen]);

  const closeModal = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onClose();
  }, [onClose]);

  const accountDelete = useCallback(async () => {
    try {
      const { data } = await deleteAccount({ client: ooClient });
      if(data?.deleteAccount.__typename === 'DeleteAccountResponse') {
        const success = await pwlessLogout();
        if(success) {
          if(homePath === TOAST_LOCAL_PATH) {
            history.push(homePath);
          } else {
            orderPath && history.push(orderPath);
          }
        } else {
          toastFail();
        }
      }
    } catch(err) {
      toastFail();
    } finally {
      onClose();
    }
  }, [deleteAccount, history, onClose, orderPath, homePath, pwlessLogout, ooClient]);

  return (
    <div className="profileSettings" data-testid="ProfileSettings" tabIndex={0}>
      <h2 className={isLoggedOut ? 'accountSubheadingDisabled' : 'accountSubheading'}>Account Settings</h2>
      <br />
      <Button
        className="deleteButton"
        onClick={openModal}>Delete this account
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        {isLoggedOut
          ?
          <>
            <ModalOverlay fadeIn fadeOut />
            <PwlessAuth source={AuthenticationSource.AccountPage} />
          </>
          :
          <>
            <ModalOverlay fadeIn={false} />
            <ModalContent contentClassName="deleteAccountModalContent">
              <DeleteAccountModal onClose={closeModal} onDelete={accountDelete} />
            </ModalContent>
          </>}
      </Modal>
    </div>
  );
};
