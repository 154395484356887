import React from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import PreviewModeBanner from 'shared/components/common/nav/preview_mode_banner/PreviewModeBanner';

export type NavType = 'normal' | 'stickyNav' | 'fixedNav' | 'absoluteNav';

type Props = {
  navType: NavType;
  shouldShowPreviewBanner: boolean;
  className?: string;
};

const Nav = (props: React.PropsWithChildren<Props>) => {
  const { isEditor } = useEditor();
  return (
    <nav className={classnames('globalNav', props.navType, props.className)} id="globalNav" aria-label="main-navigation">
      {!isEditor && <PreviewModeBanner {...props} />}
      {props.children}
    </nav>
  );
};

export default Nav;
