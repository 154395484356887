import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useEditor } from '@toasttab/sites-components';
import useScript from 'react-script-hook/lib/use-script';

import { useGetEGiftCardConfigQuery } from 'src/apollo/onlineOrdering';
import { LogoSizeOption, PageConfig } from 'src/apollo/sites';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { AdditionalPagesType } from 'shared/js/types';

import Footer from 'public/components/default_template/footer';
import Nav from 'public/components/default_template/nav/Nav';

import { giftCardRecaptchaSiteKey } from 'config';

import { GiftCardIllustration } from './GiftCardIllustration';
import { GiftCardPurchaseForm } from './GiftCardPurchaseForm';

export type CateringFormPageType = AdditionalPagesType[0]['content'] & { __typename: 'CateringFormPage' };

type Props = {
  config?: PageConfig | null;
}

export const GiftCardPurchasePage = (props: Props) => {
  const { restaurant, ooRestaurant, selectedLocation } = useRestaurant();
  const ooClient = useOOClient();
  const { data } = useGetEGiftCardConfigQuery({ variables: { restaurantGuid: selectedLocation.externalId }, skip: !selectedLocation.externalId, client: ooClient });
  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'better together page',
    actions: []
  });

  const backgroundColorStyle = useMemo(() => props.config?.backgroundColor ? { backgroundColor: props.config?.backgroundColor } : {}, [props.config?.backgroundColor]);

  const formMethods = useForm({ mode: 'all' });
  useScript({ src: `https://www.google.com/recaptcha/enterprise.js?render=${giftCardRecaptchaSiteKey}` });

  const { meta, content, theme } = restaurant;
  if(!content) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  // Use the standard logoSize when the logo is in the middle of the nav
  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;

  return (
    <div className="defaultTemplate" data-testid="giftCardPurchasePage" ref={editableRef} id="main">
      <Nav
        withShadow
        logoSrc={meta.icon}
        logoSize={
          ['header-layout-4', 'header-layout-5', 'header-layout-6', 'header-layout-7'].includes(content.navConfig?.navLayout || '') ? LogoSizeOption.Standard : null
        }
        primaryCta={primaryCta}
        nonPrimaryCtas={nonPrimaryCtas}
        shouldShowPreviewBanner={true} />

      <main className="giftCardPurchasePage" style={backgroundColorStyle}>
        <FormProvider {...formMethods}>
          <div className="card">
            <GiftCardIllustration
              message={formMethods.getValues()['message']}
              recipientName={formMethods.getValues()['recipientName']}
              amount={parseFloat(formMethods.getValues()['amount'])}
              locationName={restaurant.name} />
          </div>
          <div className="form">
            {ooRestaurant?.giftCardConfig?.purchaseAllowed && !ooRestaurant.giftCardConfig.hasGiftCardsExtension ?
              <GiftCardPurchaseForm
                // These values come back in minor units so we divide by 100 to get real value
                minPurchaseAmount={data?.eGiftCardConfig?.minimumPurchaseAmount?.value ? data?.eGiftCardConfig?.minimumPurchaseAmount?.value / 100 : undefined}
                maxPurchaseAmount={data?.eGiftCardConfig?.maximumPurchaseAmount?.value ? data?.eGiftCardConfig?.maximumPurchaseAmount?.value / 100 : undefined} />
              : <div data-testid="disabledText">Gift cards are not available for purchase right now.</div>}
          </div>
        </FormProvider>
      </main>

      <div className="footerWrapper">
        <Footer />
      </div>
    </div>
  );
};
