import React, { useContext } from 'react';

type RestaurantRoutesContextType = {
  homePath: string;
  orderPath: string;
  ecommPath?: string;
  ecommCheckoutPath?: string;
  ecommConfirmationPath?: string;
  orderPathPattern: string;
  checkoutPath: string;
  confirmationPath: string;
  accountPath: string;
}

export const RestaurantRoutesContext = React.createContext<RestaurantRoutesContextType | undefined>(undefined);

export const getOrderPath = (shortUrl?: string | null) => `/order/${shortUrl}`;

type Props = {
  shortUrl?: string | null;
  homePath?: string;
  orderPath?: string;
  ecommPath?: string;
  orderPathPattern?: string;
  checkoutPathPrefix?: string;
  confirmPathPrefix?: string;
  accountPath?: string;
}

export const RestaurantRoutesContextProvider = ({
  shortUrl,
  homePath,
  orderPath,
  ecommPath,
  orderPathPattern,
  checkoutPathPrefix,
  confirmPathPrefix,
  accountPath,
  children
}: React.PropsWithChildren<Props>) => {
  return (
    <RestaurantRoutesContext.Provider value={{
      homePath: homePath || '/',
      orderPath: orderPath || getOrderPath(shortUrl),
      ecommPath: ecommPath,
      ecommCheckoutPath: `${ecommPath}/checkout`,
      ecommConfirmationPath: `${ecommPath}/confirm`,
      orderPathPattern: orderPathPattern || '/order/:slug',
      checkoutPath: `${checkoutPathPrefix || ''}/checkout`,
      confirmationPath: `${confirmPathPrefix || ''}/confirm`,
      accountPath: accountPath || `${homePath || ''}/account`
    }}>
      {children}
    </RestaurantRoutesContext.Provider>
  );
};

export const useRestaurantRoutes = () => {
  const context = useContext(RestaurantRoutesContext);
  if(!context) {
    throw new Error('useRestaurantRoutes must be used within a RestaurantRoutesContextProvider');
  }

  return context;
};

