import React from 'react';

import cx from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';

import { ToggleInput } from 'shared/components/common/forms';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

const PaymentOptionsSection = () => {
  const isIntlRestaurant = useIsIntlRestaurant();
  const { enabledPaymentOptions } = useCheckout();
  const { cart } = useCart();
  const { paymentOption, setPaymentOption } = usePayment();

  const canPayLater = enabledPaymentOptions.has(PaymentOption.UponReceipt);
  const canPayNow = enabledPaymentOptions.has(PaymentOption.PayNow);

  if(!canPayLater && !canPayNow || !cart) {
    return null;
  }

  if(!canPayNow) {
    const message =
      cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
        'Please pay delivery driver upon receiving your order.' :
        'Please pay at the restaurant when you pick up your order.';
    return <div className="payAtRestaurantNote">{message}</div>;
  }

  if(!isIntlRestaurant) {
    return canPayLater ?
      <div className="paymentTabs" role="tablist" data-testid="payment-option-buttons">
        <button
          type="button"
          role="tab"
          aria-selected={paymentOption !== PaymentOption.UponReceipt}
          className={cx({ selected: paymentOption !== PaymentOption.UponReceipt })}
          onClick={() => setPaymentOption(PaymentOption.PayNow)}>
          Pay now
        </button>
        <button
          type="button"
          role="tab"
          aria-selected={paymentOption === PaymentOption.UponReceipt}
          className={cx({ selected: paymentOption === PaymentOption.UponReceipt })}
          onClick={() => setPaymentOption(PaymentOption.UponReceipt)}>
          {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
        </button>
      </div>
      : null;
  }

  return (
    <div data-testid="payment-option-buttons">
      {canPayLater &&
        <div className="paymentButtons">
          <ToggleInput
            checked={paymentOption !== PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.PayNow)}
            name="payNow"
            id="payNow"
            type="radio"
            dataTestId="input-payNow">
            Pay now
          </ToggleInput>
          <ToggleInput
            checked={paymentOption === PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.UponReceipt)}
            name="payLater"
            id="payLater"
            type="radio"
            dataTestId="input-payLater">
            {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
          </ToggleInput>
        </div>}
    </div>
  );
};

export default PaymentOptionsSection;
