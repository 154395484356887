import React, { useEffect, useState } from 'react';

import parseISO from 'date-fns/parseISO';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';

import { Modal, ModalCloseButton, ModalOverlay, ScrollableModalContent } from 'shared/components/common/modal';
import { toLocalDateTime } from 'shared/js/timeUtils';

import DiningOptions from 'public/components/default_template/online_ordering/dining_options/DiningOptions';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { getDateTimeIndexes, useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

export const formatFulfillmentDateTime = (fulfillmentDateTime?: string | null ) => {
  const parsedTime = fulfillmentDateTime ? parseISO(fulfillmentDateTime) : null;
  return parsedTime ? toLocalDateTime(parsedTime) : 'ASAP';
};

const DiningOptionsModal = () => {
  const { cart } = useCart();
  const { fulfillmentData, selectedDiningOptionBehavior, setGuestSelectedFulfillmentTime, fulfillmentModalOpen, setFulfillmentModalOpen } = useFulfillment();
  const { clearDeliveryValidationError, hasCheckedStoredLocation, isValidating, validDeliveryAddress } = useDelivery();
  const { itemInCartWithMaxLeadTime, itemsInCartWithPreorderRule, itemsInCartWithPickupWindowRule, getEarliestFulfillmentTime, validateItemFulfillmentTime } = useTimeBasedRules();
  const [forceSelectMessage, setForceSelectMessage] = useState('');
  const [removeItem, setRemoveItem] = useState(false);

  useEffect(() => {
    if(selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && hasCheckedStoredLocation && !isValidating && !validDeliveryAddress) {
      setForceSelectMessage('Please enter a delivery address.');
      return;
    }

    if(fulfillmentData?.cartFulfillmentData.fulfillmentType === FulfillmentType.Asap && !fulfillmentData?.scheduleData.asapAvailableNow && fulfillmentData?.scheduleData?.futureScheduleDates.length) {
      const diningBehavior = fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Delivery' : 'Pickup';
      setForceSelectMessage(`${diningBehavior} is not available right now. Please schedule your order.`);
      return;
    }

    const [dayIndex, timeIndex] = getDateTimeIndexes(fulfillmentData?.cartFulfillmentData.fulfillmentDateTime, fulfillmentData?.scheduleData?.futureScheduleDates);
    if(dayIndex != null && timeIndex != null && (dayIndex < 0 || timeIndex < 0)) {
      setForceSelectMessage('Your order time is no longer available. Please re-schedule your order.');
      return;
    }

    // The last item in the list should be the most recently added one, which is the one we need to validate
    const preorderInvalid = !validateItemFulfillmentTime(itemsInCartWithPreorderRule[itemsInCartWithPreorderRule.length - 1]?.itemGuid);
    const pickupWindowInvalid = !validateItemFulfillmentTime(itemsInCartWithPickupWindowRule[itemsInCartWithPickupWindowRule.length - 1]?.itemGuid);

    if(itemInCartWithMaxLeadTime || preorderInvalid || pickupWindowInvalid) {
      if(itemInCartWithMaxLeadTime) {
        const fulfillmentTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime;
        if(fulfillmentTime && parseISO(fulfillmentTime) > getEarliestFulfillmentTime(itemInCartWithMaxLeadTime?.itemGuid, selectedDiningOptionBehavior)) {
        // If the chosen fulfillment time is still later than the earliest possible fulfillment time for the current cart, we don't need to open the modal
          return;
        }
      }
      const diningBehavior = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'pickup';
      const selectMessage = `Please update your ${diningBehavior} time to continue ordering or remove the item from your cart.`;
      setForceSelectMessage(selectMessage);
      setRemoveItem(true);
      return;
    }

    // If we've made it this far, there's no error
    setForceSelectMessage('');
  // only run when fulfillment data changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fulfillmentData,
    hasCheckedStoredLocation,
    cart,
    selectedDiningOptionBehavior,
    itemInCartWithMaxLeadTime,
    itemsInCartWithPreorderRule,
    itemsInCartWithPickupWindowRule,
    isValidating,
    validDeliveryAddress
  ]);

  useEffect(() => {
    if(forceSelectMessage) {
      setFulfillmentModalOpen(true);
    }
  }, [forceSelectMessage, setFulfillmentModalOpen]);

  const handleButtonClick = async () => {
    setForceSelectMessage('');
    setRemoveItem(false);
    await handleClose();
  };

  const handleClose = async () => {
    clearDeliveryValidationError();
    await setFulfillmentModalOpen(false);
    setGuestSelectedFulfillmentTime(true);
  };

  return (
    <Modal testId="dining-options-modal" isOpen={fulfillmentModalOpen} preventOverlayClose={forceSelectMessage !== ''} onClose={handleClose} >
      <ModalOverlay fadeIn />
      <ScrollableModalContent contentClassName="diningOptionsModalContent" fadeIn ariaLabelledBy="dining-options-modal-header">
        <div className="header">
          <h3 id="dining-options-modal-header">Order details</h3>
          {!forceSelectMessage && <ModalCloseButton />}
        </div>
        <DiningOptions forceSelectMessage={forceSelectMessage} onButtonClick={handleButtonClick} removeItem={removeItem} />
      </ScrollableModalContent>
    </Modal>
  );
};

export default DiningOptionsModal;
