import { fromUnixTime } from 'date-fns';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';
import { Cart, CompletedOrder } from 'src/public/components/online_ordering/types';
import { toLocalDateTime, toLocalTime } from 'src/shared/js/timeUtils';

const FULFILLMENT_TIME_KEY = 'fulfillmentTime';

export const hasFulfillmentTimeChanged = (order: CompletedOrder): boolean => {
  const savedFulfillmentTime = getSavedFulfillmentTime(order.guid);
  if(order.estimatedFulfillmentDate && savedFulfillmentTime) {
    const orderFulfillmentDateTime = new Date(order.estimatedFulfillmentDate);
    // We want to check up to the minute but not seconds
    const diff = Math.abs(savedFulfillmentTime.getTime() - orderFulfillmentDateTime.getTime());
    return savedFulfillmentTime.getDate() !== orderFulfillmentDateTime.getDate() ||
      // we do not show the notification for any changes under 3 minutes
      diff >= 180000;
  }
  return false;
};

// Gets the saved fulfillment time for the given guid
export const getSavedFulfillmentTime = (guid: string) => {
  const key = FULFILLMENT_TIME_KEY + '|' + guid;
  const value = localStorage.getItem(key);
  return value ? new Date(value) : null;
};

// Saves the fulfillment time for the given cart and guid to local storage
export const saveFulfillmentTime = (cart: Cart | null | undefined, guid: string) => {
  if(cart) {
    const fulfillmentDateTime = getFulfillmentDateTime(cart);
    if(fulfillmentDateTime) {
      localStorage.setItem(FULFILLMENT_TIME_KEY + '|' + guid, fulfillmentDateTime);
    }
  }
};

// Gets the fulfillment date time for the given cart
const getFulfillmentDateTime = (cart: Cart) => {
  const serverTime = Date.now();
  if(cart.fulfillmentType === FulfillmentType.Asap || !cart.fulfillmentDateTime) {
    // For ASAP, we are only given the quote time so we have to calculate the fulfillment time
    const quoteTime = cart.diningOptionBehavior === DiningOptionBehavior.TakeOut ? cart.takeoutQuoteTime : cart.deliveryQuoteTime;

    return quoteTime
      ? new Date(serverTime + quoteTime * 60000).toISOString()
      : new Date(serverTime).toISOString();
  } else {
    return cart.fulfillmentDateTime;
  }
};

export const getFulfillmentTimeNotificationText = (fulfillmentDateTime: number) => {
  const serverTime = new Date(Date.now());
  const fulfillmentDateObject = fromUnixTime(fulfillmentDateTime / 1000);
  // If the new fulfillment date is the same, we just want to display the new time
  return serverTime.getDate() !== fulfillmentDateObject.getDate() ?
    toLocalDateTime(fulfillmentDateObject)
    : toLocalTime(fulfillmentDateObject.toISOString());
};


export const getReadyTimeChangeBucket = (cartOrOrder: Cart | CompletedOrder, cartGuid: string | null | undefined, orderPlacedSucessfully: boolean) => {
  if(!cartOrOrder || !cartGuid) {
    return null;
  }

  const savedTime = getSavedFulfillmentTime(cartGuid);
  if(savedTime === null) {
    return null;
  }
  let newTime : Date;
  if( cartOrOrder.__typename === 'Cart') {
    newTime = new Date(getFulfillmentDateTime(cartOrOrder as Cart));
  } else {
    // eslint-disable-next-line no-extra-parens
    newTime = new Date((cartOrOrder as CompletedOrder).estimatedFulfillmentDate || 0);
  }
  const diff = Math.abs(newTime.getTime() - savedTime.getTime());

  if(diff < 60000) { // Less than 1 minute
    return orderPlacedSucessfully ? 'noChangeAndOrderSubmitted' : 'noChangeAndOrderFailed';
  } else if(orderPlacedSucessfully) {
    return diff < 180000 ? 'smallChangeAndOrderSubmittedNoNotification' : 'smallChangeAndOrderSubmittedWithNotification';
  }
  return 'largeChangeAndOrderFailed';
};
