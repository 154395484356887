import React from 'react';
import { CSSProperties } from 'react';
import { Id, Slide, toast, ToastContainer } from 'react-toastify';

import { TooltipCloseIcon } from 'src/public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentBehaviorTooltip';

export const SitesToastContainer = ({ containerId = 'mainToastContainer', style } : {containerId?: Id, style?: CSSProperties}) => {
  return (
    <ToastContainer
      position="bottom-center"
      containerId={containerId}
      className={'navToastContainer'}
      enableMultiContainer
      autoClose={3000}
      transition={Slide}
      hideProgressBar
      newestOnTop={false}
      closeButton={TooltipCloseIcon}
      style={style}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      theme="dark"
      limit={2} />
  );
};

export const alertSuccess = (message: string, containerId: Id = 'mainToastContainer', style?: CSSProperties) => {
  toast.success(message, { role: 'alert', style, containerId: containerId });
};

export const alertError = (message: string, containerId: Id = 'mainToastContainer', style?: CSSProperties) => {
  toast.error(message, { role: 'alert ', style, containerId: containerId });
};
