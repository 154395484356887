import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckout } from 'public/components/online_ordering/CheckoutContext';

const useCanSubmitOrder = () => {
  const { canCheckout } = useCheckout();
  const { formState } = useFormContext();

  return useMemo(() => canCheckout(formState), [canCheckout, formState]);
};

export default useCanSubmitOrder;
