import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation, withRouter } from 'react-router';

import { useRestaurantByShortUrlQuery } from 'src/apollo/onlineOrdering';
import { RequestContextProps } from 'src/lib/js/context';
import useRefSize from 'src/lib/js/hooks/useRefSize';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { AdyenPaymentWrapper } from 'public/components/default_template/online_ordering/checkout/international/AdyenPaymentForm';
import { AdyenMessage } from 'public/components/default_template/online_ordering/checkout/international/types';
import { useIsCartValid } from 'public/components/default_template/online_ordering/checkout/useIsCartValid';
import { OrderError } from 'public/components/online_ordering/CheckoutContext';

const AdyenPage = ({ staticContext }: RequestContextProps) => {
  // @ts-ignore
  const referrer = typeof window !== 'undefined' ? window.__REFERRER__ : staticContext?.req?.headers.referer;
  const targetOrigin = new URL(referrer).origin;

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const shortUrl = params.get('shortUrl');
  const cartGuid = params.get('cartGuid');
  const checkAmount = params.get('checkAmount');
  const tipAmount = params.get('tipAmount');
  const firstName = params.get('firstName');
  const lastName = params.get('lastName');
  const email = params.get('email');
  const phone = params.get('phone');

  const ooClient = useOOClient();
  const { data } = useRestaurantByShortUrlQuery({
    variables: { shortUrl: decodeURI(shortUrl || '') },
    client: ooClient,
    skip: !shortUrl
  });

  const paymentRef = useRef<HTMLDivElement | null>(null);

  const { height } = useRefSize(paymentRef);
  useEffect(() => {
    window.parent.postMessage(JSON.stringify({ name: AdyenMessage.HEIGHT, data: { sourceId: 'ADYEN_PAYMENT', height } }), targetOrigin);
  }, [targetOrigin, height]);

  const setOrderError = (error: OrderError | null) => window.parent.postMessage(JSON.stringify({ name: AdyenMessage.ERROR, data: { sourceId: 'ADYEN_PAYMENT', error } }), targetOrigin);
  const refetchCart = () => window.parent.postMessage(JSON.stringify({ name: AdyenMessage.REFETCH_CART, data: { sourceId: 'ADYEN_PAYMENT' } }), targetOrigin);
  const { isCartValid } = useIsCartValid(setOrderError, refetchCart);
  const validateCart = useCallback(async () => {
    return !!cartGuid && await isCartValid(
      cartGuid,
      { firstName, lastName, email, phone }
    );
  }, [isCartValid, cartGuid, firstName, lastName, email, phone]);

  if(
    data?.restaurantV2ByShortUrl?.__typename !== 'Restaurant'
    || cartGuid === null
    || checkAmount === null
    || tipAmount === null
    || email === null
  ) {
    return null;
  }

  return (
    <div ref={paymentRef}>
      <AdyenPaymentWrapper
        rxGuid={data.restaurantV2ByShortUrl.guid}
        i18n={data.restaurantV2ByShortUrl.i18n}
        cartGuid={cartGuid}
        checkAmount={parseFloat(checkAmount)}
        tipAmount={parseFloat(tipAmount)}
        guestEmail={email}
        preSubmit={validateCart}
        onAdditionalDetails={async () => {
          window.parent.postMessage(JSON.stringify({ name: AdyenMessage.SHOW_OVERLAY, data: { sourceId: 'ADYEN_PAYMENT' } }), targetOrigin);
          return await validateCart();
        }}
        onPaymentSubmitted={() => window.parent.postMessage(JSON.stringify({ name: AdyenMessage.SHOW_OVERLAY, data: { sourceId: 'ADYEN_PAYMENT' } }), targetOrigin)}
        onPaymentFailed={() => window.parent.postMessage(JSON.stringify({ name: AdyenMessage.HIDE_OVERLAY, data: { sourceId: 'ADYEN_PAYMENT' } }), targetOrigin)}
        onPaymentCompleted={(paymentIntentID: string) => window.parent.postMessage(JSON.stringify({
          name: AdyenMessage.PLACE_ORDER,
          data: { sourceId: 'ADYEN_PAYMENT', paymentIntentID }
        }), targetOrigin)} />
    </div>
  );
};

export default withRouter<RequestContextProps, React.ComponentType<RequestContextProps>>(AdyenPage);
