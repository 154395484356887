import { useEffect, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import config from 'config';

interface SetupStatus {
  cooSetupStatus: {
    acceptingCooOrders: boolean
    shortUrl?: string,
  }
}

const fetchCateringSetupStatus = async (restaurantGuid: string): Promise<SetupStatus> => {
  try {
    const url = `${config.resources.apiHost}/catering/v2/public/restaurant/setupStatus`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Toast-Restaurant-External-ID': restaurantGuid
      }
    });
    return await response.json();
  } catch(err) {
    return { cooSetupStatus: { acceptingCooOrders: false } };
  }
};

const getCateringOOShortUrl = async (restaurantGuid: string) => {
  const response = await fetchCateringSetupStatus(restaurantGuid);
  // The catering API calculates a few things (whether or not the rx has COO, if it's
  // on and fully set up, etc) which is why its the condition to returning the url.
  const showCateringLink = response.cooSetupStatus.acceptingCooOrders;
  return showCateringLink ? response.cooSetupStatus.shortUrl : undefined;
};

const getCateringOOFullUrl = (shortUrl: string) => {
  return `${config.resources.toastwebBaseUri}catering/${shortUrl}?utm_source=toast_local`;
};

export const useCatering = (restaurantGuid: string) => {
  const [cateringUrl, setCateringUrl] = useState<string>();
  const { nvsCateringToastLocal } = useFlags();

  useEffect(() => {
    if(restaurantGuid && nvsCateringToastLocal) {
      getCateringOOShortUrl(restaurantGuid)
        .then(shortUrl => {
          const fullUrl = shortUrl ? getCateringOOFullUrl(shortUrl) : undefined;
          setCateringUrl(fullUrl);
        })
        .catch(_ => setCateringUrl(undefined));
    }
  }, [restaurantGuid, nvsCateringToastLocal]);

  return cateringUrl;
};
