import React, { useCallback, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import cx from 'classnames';

import { OnlineOrderingSpiCreatePaymentIntentSuccessResponse, GetClientTokenQuery, useGetClientTokenQuery, OnlineOrderingSpiGetClientTokenSuccessResponse } from 'src/apollo/onlineOrdering';
import { reportErrorMessageWithData } from 'src/lib/js/clientError';
import { _quoteFontFamily } from 'src/shared/js/fontUtils';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useComponentTimeout } from 'shared/js/hooks/useComponentTimeout';

import { getSiteStyles } from 'public/components/default_template/meta/StyleMeta';
import { useCart } from 'public/components/online_ordering/CartContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import { ToastCashPaymentToggle } from './ToastCashPaymentToggle';
import { useTempEventTracker, useSpiSdk } from './useSpi';

const SPI_IFRAME_ANCHOR_ID = 'spi-frame-anchor';
const IFRAME_ALERT_TIMEOUT_SECS = 5;

const spiReady = (spiSdk: any, restaurantGuid: string, tokenData?: GetClientTokenQuery, paymentIntent?: OnlineOrderingSpiCreatePaymentIntentSuccessResponse) =>
  spiSdk && restaurantGuid && tokenData?.oo.spiGetClientToken.__typename === 'OnlineOrderingSpiGetClientTokenSuccessResponse' && paymentIntent?.sessionSecret;

type Props = {
  anchorClassName?: string;
  allowToastCash?: boolean
  disableSPI?: boolean
}

const getSpiStyles = (siteStyles: ReturnType<typeof getSiteStyles>) => {
  return {
    // styles matching checkout_section.scss and text.scss
    color: '#252525',
    backgroundColor: 'white',
    iconColor: siteStyles.whiteContrastingColor,
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: 'normal',
    fontFamily: `${_quoteFontFamily(siteStyles.bodyFont.fontFamily)}, Effra, sans-serif`
  };
};

const CreditCardFormSPI = ({ anchorClassName, allowToastCash, disableSPI }: Props) => {
  const spiSdk = useSpiSdk();
  const { track } = useTempEventTracker();
  const [loadingForm, setLoadingForm] = useState(true);
  const { restaurant, ooRestaurant } = useRestaurant();
  const { restaurantGuid } = useCart();
  const { setPaymentOption, setIsPaymentValid, paymentIntent, setUserInfoRequired, setPaymentType } = usePayment();
  const { data: tokenData, error: tokenError } = useGetClientTokenQuery();

  const { setIsLoaded } = useComponentTimeout(
    'SPI CC form',
    IFRAME_ALERT_TIMEOUT_SECS,
    spiReady(spiSdk, restaurantGuid, tokenData, paymentIntent),
    {
      restaurantName: ooRestaurant?.name,
      paymentIntentId: paymentIntent?.id
    }
  );

  useEffect(() => {
    track('temp_SPI_loaded', {});
  // run once when this component is mounted
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // A bunch of temporary tracking here to investigate where spi is failing...
  useEffect(() => {
    if(spiSdk) {
      track('temp_SPI_sdk_loaded', {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spiSdk]);

  useEffect(() => {
    if(tokenData?.oo.spiGetClientToken.__typename === 'OnlineOrderingSpiGetClientTokenSuccessResponse') {
      track('temp_SPI_client_token', {});
    } else if(tokenData?.oo.spiGetClientToken.__typename === 'OnlineOrderingPaymentIntentError') {
      track('temp_SPI_client_token_failed', {});
      reportErrorMessageWithData('Failed to create SPI client token', { rxGuid: restaurantGuid });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData]);

  useEffect(() => {
    if(tokenError) {
      track('temp_SPI_client_token_failed', { message: tokenError.message });
      reportErrorMessageWithData('Failed to create SPI client token', { rxGuid: restaurantGuid, message: tokenError.message });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenError]);

  useEffect(() => {
    if(!loadingForm) {
      track('temp_SPI_sdk_initialized', {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingForm]);

  const spiStatusCallback = useCallback((ev: any) => {
    setPaymentOption(PaymentOption.PayNow);
    setIsPaymentValid(ev.content.isValid);
    setPaymentType(ev.content.selectedPaymentMethod);
    setUserInfoRequired(ev.content.selectedPaymentMethod !== 'APPLE_PAY' && ev.content.selectedPaymentMethod !== 'GOOGLE_PAY');
  }, [setPaymentOption, setIsPaymentValid, setPaymentType, setUserInfoRequired]);

  useEffect(() => {
    let frame: HTMLIFrameElement | undefined;
    if(spiReady(spiSdk, restaurantGuid, tokenData, paymentIntent)) {
      // spiReady ensures tokenData is defined
      const tokenResponse = tokenData!.oo.spiGetClientToken as OnlineOrderingSpiGetClientTokenSuccessResponse;
      frame = spiSdk.initialize(
        (ev: any) => {
          setIsLoaded(true); // stop the timer
          setLoadingForm(false); // hide the skeleton
          spiStatusCallback(ev); // call with the default content to initialize the state
          spiSdk.monitor(spiStatusCallback);
        },
        (err: any) => {
          reportErrorMessageWithData(
            'Failed to initialize SPI SDK',
            {
              rxGuid: restaurantGuid,
              message: err?.cause?.message,
              developerMessage: err?.cause?.developerMessage
            }
          );
        },
        {
          oauthToken: tokenResponse.token,
          domElementId: SPI_IFRAME_ANCHOR_ID,
          merchantId: restaurantGuid,
          // spiReady ensures paymentIntent is defined
          sessionSecret: paymentIntent!.sessionSecret,
          acceptAmex: ooRestaurant?.creditCardConfig.amexAccepted ?? false,
          zipRequired: true
        },
        getSpiStyles(getSiteStyles(restaurant.meta))
      );
    }

    // clean up when unmounting
    return () => {
      try {
        frame && document.removeChild(frame);
      } catch(e) {
        // ignore
      }
    };
  }, [spiSdk, restaurantGuid, ooRestaurant?.creditCardConfig.amexAccepted, tokenData, paymentIntent, spiStatusCallback, setIsLoaded, restaurant.meta]);

  return (
    <div className="creditCardFormSpi">
      {allowToastCash ?
        <ToastCashPaymentToggle wrapperStyle={getSpiStyles(getSiteStyles(restaurant.meta))} />
        : null}
      <div className="disabledWrapper">
        <div id={SPI_IFRAME_ANCHOR_ID} className={anchorClassName} aria-disabled={disableSPI}>
          {loadingForm ? <Skeleton width="100%" height="218px" /> : <></>}
        </div>
        {/* Important - overlay must be last */}
        <div className={cx('disabledOverlay', { ['enabled']: disableSPI })}></div>
      </div>
    </div>
  );
};

export default CreditCardFormSPI;
