import React, { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

import { ReorderableItemsQuery } from 'src/apollo/onlineOrdering';
import { useModal } from 'src/shared/components/common/modal';

export type Items = ReorderableItemsQuery['reorderableItems']
export type Item = Items['items'][0];

export type ReorderContextType = {
  /** The item to display in the `ItemModal` for reorder */
  reorderItem?: Item;
  setReorderItem: (item?: Item) => void;
  clearReorderItem: () => void;

  isHistoryOpen: boolean;
  onHistoryClose: () => void;
  onHistoryOpen: () => void;

  isReorderItemOpen: boolean;
  onReorderItemClose: () => void;
  onReorderItemOpen: () => void;
}

const ReorderContext = createContext<ReorderContextType | undefined>(undefined);

export const ReorderContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [reorderItem, setReorderItem] = useState<Item | undefined>(undefined);
  const clearReorderItem = () => { setReorderItem(undefined); };
  const { isOpen: isHistoryOpen, onClose: onHistoryClose, onOpen: onHistoryOpen } = useModal();
  const { isOpen: isReorderItemOpen, onClose: onReorderItemClose, onOpen: onReorderItemOpen } = useModal();

  const contextValue = useMemo(() => ({
    reorderItem,
    setReorderItem,
    clearReorderItem,
    isHistoryOpen,
    onHistoryClose,
    onHistoryOpen,
    isReorderItemOpen,
    onReorderItemClose,
    onReorderItemOpen
  }), [reorderItem, isHistoryOpen, onHistoryClose, onHistoryOpen, isReorderItemOpen, onReorderItemClose, onReorderItemOpen]);

  return (
    <ReorderContext.Provider value={contextValue}>
      {children}
    </ReorderContext.Provider>
  );
};

export const useReorder = () => {
  const context = useContext(ReorderContext);
  if(!context) {
    throw new Error('useReorder must be used within a ReorderContextProvider');
  }
  return context;
};
