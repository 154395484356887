
import React, { createContext, useContext, useState } from 'react';

export type LocationSearchErrorContextType = {
  currentLocationError: string | undefined;
  setCurrentLocationError: (error: string | undefined) => void;
};

export const LocationSearchErrorContext = createContext<LocationSearchErrorContextType | undefined>(undefined);

export const LocationSearchErrorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentLocationError, setCurrentLocationError] = useState<string | undefined>(undefined);
  return (
    <LocationSearchErrorContext.Provider value={{
      currentLocationError,
      setCurrentLocationError
    }}>
      {children}
    </LocationSearchErrorContext.Provider>
  );
};

export const useLocationSearchError = () => {
  const context = useContext(LocationSearchErrorContext);
  if(!context) {
    throw new Error('useLocationSearchError must be used within a LocationSearchErrorContextProvider');
  }
  return context;
};
