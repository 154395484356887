import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';

type Props = {
  id: string;
  warn?: (value: string) => string | undefined;
  warning?: string;
  focus?: boolean;
  focusMessage?: string;
  showErrorMessage?: boolean;
}

const usePreviousError = (value: string) => {
  const prev = useRef('');
  useEffect(() => { prev.current = value; }, [value]);
  return prev.current;
};

const InputMessage = (props: Props) => {
  const { id, warn, warning, focus, focusMessage, showErrorMessage = true } = props;
  const { formState: { errors }, watch } = useFormContext();
  const inputValue = watch(id);

  const [warningMessage, setWarningMessage] = useState('');
  // keep the previous error message to allow the animation to complete before the message is cleared
  const prevErrorMessage = usePreviousError(errors[id]?.message || '');
  const showFocusMsg = useMemo(() => !!focusMessage && !!focus, [focusMessage, focus]);
  const msg = errors[id]?.message || warningMessage || prevErrorMessage;

  useEffect(() => {
    if(warning) {
      setWarningMessage(warning);
    } else if(warn && inputValue) {
      setWarningMessage(warn(inputValue) || '');
    } else {
      setWarningMessage('');
    }
  }, [inputValue, warn, warning, setWarningMessage]);

  return (
    <div className="inputMessage" id={`${id}-input-message`}>
      <AnimatedSection expanded={showFocusMsg}>
        <div className="note">{focusMessage}</div>
      </AnimatedSection>
      <AnimatedSection expanded={!showFocusMsg && showErrorMessage && msg}>
        <div className="errorMessage">{msg}</div>
      </AnimatedSection>
    </div>
  );
};

export default InputMessage;
