import React from 'react';

import ItemHistoryModal from './ItemHistoryModal';
import { useReorder } from './ReorderContext';

const ItemHistoryModalWrapper: React.FC = () => {
  const { isHistoryOpen, onHistoryClose } = useReorder();

  return <ItemHistoryModal isOpen={isHistoryOpen} onClose={onHistoryClose} />;
};

export default ItemHistoryModalWrapper;
