import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import * as Sentry from '@sentry/react';
import { _heading, FieldType, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import camelCase from 'lodash/camelCase';
import isEmail from 'validator/es/lib/isEmail';

import { ButtonType, Image, PageConfig, useSubmitFormPageMutation } from 'src/apollo/sites';
import DatePicker from 'src/shared/components/common/form_input/DatePicker';
import InputField from 'src/shared/components/common/form_input/InputField';

import Button from 'shared/components/common/button';
import { _Image } from 'shared/components/common/editable_image/EditableImage';
import FormLocationSelector from 'shared/components/common/form_input/LocationInput';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import TextArea from 'shared/components/common/form_input/TextArea';
import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { AdditionalPagesType } from 'shared/js/types';

import Footer from 'public/components/default_template/footer';
import { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import Nav from 'public/components/default_template/nav/Nav';

export type FormPageType = AdditionalPagesType[0]['content'] & { __typename: 'FormPage' };
type FormField = FormPageType['fields'][0];

export const validatePhone = (value: string, required: boolean = false) => (value || required) && !isValidPhoneNumber(value, 'US') ? 'Please enter a valid phone number' : true;

type Props = {
  content: FormPageType;
  id: string;
  editPathRoot?: EditPathRoot
  config?: PageConfig | null;
  title: string;
}

const getFieldId = (field: FormField) => field.guid || camelCase(field.label);

const getFormField = (field: FormField) => {
  switch(field.fieldType) {
    case 'text':
      return <InputField key={field.guid || field.label} id={getFieldId(field)} label={field.label} required={field.required || false} />;
    case 'email':
      return <InputField
        validate={(value: string) => (value || field.required) && !isEmail(value) ? 'Please enter a valid email address' : true}
        key={field.guid || field.label}
        type="email"
        id={getFieldId(field)}
        label={field.label}
        required={field.required || false} />;
    case 'textarea':
      return <TextArea key={field.guid || field.label} id={getFieldId(field)} label={field.label} required={field.required || false} />;
    case 'telephone':
      return <PhoneInput
        validate={validatePhone}
        key={field.guid || field.label}
        id={getFieldId(field)}
        label={field.label}
        required={field.required || false} />;
    case 'location':
      return <FormLocationSelector key={field.guid || field.label} id={getFieldId(field)} label={field.label} required={field.required || false} />;
    case 'date':
      return <DatePicker key={field.guid || field.label} id={getFieldId(field)} label={field.label} required={field.required || false} futureDatesOnly={false} onThemedSurface={false} />;
    default:
      return null;
  }
};

const FormPage = (props: Props) => {
  const { useEditableRef, isEditor } = useEditor();
  const { editableRef: formEditableRef } = useEditableRef<HTMLDivElement>({ name: 'form', displayName: 'Form', actions: [], schema: { fields: [] } });
  const { editableRef: imageEditableRef } = useEditableRef<HTMLDivElement>({
    name: 'form image', actions: [], schema:
    {
      fields: [
        { displayName: 'Image', path: `${props.editPathRoot}.content` || '', type: FieldType.FormImage, value: '' }
      ]
    }
  });

  const backgroundColorStyle = props.config?.backgroundColor ? { backgroundColor: props.config?.backgroundColor } : {};

  const [submitForm] = useSubmitFormPageMutation();
  const { restaurant } = useRestaurant();
  const formMethods = useForm({ mode: 'onTouched' });
  const { handleSubmit } = formMethods;
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit = useCallback(async (data: any) => {
    setSubmitting(true);
    const idToLabelMap: { [key: string]: any} = props.content.fields.reduce((map, field) => ({
      ...map,
      [getFieldId(field)]: field.label
    }), new Map<string, any>());

    let initialValues = {
      ['Restaurant']: { name: 'Restaurant', value: restaurant.name },
      ['Form Page']: { name: 'Form Page', value: props.title }
    };
    const formattedData = Object.entries(data).reduce((data, [key, value]) => ({
      ...data,
      [key]: { name: idToLabelMap[key], value: value }
    }), initialValues);
    try {
      await submitForm({ variables: { data: formattedData, formId: props.id } });
      setSubmitting(false);
      setSubmitted(true);
    } catch(error) {
      setSubmitting(false);
      setSubmitError(true);
      Sentry.captureMessage(`ERROR: ${error}`);
    }
  }, [props.content.fields, props.id, submitForm, props.title, restaurant]);

  const image: Image | null | undefined = useMemo(() => {
    if(props.content?.image) {
      return props.content?.image;
    } else if(props.content?.img) {
      return {
        src: props.content.img,
        altText: 'Image',
        displaySrc: '',
        __typename: 'Image'
      };
    } else {
      return null;
    }
  }, [props.content]);
  const imagePosition: string = useMemo(() => {
    if(props.content?.imagePosition) return props.content?.imagePosition.toString() || 'above';
    return 'above';
  }, [props.content?.imagePosition]);

  const { meta, content, theme } = restaurant;
  if(!content) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;
  return (
    <div className="defaultTemplate formTemplate" ref={imageEditableRef} data-testid="form-page" style={backgroundColorStyle} id="main">
      <Nav
        withShadow
        logoSrc={meta.icon}
        logoObject={meta.iconObject}
        primaryCta={primaryCta}
        nonPrimaryCtas={nonPrimaryCtas}
        shouldShowPreviewBanner={true} />
      <main className={classnames('formPage', imagePosition)} aria-live={'polite'}>
        {image && imagePosition &&
            <_Image alt={image.altText ?? ''} imageObject={image} imageObjectPath={`${props.editPathRoot}.content.image`}
              className={classnames('image', imagePosition)} />}
        {submitted || submitError ?
          <div className="formWrapper">
            <div className="form">
              {submitted ?
                <h1 className="postSubmit">Your form is submitted!</h1> :
                <><h1 className="postSubmit" role={'alert'}>We had trouble submitting the form</h1> <div>Please try again, or contact the restaurant directly</div></>}
            </div>
          </div> :
          <FormProvider {...formMethods}>
            <div className="formWrapper" aria-live={'off'}>
              <div className="form" ref={formEditableRef}>
                <div className="heading">
                  <_heading styleLevel={1} html={props.content.title}
                    editPath={`${props.editPathRoot}.content.title`} />
                  {props.content.subtitle ?
                    <_heading styleLevel={2} html={props.content.subtitle}
                      editPath={`${props.editPathRoot}.content.subtitle`} />
                    : null}
                </div>
                <div className={classnames('fieldsWrapper', isEditor ? 'disableClicks' : '')}>
                  {props.content.fields.map(getFormField)}
                </div>
                {!props.content.hideButton ? <Button variant={ButtonType.Primary} onClick={handleSubmit(onSubmit)} disabled={submitting} className="submit">Submit</Button> : null}
              </div>
            </div>
          </FormProvider>}
      </main>
      <div className="footerWrapper"><Footer /></div>
    </div>
  );
};

export default FormPage;
