import { useMemo } from 'react';

import { UAParser } from '@amplitude/ua-parser-js';
import { NormalizedCacheObject } from '@apollo/client';

import { createClient } from 'src/apollo/createClient';
import { AppStatus, useAppPromoDataQuery } from 'src/apollo/sites';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { resources } from 'config';

declare global {
  interface Window {
    __APOLLO_STATE__: NormalizedCacheObject | undefined;
  }
}

type Props = {
  skip?: boolean
  userAgent?: string;
}

export type AppPromoData = {
  link?: string | null;
  appLogo?: string;
  appName: string;
}

export const useBrandedAppPromo = (props?: Props): AppPromoData | undefined => {
  const client = useMemo(() => createClient(resources.apiEndpoint,
    typeof window !== 'undefined' ? window.__APOLLO_STATE__ : undefined,
    false,
    { enabled: true },
    undefined,
    false,
    false,
    resources.clientQueryTimeoutMs), []);
  const { restaurant } = useRestaurant();
  const { data: appData } = useAppPromoDataQuery({ variables: { siteGuid: restaurant.id! }, skip: props?.skip || !restaurant.id, client, ssr: !!props?.userAgent });

  if(!appData || !appData.appConfig || appData.appConfig?.appStatus === AppStatus.Churned || !props?.userAgent && typeof window === 'undefined') {
    return undefined;
  }

  const parserResults = new UAParser(props?.userAgent || window.navigator.userAgent).getResult();

  return {
    link: parserResults.device.vendor === 'Apple' ? appData.appUrls?.appleAppStore : appData.appUrls?.googlePlay,
    appLogo: appData.appDisplay?.appIcon?.displaySrc,
    appName: appData.appConfig.appName || restaurant.name
  };
};
