import React, { useCallback } from 'react';

import { useLocationSearchError } from 'src/shared/components/common/location_search/LocationSearchErrorContext';

type LocationSearchErrorProps = {
  setShowSearchLabel?: (show: boolean) => void;
  noNearbyLocations?: boolean;
  shouldShowAllLocations?: boolean;
  setShouldShowAllLocations?: (show: boolean) => void;
}
export const LocationSearchError = ({
  setShouldShowAllLocations,
  setShowSearchLabel,
  shouldShowAllLocations,
  noNearbyLocations
}: LocationSearchErrorProps) => {
  const { currentLocationError, setCurrentLocationError } = useLocationSearchError();
  const showAllLocations = useCallback(() => {
    setShouldShowAllLocations && setShouldShowAllLocations(true);
    setCurrentLocationError(undefined);
  }
  , [setCurrentLocationError, setShouldShowAllLocations]);
  if(currentLocationError === 'POSITION_UNAVAILABLE' ) {
    setShowSearchLabel && setShowSearchLabel(false);
    return (
      <div className="placesAutocompleteError">
        <span>We couldn&apos;t locate you!</span>
        <p>Please enter an address or enable location access in your browser to find a nearby pickup location
          {setShouldShowAllLocations ? <>,{' '}or <a onClick={showAllLocations}>click here</a> to browse all locations alphabetically.</> : '.'}
        </p>
      </div>);
  }
  if(noNearbyLocations && !shouldShowAllLocations) {
    setShowSearchLabel && setShowSearchLabel(false);
    return (
      <div className="placesAutocompleteError">
        <span>No locations nearby</span>
        <p>Try searching a different area {setShouldShowAllLocations ? <>{' '}or <a onClick={showAllLocations}>click here</a> to browse all locations alphabetically.</> : '.'}</p>
      </div>);
  }
  return <div className="placesAutocompleteError">{currentLocationError}</div>;
};
