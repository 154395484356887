import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { DigitalSurface } from 'src/apollo/onlineOrdering';
import { Query } from 'src/lib/js/hooks/useQueryString';
import { LocationSearchError } from 'src/shared/components/common/location_search/LocationSearchError';
import { LocationSearchErrorContextProvider } from 'src/shared/components/common/location_search/LocationSearchErrorContext';

import Image from 'shared/components/common/Image';
import PlacesAutocomplete from 'shared/components/common/location_search/PlacesAutocomplete';


import { gMapsAPIKey } from 'config';

class LocationInput extends Component<Props & { history: any, wrapperRef: React.RefObject<HTMLDivElement> }, { editMode: boolean; locationError: string;}> {
  static propTypes = {
    history: PropTypes.object,
    wrapperRef: PropTypes.object,
    parsedQS: PropTypes.object,
    street: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    onLocationSelected: PropTypes.func
  };

  constructor(props: Props & { history: any, wrapperRef: React.RefObject<HTMLDivElement> }) {
    super(props);
    this.state = { editMode: false, locationError: '' };
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event: MouseEvent) {
    if(this.props.wrapperRef && event.target && !this.props.wrapperRef.current?.contains(event.target as Node)) {
      this.setState(state => ({ ...state, editMode: false }));
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onAddressSelected(location: any) {
    // fields used here must be specified in GMAPS_PLACERESULT_FIELDS
    if(location && location.geometry && location.geometry.location) {
      let street = '';
      let neighborhood = '';
      let city = '';
      let state = '';
      for(let component of location.address_components) {
        for(let componentType of component.types) {
          if(componentType === 'street_number') {
            street = street ? component.long_name + ' ' + street : component.long_name;
            break;
          } else if(componentType === 'route') {
            street = street ? street + ' ' + component.short_name : component.short_name;
            break;
          } else if(componentType === 'neighborhood') {
            neighborhood = component.long_name;
            break;
          } else if(componentType === 'locality') {
            city = component.long_name;
            break;
          } else if(componentType === 'administrative_area_level_1') {
            state = component.short_name;
            break;
          }
        }
      }
      const lat = location.geometry.location.lat();
      const long = location.geometry.location.lng();

      this.setState({ editMode: false, locationError: '' });
      this.props.onLocationSelected(street, neighborhood, city, state, lat, long);
    }
  }

  render() {
    const dropDownLabel = this.props.street || this.props.neighborhood || (this.props.city && this.props.state ? `${this.props.city}, ${this.props.state}` : this.props.city);

    return (
      <>
        <div className="location-dropdown-input-wrapper" ref={this.props.wrapperRef}>
          {this.state.editMode ?
            <div className="address-input">
              <PlacesAutocomplete
                id="rx-search-location-dropdown"
                useCurrentLocation
                placeholder="Enter a city or address"
                showGenericSpinner
                apiKey={gMapsAPIKey}
                onPlaceSelected={this.onAddressSelected}
                source={DigitalSurface.Local} />
            </div> :
            <div className="selected-location" onClick={() => this.setState({ editMode: true })}>
              <Image className="map-pin" src="toastLocal/icons/location-blue.svg" alt="Update location" />
              <div className="address">{dropDownLabel || 'Enter a city or address'}</div>
              <Image className="caret" src="toastLocal/splash/caret-down-blue.svg" alt="Caret" />
            </div>}
        </div>
        {this.state.locationError || !dropDownLabel ?
          <p className="txt-body-sm error location-error">
            {this.state.locationError || 'Enter a city or address to begin your search'}
          </p> :
          null}
      </>
    );
  }
}

type Props = {
  label?: string;
  parsedQS: Query
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  onLocationSelected: (street: string, neighborhood: string, city: string, state: string, lat: number, long: number) => void
}

const LocationDropDown = ({ label, parsedQS, street, neighborhood, city, state, onLocationSelected }: Props) => {
  const history = useHistory();
  const wrapperRef = React.createRef<HTMLDivElement>();
  return (
    <LocationSearchErrorContextProvider>
      <div className="location-dropdown-container">
        <div className="location-dropdown" ref={wrapperRef}>
          <div className="location-dropdown-label">{label}</div>
          <div className="location-dropdown-input">
            <LocationInput
              history={history} parsedQS={parsedQS} street={street} neighborhood={neighborhood} city={city} state={state} onLocationSelected={onLocationSelected} wrapperRef={wrapperRef} />
          </div>
        </div>
        <LocationSearchError />
      </div>
    </LocationSearchErrorContextProvider>
  );
};
export default LocationDropDown;
