import React from 'react';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';

export const FilterToggle = ({
  name,
  displayName,
  iconName,
  checkedIconName,
  checked,
  setChecked,
  type
}: {
  name: string,
  displayName: string,
  iconName: string,
  checkedIconName: string,
  checked: boolean,
  setChecked: (newValue: boolean) => void,
  type: 'radio' | 'checkbox'
}) => {
  const iconSrc = `toastLocal/icons/${checked ? checkedIconName : iconName}.svg`;
  return (
    <div className="accepts-toggle">
      <ToggleInput
        onChange={e => setChecked(e.target.checked)}
        textOnly
        id={`${name}Checkbox`}
        name={name}
        checked={checked}
        type={type}
        ariaLabel={displayName}>
        <div className={`toggleContents ${checked ? 'selected' : ''}`}>
          <Image alt={`${iconName} icon`} src={iconSrc} />
          <div className="toggleTextContent" id={`${name}-label`} data-testid={`${name}-label`}>
            <div className={`toggleText ${checked ? 'selected' : ''}`}>{displayName}</div>
          </div>
        </div>
      </ToggleInput>
    </div>
  );
};

