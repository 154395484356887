import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { DebugPanel } from 'src/public/components/default_template/debug/DebugPanel';
import { LoyaltyContextProvider } from 'src/public/components/online_ordering/LoyaltyContext';
import { Channel } from 'src/public/js/siteUtilities';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { PopoverContextProvider } from 'shared/components/common/popover/PopoverContext';

import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useSpiSdk } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import Popups from 'public/components/default_template/popups/Popups';
import { MenuSearchContextProvider } from 'public/components/default_template/search';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { DeliveryContextProvider } from 'public/components/online_ordering/DeliveryContext';
import { EditorFulfillmentContextProvider, FulfillmentContextProvider } from 'public/components/online_ordering/FulfillmentContext';
import OffersContextProvider from 'public/components/online_ordering/OffersContext';
import { TimeBasedRuleContextProvider } from 'public/components/online_ordering/TimeBasedRuleContext';
import { UpsellContextProvider } from 'public/components/online_ordering/UpsellContext';

type Props = {
  channel: Channel
}

export const OrderPageContexts = ({ children, channel }: React.PropsWithChildren<Props>) => {
  const ooClient = useOOClient();
  const { isEditor } = useEditor();
  // Pre-loading the SDK in an attempt to improve the time it takes for the iframe to load on the checkout page.
  // It should be loaded on the Order page for all products, when the feature flag is enabled.
  useSpiSdk(isEditor);

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider channel={channel}>
        <TimeBasedRuleContextProvider>
          <DeliveryContextProvider>
            <MenuSearchContextProvider>
              <OffersContextProvider>
                <UpsellContextProvider>
                  <LoyaltyContextProvider>
                    {isEditor
                      ?
                      <EditorFulfillmentContextProvider diningOptionBehavior={DiningOptionBehavior.TakeOut}>
                        <PopoverContextProvider contextKey={CART_POPOVER_CONTEXT_KEY}>
                          {children}
                        </PopoverContextProvider>
                      </EditorFulfillmentContextProvider>
                      :
                      <FulfillmentContextProvider>
                        <PopoverContextProvider contextKey={CART_POPOVER_CONTEXT_KEY}>
                          <Popups isOrderPage={channel === Channel.OO} />
                          {children}
                          <DebugPanel />
                        </PopoverContextProvider>
                      </FulfillmentContextProvider>}
                  </LoyaltyContextProvider>
                </UpsellContextProvider>
              </OffersContextProvider>
            </MenuSearchContextProvider>
          </DeliveryContextProvider>
        </TimeBasedRuleContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};
