import React from 'react';

import { CloseIcon } from '@toasttab/buffet-pui-icons';

import { PaginatedMenuItemsQuery } from 'src/apollo/onlineOrdering';

type Props = {
  setFilters: React.Dispatch<React.SetStateAction<Set<string>>>
  filters: Set<string>
  groupings: NonNullable<PaginatedMenuItemsQuery['paginatedMenuItems']['groupings']>
}

export const FilterTags = ({ setFilters, filters, groupings }: Props) => {
  const availableFilters = groupings?.flatMap((grouping: any) => {
    return grouping.children.map((subGroup: any) => ({
      name: subGroup.name,
      value: `${grouping.guid}:${subGroup.guid}`,
      selected: filters.has(`${grouping.guid}:${subGroup.guid}`)
    }));
  });

  const selectedFilters = availableFilters?.filter((filter: any) => filters.has(filter.value));

  return (
    selectedFilters?.length ?
      <div className="paddedContentWrapper">
        <div className="paddedContent menu filterTags">
          {selectedFilters.map((filter: any) =>
            <div className="filterTag" key={filter.value}>
              {filter.name}
              <button className="removeButton" onClick={() => {
                setFilters(filters => { filters.delete(filter.value); return new Set(filters); });
              }}>
                <CloseIcon size="xs" />
              </button>
            </div>)}
        </div>
      </div>
      : null
  );
};
