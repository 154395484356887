import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { InfoWindowF as InfoWindow, MarkerF as GoogleMapsMarker } from '@react-google-maps/api';

import { DiningOptionBehavior, RestaurantLocationsQuery, useRestaurantQuery } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { ToggleInput } from 'shared/components/common/forms';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { getDiningOptionBehaviorAvailability } from 'public/components/online_ordering/FulfillmentContext';
import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';
import LocationCta, { CtaDestination } from 'public/components/pages/location_selection_page/LocationCta';

export type OORestaurant = NonNullable<NonNullable<RestaurantLocationsQuery['restaurants']>[0]> & { __typename: 'Restaurant' };

type Props = {
  useOrderFulfillment?: boolean;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  restaurant: OORestaurant;
  onClick: () => void;
  onSelect: (guid: string, diningOptionBehavior?: DiningOptionBehavior) => void;
  isInfoOpen?: boolean;
  isSelected?: boolean;
  selectedDiningOption?: DiningOptionBehavior;
  shortUrl: string;
  orderPathPattern: string | null;
  hostName: string | null;
}

export const LocationSelection = ({ restaurant, onSelect }: { restaurant: OORestaurant; onSelect: (guid: string, diningOptionBehavior?: DiningOptionBehavior) => void;}) => {
  return (
    <div className="mapInfoWindow">
      <h3>{formatLocationAddress(restaurant.location, false)}</h3>
      <br />
      <Button
        className="orderHereButton"
        onClick={() => onSelect(restaurant.guid, undefined)}>
        Select location
      </Button>
    </div>
  );
};

export const OrderFulfillment = ({ restaurant, selectedDiningOption, shortUrl, orderPathPattern, hostName }: {
  restaurant: OORestaurant;
  selectedDiningOption?: DiningOptionBehavior;
  shortUrl: string;
  orderPathPattern: string;
  hostName: string;
}) => {
  // This grabs the dining options for the location represented by the map pin that was clicked on. This means
  // FulfillmentContext can't be used here because that represents the location that the user is currently navigated to.
  const client = useOOClient();
  const { data, loading } = useRestaurantQuery({
    variables: { restaurantGuid: restaurant.guid },
    client,
    ssr: false
  });

  const { takeoutEnabled, deliveryEnabled, canOrderTakeout, canOrderDelivery } = getDiningOptionBehaviorAvailability(data?.diningOptions);

  const [selected, setSelected] = useState<DiningOptionBehavior | undefined>(selectedDiningOption);

  return (
    <div className="mapInfoWindow">
      <h3>{formatLocationAddress(restaurant.location, false)}</h3>
      <hr />
      <div className="options">
        {loading ?
          <Skeleton width="100%" height="20px" /> :
          <>
            {!canOrderTakeout && !canOrderDelivery && <div>We&apos;re not accepting orders right now but you can still browse our menu!</div>}
            {takeoutEnabled &&
              <ToggleInput
                onChange={() => setSelected(DiningOptionBehavior.TakeOut)}
                id={`${restaurant.guid}_pickup`}
                name={restaurant.guid}
                type="radio"
                disabled={!canOrderTakeout}
                checked={selected === DiningOptionBehavior.TakeOut}>
                <div className="itemLabel">
                  <span>Pickup</span>
                  <Image alt="Pickup icon" src="icons/pickup.svg" />
                </div>
              </ToggleInput>}
            {deliveryEnabled &&
              <ToggleInput
                onChange={() => setSelected(DiningOptionBehavior.Delivery)}
                id={`${restaurant.guid}_delivery`}
                name={restaurant.guid}
                type="radio"
                disabled={!canOrderDelivery}
                checked={selected === DiningOptionBehavior.Delivery}>
                <div className="itemLabel">
                  <span>Delivery</span>
                  <Image alt="Delivery icon" src="icons/delivery.svg" />
                </div>
              </ToggleInput>}
          </>}
      </div>
      <div>
        <LocationCta
          className="fulfillmentCta"
          disabled={loading || !selected && (canOrderTakeout || canOrderDelivery)}
          shortUrl={shortUrl}
          rxName={restaurant.name}
          ctaDestination={canOrderTakeout || canOrderDelivery ? CtaDestination.OrderPage : CtaDestination.MenuPage}
          orderPathPattern={orderPathPattern}
          hostName={hostName}
          diningOptionBehavior={selected} />
      </div>
    </div>
  );
};

const Marker = (props: Props) => {
  return (
    <GoogleMapsMarker
      label={props.isSelected ? undefined : ' '}
      key={props.restaurant.guid}
      position={props.position}
      onClick={props.onClick}>
      {props.isInfoOpen ?
        <InfoWindow>
          {props.useOrderFulfillment && props.orderPathPattern && props.hostName ?
            <OrderFulfillment
              orderPathPattern={props.orderPathPattern}
              hostName={props.hostName}
              shortUrl={props.shortUrl}
              restaurant={props.restaurant}
              selectedDiningOption={props.selectedDiningOption} />
            : <LocationSelection restaurant={props.restaurant} onSelect={props.onSelect} />}
        </InfoWindow>
        : null}
    </GoogleMapsMarker>
  );
};

export default Marker;
