import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ButtonType } from 'src/apollo/sites';
import Button from 'src/shared/components/common/button';
import LoadingSpinnerOverlay from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'src/shared/components/common/modal';

import ItemCard from './ItemCard';
import { useReorderTemplate } from './useReorderTemplate';
import { usePaginatedReorderableItemsQuery } from './useReorderableItemsQueryWrapper';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ItemHistoryModal = (props: Props) => {
  const { templateOverrides } = useReorderTemplate();
  const { data, loading, paginate } = usePaginatedReorderableItemsQuery({ skip: !props.isOpen });

  const hasMoreData = data?.reorderableItems?.nextPageToken &&
    data.reorderableItems.currentPageToken !== data.reorderableItems.nextPageToken;

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      {loading && <LoadingSpinnerOverlay />}
      <ModalOverlay fadeIn />
      <ModalContent contentClassName="itemHistoryModal" fadeIn>
        <ModalCloseButton className="closeButton" />
        <h2>Order History</h2>
        <div className="items">
          {!loading || data?.reorderableItems?.items.length ?
            <>
              {data?.reorderableItems?.items.map(item => {
                return <ItemCard key={item.selection.guid} item={item} templateOverrides={templateOverrides} />;
              })}
              {hasMoreData || loading ? <Button testId="load-more" disabled={loading} variant={ButtonType.Text} onClick={paginate}>{loading ? 'Loading...' : 'Load more'}</Button> : null}
            </>
            : new Array(5).fill('')
              .map((_, index) => <Skeleton key={index} height="100px" width="100%" />)}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ItemHistoryModal;
