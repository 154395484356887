import React, { useEffect, useMemo, useState } from 'react';

import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useGiftCard } from 'src/public/components/online_ordering/GiftCardContext';
import Button from 'src/shared/components/common/button';
import ContextualLoadingSpinner from 'src/shared/components/common/loading_spinner/LoadingSpinner';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from 'src/shared/components/common/modal';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import Link from 'shared/components/common/link';

export enum DiscountsModalType {
  OFFER,
  GIFT_CARD
}

interface DiscountsModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onApply: (primary: string, secondary: string) => Promise<void>;
  currentValue?: string;
  type: DiscountsModalType;
  header: string;
  description?: string;
}

const DiscountsModal = ({ isOpen, onClose, onApply, currentValue, header, description, type }: DiscountsModalProps) => {
  const [primaryInput, setPrimaryInput] = useState('');
  const [secondaryInput, setSecondaryInput] = useState('');
  const { loadingGiftCard } = useGiftCard();
  // For offers we check the cart loading state
  const { loadingCart } = useCart();
  const buttonText = useMemo(() => currentValue ? 'Replace' : 'Apply', [currentValue]);
  const { ooRestaurant } = useRestaurant();
  const gcConfig = ooRestaurant?.giftCardConfig;
  const thirdPartyGC = type === DiscountsModalType.GIFT_CARD && gcConfig?.hasGiftCardsExtension;
  const showPin = thirdPartyGC;
  useEffect(() => setPrimaryInput(currentValue || ''), [currentValue]);

  const loadingData = useMemo(() => loadingGiftCard || loadingCart, [loadingGiftCard, loadingCart]);
  const modalHeader = useMemo(() => currentValue ?
    `Update ${header}` :
    `Add ${header}`, [currentValue, header]);
  const primaryInputComponent = useMemo(() => {
    if(type === DiscountsModalType.OFFER) {
      return <input
        className="discountsModalInput"
        type="text"
        placeholder="Enter code"
        onChange={e => setPrimaryInput(e.target.value)}
        aria-label="Enter code"
        value={primaryInput}
        disabled={loadingData}
        data-testid={'input-promoCode'} />;
    }
    // Gift Card
    return <input
      className="discountsModalInput"
      type="text"
      placeholder="Gift Card"
      onChange={e => setPrimaryInput(e.target.value)}
      aria-label="Enter code"
      value={primaryInput}
      disabled={loadingData}
      data-testid={'input-giftCardNumber'} />;
  }, [loadingData, primaryInput, type]);

  const secondaryInputComponent = useMemo(() =>
    showPin ?
      <input
        className="discountsModalInput"
        type="text"
        placeholder="Code (if applicable)"
        onChange={e => setSecondaryInput(e.target.value)}
        aria-label="Enter code"
        value={secondaryInput}
        disabled={loadingData}
        data-testid={'input-giftCardCode'} />
      : null, [loadingData, secondaryInput, showPin]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent contentClassName="fullScreenMobile">
        <ModalCloseButton className="closeModalButton" />
        <div className="discountsModal">
          <h1 className="header">{modalHeader}</h1>
          {description && <p>{description}</p>}
          <div className="inputContainer">
            {primaryInputComponent}
            {secondaryInputComponent}
            <Button
              disabled={primaryInput.length === 0 || loadingData}
              className="modalButton"
              onClick={() => onApply(primaryInput, secondaryInput)}
              type="button"
              data-testid={'apply-promoCode'}>
              {loadingData ? <ContextualLoadingSpinner size="28px" strokeWidth="3px" /> : <span>{buttonText} {header}</span>}
            </Button>
            {thirdPartyGC &&
              <p className="legal">
                This site is protected by reCAPTCHA and the Google <br />
                <Link href="https://policies.google.com/privacy?hl=en-US">Privacy Policy</Link>, and{' '}
                <Link href="https://policies.google.com/terms?hl=en-US">Terms of Service</Link>{' '}apply.
              </p>}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default DiscountsModal;
