import React, { useCallback, useState } from 'react';

import PlacesAutocomplete from 'src/shared/components/common/location_search/PlacesAutocomplete';

import ErrorNotice from 'shared/components/common/error_notice';
import { SiteRestaurantLocation } from 'shared/js/types';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { normalizeGoogleAddress, formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';

import { gPlacesAPIKey } from 'config';

type Props = {
  selectedLocation?: SiteRestaurantLocation;
  showDefaultAddress?: boolean; // Disables displaying the default value in the PlacesAutocomplete component
}

const DeliveryAddressInput = ({ selectedLocation, showDefaultAddress = true }: Props) => {
  const { cart } = useCart();
  const {
    validDeliveryAddress,
    validateDeliveryAddress,
    deliveryValidationError,
    setDeliveryValidationError
  } = useDelivery();
  const [lookupError, setLookupError] = useState('');
  const handleError = useCallback(() => setLookupError('Sorry, we had trouble verifying your address'), []);

  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    setLookupError('');
    const normalizedAddress = normalizeGoogleAddress(place);
    const addressHasStreetNumber = place.address_components?.some(component => component.types.includes('street_number'));

    if(!addressHasStreetNumber) {
      return setDeliveryValidationError('Please enter a valid address with a street number.');
    }

    if((cart?.restaurant || selectedLocation?.externalId) && addressHasStreetNumber && normalizedAddress) {
      validateDeliveryAddress(normalizedAddress, cart?.restaurant?.guid || selectedLocation?.externalId);
    }
  }, [validateDeliveryAddress, cart?.restaurant, selectedLocation, setDeliveryValidationError]);

  return (
    <>
      <div className="addressInput" data-testid="delivery-address-input">
        <PlacesAutocomplete
          id="delivery-address-input-autocomplete"
          defaultValue={showDefaultAddress
            ? formatDeliveryAddress(cart?.order?.deliveryInfo || validDeliveryAddress, true, false)
            : ''}
          placeholder="Enter your delivery address"
          apiKey={gPlacesAPIKey}
          requireZip
          onError={handleError}
          onPlaceSelected={onPlaceSelected}
          locationBias={selectedLocation?.lat && selectedLocation?.long ? { lat: selectedLocation.lat, long: selectedLocation.long } : undefined}
          normalized
          showAddress2={false} />
      </div>
      <AnimatedSection className="addressInputError" expanded={Boolean(deliveryValidationError)}>
        <div className="error"><ErrorNotice>{lookupError || deliveryValidationError}</ErrorNotice></div>
      </AnimatedSection>
    </>
  );
};

export default DeliveryAddressInput;
