import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { IdentityType, useAddGuestIdentityMutation, useGuestQuery, useUpdateBasicInfoMutation } from 'src/apollo/onlineOrdering';
import { profileInformationSchema } from 'src/public/components/default_template/online_ordering/account/form-helpers';
import VerifiedStatus from 'src/public/components/default_template/online_ordering/account/verified_status/VerifiedStatus';
import VerifyEmailModal from 'src/public/components/default_template/online_ordering/account/verifyEmailModal/VerifyEmailModal';
import Image from 'src/shared/components/common/Image';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import InputField from 'src/shared/components/common/form_input/InputField';
import { InternationalPhoneInput } from 'src/shared/components/common/form_input/InternationalPhoneInput';
import LoadingSpinnerOverlayWrapper from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';
import { ScreenWidth, useIsMobile } from 'src/shared/js/utils/WindowContext';

import { alertError, alertSuccess } from 'shared/js/alertUtils';


interface ProfileInformationProps { isLoggedOut?: boolean; }

const toastSuccess = () => {
  alertSuccess('Your name has been updated');
};

const toastFail = () => {
  alertError('There was an error while saving. Please refresh the page.');
};

const toastEmailFailGeneric = () => {
  alertError('There was an error while updating email. Please try again.');
};

export const ProfileInformation: FC<ProfileInformationProps> = ({ isLoggedOut }) => {
  const { data: guestData, loading: guestDataLoading } = useGuestQuery();
  const contact = guestData?.guest?.contact;

  const [firstName, setFirstName] = useState(contact?.firstName);
  const [lastName, setLastName] = useState(contact?.lastName);
  const ooClient = useOOClient();
  const [updateBasicInfo] = useUpdateBasicInfoMutation();
  const [addGuestIdentity] = useAddGuestIdentityMutation();
  const { intlOoPhoneCountryDropDown } = useFlags();
  const [updatedEmail, setUpdatedEmail] = useState('');

  const [loading, setLoading] = useState(false);
  //We only look at the first email because we only support one email per guest.
  //If we ever support multiple emails the first email will be the primary email and we will likely want to update this logic.
  const isEmailVerified = contact?.contactEmails?.[0]?.isVerified;

  const isMobile = useIsMobile(ScreenWidth.SMALL);

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver<{ firstName?: string | null; lastName?: string | null; email?: string | null; phone?: string | null}>(profileInformationSchema),
    defaultValues: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      email: contact?.email,
      phone: contact?.phone
    }
  });

  useEffect(() => {
    if(isLoggedOut) {
      return formMethods.reset({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      });
    }

    return formMethods.reset({
      firstName: firstName || contact?.firstName,
      lastName: lastName || contact?.lastName,
      email: updatedEmail || contact?.email,
      phone: contact?.phone
    });
  }, [formMethods, contact, formMethods.reset, isLoggedOut, updatedEmail, firstName, lastName, intlOoPhoneCountryDropDown]);

  const sendCode = useCallback(async (email: string) => {
    return await addGuestIdentity({
      variables: {
        input: {
          identityType: IdentityType.Email,
          identityString: email
        }
      },
      client: ooClient
    });
  }, [addGuestIdentity, ooClient]);

  const updateGuestsBasicInfo = useCallback(async (data: any, didUpdateEmail: boolean) => {
    if(contact?.firstName !== data.firstName || contact?.lastName !== data.lastName) {
      const phone = data.phone;
      const firstName = data.firstName;
      const lastName = data.lastName;
      try {
        const { data } = await updateBasicInfo({
          variables: {
            input: {
              firstName,
              lastName,
              phone
            }
          },
          client: ooClient
        });
        if(data?.updateBasicInfo.__typename === 'UpdateBasicInfoResponse') {
          toastSuccess();
        } else {
          toastFail();
        }
      } catch(err) {
        toastFail();
      }
    }

    setUpdatedEmail('');

    didUpdateEmail && alertSuccess('Email updated');
  }, [updateBasicInfo, ooClient, contact]);

  const updateGuestEmail = useCallback(async (email: string | undefined | null): Promise<boolean> => {
    if(!email) return false;
    setLoading(true);
    try {
      const { data } = await sendCode(email);
      const response = data?.addGuestIdentity;
      setLoading(false);
      switch(response?.__typename) {
        case 'AddGuestIdentitySuccess':
          setUpdatedEmail(email);
          return true;
        case 'GuestIdentityAlreadyExistsForCurrentGuestError':
        case 'InvalidEmailError':
        case 'ProfileLockedForDeletionError':
        case 'UnexpectedError':
          alertError(response.message);
          break;
      }
    } catch(err) {
      setLoading(false);
      toastEmailFailGeneric();
    }
    return false;
  }, [sendCode]);

  const didUpdateGuestsEmail = useCallback(async (data: any) => {
    const email = data.email;
    if(contact?.email && email !== contact?.email) {
      return await updateGuestEmail(email);
    }
    return false;
  }, [contact, updateGuestEmail]);

  const onSubmit = useCallback(async (data: any) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    const updatedEmail = await didUpdateGuestsEmail(data);
    if(updatedEmail) return; // If guest attempts to update their email, the closing actions on the modal will trigger the basic account updates.
    updateGuestsBasicInfo(data, updatedEmail);
  }, [didUpdateGuestsEmail, updateGuestsBasicInfo]);

  const disabled = !(formMethods.formState.isValid && formMethods.formState.isDirty) || formMethods.formState.isSubmitting || isLoggedOut;
  const phoneLabel = contact?.phone ? <>Phone <VerifiedStatus isVerified={true} /></> : 'Phone';
  const emailLabel = contact?.email ? <>Email <VerifiedStatus isVerified={isEmailVerified || false} /></> : 'Email';

  const verifyEmailAction = () => {
    setUpdatedEmail(contact?.email || '');
    updateGuestEmail(contact?.email);
  };
  return (
    <div className="profileInformation" data-testid="ProfileInformation" role="form">
      <h2 className={isLoggedOut ? 'accountSubheadingDisabled' : 'accountSubheading'}>My Information</h2>
      <div className="infoWrapper">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <InputField disabled={isLoggedOut} id="firstName" type="text" label="First name" required />
            <InputField disabled={isLoggedOut} id="lastName" type="text" label="Last name" required />
            <div className="emailInputWrapper">
              <InputField disabled={isLoggedOut} id="email" type="text" label={emailLabel} required classNames="emailInputToVerify" />
              {!isEmailVerified && disabled && contact?.email &&
              <button className="verifyCurrentEmail" type="button" data-testid="VerifyEmailBtn" onClick={verifyEmailAction}>{isMobile ? 'Verify' : 'Verify email'}</button>}
            </div>
            {intlOoPhoneCountryDropDown ?
              <InternationalPhoneInput
                disabled={isLoggedOut}
                id="phone"
                label={phoneLabel}
                readOnly={true}
                value={formMethods.getValues('phone') || ''} />
              : <InputField disabled={isLoggedOut} id="phone" type="text" label={phoneLabel} readOnly={true} />}
            <button type="submit" className="submitPhoneButton" disabled={disabled} aria-live="polite" aria-disabled={disabled}>
              <div className="text"><span>Update account on </span><Image alt="Toast" src="icons/toast-logo-white-with-text.svg" /></div>
            </button>

            {loading && guestDataLoading && <LoadingSpinnerOverlayWrapper />}
            {updatedEmail && <VerifyEmailModal
              email={updatedEmail}
              onCloseModal={async (didUpdateEmail: boolean) => updateGuestsBasicInfo(formMethods.getValues(), didUpdateEmail)}
              resendCode={sendCode} />}
          </form>
        </FormProvider>
      </div>
      <div className="sectionSeparatorWrapper">
        <hr className="sectionSeparator" />
      </div>
    </div>
  );
};

export default ProfileInformation;
