import React, { ReactNode, useMemo } from 'react';

import { ServingTrayIcon } from '@toasttab/buffet-pui-icons';

import { Location } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';

import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';

import CommissionDisclaimer from './CommissionDisclaimer';


type Props = {
  phone?: string | null;
  hoursHref?: string;
  reservationsHref?: string;
  location?: Location | null;
  giftCardHref?: string | null;
  cateringOOHref?: string | null;
};

const Overview = (props: Props) => {
  const ctas: CTAProps[] = useMemo(() => {
    const ctas: CTAProps[] = [];
    if(props.phone) {
      ctas.push({ action: 'Call', href: `tel:${props.phone}`, newTab: false });
    }
    if(props.hoursHref) {
      ctas.push({ action: 'Hours', href: props.hoursHref, newTab: false });
    }
    if(props.reservationsHref) {
      ctas.push({ action: 'Reservations', href: props.reservationsHref, newTab: true });
    }
    if(props.location) {
      const formattedAddress = formatLocationAddress(props.location);
      const googleMapsHref = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
      ctas.push({ action: 'Directions', href: googleMapsHref, newTab: true });
    }
    if(props.cateringOOHref) {
      ctas.push({ action: 'Catering', href: props.cateringOOHref, newTab: true });
    }
    if(props.giftCardHref) {
      ctas.push({ action: 'Gift Cards', href: props.giftCardHref, newTab: true });
    }
    return ctas;
  }, [props.phone, props.hoursHref, props.reservationsHref, props.location, props.giftCardHref, props.cateringOOHref]);

  const addressLine2: string = useMemo(() => {
    const first = props.location?.city ? props.location.city : '';
    const second = `${props.location?.state ? props.location?.state : ''} ${props.location?.zip ? props.location?.zip : ''}`;
    if(second.trim() === '') {
      return first;
    }
    return `${first}, ${second}`;
  }, [props.location]);

  return (
    <div className="rx-overview">
      {props.location &&
        <div className="address hidden-sm-down">
          <p className="line-1">{props.location?.address1}</p>
          <p className="line-1">{props.location?.address2}</p>
          <p className="line-2">{addressLine2}</p>
        </div>}
      <hr className="hidden-sm-down" />
      <div className="hidden-sm-down"><CommissionDisclaimer /></div>
      <hr />
      <div className="ctas">
        {ctas.map((cta, i) => <CTA {...cta} key={i}></CTA>)}
      </div>
    </div>
  );
};

type CTAAction = 'Call' | 'Hours' | 'Reservations' | 'Directions' | 'Gift Cards' | 'Catering';

type CTAProps = {
  action: CTAAction;
  href: string;
  newTab: boolean;
};

type CTAIconProps = {
  src?: string;
  alt?: string;
  icon?: ReactNode;
};

const icons: Record<CTAAction, CTAIconProps> = {
  Reservations: { src: '/icons/calendar-default-icon-color.svg', alt: 'calendar icon' },
  Hours: { src: '/icons/clock-default-icon-color.svg', alt: 'clock icon' },
  Call: { src: '/icons/phone-default-icon-color.svg', alt: 'phone icon' },
  Directions: { src: '/icons/waypointSign-default-icon-color.svg', alt: 'waypoint sign icon' },
  Catering: { icon: <ServingTrayIcon aria-label="catering icon" /> },
  'Gift Cards': { src: '/icons/gift-default-icon-color.svg', alt: 'gift icon' }
};


const CTA = ({ action, href, newTab }: CTAProps) => {
  const linkOptions: {target?: string, rel?: string} = {};
  if(newTab && !href.includes('toasttab')) {
    linkOptions.target = '_blank';
    linkOptions.rel = 'noreferrer noopener';
  }
  return (
    <a href={href} className="cta" {...linkOptions}>
      <div className="icon">
        {icons[action].src ?
          <Image src={icons[action].src} alt={icons[action].alt ?? ''} height={20}></Image>
          :
          icons[action].icon}
      </div>
      <p>{action}</p>
    </a>
  );
};
export default Overview;
