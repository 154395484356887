import React from 'react';

import classNames from 'classnames';

const VerifiedStatus = ({ isVerified }: { isVerified: boolean }) => {
  const verifiedStatus = isVerified ? 'verified' : 'unVerified';
  return (
    <span className={classNames('verifiedStatus', verifiedStatus)} data-testid={isVerified ? 'VerifiedStatus' : 'UnVerifiedStatus'}>
      {isVerified ? 'VERIFIED' : 'UNVERIFIED'}
    </span>
  );
};
export default VerifiedStatus;
