import React from 'react';
import { useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { EcommercePage, FilePage, Redirect, TemplatePage, MenuPage as MenuPageType } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';
import { getHost } from 'src/lib/js/utilities';

import RedirectWithStatus from 'shared/components/common/RedirectWithStatus';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';
import { AdditionalPagesType } from 'shared/js/types';

import AccountPage from 'public/components/default_template/AccountPage';
import CheckoutPage from 'public/components/default_template/CheckoutPage';
import ConfirmationPage from 'public/components/default_template/ConfirmationPage';
import EcommCheckoutPage from 'public/components/default_template/EcommCheckoutPage';
import EcommConfirmationPage from 'public/components/default_template/EcommConfirmationPage';
import EcommPage from 'public/components/default_template/EcommPage';
import MenuPage from 'public/components/default_template/MenuPage';
import OrderPage from 'public/components/default_template/OrderPage';
import MainPage, { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import { generateAddlPageMetaFields } from 'public/components/default_template/main_page/generateMetaFields';
import { SiftContextProvider } from 'public/components/default_template/meta/sift/SiftContext';
import { CustomerContextProviderWrapper } from 'public/components/online_ordering/CustomerContextWrapper';
import AppRedirect from 'public/components/pages/app_redirect/AppRedirect';
import CateringFormPage, { CateringFormPageType } from 'public/components/pages/catering/CateringFormPage';
import EmailSignupPage, { EmailMarketingPageType } from 'public/components/pages/email_marketing_page/EmailSignupPage';
import FormPageComponent, { FormPageType } from 'public/components/pages/form_page/FormPage';
import { GiftCardPurchasePage } from 'public/components/pages/gift_card_purchase_page/GiftCardPurchasePage';
import IFramePage from 'public/components/pages/iframe_page/IFramePage';
import LocationSelectionPage from 'public/components/pages/location_selection_page/LocationSelectionPage';
import LoyaltyPage, { LoyaltyPageType } from 'public/components/pages/loyalty_page/LoyaltyPage';
import MapPage from 'public/components/pages/map_page/MapPage';
import DynamicMenuPage from 'public/components/pages/menu_page/DynamicMenuPage';
import PageShell from 'public/components/pages/page_shell';
import AdyenPage from 'public/components/pages/payment_page/AdyenPage';
import PdfPage from 'public/components/pages/pdf_page/PDFPage';
import TemplatePageComponent from 'public/components/pages/template_page';
import { UntappdMenuPage, UntappdMenuPageType } from 'public/components/pages/untappd_menu_page/UntappdMenuPage';

import { PageDomainProvider } from './PageDomainContext';
import { OrderRoutes } from './ToastOrderRoutes';
import useSitePagesData from './useSitePagesData';

type PageType = AdditionalPagesType[0];

const SitesRoutes = ({ staticContext }: { staticContext?: RequestContext }) => {
  const { pathname } = useLocation();
  const host = getHost(staticContext);

  const { sitePagesData: siteRestaurant, loading } = useSitePagesData(staticContext);

  if(loading) {
    return null;
  }

  if(!host || !siteRestaurant) {
    return <NoMatch404 meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} />;
  }

  const pages = siteRestaurant.additionalPages;
  const hasMultiLocations = (siteRestaurant.locations?.length || 0) > 1;
  const redirects = siteRestaurant.redirects as Array<Redirect>;
  const primaryColor = siteRestaurant.meta?.primaryColor;
  const meta = siteRestaurant.meta;
  const theme = siteRestaurant.theme;
  const pathToPage = (pageIdx: number): EditPathRoot => `additionalPages[${pageIdx}]`;

  const hasHomePage = !siteRestaurant.config.homePageConfig?.optedOut;
  const hasMenuPage = !siteRestaurant.config.menuConfig?.optedOut;
  const hasOoPage = !siteRestaurant.config.ooConfig?.optedOut;

  const orderComponent = hasOoPage ?
    <PageShell noPopups={!hasMultiLocations} titleTag={hasMultiLocations ? 'Location Selection' : 'Order Online'} ooUsage={!hasMultiLocations ? RxDataUsage.Required : RxDataUsage.Optional}>
      {hasMultiLocations ? <LocationSelectionPage /> : <OrderPage />}
    </PageShell> :
    <NoMatch404 meta={meta} siteTheme={theme} />;

  const menuComponent = hasMenuPage ? <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell> : <NoMatch404 meta={meta} siteTheme={theme} />;

  // set the component rendered at / based on which pages the site has opted into
  const homeComponent =
    hasHomePage
      ? <PageShell ooUsage={RxDataUsage.Optional}><MainPage /></PageShell>
      : hasOoPage
        ? orderComponent
        : hasMenuPage
          ? menuComponent
          : <NoMatch404 meta={meta} siteTheme={theme} />;

  if(siteRestaurant.config?.isMenuOnly && hasMenuPage) {
    return (
      <CustomerContextProviderWrapper>
        <SiftContextProvider>
          <Switch>
            {redirects.map(({ from, to, status }) => <Route key={from} exact path={from} component={() => <RedirectWithStatus to={to} status={status} />} />)}
            <Route exact path="/" component={() => menuComponent} />
            <Route exact path="/menu/:slug/:menuSlug([^_]+_)?:menuGuid?" component={() => menuComponent} />
            <Route exact path="/menu/:slug" component={() => menuComponent} />
            <Route exact path="/menu" component={() => menuComponent} />
            <Route exact path="/map" component={() => <MapPage color={primaryColor} siteTheme={theme} />} />
            <Route exact path="/sites-web/v1/map" component={() => <MapPage color={primaryColor} siteTheme={theme} />} />
            <Route component={() => <NoMatch404 meta={siteRestaurant?.meta} siteTheme={theme} />} />
          </Switch>
        </SiftContextProvider>
      </CustomerContextProviderWrapper>
    );
  }

  if(siteRestaurant.config?.isOnlineOrderingOnly && siteRestaurant.config?.isOnlineOrderingEnabled) {
    return <OrderRoutes staticContext={staticContext} sitesUsage={RxDataUsage.Required} />;
  }

  const routes = [
    // MainPage needs OO data to load the Promo/Loyalty banners, but failing to load OO shouldnt cause MainPage to fail.
    { path: '/', component: () => homeComponent },
    { path: '/brandedAppRedirect', component: () => <PageShell ooUsage={RxDataUsage.None}><AppRedirect /></PageShell> },
    { path: '/map', component: () => <MapPage color={primaryColor} siteTheme={theme} /> },
    { path: '/sites-web/v1/map', component: () => <MapPage color={primaryColor} siteTheme={theme} /> },
    { path: '/iframe', component: () => <IFramePage /> },
    { path: '/sites-web/v1/iframe', component: () => <IFramePage /> },
    { path: '/adyen', component: () => <AdyenPage /> },
    { path: '/sites-web/v1/adyen', component: () => <AdyenPage /> }
  ];

  if(hasMenuPage) {
    routes.push({ path: '/menu/:slug/:menuSlug([^_]+_)?:menuGuid?', component: () => menuComponent });
    routes.push({ path: '/menu/:slug', component: () => menuComponent });
    routes.push({ path: '/menu', component: () => menuComponent });
  }

  pages?.forEach((page: PageType, pageIdx: number) => {
    const route = page.route.startsWith('/') ? page.route : `/${page.route}`;
    const pageSeoMeta = generateAddlPageMetaFields(page.meta, page.route, siteRestaurant);

    if(page.content.__typename === 'FilePage') {
      const content = page.content as FilePage;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title || content.name} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Optional} >
            <PdfPage file={content.filePath} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'TemplatePage') {
      const content = page.content as TemplatePage;
      routes.push({
        path: route,
        component: () =>
          <PageShell ooUsage={RxDataUsage.Optional}>
            <TemplatePageComponent content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} pageSeoMeta={pageSeoMeta} bulkCalls={page.bulkCalls} />
          </PageShell>
      });
    } else if(page.content.__typename === 'FormPage') {
      const content = page.content as FormPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Optional} >
            <FormPageComponent content={content} editPathRoot={pathToPage(pageIdx)} id={page.route} config={page.config} title={page.meta?.title || page.route} />
          </PageShell>
      });
    } else if(page.content.__typename === 'LoyaltySignUpPage') {
      const content = page.content as LoyaltyPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Optional} >
            <LoyaltyPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'EmailMarketingPage') {
      const content = page.content as EmailMarketingPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Optional} >
            <EmailSignupPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'CateringFormPage') {
      const content = page.content as CateringFormPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={page.meta?.title || page.route.replace('/', '')} ooUsage={RxDataUsage.Optional} >
            <CateringFormPage rxGuid={siteRestaurant?.locations ? siteRestaurant?.locations[0]?.externalId : ''} content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'UntappdMenuPage') {
      const content = page.content as UntappdMenuPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Optional} >
            <UntappdMenuPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'GiftCardPurchasePage') {
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Required}>
            <GiftCardPurchasePage config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'EcommercePage') {
      const content = page.content as EcommercePage;
      const ecommComponent =
        <PageShell ecommPath={page.route} titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Required}>
          <EcommPage ecommContent={content} />
        </PageShell>;
      // @ts-ignore
      const ecommRoutes = [
        { path: route, component: () => ecommComponent },
        { path: `${route}/checkout`, component: () => <PageShell ecommPath={page.route} noPopups titleTag="Checkout" ooUsage={RxDataUsage.Required}><EcommCheckoutPage /></PageShell> },
        { path: `${route}/confirm`, component: () => <PageShell ecommPath={page.route} noPopups titleTag="Order Confirmation" ooUsage={RxDataUsage.Required}><EcommConfirmationPage /></PageShell> },
        { path: `${route}/:slug/:itemSlug(item-[^_]+_.+)?`, component: () => ecommComponent },
        { path: `${route}/add/:guid(.+)/:ignore`, component: () => ecommComponent },
        { path: `${route}/:slug/:menuSlug([^_]+_)?:menuGuid?`, component: () => ecommComponent },
        { path: `${route}/:slug`, component: () => ecommComponent }
      ];
      routes.push(...ecommRoutes);
    } else if(page.content.__typename === 'MenuPage') {
      const content = page.content as MenuPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={pageSeoMeta.title} descriptionTag={pageSeoMeta.description} ooUsage={RxDataUsage.Required}>
            <DynamicMenuPage content={content} config={page.config} bulkCalls={page.bulkCalls} editPathRoot={pathToPage(pageIdx)} />
          </PageShell>
      });
    }
  });

  if(siteRestaurant.config?.isOnlineOrderingEnabled && hasOoPage) {
    const orderRoutes = [
      { path: '/order', component: () => orderComponent },
      { path: '/order/:slug/:itemSlug(item-[^_]+_.+)?', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/add/:guid(.+)/:ignore', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/:slug/:menuSlug([^_]+_)?:menuGuid?', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/:slug', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      {
        path: '/checkout', component: () =>
          <PageShell noPopups titleTag="Checkout" ooUsage={RxDataUsage.Required}><CheckoutPage /></PageShell>
      },
      { path: '/confirm', component: () => <PageShell noPopups titleTag="Order Confirmation" ooUsage={RxDataUsage.Required}><ConfirmationPage /></PageShell> },
      { path: '/confirm/:pathOrderGuid', component: () => <PageShell noPopups titleTag="Order Confirmation" ooUsage={RxDataUsage.Required}><ConfirmationPage /></PageShell> },
      { path: '/account', component: () => <PageShell noPopups titleTag="My Account" ooUsage={RxDataUsage.Required}><AccountPage /></PageShell> }
    ];


    routes.push(...orderRoutes);
  }

  const routesByPath = new Map(routes.map(r => [r.path, r]));
  const verifiedDomains = siteRestaurant.domainsV2.filter(d => !d.pendingVerification);
  const currentDomain = verifiedDomains.find(d => d.domain === host);
  // a domain with a page route renders that route's component a the root
  const routeOverride = currentDomain?.pageRoute ? routesByPath.get(currentDomain.pageRoute) : null;

  return (
    <CustomerContextProviderWrapper>
      <PageDomainProvider domains={verifiedDomains} host={host} pathname={pathname}>
        <SiftContextProvider>
          <Switch>
            {/* when the domain is mapped to a page, this should take priority over redirects */}
            {routeOverride ? <Route exact path="/" component={routeOverride.component} /> : null}
            {redirects.map(({ from, to, status }) => <Route key={from} exact path={from} component={() => <RedirectWithStatus to={to} status={status} />} />)}
            {routes.map((r, i) => <Route exact key={`${r.path}-${i}`} path={r.path} component={r.component} /> )}
            <Route component={() => <NoMatch404 meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} />} />
          </Switch>
        </SiftContextProvider>
      </PageDomainProvider>
    </CustomerContextProviderWrapper>
  );
};

export default SitesRoutes;
