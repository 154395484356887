import React from 'react';

import classnames from 'classnames';
import startCase from 'lodash/startCase';

import { reportError } from 'src/lib/js/clientError';
import { FullSchedule, getCurrentScheduleInterval, getNextScheduleInterval, getToday, formatTimeString } from 'src/lib/js/schedule';


type Props = {
  isOpen: boolean;
  schedule: FullSchedule;
  timezoneId: string;
}

const OpenStatus = ({ isOpen, schedule, timezoneId }: Props) => {
  const overrideDescription = schedule[getToday(timezoneId)].overrideDescription;

  let nextEventString;
  if(isOpen) {
    const currentInterval = getCurrentScheduleInterval(schedule, timezoneId);
    if(currentInterval) {
      nextEventString = `Closes at ${formatTimeString(currentInterval.endTime)}`;
    } else {
      reportError('Location indicated as open, cannot get current interval!');
    }
  } else {
    const nextInterval = getNextScheduleInterval(schedule, timezoneId);
    if(nextInterval) {
      nextEventString = `Opens ${startCase(nextInterval.day)} at ${formatTimeString(nextInterval.interval.startTime)}`;
    }
  }

  const openOrClosed = isOpen ? 'Open now' : 'Closed';
  const statusDescriptor = overrideDescription ?? nextEventString;

  return (
    <div>
      <div className={classnames('circle', { open: isOpen })} />
      <span suppressHydrationWarning={true} >
        <strong suppressHydrationWarning={true} data-testid="open-status-text">{openOrClosed}</strong>
        {statusDescriptor && <><span aria-hidden="true"> • </span>{statusDescriptor}</>}
      </span>
    </div>);
};

export default OpenStatus;
