import React, { useMemo, useRef } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { EcommercePage, HeroContentType, ImageRatio, MenuTemplate, StyleIntensity } from 'src/apollo/sites';
import { useSpotlightContext } from 'src/public/components/default_template/spotlight/SpotlightContext';

import GlobalNav from 'shared/components/common/nav/Nav';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import StickyFooter from 'public/components/default_template/footer/sticky_footer/StickyFooter';
import MenuPageHeader from 'public/components/default_template/menu_header/MenuPageHeader';
import { useMenuNav } from 'public/components/default_template/menu_nav/MenuNav';
import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import DeeplinkItemModal from 'public/components/default_template/online_ordering/item_modal/DeeplinkItemModal';
import { ItemResolutionModal } from 'public/components/default_template/online_ordering/item_modal/ItemResolutionModal';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';
import { Channel } from 'public/js/siteUtilities';

import OrderPageWrapper, { OrderPageContentProps } from './online_ordering/pages/OrderPageWrapper';
import { FilterNav } from './paginated_menu/FilterNav';
import { FilterTags } from './paginated_menu/FilterTags';
import { PaginatedMenu } from './paginated_menu/PaginatedMenu';

type EcommPageContentProps = {
  ecommContent: EcommercePage,
} & OrderPageContentProps;

export const EcommPageContent = ({ ecommContent, scrollNavRef, navOpen, hasHero, hideHero, usingSecondaryNav, itemGuid }: EcommPageContentProps) => {
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant } = useRestaurant();
  const { cart } = useCart();
  const navRef = useRef<HTMLDivElement>(null);
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const { isHidden } = useSpotlightContext();

  const { isEditor, useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'menu', displayName: 'Menu', actions: [], schema: { fields: [] }, skipRef: navRef });
  const channelGuid = selectedLocation?.ecommMenuChannelGuid || undefined;

  const headerRefPath = useMemo(() => {
    const ecommPageIndex = siteRestaurant.additionalPages?.findIndex(page => page.pageType === 'ecommerce');
    return `additionalPages[${ecommPageIndex}].content.config.hero.heroImage`;
  }, [siteRestaurant.additionalPages]);

  const {
    menus,
    menuItems,
    groupings,
    filters,
    setFilters,
    fetchMore,
    loadingNewFilterData,
    loading
  } = useOOMenus({ respectAvailability: !isEditor, channelGuid, requirePagination: true, skipPopularItems: true });
  const { itemGuidsToItemsMap } = useMenuNav({ menus, page: '/order' });

  const deeplinkItem = itemGuid ? itemGuidsToItemsMap.get(itemGuid) : null;

  // We hard-code these values for now, though we may some day make them configurable
  const formatConfig = {
    template: MenuTemplate.TopImageV2,
    borderStroke: StyleIntensity.Subtle,
    roundedCorner: StyleIntensity.Subtle,
    condenseImagelessGroups: true,
    dropShadow: StyleIntensity.None,
    imageRatio: ImageRatio.Shorter
  };

  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  const spotlight = siteRestaurant.content?.spotlightBanner;
  const spotlightBannerAppliedToEcommPage = (spotlight?.applicablePages?.filter(page => page === '/order' || page === '/online') ?? []).length > 0;
  const navSpotlightEnabled = !isHidden && !!spotlight?.enabled && spotlight?.fixed === true && spotlightBannerAppliedToEcommPage;

  return (
    <>
      {!hideHero &&
          <MenuPageHeader
            heading={ecommContent.headingText || 'Online Store'}
            subHeading={ecommContent.subheadingText}
            heroConfig={ecommContent.config?.hero as HeroContentType}
            refPath={headerRefPath} />}
      <div className="pageContent" ref={scrollNavRef} data-testid="order-page" role="main" id="main" aria-label="menu" tabIndex={0}>
        {!restaurant?.hasOnlineOrderingModule ?
          <div className="onlineOrderingUnavailable">Online ordering is currently unavailable. Please contact the restaurant to place an order.</div> :
          <div ref={editableRef}>
            <div className={classnames('navAndMenu', { sideBySide })}>
              <GlobalNav navType="stickyNav"
                shouldShowPreviewBanner={false}
                className={classnames('secondaryNav', {
                  withoutHero: !hasHero,
                  withShadow: usingSecondaryNav,
                  globalNavOpen: navOpen || !usingSecondaryNav,
                  withNavSpotlight: navSpotlightEnabled,
                  sideBySide
                })}>
                {menuItems?.length && groupings ? <FilterNav groupings={groupings} filters={filters} setFilters={setFilters} /> : null}
              </GlobalNav>
              <div className="menuSections">
                {menuItems?.length && groupings ? <FilterTags groupings={groupings} filters={filters} setFilters={setFilters} /> : null}
                <div className={classnames('menuSectionWrapper', getTemplateClass(MenuTemplate.TopImageV2))}>
                  {menuItems?.length && groupings ?
                    <PaginatedMenu
                      channelGuid={channelGuid}
                      menuItems={menuItems}
                      loading={loading || false}
                      fetchMore={fetchMore}
                      loadingNewFilterData={loadingNewFilterData || false}
                      menuConfig={{ format: formatConfig, colors: ecommContent.config?.colors }}
                      hideOutOfStockItems={ecommContent.config?.hideOutOfStockItems} />
                    : null}
                </div>
              </div>
            </div>
            {cart?.order?.numberOfSelections ?
              <StickyFooter primaryCta={{ text: `View order (${cart.order.numberOfSelections})`, type: 'customAction', onClick: () => context?.open() }} /> :
              null}
          </div>}
      </div>
      {/*TODO: Create EcommPageSchema */}
      {/*<OrderPageSchema restaurant={restaurant} site={siteRestaurant} selectedLocation={selectedLocation} locationSchedule={locationSchedule} menus={menus} />*/}
      {/* Only necessary when the guest has attempted to add an item to the cart that conflicts with the cart's current selections */}
      <ItemResolutionModal channelGuid={channelGuid} />
      {deeplinkItem && <DeeplinkItemModal item={deeplinkItem} />}
    </>
  );
};

const EcommPage = ({ ecommContent }: { ecommContent: EcommercePage }) => {
  return (
    <OrderPageWrapper channel={Channel.ECOMM}>
      {props =>
        <EcommPageContent
          ecommContent={ecommContent}
          {...props} />}
    </OrderPageWrapper>);
};
export default React.memo(EcommPage);
