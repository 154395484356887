import { FieldValues } from 'react-hook-form';

export function getAge(dateOfBirth: Date): number {
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDifference = today.getMonth() - dateOfBirth.getMonth();
  const dayDifference = today.getDate() - dateOfBirth.getDate();

  // when monthDifference/dayDifference is negative, it means the birthday hasn't happened yet with
  // within the current year and so we should decrement the age by 1
  if(monthDifference < 0 || monthDifference === 0 && dayDifference < 0) {
    age--;
  }

  return age;
}

export function is21OrOlder(dateOfBirth: Date): boolean {
  return getAge(dateOfBirth) >= 21;
}

export function onBirthdaySubmit(onSuccess: () => void, onFailure: () => void): ({ dateOfBirth }: FieldValues) => void {
  return ({ dateOfBirth }: FieldValues) => {
    if(is21OrOlder(dateOfBirth)) {
      onSuccess();
    } else {
      onFailure();
    }
  };
}
