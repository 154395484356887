import React, { useCallback } from 'react';
import { Calendar, CalendarGrid, DatePicker, Dialog, Group, Button, Heading, CalendarCell, DateInput, DateSegment, Popover } from 'react-aria-components';
import { useFormContext, Controller } from 'react-hook-form';

import { CalendarDate, today } from '@internationalized/date';
import classnames from 'classnames';


import { CalendarIcon } from 'src/public/components/default_template/online_ordering/item_tags/ItemTagIcons';

import Image from 'shared/components/common/Image';
import InputMessage from 'shared/components/common/form_input/InputMessage';


type Props = {
  id: string;
  label: string;
  required?: boolean;
  /** When true, only dates today and in the future can be selected. Defaults to true */
  futureDatesOnly?: boolean;
  /** Used to ensure correct color choice. Set to false if the surface color is always white. Otherwise, set to true to use themed datepicker colors. */
  onThemedSurface: boolean;
  dateInputStyle?: React.CSSProperties;
}

const AriaDatePicker = ({ id, label, required, futureDatesOnly = true, onThemedSurface: onThemedBackground, dateInputStyle }: Props) => {
  const { control } = useFormContext();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isDateUnavailable = useCallback((date: CalendarDate) => futureDatesOnly && date < today(timezone), [timezone, futureDatesOnly]);

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        <div className={classnames('formInput', { filled: !!value, error: error, inUse: true })}>
          <div className="inputWrapper">
            <>
              <div className="inputElementWrapper">
                <DatePicker
                  className={classnames('react-aria-DatePicker', { onThemedBackground: onThemedBackground })}
                  id={id}
                  onChange={value => {
                    if(value) {
                      onChange(value.toDate(timezone));
                    }
                  }}
                  isDateUnavailable={isDateUnavailable}>
                  <Group>
                    <label style={dateInputStyle} htmlFor={id}>{label}{required ? '*' : ''}</label>
                    <DateInput style={dateInputStyle}>{segment => <DateSegment segment={segment} />}</DateInput>
                    <Button className="calendarToggle"><CalendarIcon color="currentColor" /></Button>
                  </Group>
                  <Popover>
                    <Dialog>
                      <Calendar
                        aria-label={`${label} calendar`}>
                        <header>
                          <Button slot="previous" className="changeMonthArrow">◀</Button>
                          <Heading />
                          <Button slot="next" className="changeMonthArrow">▶</Button>
                        </header>
                        <>
                          <CalendarGrid>
                            {date => <CalendarCell date={date} key={date.toString()} />}
                          </CalendarGrid>
                        </>
                      </Calendar>
                    </Dialog>
                  </Popover>
                </DatePicker>
              </div>
              {error && <Image alt="Warning icon" src="icons/warning-red.svg" />}
            </>
          </div>
          <InputMessage id={id} />
        </div>} />
  );
};

export default AriaDatePicker;
