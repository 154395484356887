import React, { useEffect, useMemo, useRef, useState } from 'react';
import { generatePath } from 'react-router';

import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import { useRestaurantPromoBannersQuery } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import Link from 'shared/components/common/link';
import { useModalContext } from 'shared/components/common/modal';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useOptionalCart } from 'public/components/online_ordering/CartContext';

const PromoCode = ({ promoCodeGuid, fontFamily }: { promoCodeGuid: string; fontFamily?: string }) => {
  const client = useOOClient();
  const { onClose } = useModalContext();
  const { orderPathPattern } = useRestaurantRoutes();
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const cartContext = useOptionalCart();
  const { restaurant } = useRestaurant();
  const timeoutKey = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [copyMessageVisible, setCopyMessageVisible] = useState(false);

  // Clean up the timeout call on dismount, if needed.
  useEffect(() => {
    return () => {
      if(timeoutKey.current) {
        clearTimeout(timeoutKey.current);
      }
    };
  }, []);

  const onClickCode = (code: string) => {
    copy(code);
    setCopyMessageVisible(true);
    timeoutKey.current = setTimeout(() => {
      timeoutKey.current = null;
      setCopyMessageVisible(false);
    }, 2000);
  };

  const locations = restaurant.locations;

  const { data } = useRestaurantPromoBannersQuery({
    client,
    variables: { restaurantGuids: locations?.map(loc => loc.externalId) || [] },
    skip: !locations?.length,
    ssr: false
  });

  const banners = useMemo(
    () =>
      data?.restaurants?.flatMap(restaurant =>
        restaurant.__typename === 'Restaurant'
          ? restaurant.promoBanners?.__typename === 'PromoBannerResponse'
            ? restaurant.promoBanners.promoCodeBanners?.flatMap(banner => ({
              restaurantName: restaurant.name,
              shortUrl: restaurant.shortUrl,
              ...banner
            }))
            : []
          : []),
    [data]
  );

  const promoCode = banners?.find(banner => banner.bannerGuid === promoCodeGuid);
  const hasCart = !!cartContext?.cart;

  if(!promoCode) {
    return null;
  }

  return (
    <>
      <h2><Image src="icons/tag.svg" alt="" />  {promoCode.bannerText}</h2>
      <div className="promoCode">
        {locations?.length && locations.length > 1 ?
          <div className="bannerText">
          Only available at {promoCode.restaurantName}
          </div>
          : null}
        <div className="codeSection">
          <span className="code" onClick={() => onClickCode(promoCode.promoCode)}>{promoCode.promoCode}</span>
          <div className={classNames('copyMessage', { show: copyMessageVisible })}>Copied!</div>
          {hasCart ?
            <Button variant={ButtonType.Text} style={{ fontFamily }} onClick={async () => {
              onClose();
              context?.open();
              await cartContext.applyPromoCode(promoCode.promoCode);
            }}>
          Apply code
            </Button>
            : <Link className="link" href={generatePath(orderPathPattern, { slug: promoCode.shortUrl })}>Order now</Link>}
        </div>
      </div>
    </>
  );
};

export default PromoCode;
