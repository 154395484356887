import { isInternationalCountryIsoCode } from '@toasttab/buffet-pui-country-utilities';

import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { SiteData } from 'shared/js/types';

export function useIsIntlRestaurant() {
  const res = useOptionalRestaurant();
  return isInternationalCountryIsoCode(res?.ooRestaurant?.i18n?.country || 'US');
}

export const siteHasIntlLocation = (site?: SiteData | null) =>
  site?.locations?.some(loc => isInternationalCountryIsoCode(loc.country)) ?? false;
