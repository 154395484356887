import React from 'react';
import { FormProvider } from 'react-hook-form';

import { PopupButtonActionType, PopupLayout } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import InputField from 'shared/components/common/form_input/InputField';

import { PopupContentProps } from 'public/components/default_template/popups/PopupContents';
import PromoCode from 'public/components/default_template/popups/PromoCode';

/**
 * Popup layout default component. If no layout is specified this is the component that will render
 */
export const PopupDefaultLayout = ({ popup, description, header, onClose, submitted, submitting, formMethods, handleSubmit, onSubmit }: PopupContentProps) => {
  return (
    <>
      <div className="popupContent" data-testid="popup-default-layout-content">
        {popup.promoCodeGuid
          ?
          <div
            style={{
              color: description.color,
              fontFamily: description.fontFamily
            }}>
            <PromoCode
              promoCodeGuid={popup.promoCodeGuid}
              fontFamily={description.fontFamily} />
          </div>
          :
          <h2
            id="popup-heading"
            style={{ color: header.color, fontFamily: header.fontFamily }}>
            {header.text}
          </h2>}
        <div className="popupBody">
          {(popup.layout === PopupLayout.ImageAndText ||
            popup.layout === PopupLayout.FormTextAndImage ||
            popup.layout === PopupLayout.FormSlideIn) &&
          popup.image
            ?
            <Image className="image" src={popup.image} alt="" />
            : null}
          <p
            style={{
              color: description.color,
              fontFamily: description.fontFamily
            }}>
            {description.text}
          </p>
        </div>
        {submitted ? <h2>{popup.form?.successMessage}</h2> : null}
        {!submitted &&
        (popup.layout === PopupLayout.FormTextAndImage ||
          popup.layout === PopupLayout.FormTextOnly ||
          popup.layout === PopupLayout.FormSlideIn) &&
        popup.form
          ?
          <div className="popupForm">
            <FormProvider {...formMethods}>
              <form>

                <InputField
                  id={popup.form.formLabel}
                  label={popup.form.formLabel}
                  required />
                {popup.layout === PopupLayout.FormTextOnly &&
                popup.form.secondaryFormLabel
                  ?
                  <InputField
                    required
                    id={popup.form.secondaryFormLabel}
                    label={popup.form.secondaryFormLabel} />
                  : null}
              </form>
            </FormProvider>
          </div>
          : null}
      </div>
      <div className="popupActions" data-testid="popup-default-layout-actions">
        {popup.buttons?.map((btn, i) => {
          if(!btn) return null;
          return (
            <Button
              key={`modal-action-${i}`}
              disabled={submitting || submitted}
              onClick={() => {
                switch(btn.action?.action) {
                  case PopupButtonActionType.Link:
                    if(btn.action?.metadata) {
                      window.open(
                        btn.action?.metadata,
                        '_blank',
                        'noopener noreferrer'
                      );
                    }
                    onClose();
                    break;
                  case PopupButtonActionType.Submit:
                    if(popup.form) {
                      handleSubmit(onSubmit)();
                    } else {
                      onClose();
                    }
                    break;
                  case PopupButtonActionType.Close:
                  default:
                    onClose();
                    break;
                }
              }}
              variant={btn.buttonType}>
              {btn.text}
            </Button>
          );
        })}
        {!popup.buttons?.length && popup.button
          ?
          <Button
            disabled={submitting || submitted}
            onClick={() => {
              if(popup.form) {
                handleSubmit(onSubmit)();
              } else {
                onClose();
              }
            }}
            variant={popup.button.buttonType}>
            {popup.button?.text}
          </Button>
          : null}
      </div>
    </>
  );
};
