import isEmail from 'validator/lib/isEmail';
import * as yup from 'yup';

yup.addMethod(yup.string, 'isEmail', function(message) {
  return this.test('isEmail', message, function(value) {
    const { path, createError } = this;
    return isEmail(value || '') || createError({ path, message });
  });
});

export const profileInformationSchema = yup.object({
  firstName: yup.string().trim()
    .max(250)
    .required(),
  lastName: yup.string().trim()
    .max(250)
    .required(),
  email: yup.string().trim()
    .isEmail('Please enter a valid email address')
    .required(),
  phone: yup.string().trim()
    .required()
}).required();
