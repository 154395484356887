import React from 'react';

import Image from 'shared/components/common/Image';

const DISCOVER_PAGE_URL = 'https://www.toasttab.com/catering/locations/discover?utm_source=toast_local';
const PROMO_IMG_URL = 'https://cdn.toasttab.com/static/0dc46b83bbb4b098106760929d4da54d755ed258/projects/new-ventures/catering/catering_promo.png';

const CateringPromo = () => {
  return (
    <div className="promoContainerPadding">
      <div className="promoContainer">
        <div className="promoContent">
          <div className="titleContainer">
            <div className="divider">
              <Image alt="Toast logo" src="/icons/toast-logo-filled.svg" className="logo" eagerLoad />
            </div>
            <div className="titleText">Catering & Events</div>
          </div>
          <h2>Order catering online directly from the restaurants you love</h2>
          <br />
          <a href={DISCOVER_PAGE_URL} className="button-gray cateringButton">
            Discover catering near me
          </a>
        </div>
        <div className="promoImg">
          <Image className="cateringImg" alt="Catering image" src={PROMO_IMG_URL} />
        </div>
      </div>
    </div>
  );
};

export default CateringPromo;
