import React from 'react';

import { PopupLayout } from 'src/apollo/sites';

import { EmptyEmbeddedCodeSection } from 'shared/components/common/embedded_code/EmbeddedCode';

import { DynamicSectionPopup } from 'public/components/default_template/popups/DynamicSectionPopup';
import { PopupContentProps } from 'public/components/default_template/popups/PopupContents';
import { PopupDefaultLayout } from 'public/components/default_template/popups/PopupDefaultLayout';
import { DateOfBirthPopup } from 'public/components/default_template/popups/date_of_birth/DateOfBirthPopup';
import { LoyaltySignupPopup } from 'public/components/default_template/popups/loyalty_signup/LoyaltySignupPopup';
import { SmsMarketingSignupPopup } from 'public/components/default_template/popups/sms_marketing_signup/SmsMarketingSignupPopup';

/**
 * Returns the appropriate layout for the popup given else returns the default layout
 */
export const PopupLayoutSelector = (props: PopupContentProps) => {
  const { popup, header, description, onClose, dynamicBlock, popupIndex } = props;
  switch(popup.layout) {
    case PopupLayout.DateOfBirthVerification:
      return (
        <DateOfBirthPopup {...props} />
      );
    case PopupLayout.SmsMarketingSignup:
      return (
        <SmsMarketingSignupPopup {...props} />
      );
    case PopupLayout.LoyaltySignup:
      return (
        <LoyaltySignupPopup
          header={header.text} headerColor={header.color} subheaderColor={description.color}
          onClose={onClose} successTimeout={(popup?.form?.successTimeout || 3) * 1000} />
      );
    case PopupLayout.EmbeddedCode:
      if(dynamicBlock) {
        return <DynamicSectionPopup popupIndex={popupIndex} dynamicBlock={dynamicBlock} />;
      } else {
        return <EmptyEmbeddedCodeSection />;
      }
    default:
      return (
        <PopupDefaultLayout {...props} />
      );
  }
};
