import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';

import FormattedPrice from 'shared/components/common/price/FormattedPrice';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { TagIcon } from 'public/components/default_template/offers/OffersIcons';
import { useCart } from 'public/components/online_ordering/CartContext';

const FreeShippingProgressBar = ({ threshold, loading }: { threshold: number, loading?: boolean }) => {
  const { restaurant } = useRestaurant();
  const { cart } = useCart();
  const cartTotal = useMemo(() => cart?.order?.totalV2, [cart?.order?.totalV2]);
  const achieved = useMemo(() => cartTotal && cartTotal >= threshold, [cartTotal, threshold]);

  if(!cartTotal) {
    return null;
  }

  const tagColor = achieved ? '#408140' : restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary;

  const percentComplete = achieved ? 100 : cartTotal / threshold * 100;

  return (
    <div className="offerStatusWrapper" data-testid="free-shipping-progress-bar">
      {loading ?
        <Skeleton width="100%" height="100%" className="loadingSkeleton" /> :
        <>
          {!achieved && <PercentBar percent={percentComplete} />}
          <div data-testid="offer-status" className={classNames('offerStatus', achieved ? 'applied' : null)}>
            <div className="offerDesc">
              <TagIcon color={tagColor} altText="Offer" />
              <div data-testid="message">
                {achieved ?
                  <>{'You\'ve qualified for free shipping!'}</> :
                  <>Spend <FormattedPrice value={threshold - cartTotal} /> more to earn free shipping on this order.</>}
              </div>
            </div>
          </div>
        </>}
    </div>
  );
};

const PercentBar = ({ percent }: {percent?: number}) => {
  if(!percent) {
    return null;
  }
  return (
    <div className="percentBar" data-testid="percent-bar">
      <div className="percentFill" data-testid="percent-fill" style={{ width: `${percent}%` }} />
    </div>
  );
};

export default FreeShippingProgressBar;
