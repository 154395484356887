import { defaultCountries, parseCountry } from 'react-international-phone';

import { CountryCode, isSupportedCountry } from 'libphonenumber-js';

/**
 * @param country Country code to validate
 * @returns Country code of the restaurant if the restaurant has a country code and it is a code supported by libphonenumber-js, otherwise default to 'US'
 */
export const asValidPhoneCountryCode = (countryCode: any) => (
  countryCode && isSupportedCountry(countryCode)
    ? countryCode
    : 'US'
) as CountryCode;

export const SUPPORTED_COUNTRIES = defaultCountries.reduce((countries, currentVal) => {
  const parsedCountry = parseCountry(currentVal);

  if([
    'us',
    'ca',
    'ie',
    'gb',
    'au',
    'nz'
  ].includes(parsedCountry.iso2)) {
    return [...countries, parsedCountry];
  }

  return countries;
}, []);


export const formatPhoneNumberForDisplay = (phoneNumber: string) => {
  const phoneNumberNoSpecialCharacters = phoneNumber.replace(/\D/g, '');
  const phoneLength = phoneNumberNoSpecialCharacters?.length;

  if(phoneLength === 10) {
    return phoneNumberNoSpecialCharacters.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if(phoneLength === 11) {
    return phoneNumberNoSpecialCharacters.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
  }

  return phoneNumber;
};
