import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';

import {
  DiningOptionBehavior,
  PackagingInfo,
  PlaceCashOrderInput,
  PlaceCcOrderInput,
  PlaceCcOrderMutation,
  PlacePaidOrderInput,
  PlaceOrderErrorCode,
  usePlaceCashOrderMutation,
  usePlaceCcOrderMutation,
  usePlacePaidOrderMutation
} from 'src/apollo/onlineOrdering';
import { reportErrorMessageWithData } from 'src/lib/js/clientError';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { Channel } from 'src/public/js/siteUtilities';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getEcommShippingFee } from 'public/components/default_template/online_ordering/cart/cartUtils';
import { getCallingCodeForOrder, getNationalPhoneNumberForOrder, getPaymentOption, applyContactlessIndicator } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { useRefreshToastCashAmount } from 'public/components/default_template/online_ordering/checkout/payment/ToastCashPaymentToggle';
import { useSpi, useTempEventTracker } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useGiftCard } from 'public/components/online_ordering/GiftCardContext';
import { PaymentOption, usePayment, PaymentCompletedParams } from 'public/components/online_ordering/PaymentContext';

import { getDigitalSurface, getIsCustomDomain } from './attributionUtils';


type PlaceOrderResponse = PlaceCcOrderMutation['placeOrder'] & { __typename: 'PlaceOrderResponse' };
type CompletedOrder = PlaceOrderResponse['completedOrder'];

export type CheckoutFormData = {
  yourInfoFirstName: string;
  yourInfoLastName: string;
  yourInfoEmail: string;
  yourInfoPhone: string;
  curbsidePickup: boolean;
  curbsidePickupVehicle?: string;
  curbsidePickupVehicleColor?: string;
  deliveryAddress2?: string;
  deliveryInstructions?: string;
  deliveryZipCode?: string;
  addressLabel?: string;
  semiPaymentIntentId?: string;
  yourInfoCountryCode?: string;
};

export type OrderError = {
  message: string;
  type: string;
  refetchCart?: boolean;
};

export enum CheckoutMode {
  Toast = 'Toast',
  Guest = 'Guest'
}

export type CompleteOrderHandler = (order: CompletedOrder | undefined | null) => void;

export type ApmAuthResult = {
  apmName: string,
  canPlaceOrder: boolean,
  criticalError: boolean,
  token?: string
}

export type CheckoutContextType = {
  checkoutMode: CheckoutMode;
  setCheckoutMode: (mode: CheckoutMode) => void;
  createAccount: boolean;
  setCreateAccount: (create: boolean) => void;
  saveNewAddress: boolean;
  setSaveNewAddress: (save: boolean) => void;
  contactlessDelivery: boolean;
  setContactlessDelivery: (contactless: boolean) => void;
  giftCardAppliedAmount: number;
  toastCashAppliedAmount: number;
  orderTotal: number;
  placeOrder: (
    cartGuid: string,
    checkoutFormData: CheckoutFormData,
    completedOrderHandler: CompleteOrderHandler,
    paymentId?: string,
    paymentCompletedParams?: PaymentCompletedParams | null
  ) => Promise<CompletedOrder | undefined | null>;
  orderError: OrderError | null;
  setOrderError: (err: OrderError | null) => void;
  canCheckout: (formState: any) => boolean;
  enabledPaymentOptions: Set<PaymentOption>;
  packagingOptions: PackagingInfo;
  setPackagingOptions: (packagingOptions: PackagingInfo) => void;
  showAdyenOverlay: boolean;
  setShowAdyenOverlay: (show: boolean) => void;
};

export const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export const CheckoutContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { spiSurchargingEnabled } = useSpi();
  const isIntlRestaurant = useIsIntlRestaurant();
  const { track } = useTempEventTracker();
  const { ooRestaurant, toastProduct } = useRestaurant();
  const { cart, refetchCart, channel } = useCart();
  const { giftCardNumber, verificationCode, giftCardAvailableBalance } = useGiftCard();
  const { customer, refreshAuthToken } = useCustomer();
  const giftCardAppliedAmount = useMemo(() => Math.min(giftCardAvailableBalance, cart?.order?.totalV2 || 0), [giftCardAvailableBalance, cart?.order]);

  const [placeCashOrderMutation] = usePlaceCashOrderMutation();
  const [placeCcOrderMutation] = usePlaceCcOrderMutation();
  const [placePaidOrderMutation] = usePlacePaidOrderMutation();
  const [orderError, setOrderError] = useState<OrderError | null>(null);

  // Customer Info
  const [checkoutMode, setCheckoutMode] = useState<CheckoutMode>(customer ? CheckoutMode.Toast : CheckoutMode.Guest);
  const [createAccount, setCreateAccount] = useState(false);
  const [saveNewAddress, setSaveNewAddress] = useState(false);
  const [contactlessDelivery, setContactlessDelivery] = useState(false);

  // Payment
  const {
    paymentOption, isPaymentValid, tipEnabled,
    tipAmount, fundraisingAmount, paymentType, paymentIntent, toastCashInfo
  } = usePayment();
  const toastCashAppliedAmount = useMemo(() => Math.min(toastCashInfo?.toastCashAvailableAmount ?? 0, (cart?.order?.totalV2 || 0) + tipAmount - giftCardAppliedAmount),
    [toastCashInfo?.toastCashAvailableAmount, cart?.order, tipAmount, giftCardAppliedAmount]);
  const { ooToastCashSpend: ooToastCashSpendEnabled, intlOoPhoneCountryDropDown } = useFlags();
  const refreshToastCashAmount = useRefreshToastCashAmount();

  const enabledPaymentOptions = useMemo(() => {
    const enabledOptions = new Set<PaymentOption>();

    const canPayLater = (cart?.paymentOptions.uponReceipt.length || 0) > 0;
    const canPayNow = (cart?.paymentOptions.atCheckout.length || 0) > 0;

    if(canPayLater) {
      enabledOptions.add(PaymentOption.UponReceipt);
    }
    if(canPayNow) {
      enabledOptions.add(PaymentOption.PayNow);
    }
    return enabledOptions;
  }, [cart?.paymentOptions.uponReceipt.length, cart?.paymentOptions.atCheckout.length]);

  // PackagingOptions
  const [packagingOptions, setPackagingOptions] = useState<PackagingInfo>({ packagingItems: [] } as PackagingInfo);

  // Return new delivery address if the customer has provided an apt number or delivery instructions
  // during checkout. Also return data if the customer wants to save their address. The addressLabel
  // field only appears for logged in customers.
  const getNewDeliveryAddress = useCallback((checkoutFormData: CheckoutFormData, contactlessDelivery: boolean) => {
    if(cart?.diningOptionBehavior === DiningOptionBehavior.Delivery) {
      const saveAddressFields = saveNewAddress && checkoutFormData.addressLabel
        ? { name: checkoutFormData.addressLabel, saveAddress: true }
        : {};

      const notes = checkoutFormData.deliveryInstructions || cart?.order?.deliveryInfo?.notes;
      const finalNotes = contactlessDelivery && notes ? applyContactlessIndicator(notes) : notes;

      if(!isEmpty(saveAddressFields) || checkoutFormData.deliveryAddress2 || checkoutFormData.deliveryInstructions ||
      checkoutFormData.deliveryZipCode) {
        return {
          newAddress: {
            deliveryInfo: {
              address1: cart?.order?.deliveryInfo?.address1,
              address2: checkoutFormData.deliveryAddress2 || cart?.order?.deliveryInfo?.address2,
              city: cart?.order?.deliveryInfo?.city,
              state: cart?.order?.deliveryInfo?.state,
              zipCode: checkoutFormData.deliveryZipCode || cart?.order?.deliveryInfo?.zipCode,
              latitude: cart?.order?.deliveryInfo?.latitude,
              longitude: cart?.order?.deliveryInfo?.longitude,
              administrativeArea: cart?.order?.deliveryInfo?.administrativeArea,
              country: cart?.order?.deliveryInfo?.country,
              notes: finalNotes
            },
            ...saveAddressFields
          }
        };
      }
    }

    return {};
  }, [saveNewAddress, cart]);

  const handlePlaceOrderError = (response: any, completeOrderHandler: CompleteOrderHandler) => {
    if(response?.data?.placeOrder.__typename === 'PlaceOrderResponse') {
      completeOrderHandler(response.data.placeOrder.completedOrder);
    } else if(response?.data?.placeOrder.__typename === 'PlaceOrderError') {
      const errorData = {
        type: response.data.placeOrder.placeOrderErrorCode,
        message: response.data.placeOrder.message
      };
      if(errorData.type == PlaceOrderErrorCode.CriticalError || errorData.type == PlaceOrderErrorCode.PlaceOrderFailed) {
        reportErrorMessageWithData('Error placing order', errorData);
        // A CriticalError may occur because of an expired auth token, so try refreshing it.
        refreshAuthToken();
      }
      setOrderError(errorData);
      if(errorData.type === PlaceOrderErrorCode.GuestCurrencyAccountInsufficientFunds || errorData.type === PlaceOrderErrorCode.GuestCurrencyAccountPaymentDenied) {
        // On error, also re-hydrate guest wallet data
        if(ooToastCashSpendEnabled) {
          refreshToastCashAmount();
        }
      }
      if(!isIntlRestaurant) {
        track(
          'temp_SPI_placeOrder_failed',
          {
            message: response?.data?.placeOrder?.message,
            paymentIntentId: paymentIntent?.id,
            paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal, isIntlRestaurant)
          }
        );
      }
    } else if(response?.data?.placeOrder.__typename === 'PlaceOrderCartUpdatedError') {
      refetchCart();
      setOrderError({
        type: response.data.placeOrder.placeOrderCartUpdatedErrorCode,
        message: response.data.placeOrder.message
      });
      if(!isIntlRestaurant) {
        track(
          'temp_SPI_placeOrder_failed',
          {
            message: response.data.placeOrder.message,
            paymentIntentId: paymentIntent?.id,
            paymentType: getPaymentOption(paymentType, paymentOption, giftCardAppliedAmount, orderTotal, isIntlRestaurant)
          }
        );
      }
    }
  };

  const placeOrder = async (
    cartGuid: string,
    checkoutFormData: CheckoutFormData,
    completeOrderHandler: CompleteOrderHandler,
    paymentId?: string,
    paymentCompletedParams?: PaymentCompletedParams | null
  ) => {
    const commonInputData = {
      cartGuid,
      customer: {
        firstName: checkoutFormData.yourInfoFirstName,
        lastName: checkoutFormData.yourInfoLastName,
        email: checkoutFormData.yourInfoEmail,
        phone: getNationalPhoneNumberForOrder(checkoutFormData.yourInfoPhone, intlOoPhoneCountryDropDown),
        phoneCountryCode: getCallingCodeForOrder(checkoutFormData.yourInfoPhone, checkoutFormData.yourInfoCountryCode, intlOoPhoneCountryDropDown)
      },
      ...ooRestaurant?.packagingConfig?.enabled && { packagingInfo: packagingOptions },
      ...getNewDeliveryAddress(checkoutFormData, contactlessDelivery),
      ...giftCardNumber && giftCardAppliedAmount && {
        giftCard: {
          cardNumber: giftCardNumber.replace(/\s/g, ''),
          expectedAvailableBalance: giftCardAppliedAmount,
          verificationCode
        }
      },
      ...checkoutFormData.curbsidePickup && checkoutFormData.curbsidePickupVehicle && {
        curbsidePickupV2: {
          transportDescription: checkoutFormData.curbsidePickupVehicle,
          ...checkoutFormData.curbsidePickupVehicleColor && { transportColor: checkoutFormData.curbsidePickupVehicleColor }
        }
      },
      ...checkoutFormData.curbsidePickup && !checkoutFormData.curbsidePickupVehicle && { curbsidePickup: { selected: true } },
      digitalSurface: getDigitalSurface(toastProduct, channel),
      isCustomDomain: typeof window !== 'undefined' ? getIsCustomDomain(window.location.hostname) : null
    };

    if(!isIntlRestaurant) {
      let placeOrderResponse = null;

      if(paymentOption === PaymentOption.PayNow) {
        const orderInput = commonInputData as PlacePaidOrderInput;
        orderInput.tipAmount = tipAmount;
        orderInput.deliveryCommunicationConsentGiven = true;
        if(fundraisingAmount) {
          orderInput.fundraisingInput = { fundraisingAmount };
        }
        if(toastCashAppliedAmount && toastCashInfo?.toastCashAccountId) {
          orderInput.guestCurrencyAccountPayment = {
            expectedGuestCurrencyAccountPaymentAmount: toastCashAppliedAmount,
            guestCurrencyAccountId: toastCashInfo.toastCashAccountId
          };
        }
        // paymentId may be undefined if a gift card, loyalty, or promo code covers the entire check amount.
        orderInput.paymentId = paymentId;

        if(spiSurchargingEnabled) {
          orderInput.paymentMethodId = paymentCompletedParams?.paymentMethodId;
          orderInput.surchargeAmount = paymentCompletedParams?.surchargeAmount;
        }

        placeOrderResponse = await placePaidOrderMutation({
          variables: { input: orderInput },
          context: { headers: { 'Toast-Restaurant-External-ID': ooRestaurant?.guid } }
        });
        track('temp_SPI_order_placed', { paymentType });
      } else {
        const orderInput = commonInputData as PlaceCashOrderInput;
        placeOrderResponse = await placeCashOrderMutation({ variables: { input: orderInput } });
      }
      handlePlaceOrderError(placeOrderResponse, completeOrderHandler);
      return;
    }

    let response = null;
    if(paymentOption === PaymentOption.PayNow) {
      const orderInput = commonInputData as PlaceCcOrderInput;
      orderInput.deliveryCommunicationConsentGiven = true;
      orderInput.tipAmount = tipAmount;
      if(fundraisingAmount) {
        orderInput.fundraisingInput = { fundraisingAmount };
      }

      if(checkoutFormData.semiPaymentIntentId) {
        orderInput.semiPaymentIntentId = checkoutFormData.semiPaymentIntentId;
      }

      response = await placeCcOrderMutation({
        variables: { input: orderInput },
        context: { headers: { 'Toast-Restaurant-External-ID': ooRestaurant?.guid } }
      });
    } else {
      const orderInput = commonInputData as PlaceCashOrderInput;
      response = await placeCashOrderMutation({ variables: { input: orderInput } });
    }

    handlePlaceOrderError(response, completeOrderHandler);
    return null;
  };

  const orderTotal = useMemo(
    () => Number(cart?.order?.totalV2 || 0) + (tipEnabled ? tipAmount : 0) + fundraisingAmount - giftCardAppliedAmount - toastCashAppliedAmount,
    [cart?.order?.totalV2, tipEnabled, tipAmount, fundraisingAmount, giftCardAppliedAmount, toastCashAppliedAmount]
  );

  const canCheckout = useCallback((formState: any) => {
    // The Int'l payment flow doesn't currently use isPaymentValid. canCheckout is used to determine if the user can proceed to payment.
    if(isIntlRestaurant) return formState.isValid && !formState.isSubmitting;

    if(channel === Channel.ECOMM && !getEcommShippingFee(cart)) return false;

    return !!(!formState.isSubmitting
      && formState.isValid
      && paymentOption !== null
      && (
        paymentOption === PaymentOption.UponReceipt
        || orderTotal === 0
        || paymentOption === PaymentOption.PayNow && isPaymentValid
      ));
  }, [paymentOption, orderTotal, isPaymentValid, isIntlRestaurant, channel, cart]);

  const [showAdyenOverlay, setShowAdyenOverlay] = useState<boolean>(false);

  return (
    <CheckoutContext.Provider value={{
      checkoutMode,
      setCheckoutMode,
      createAccount,
      setCreateAccount,
      saveNewAddress,
      setSaveNewAddress,
      contactlessDelivery,
      setContactlessDelivery,
      giftCardAppliedAmount,
      toastCashAppliedAmount,
      orderTotal,
      placeOrder,
      orderError,
      setOrderError,
      canCheckout,
      enabledPaymentOptions,
      packagingOptions,
      setPackagingOptions,
      showAdyenOverlay,
      setShowAdyenOverlay
    }}>
      {props.children}
    </CheckoutContext.Provider>);
};

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if(!context) {
    throw new Error('useCheckout must be used within a CheckoutContextProvider');
  }

  return context;
};
