import localConfig from './config-local';
import prodConfig from './config-production';
import stagingConfig from './config-staging';

let envConfig: typeof localConfig | typeof prodConfig | typeof stagingConfig;
if(process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
  envConfig = require('./config-production');
} else if(process.env.NODE_ENV === 'preproduction') {
  envConfig = require('./config-staging');
} else if(process.env.NODE_ENV === 'sandbox') {
  envConfig = require('./config-sandbox');
} else {
  try {
    envConfig = require('./config');
  } catch(e) {
    console.error(`Could not find config/config.js. Falling back to local to config/config-local.js due to error: ${e}`);
    envConfig = require('./config-local');
  }
}

export const ipapiKey = envConfig.ipapikey;
export const environment = envConfig.environment;
export const server = envConfig.server;
export const serviceName = envConfig.serviceName;
export const resources = envConfig.resources;
export const sentry = envConfig.sentry;
export const gMapsAPIKey = envConfig.gMapsAPIKey;
export const gPlacesAPIKey = envConfig.gPlacesAPIKey;
export const ldClientId = envConfig.ldClientId;
export const ldSdkKey = envConfig.ldSdkKey;
export const giftCardRecaptchaSiteKey = envConfig.giftCardRecaptchaSiteKey;
export const toastDopplerSecret = envConfig.toastDopplerSecret;
export const amplitudeApiKey = envConfig.amplitudeApiKey;
export const owg = envConfig.owg;
export const apiVersion = envConfig.apiVersion;
export const datadog = envConfig.datadog;
export const toastApi = envConfig.toastApi;
export const trustedHosts = envConfig.trustedHosts;
export const giaAuth = envConfig.giaAuth;
export const cache = envConfig.cache;
export const enableCompression = envConfig.enableCompression;
export const sift = envConfig.sift;
export const toast = envConfig.toast;
export const responseCache = envConfig.responseCache;

export default envConfig;
