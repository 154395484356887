import React, { useCallback } from 'react';

import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import DropDown from 'shared/components/common/dropdown';

import { LOADING_DATE_STRING, formatTimeString, formatDateString } from './reservationUtils';

type DateTimeProps = {
  dateOptions: Date[] | null;
  selectedDate: Date | null;
  setSelectedDate: any;
  timeOptions: Date[] | null;
  selectedTime: Date | null;
  setSelectedTime: any;
  timezone: string;
};

type Props = {
  options: Date[] | null;
  selected: Date | null;
  setSelected: any;
  format?: any;
  startingString: string;
};

export const Selector = ({ options, selected, setSelected, format, startingString }: Props) => {
  // Refer to getHoursListFromReservationHours in reservationUtils.tsx for the logic behind the following line
  // @see {@link https://toasttab.atlassian.net/browse/WOO-1109} for why we default to LOADING_DATE_STRING datetime
  const optionsLoading = options?.[0]?.getTime() === new Date(LOADING_DATE_STRING).getTime();
  let dropDownLabel;
  if(optionsLoading) {
    dropDownLabel = <div className="selected disabled" data-testid="selector-dropdown-label" />;
  } else if(options && options.length === 0) {
    dropDownLabel = <div className="selected disabled" data-testid="selector-dropdown-label">No times available</div>;
  } else if(options === null || selected === null) {
    dropDownLabel = <div className="selected disabled" data-testid="selector-dropdown-label">{startingString}</div>;
  } else {
    dropDownLabel = <div className="selected" data-testid="selector-dropdown-label">{format(selected)}</div>;
  }


  return (
    <div className="selector">
      <DropDown label={dropDownLabel} withBorder leftAligned>
        {({ close, ref }) => options?.map(option =>
          <DropDownOption
            key={format(option)}
            className="option"
            onSelect={e => {
              e.preventDefault();
              e.stopPropagation();
              setSelected(option);
              close();
              ref?.current?.focus();
            }}>
            {format(option)}
          </DropDownOption>)}
      </DropDown>
    </div>
  );
};

const DateTimeSelector = ({
  dateOptions,
  selectedDate,
  setSelectedDate,
  timeOptions,
  selectedTime,
  setSelectedTime,
  timezone
}: DateTimeProps) => {
  const formatTimeStringWithTimezone = useCallback((date: Date) => {
    return formatTimeString(date, timezone);
  }, [timezone]);

  return (
    <>
      <div className="dateTimeSection" data-testid="date-time-selector">
        <div className="dateSection">
          <h3 className="sectionTitle">Date</h3>
          <Selector options={dateOptions} selected={selectedDate} setSelected={setSelectedDate} format={formatDateString} startingString={'Select a date'} />
        </div>
        <div className="timeSection">
          <h3 className="sectionTitle">Time</h3>
          <Selector options={timeOptions} selected={selectedTime} setSelected={setSelectedTime} format={formatTimeStringWithTimezone} startingString={'Select a time'} />
        </div>
      </div>
    </>
  );
};

export default DateTimeSelector;
