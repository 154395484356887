import React from 'react';

import urljoin from 'url-join';

import UserNavWrapper from 'src/public/components/default_template/nav/UserNav';
import { SitesToastContainer } from 'src/shared/js/alertUtils';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';

import { TOAST_LOCAL_PATH } from 'public/js/siteUtilities';

import { resources, server } from 'config';

const LocalPromo = () =>
  <div className="posPromoMini hidden-sm-down ">
    <div className="promoForRestaurant">For Restaurants
      <div className="promoHyphen">-</div>
      <a className="navPromoLink" href="https://pos.toasttab.com/request-demo?utm_source=toast_local&utm_medium=internal_referral&utm_content=nav_bar&utm_term=toast_local">
      Get a Demo
      </a>
    </div>
    <div className="verticalBar"></div>
    <div className="promoMobileLinks">
      <a className="navPromoLink" href="https://toasttakeout.page.link/Ktwp" rel="nofollow">
      iOS
      </a>
      <a className="navPromoLink" href="https://toasttakeout.page.link/Ktwp" rel="nofollow">
      Android
      </a>
    </div>
  </div>;


const Nav = () => {
  return (
    <>
      <div className="toastLocalNav navWrapper">
        <div className="nav">
          <div className="left">
            <div data-testid="logoLink" nav-role="logo">
              <Link href={urljoin(`${server.protocol}://${resources.toastLocalHost}`, TOAST_LOCAL_PATH)} sameTab>
                <div className="logoWrapper">
                  <Image alt="Toast logo" src="/icons/toast-logo-orange.svg" className="logo" eagerLoad />
                </div>
              </Link>
            </div>
          </div>
          <div className="middle"></div>
          <div className="right">
            <LocalPromo />
            <div className="loginBtn ">
              <UserNavWrapper />
            </div>
          </div>
        </div>
      </div>
      <SitesToastContainer />
    </>
  );
};

export default Nav;
