import React, { useMemo } from 'react';

import { PlaceHolderImageGenerationEnum } from 'src/apollo/sites';
import { formatImageURL } from 'src/lib/js/utilities';
import { DefaultPlaceHolderMenuImage } from 'src/public/components/default_template/menu_section/DefaultPlaceHolderMenuImage';
import { getMenuItemSrcSet } from 'src/public/components/default_template/menu_section/MenuItemCard';
import { TemplateOverrides } from 'src/public/components/default_template/menu_section/menuStylingOverrides';
import Image from 'src/shared/components/common/Image';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import BaseCard from './BaseCard';
import { useReorder } from './ReorderContext';
import type { Item } from './ReorderContext';

type Props = {
  item: Item
  templateOverrides?: TemplateOverrides
  testId?: string
}

const ItemCard = (props: Props) => {
  const { setReorderItem } = useReorder();
  const { item, templateOverrides } = props;
  const { selection } = props.item;

  const image = useMemo(() => {
    let imgSrc = item.imageUrls?.medium || item.imageUrls?.small || item.imageUrls?.large || item.imageUrls?.xs;
    imgSrc = imgSrc && formatImageURL(imgSrc);
    const imgSrcSet = item.imageUrls && getMenuItemSrcSet(item.imageUrls);

    if(imgSrc && imgSrcSet) {
      return <Image src={imgSrc} srcSet={imgSrcSet} className="itemImage" alt="" data-testid={item.selection.name + '-image'} />;
    } else if(templateOverrides?.placeholderImageConfig.generatePlaceholderImage === PlaceHolderImageGenerationEnum.RepeatedText) {
      return <DefaultPlaceHolderMenuImage className="itemImage" templateOverrides={templateOverrides} />;
    } else {
      return null;
    }
  }, [item, templateOverrides]);

  const modifierText = useMemo(() => [...new Set(selection.modifiers?.map(modifier => modifier?.name))]
    .filter(name => Boolean(name))
    .join(' · '), [selection.modifiers]);
  const price = selection.preDiscountPrice || 0;

  return (
    <BaseCard
      data-testid={props.testId}
      onClick={() => setReorderItem(item) }>
      <div className="itemInfo" data-testid={`item-${selection.guid}`}>
        <div className="headerText" data-testid="item-text">{selection.name}</div>
        <div className="modifierText" data-testid="item-modifier-text">{modifierText}</div>
        <div className="itemPrice" data-testid="item-price">{price ? <FormattedPrice value={price} /> : null}</div>
      </div>
      {image}
    </BaseCard>
  );
};

export default ItemCard;
