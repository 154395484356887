import React, { useMemo } from 'react';

import { DoMenus_GetMenuItemsItem as DoMenusGetMenuItemsItem } from 'src/apollo/onlineOrdering';

import { GroupItemBackupContent, MenuGroupEditableProps } from 'shared/components/common/dynamic_menus/menu_group/MenuGroup';
import Link from 'shared/components/common/link';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { getOrderPath } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { menuItemPath } from 'public/components/default_template/menu_section/MenuItemCard';

import MenuItem from './MenuItem';

type LinkedItemProps = {
  item: DoMenusGetMenuItemsItem | GroupItemBackupContent
} & MenuGroupEditableProps

// A menu item that is designed to live inside a MenuGroup block that links to the OO page.
export const LinkedItem = (props: LinkedItemProps) => {
  const { item } = props;
  const { restaurant, locations } = useRestaurant();
  const hasOoPage = restaurant.config?.isOnlineOrderingEnabled && !restaurant.config.ooConfig?.optedOut;

  const linkHref = useMemo(() => {
    if(!hasOoPage || !locations) {
      return null;
    } else if(locations?.length === 1 && locations[0]?.shortUrl && item.guid && item.name) {
      // If there is just one location, link directly to the item.
      return menuItemPath(
        getOrderPath(locations[0]?.shortUrl),
        item.name,
        item.guid
      );
    } else {
      // If there is more than one location, don't assume which location the user
      // would like to see. Fallback to the order page if there is a data issue.
      return '/order';
    }
  }, [hasOoPage, item.guid, item.name, locations]);

  const itemComponent = useMemo(() => {
    return (
      <MenuItem {...props} />
    );
  }, [props]);

  return linkHref
    ? <Link href={linkHref} key={item.guid}>{itemComponent}</Link>
    : <div>{itemComponent}</div>;
};

export default LinkedItem;
