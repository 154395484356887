import React from 'react';

import { useEditor } from '@toasttab/sites-components';

import { SiteContentDataFragment, PageConfig, SeoMeta, BulkCalls } from 'src/apollo/sites';

import MainPage, { EditPathRoot, MainPage as MainPageWithoutRouter } from 'public/components/default_template/main_page/MainPage';

type SiteContent = NonNullable<SiteContentDataFragment>['content'] & { __typename: 'SiteContent' };

type Props = {
  content: any;
  editPathRoot: EditPathRoot
  config?: PageConfig | null;
  pageSeoMeta?: SeoMeta
  bulkCalls?: BulkCalls | null | undefined
}

const TemplatePage = (props: Props) => {
  // TODO: add conditions for other template types
  const { isEditor } = useEditor();

  return isEditor
    ? <MainPageWithoutRouter content={props.content as SiteContent} editPathRoot={props.editPathRoot} config={props.config} pageSeoMeta={props.pageSeoMeta} bulkCalls={props.bulkCalls} /> :
    <MainPage content={props.content as SiteContent} editPathRoot={props.editPathRoot} config={props.config} pageSeoMeta={props.pageSeoMeta} bulkCalls={props.bulkCalls} />;
};

export default TemplatePage;
