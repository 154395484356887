import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { validate } from 'uuid';

import { CtaType, LogoSizeOption } from 'src/apollo/sites';
import useElementInBounds from 'src/lib/js/hooks/useElementInBounds';
import useScrollDirection from 'src/lib/js/hooks/useScrollDirection';
import { useSpotlightContext } from 'src/public/components/default_template/spotlight/SpotlightContext';
import { Channel } from 'src/public/js/siteUtilities';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { CTAData } from 'public/components/default_template/ctas';
import Footer from 'public/components/default_template/footer';
import { useMenuNav } from 'public/components/default_template/menu_nav/MenuNav';
import PageMeta from 'public/components/default_template/meta/PageMeta';
import Nav from 'public/components/default_template/nav/Nav';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import { useSpi } from 'public/components/default_template/online_ordering/checkout/payment/useSpi';
import { CreditCardSurchargeDisclaimer } from 'public/components/default_template/surcharges/CreditCardSurchargeDisclaimer';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';

import { OrderPageContexts } from './OrderPageContexts';

export type OrderPageContentProps = {
  scrollNavRef?: React.Ref<HTMLDivElement>;
  navOpen?: boolean;
  hasHero?: boolean;
  hideHero?: boolean;
  usingSecondaryNav?: boolean;
  menuGuid?: string;
  groupGuid?: string;
  itemGuid?: string;
};

type Params = {
  menuGuid?: string;
  groupGuid?: string;
  itemSlug?: string;
  guid?: string;

}

type Props = {
  channel: Channel,
  children: ((props: OrderPageContentProps) => React.ReactElement) | React.ReactElement
}

const getGuidsFromHash = (hash?: string): { menuGuid?: string; groupGuid?: string; } => {
  if(hash) {
    // anchored deeplinks are two guids mashed together, one after another,
    // each prepended with a single 'd' character. We remove the leading '#',
    // then split them apart
    const menuGuid = hash.slice(2, hash.length / 2 + 1);
    const groupGuid = hash.slice(hash.length / 2 + 2);
    return {
      menuGuid: validate(menuGuid) ? menuGuid : undefined,
      groupGuid: validate(groupGuid) ? groupGuid : undefined
    };
  }

  return {};
};

const OrderPageWrapper = ({ params, children }: { params: Params } & Props) => {
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant } = useRestaurant();
  const { scrollDirection } = useScrollDirection();
  const cartPopoverContext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const [scrollNavRef, inBounds] = useElementInBounds<HTMLDivElement>({ maxScrollY: 1 });
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const { surcharges } = useCart();
  const { spiSurchargingEnabled } = useSpi();
  const { isHidden } = useSpotlightContext();

  useEffect(() => {
    require('smoothscroll-polyfill').polyfill();
  }, []);

  const { menus, loading: loadingMenus } = useOOMenus({});

  const { refMenuPages } = useMenuNav({ menus, page: '/order' });

  const { title, description } = useMemo(() => {
    const formattedMenuGroups = refMenuPages.slice(0, 3)
      .flatMap(menu => menu.groups)
      .slice(0, 3)
      .map(group => group.name)
      .join(', ');
      // Source the location-specific page seo meta override if it exists
    const title = selectedLocation?.orderPageConfigOverride?.seoMeta?.title || siteRestaurant.config.ooConfig?.seoMeta?.title || 'Order Online';
    const description = selectedLocation?.orderPageConfigOverride?.seoMeta?.description || siteRestaurant.config.ooConfig?.seoMeta?.description ||
    `Order online from ${selectedLocation.name || restaurant?.name}, including ${formattedMenuGroups}. Get the best prices and service by ordering direct!`;
    return { title, description };
  }, [refMenuPages,
    restaurant?.name,
    selectedLocation.name,
    selectedLocation?.orderPageConfigOverride?.seoMeta?.description,
    siteRestaurant.config.ooConfig?.seoMeta?.description,
    selectedLocation?.orderPageConfigOverride?.seoMeta?.title,
    siteRestaurant.config.ooConfig?.seoMeta?.title]);

  if(!menus && loadingMenus) {
    return <LoadingSpinnerOverlay />;
  }
  if(!restaurant?.shortUrl) {
    return <NoMatch404 meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} />;
  }

  const { meta, content, theme } = siteRestaurant;
  if(!content || !refMenuPages) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const usesOOBasic = siteRestaurant?.config?.hasFullCustomization === false; // only a value of 'false' indicates OO Basic tier; true, null, and undefined indicate OO Pro

  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;
  const giftCardCTA: Omit<CTAData, 'submenus'> = {
    type: CtaType.Link,
    text: 'Buy Gift Cards',
    link: restaurant.giftCardLinks.purchaseLink
  };
  const newNonPrimaryCtas = restaurant.giftCardLinks.purchaseLink && usesOOBasic ? [...nonPrimaryCtas || [], giftCardCTA] : nonPrimaryCtas;
  const hasHero = !!siteRestaurant.config.ooConfig?.heroImage?.src;
  const usingSecondaryNav = inBounds;
  const navOpen = (scrollDirection === 'up' && !isMobile || cartPopoverContext?.isOpen) && usingSecondaryNav;
  const spotlightBanner = content.spotlightBanner;
  const spotlightBannerAppliedToOrderPage = (spotlightBanner?.applicablePages?.filter(page => page === '/order' || page === '/online') ?? []).length > 0;
  const hasNavSpotlight = !isHidden && !!spotlightBanner?.enabled && spotlightBanner?.fixed === true && spotlightBannerAppliedToOrderPage;
  return (
    <>
      <PageMeta title={title} description={description} />
      <div className="defaultTemplate">
        <Nav
          withShadow={!usingSecondaryNav}
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          logoSize={LogoSizeOption.Standard}
          navType="stickyNav"
          primaryCta={primaryCta}
          nonPrimaryCtas={newNonPrimaryCtas}
          forceDisplayToastLogin
          withCart
          withOOOptions
          className={classnames({ collapsed: !navOpen && usingSecondaryNav, withNavSpotlight: hasNavSpotlight })}
          shouldShowPreviewBanner={false} />
        {typeof children === 'function'
          ? children({ scrollNavRef, navOpen, hasHero, usingSecondaryNav, menuGuid: params.menuGuid, groupGuid: params.groupGuid, itemGuid: params.itemSlug?.split('_')?.[1] ?? params.guid })
          : children}
        <Footer>
          <CreditCardSurchargeDisclaimer className="headerRule" spiSurchargingEnabled={spiSurchargingEnabled} surcharges={surcharges} />
        </Footer>
      </div>
    </>
  );
};

const ParamDetectingOrderPage = (props: Props) => {
  const params = useParams<Params>();
  const { hash } = useLocation();
  const anchorGuids = getGuidsFromHash(hash);
  return (
    <MemoizedOrderPageWrapper
      {...props}
      params={{ menuGuid: params.menuGuid || anchorGuids.menuGuid, groupGuid: anchorGuids.groupGuid, itemSlug: params.itemSlug, guid: params.guid }} />
  );
};

const MemoizedOrderPageWrapper = React.memo(OrderPageWrapper);

export const ContextualOrderPageWrapper = (props: Props) => {
  const { isEditor } = useEditor();

  return (
    <OrderPageContexts channel={props.channel}>
      {isEditor ? <MemoizedOrderPageWrapper params={{}} {...props} /> : <ParamDetectingOrderPage {...props} /> }
    </OrderPageContexts>
  );
};

export default React.memo(ContextualOrderPageWrapper);
