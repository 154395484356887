import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

import { useEditor } from '@toasttab/sites-components';


import Snippets from './Snippets';

interface TagManagerScriptProps {
  gtmId: string;
}

const TagManagerScript = ({ gtmId }: TagManagerScriptProps) => {
  const { isEditor } = useEditor();
  const tags = useMemo(() => {
    if(!gtmId) {
      return null;
    }
    return Snippets.tags({ id: gtmId, dataLayer: [], dataLayerName: 'dataLayer', events: {}, preview: '', auth: '' });
  }, [gtmId]);
  if(isEditor || !tags?.script) {
    return null;
  }
  return (
    <Helmet prioritizeSeoTags={true} script={[{ type: 'text/javascript', innerHTML: tags.script }]} key={gtmId}>
    </Helmet>
  );
};

export const TagManagerNoScript = ({ gtmId }: TagManagerScriptProps) => {
  const tags = useMemo(() => {
    if(!gtmId) {
      return null;
    }
    return Snippets.tags({ id: gtmId, dataLayer: [], dataLayerName: 'dataLayer', events: {}, preview: '', auth: '' });
  }, [gtmId]);
  if(!tags?.iframe) {
    return null;
  }
  return (
    <Helmet>
      <noscript
        dangerouslySetInnerHTML={{ __html: tags.iframe }} />
    </Helmet>
  );
};

const TagManager = (props: TagManagerScriptProps) => {
  return (
    <>
      <TagManagerScript {...props} />
      <TagManagerNoScript {...props} />
    </>
  );
};

export default TagManager;
