import React, { useMemo } from 'react';

import { SwiperSlide } from 'swiper/react';

import { TemplateOverrides } from 'public/components/default_template/menu_section/menuStylingOverrides';

import ItemCard from './ItemCard';
import { useReorder } from './ReorderContext';
import SeeMoreCard from './SeeMoreCard';
import { useReorderableItemsQuery } from './useReorderableItemsQueryWrapper';

/**
 * Maximum number of slides to display, including "See More".
 */
const MAX_SLIDES = 6;

interface Props {
  templateOverrides?: TemplateOverrides;
}

export const useItemSlides = ({ templateOverrides }: Props = {}) => {
  const { onHistoryOpen } = useReorder();
  const { data } = useReorderableItemsQuery();

  return useMemo(() => {
    const hasNextPage = Boolean(data?.reorderableItems.nextPageToken);
    let items = data?.reorderableItems.items ?? [];
    let includeSeeMore = false;

    if(items.length > MAX_SLIDES || items.length == MAX_SLIDES && hasNextPage) {
      // "See More" replaces the last slide.
      items = items.slice(0, MAX_SLIDES - 1);
      includeSeeMore = true;
    } else if(hasNextPage) {
      // The current page could have fewer items than MAX_SLIDES but there may be more pages.
      includeSeeMore = true;
    }

    const slides = items.map(item =>
      <SwiperSlide key={item.selection.guid}>
        <ItemCard item={item} templateOverrides={templateOverrides} />
      </SwiperSlide>);

    if(includeSeeMore) {
      slides.push(
        <SwiperSlide key="seeMore">
          <SeeMoreCard onClick={onHistoryOpen} testId="see-more" />
        </SwiperSlide>
      );
    }

    return slides;
  }, [data?.reorderableItems.items, data?.reorderableItems.nextPageToken, onHistoryOpen, templateOverrides]);
};
