import React, { createContext, Fragment, useCallback, useContext, useEffect } from 'react';

import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion } from 'framer-motion';

import ErrorBoundary from 'shared/components/common/error_boundary/ErrorBoundary';

import Portal from './Portal';

type ModalContextType = {
  onClose: () => void;
  preventOverlayClose?: boolean;
}

type OverlayProps = {
  fadeIn?: boolean;
  fadeOut?: boolean;
  opacity?: number;
  color?: string;
}

type Props = {
  // Prevents clicking outside the modal from closing it
  preventOverlayClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  testId?: string;
  animateClose?: boolean;
}

type ContentProps = {
  wrapperClassName?: string;
  contentClassName?: string;
  slideIn?: boolean;
  slideOut?: boolean;
  fadeIn?: boolean;
  style?: React.CSSProperties | undefined;
  ariaLabelledBy?: string;
  disableFocusTrap?: boolean;
}

const ModalContext = createContext<ModalContextType>({ onClose: () => { } });

export const ModalOverlay = ({ fadeIn, fadeOut, opacity, color }: OverlayProps) => {
  const { onClose, preventOverlayClose } = useModalContext();
  const isClient = typeof window !== 'undefined';

  const onOverlayClick = useCallback(() => {
    if(!preventOverlayClose) {
      onClose();
    }
  }, [preventOverlayClose, onClose]);

  const targetOpacity = typeof opacity === 'number' ? opacity / 100 : undefined;

  return (
    <motion.div
      initial={fadeIn && isClient ? { opacity: 0 } : undefined}
      animate={fadeIn ? { opacity: targetOpacity || 1 } : undefined}
      exit={fadeIn || fadeOut ? { opacity: 0 } : undefined}
      transition={{ duration: 0.2 }}
      onClick={onOverlayClick}
      style={{ opacity: targetOpacity, backgroundColor: color }}
      className="modalOverlay" />
  );
};

// A version of ModalContent that will add a scrollable class if the content is taller than the window
export const ScrollableModalContent = (props: React.PropsWithChildren<ContentProps>) => {
  useEffect(() => {
    const el = document.querySelector(`.${props.contentClassName}`);
    if(!el) {
      return;
    }
    const modalWrapper = document.querySelector('.modalWrapper');
    const padding = modalWrapper ? parseInt(window.getComputedStyle(modalWrapper).paddingTop) : 0;
    // top-padding + bottom-padding + content height
    const height = padding * 2 + el.scrollHeight;
    if(window.innerHeight < height) {
      if(!el.classList.contains('scrollable')) {
        el.classList.add('scrollable');
      }
    } else {
      if(el.classList.contains('scrollable')) {
        el.classList.remove('scrollable');
      }
    }
  });
  return <ModalContent {...props} />;
};

export const ModalContent = (props: React.PropsWithChildren<ContentProps>) => {
  const isClient = typeof window !== 'undefined';

  const Wrapper = useCallback(({ children }: React.PropsWithChildren<{}>) =>
    props.disableFocusTrap ? <>{children}</> : <FocusTrap focusTrapOptions={{ allowOutsideClick: true, initialFocus: '#modal-content' }}>{children}</FocusTrap>, [props.disableFocusTrap]);

  return (
    <ErrorBoundary>
      <Wrapper>
        <div className={classnames('modalWrapper', props.wrapperClassName)} role="dialog" aria-modal="true" aria-labelledby={props.ariaLabelledBy}>
          <motion.div
            initial={props.slideIn && isClient ? { translateX: '100vw' } : props.fadeIn && isClient ? { opacity: 0 } : undefined}
            animate={props.slideIn ? { translateX: '0vw' } : props.fadeIn ? { opacity: 1 } : undefined}
            exit={props.slideIn || props.slideOut ? { translateX: '100vw' } : props.fadeIn ? { opacity: 0 } : undefined}
            transition={{ duration: props.slideIn ? 0.5 : 0.2 }}
            className={classnames('modalContent', props.contentClassName)}
            style={props.style}
            data-testid="modal-content"
            id="modal-content">
            {props.children}
          </motion.div>
        </div>
      </Wrapper>
    </ErrorBoundary>
  );
};

type ModalCloseButtonProps = {
  className?: string
  onClose?: () => void
}

export const ModalCloseButton = ({ className, onClose }: ModalCloseButtonProps) => {
  const { onClose: defaultClose } = useModalContext();

  return (
    <button data-testid="modal-close-button" aria-label="Close" type="button" className={classnames('closeButton', className)} onClick={onClose ?? defaultClose} >
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'block' }} width="15" height="15" viewBox="0 0 15 15" fill="none">
        <path d="M1 0.875L14 13.875M14 0.875L1 13.875" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </button>
  );
};

export const Modal = (props: React.PropsWithChildren<Props>) => {
  const { onClose, preventOverlayClose, animateClose = true, isOpen } = props;

  const handleEscape = useCallback((e: KeyboardEvent) => {
    if(e.key === 'Escape' && !preventOverlayClose) {
      onClose();
    }
  }, [onClose, preventOverlayClose]);

  useEffect(() => {
    if(isOpen) {
      document.addEventListener('keydown', handleEscape);
    }
    return () => {
      if(isOpen) {
        document.removeEventListener('keydown', handleEscape);
      }
    };
  }, [handleEscape, isOpen]);

  const Wrapper = animateClose ? AnimatePresence : Fragment;
  return (
    <ModalContext.Provider value={{
      onClose: props.onClose,
      preventOverlayClose: props.preventOverlayClose
    }}>
      <Wrapper>
        {props.isOpen &&
          <Portal>
            <div className={classnames('modal', props.className)} data-testid={props.testId}>
              {props.children}
            </div>
          </Portal>}
      </Wrapper>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
