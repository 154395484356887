import React, { useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';

import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { CartContextProvider } from 'src/public/components/online_ordering/CartContext';
import { LoyaltyContextProvider } from 'src/public/components/online_ordering/LoyaltyContext';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { usePageViewed } from 'src/shared/js/hooks/usePageViewed';
import { asValidPhoneCountryCode } from 'src/shared/js/phoneUtils';

import Image from 'shared/components/common/Image';
import PhoneLink from 'shared/components/common/link/PhoneLink';

import { LoyaltyCard, LoyaltyCardType } from 'public/components/default_template/offers/LoyaltyCard';
import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import { calculateDiscountsTotal } from 'public/components/default_template/online_ordering/cart/cartUtils';
import OrderItems, { ECOMM_ITEM_NAME, OrderPrices } from 'public/components/default_template/online_ordering/order/OrderItems';
import { CreditCardSurchargeDisclaimerBase } from 'public/components/default_template/surcharges/CreditCardSurchargeDisclaimer';
import { useCompletedOrder } from 'public/components/online_ordering/OrderContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';
import { calculateSubtotal } from 'public/components/online_ordering/types';

import { PackagingSelections } from './PackagingSelections/PackagingSelections';

const EcommOrderConfirmation = () => {
  const { completedOrder, loadingCompletedOrder } = useCompletedOrder();
  const { search } = useLocation();
  const orderTotal = completedOrder ? completedOrder.totalV2 - (completedOrder.giftCard?.appliedBalance || 0) : 0;
  const subtotal = useMemo(() => calculateSubtotal(completedOrder), [completedOrder]);
  const { ooRestaurant } = useRestaurant();
  const i18nCountryCode = asValidPhoneCountryCode(ooRestaurant?.i18n?.country);
  const resetReferrerOnPageLoad = (searchParams: URLSearchParams) => {
    const referrer = searchParams.get('source');
    if(referrer != '') {
      searchParams.set('source', '');
    }
  };

  const resetReferrer = useCallback(() => {
    if(completedOrder) {
      const searchParams = new URLSearchParams(search);
      // If we are referred via email, we don't want subsequent
      // refreshes of the page to track as email referrers
      resetReferrerOnPageLoad(searchParams);
    }
  }, [completedOrder, search]);

  usePageViewed(resetReferrer);

  if(loadingCompletedOrder) {
    return (
      <div className="orderConfirmation checkoutForm">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if(!completedOrder) {
    return <EmptyCart />;
  }

  const getRxName = () => {
    // using the white label name keeps the confirmation page consistent with the checkout page
    return ooRestaurant?.whiteLabelName || completedOrder.restaurant.name;
  };

  return (
    <div className="orderConfirmation checkoutForm">
      <section className="checkoutSection currentSection flat">
        <h2 className="checkoutSectionHeader">
          <div>Thanks for your order{completedOrder.customerV3?.firstName && `, ${completedOrder.customerV3?.firstName}`}!</div>
          <div className="checkNumber">Order #{completedOrder.checkNumber}</div>
        </h2>
        <div className="fixedSection">
          <div>An email receipt has been sent to {completedOrder.customerV3?.email || 'you'}</div>
          {completedOrder.restaurant.loyaltyConfig?.loyaltySignupEnabled ?
            <CartContextProvider>
              <LoyaltyContextProvider>
                <div className="loyaltySection" data-testid="loyalty-section">
                  <LoyaltyCard type={LoyaltyCardType.OrderConfirmationPage} completedOrderBehavior={completedOrder.diningOptionBehavior} />
                </div>
              </LoyaltyContextProvider>
            </CartContextProvider>
            : <hr />}
          <div>
            <h2 className="subHeader rxName">{getRxName()}</h2>
            <PhoneLink className="linkText phone" phoneNumber={completedOrder.restaurant.location.phone} countryCode={i18nCountryCode} />
            <br />
            <br />
            <div className="sectionRows">
              <div className={classnames('sectionRow', completedOrder.guestCommunication && 'noMargin')}>
                <div className="icon">
                  <Image alt="Order type" src={completedOrder.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'icons/delivery.svg' : 'icons/pickup.svg'} />
                </div>
                Delivery{completedOrder.deliveryInfo ? ` to ${formatDeliveryAddress(completedOrder.deliveryInfo, true)}` : ''}
              </div>
            </div>
            <h2 className="subHeader">Order summary</h2>
            <div className="cartOrder">
              <div className="cartData">
                <OrderItems loading={loadingCompletedOrder} selections={completedOrder.selections} />
                {Boolean(completedOrder.packagingSelections.length) &&
                <PackagingSelections packaging={completedOrder.packagingSelections || []} />}
                <OrderPrices
                  loading={loadingCompletedOrder}
                  subtotal={subtotal}
                  discountsTotal={calculateDiscountsTotal(completedOrder?.discounts?.globalReward?.amount || 0, completedOrder.discountsTotal)}
                  diningOptionBehavior={completedOrder.diningOptionBehavior}
                  deliveryFee={completedOrder.deliveryServiceCharges}
                  gratuityServiceCharges={completedOrder.gratuityServiceCharges}
                  processingServiceCharges={completedOrder.processingServiceCharges}
                  nonDeliveryNonGratuityNonUbpServiceCharges={completedOrder.nonDeliveryNonGratuityNonUbpServiceCharges}
                  tax={completedOrder.taxV2}
                  tip={completedOrder.tip}
                  fundraisingAmount={completedOrder.fundraisingTotal}
                  giftCardAppliedAmount={completedOrder.giftCard?.appliedBalance}
                  surchargeAmount={completedOrder.surchargeServiceCharges.baseAmount}
                  surchargeTaxAmount={completedOrder.surchargeServiceCharges.taxAmount}
                  shipping={completedOrder.selections.find(selection => selection.name === ECOMM_ITEM_NAME)?.price}
                  enableTooltip={false} />
                <div className="prices">
                  <div className="cart-flex-row">
                    <div className="totalPrice">Total</div>
                    <div className="totalPrice"><FormattedPrice value={orderTotal} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            completedOrder.surchargeServiceCharges.baseAmount > 0 &&
              <CreditCardSurchargeDisclaimerBase
                className="confirmPageRule"
                surchargeAmount={completedOrder.surchargeServiceCharges.baseAmount + completedOrder.surchargeServiceCharges.taxAmount}
                surchargeRatePercent={completedOrder.surchargeServiceCharges.rate} />
          }
        </div>
      </section>
    </div>
  );
};

export default EcommOrderConfirmation;
