import React, { useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import partition from 'lodash/partition';

import { useModifierContext } from 'public/components/online_ordering/ModifierContext';
import { ModifierGroup, Modifier } from 'public/components/online_ordering/types';

import SelectionRow from './SelectionRow';

type ListProps = {
  group?: ModifierGroup;
  firstModifierGroup?: boolean;
}

const SelectionList = ({ group, firstModifierGroup }: ListProps) => {
  const { isInvalid, registerModifierGroup, getGroupSelectionsCount } = useModifierContext();


  const ariaAlertText = useMemo(() => {
    if(group) {
      const { guid, name, minSelections, maxSelections } = group;
      if(isInvalid(guid)) {
        const currentSelectionsCount = getGroupSelectionsCount(guid);
        if(minSelections && maxSelections) {
          return `Please select between ${minSelections} and ${maxSelections} for ${name}. You currently have ${currentSelectionsCount} selected.`;
        } else if(minSelections && currentSelectionsCount) {
          return `Please select at least ${minSelections} for ${name}. You currently have ${currentSelectionsCount} selected.`;
        }
        return `Please make a selection for ${name}.`;
      }
    }
    return '';
  }, [getGroupSelectionsCount, group, isInvalid]);

  if(!group) {
    return null;
  }

  const { guid, name, modifiers, minSelections, maxSelections } = group;

  const groups = modifiers ? partition(modifiers, modifier => modifier.modifierGroups?.length) : null;
  const groupsWithSubgroups = groups?.[0] ?? [];
  const groupsWithoutSubgroups = groups?.[1] ?? [];

  const inputType = typeof minSelections === 'number' && typeof maxSelections === 'number'
  && minSelections === maxSelections && maxSelections === 1
    ? 'radio'
    : 'checkbox';

  const label = `menu-group-${guid}`;
  const required = Boolean(minSelections && minSelections > 0);

  return (
    <div
      className="modifierGroup"
      {...registerModifierGroup(guid)}
      aria-labelledby={label}
      aria-required={required}
      role="group"
      data-testid="selection-list"
      tabIndex={0}>
      <div className="title">
        <div>
          <div className="modifierGroupTitle" id={`menu-group-${guid}`}>
            {name || <Skeleton />}
            {/* When this group is missing a required modifier, this text is read to the screen reader with the title */}
            <div className="ariaAlert" data-testid="screen-reader-alert">
              {ariaAlertText}
            </div>
          </div>
          {required &&
          <>
            <div className={classnames('required', { highlight: isInvalid(guid) })}>Required*</div>
          </>}
          <div className="modifierGroupSubtitle">
            {maxSelections && `Please select ${minSelections === maxSelections ? '' : minSelections ? `${minSelections} to ` : 'up to '}${maxSelections}`}
          </div>
        </div>
      </div>
      <div className="selections" role={inputType === 'radio' ? 'radiogroup' : 'group'} data-testid="selections">
        {groups ?
          <>
            {groupsWithSubgroups.length > 0 && <Selections groups={groupsWithSubgroups} group={group} hasSubgroups={true} inputType={inputType} firstModifierGroup={firstModifierGroup} />}
            {groupsWithoutSubgroups.length > 0 &&
                <Selections groups={groupsWithoutSubgroups} group={group} hasSubgroups={false} inputType={inputType} firstModifierGroup={groupsWithSubgroups.length === 0 && firstModifierGroup} />}
          </>
          : new Array(10).fill(0)
            .map((_, index) =>
              <div className="row skeleton" key={`skeleton${index}`}>
                <Skeleton width="100%" />
              </div>)}
      </div>
    </div>
  );
};

const Selections = ({
  groups,
  group,
  hasSubgroups,
  inputType,
  firstModifierGroup
}: {
  groups: Modifier[],
  group: ModifierGroup,
  hasSubgroups: boolean,
  inputType: 'radio' | 'checkbox',
  firstModifierGroup?: boolean
}) => {
  const { displayedModifier } = useModifierContext();

  const { guid, maxSelections } = group;

  const groupSelections = displayedModifier?.modifierGroups[guid];
  const selectedCount = Object.values(groupSelections ?? {})
    .map(mod => mod.quantity)
    .reduce((curr, q) => curr + q, 0);
  const isModifierDisabled = useCallback((modifier: Modifier) => {
    return !!modifier.outOfStock || !groupSelections?.[modifier.itemGuid] && inputType === 'checkbox' && !!maxSelections && selectedCount >= maxSelections;
  }, [groupSelections, inputType, maxSelections, selectedCount]);
  const firstNonDisabledSelectionIndex = groups.findIndex(modifier => !isModifierDisabled(modifier));

  return (
    <div className={hasSubgroups ? 'parentSelections' : 'toggles'} role="group">
      {groups.map((modifier: Modifier, index) =>
        <SelectionRow
          key={modifier.itemGuid}
          modifier={modifier}
          inputType={inputType}
          groupGuid={guid}
          autoFocus={index === firstNonDisabledSelectionIndex && firstModifierGroup}
          canAddMore={!maxSelections || selectedCount < maxSelections}
          disabled={isModifierDisabled(modifier)} />)}
    </div>
  );
};

export default SelectionList;
