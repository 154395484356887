import React, { useCallback, useEffect } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';

import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import { BookingStatus } from 'src/apollo/onlineOrdering';
import { useMakeReservationMutation, MakeReservationRequest, ReservationGuest, DepositRequest, ButtonType } from 'src/apollo/sites';
import InputField from 'src/shared/components/common/form_input/InputField';

import Button from 'shared/components/common/button';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import DepositMessage from './DepositMessage';
import OccasionSelector from './OccasionSelector';
import ReservationOverview from './ReservationOverview';
import { Deposit, SeatingLocation, getSelectedDateTime } from './reservationUtils';

export const isCalifornia = (rxState?: string | null) => rxState?.toString().trim()
  .toLowerCase() === 'ca';

export type ReservationFormData = {
  yourInfoFirstName: string;
  yourInfoLastName: string;
  yourInfoEmail: string;
  yourInfoPhone: string;
  occasion: string;
  specialRequests: string;
};

type Props = {
  restaurantGuid: string;
  numGuests: number;
  serviceArea: SeatingLocation | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  deposit: Deposit | null;
  changeSelection: any;
  cancellationPolicy?: string | null;
  timezone: string;
  setSelectedDate: (arg0: Date) => void;
  setSelectedTime: (arg0: Date) => void;
  setSelectedSeatingLocation: (arg0: SeatingLocation) => void;
  setNumGuests: (arg0: number) => void;
  setReservationGuid: (arg0: string) => void;
  onResCreated: (arg0: boolean) => void;
  onResError: (arg0: string) => void;
  setIsLoading: (arg0: boolean) => void;
  setSubmittedOccasion: (arg0: string) => void;
  setSubmittedEmail: (arg0: string) => void;
  restaurantLocationState?: string | null
};
const SUCCESSFUL_BOOKING_STATUSES = [BookingStatus.RCreated.valueOf(), BookingStatus.RConfirmed.valueOf()];

const ReservationForm = ({
  restaurantGuid, numGuests, serviceArea, selectedDate, selectedTime, deposit, changeSelection, cancellationPolicy, timezone,
  setSelectedDate, setSelectedTime, setSelectedSeatingLocation, setNumGuests, setReservationGuid, onResCreated, onResError, setIsLoading,
  setSubmittedOccasion, setSubmittedEmail, restaurantLocationState
}: Props) => {
  const [submitForm, { data, error }] = useMakeReservationMutation();
  const { customer } = useCustomer();

  useEffect(() => {
    if(!selectedDate || !selectedTime) {
      return;
    }
    // Check to see if everything matches.
    let somethingHasChanged = false;
    const expectedStartTime = data?.makeReservation?.expectedStartTime;
    let timeDiff = expectedStartTime ? Math.abs(new Date(expectedStartTime).getTime() - getSelectedDateTime(selectedDate, selectedTime).getTime()) : 0;
    if(expectedStartTime && timeDiff > 0) {
      somethingHasChanged = true;
      setSelectedDate(new Date(expectedStartTime));
      setSelectedTime(new Date(expectedStartTime));
    }
    let bookedDiningArea = data?.makeReservation?.serviceAreaGroup?.name;
    if(!bookedDiningArea) {
      // Deprecated way of getting area name
      bookedDiningArea = data?.makeReservation?.externalServiceAreas?.length ? data.makeReservation.externalServiceAreas[0] : '';
    }
    if(bookedDiningArea && bookedDiningArea !== serviceArea?.name) {
      somethingHasChanged = true;
      setSelectedSeatingLocation({ name: bookedDiningArea } as SeatingLocation);
    }
    const bookedNumGuests = data?.makeReservation?.partySize ? parseInt(data?.makeReservation?.partySize) : null;
    if(bookedNumGuests && bookedNumGuests !== numGuests) {
      somethingHasChanged = true;
      setNumGuests(bookedNumGuests);
    }

    const bookedOccasion = data?.makeReservation?.specialOccasion;
    if(bookedOccasion && bookedOccasion !== 'NONE') {
      somethingHasChanged = true;
      setSubmittedOccasion(bookedOccasion.charAt(0) + bookedOccasion.slice(1).toLowerCase());
    }

    setReservationGuid(data?.makeReservation?.reservationGuid || '');

    if(SUCCESSFUL_BOOKING_STATUSES.includes(data?.makeReservation?.bookingStatus || '')) {
      onResCreated(somethingHasChanged);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onResError(error?.toString() || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = useCallback(async (reservationFormData: ReservationFormData) => {
    if(!selectedDate || !selectedTime) {
      return;
    }
    setIsLoading(true);
    const depositInfo: DepositRequest = {
      depositAmount: deposit?.actualAmount ?? 0,
      depositBookableConfigGuid: deposit?.guid ?? ''
    };
    const guestInfo: ReservationGuest = {
      firstName: reservationFormData.yourInfoFirstName,
      lastName: reservationFormData.yourInfoLastName,
      email: reservationFormData.yourInfoEmail,
      phoneNumber: reservationFormData.yourInfoPhone
    };

    let requestParams: MakeReservationRequest;
    if(deposit === null) {
      requestParams = {
        partySize: numGuests,
        dateTime: getSelectedDateTime(selectedDate, selectedTime).toISOString(),
        specialOccasion: reservationFormData.occasion ? reservationFormData.occasion.toUpperCase() : 'NONE',
        bookingNotes: reservationFormData.specialRequests,
        publicServiceAreaName: serviceArea?.name,
        // Empty string required, otherwise request will fail.
        externalServiceArea: '',
        requestedServiceAreaGroups: serviceArea?.guid ? [serviceArea?.guid] : undefined,
        guest: guestInfo
      };
    } else {
      requestParams = {
        partySize: numGuests,
        dateTime: getSelectedDateTime(selectedDate, selectedTime).toISOString(),
        deposit: depositInfo,
        specialOccasion: reservationFormData.occasion ? reservationFormData.occasion.toUpperCase() : 'NONE',
        bookingNotes: reservationFormData.specialRequests,
        publicServiceAreaName: serviceArea?.name,
        // Empty string required, otherwise request will fail.
        externalServiceArea: '',
        requestedServiceAreaGroups: serviceArea?.guid ? [serviceArea?.guid] : undefined,
        guest: guestInfo
      };
    }

    setSubmittedEmail(reservationFormData.yourInfoEmail);

    submitForm({
      variables: {
        restaurantGuid: restaurantGuid,
        reservationDetails: requestParams
      }
    });
  }, [restaurantGuid, numGuests, selectedDate, selectedTime, serviceArea, submitForm, setIsLoading, deposit, setSubmittedEmail]);

  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: {
      yourInfoPhone: customer?.phone || '',
      yourInfoEmail: customer?.email || '',
      yourInfoFirstName: customer?.firstName || '',
      yourInfoLastName: customer?.lastName || '',
      occasion: '',
      specialRequests: ''
    }
  });
  const { handleSubmit, setValue, formState } = formMethods;
  const occasion = useWatch({ name: 'occasion', control: formMethods.control });
  const validatePhone = useCallback((value: string) => !isValidPhoneNumber(value, 'US') ? 'Please enter a valid phone number' : true, []);

  return (
    <div data-testid="reservation-form">
      <div className={classnames('currentSelection')}>
        <ReservationOverview numGuests={numGuests} selectedDate={selectedDate} selectedTime={selectedTime} serviceArea={serviceArea} timezone={timezone} />
      </div>
      {deposit &&
        <DepositMessage
          deposit={{
            depositPolicy: deposit.depositPolicy,
            strategyType: deposit.strategyType,
            amountPerGuest: deposit.amountPerGuest,
            actualAmount: deposit.actualAmount
          }}
          numGuests={numGuests}
          serviceArea={serviceArea?.name} /> }
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {formMethods.formState.isSubmitting && <LoadingSpinnerOverlay withBorderRadius />}
          <div className={classnames('guestForm', { withDeposit: deposit })}>
            <InputField id="yourInfoFirstName" type="text" autoComplete="given-name" label="First name" defaultValue={customer?.firstName || ''}
              required />
            <InputField id="yourInfoLastName" type="text" autoComplete="family-name" label="Last name" defaultValue={customer?.lastName || ''}
              required />
            <PhoneInput id="yourInfoPhone" required label="Phone number" validate={validatePhone} autoComplete="tel" defaultValue={customer?.phone || ''}
              filled={Boolean(customer?.phone)}
              focusMessage="By providing your phone number, you consent to receive automated text messages from Toast and the restaurant related to your booking and dining experience."
              initialFocus={false} />
            <InputField id="yourInfoEmail" type="email" autoComplete="email" label="Email" defaultValue={customer?.email || ''}
              required validate={value => isEmail(value) || 'enter a valid email address'}
              focusMessage="Your email will be used to message you about your booking. You may also receive marketing messages from Toast and restaurants you visit, unless you unsubscribe." />
            <OccasionSelector formState={formState} occasion={occasion} setOccasion={(value: string) => setValue('occasion', value, { shouldDirty: true })} />
            <InputField id="specialRequests" type="text" label="Special Requests" defaultValue={''} />
            <div className={classnames('policies')}>
              <h3 className="cancellationTitleSection">Reservation Policy</h3>
              {cancellationPolicy ?
                <div className="cancellationPolicy">
                  {cancellationPolicy}
                </div>
                : null}
              <div className="disclaimer">
                By clicking &ldquo;Complete booking&rdquo;, you agree to Toast&rsquo;s{' '}
                <a
                  href="https://pos.toasttab.com/terms-of-service"
                  rel="noopener noreferrer"
                  target="_blank">
                  Guest Terms of Service
                </a>{' '}
                and acknowledge that your information will be processed pursuant to Toast&rsquo;s{' '}
                <a
                  href="https://pos.toasttab.com/privacy"
                  rel="noopener noreferrer"
                  target="_blank">
                  Privacy Statement.
                </a>{' '}
                {isCalifornia(restaurantLocationState) &&
                  <>
                    Additional info for California residents available{' '}
                    <a data-testid="ca-addendum-link" href="https://pos.toasttab.com/privacy#addendum-a" rel="noreferrer noopener" target="_blank">here.</a>{' '}
                  </>}
              </div>
            </div>
            <span className="findTableButton">
              <div className="backButton" onClick={changeSelection}>
                <ChevronLeftIcon />
                <button type="button" className="changeSelection">Back</button>
              </div>
              <Button variant={ButtonType.Primary} onClick={handleSubmit(onSubmit)}>{deposit ? 'Continue to payment' : 'Complete Booking'}</Button>
            </span>

          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default ReservationForm;
