import React, { useState } from 'react';

import { AddCircleIcon } from '@toasttab/buffet-pui-icons';

import { ButtonType } from 'src/apollo/sites';

import Button from 'shared/components/common/button';

import { useDelivery } from 'public/components/online_ordering/DeliveryContext';

export const Address2Input = () => {
  const { address2, setAddress2 } = useDelivery();
  const [enabled, setEnabled] = useState(false);
  const placeholderText = 'Add an apartment or suite number';
  return (
    <div className="address2Input">
      {enabled || !!address2 ?
        <>
          <div className="prompt">Apartment/Suite</div>
          <div className="addressInput ">
            <input
              className="input"
              data-testid="address2InputField"
              style={{ padding: '4px 12px 4px 24px' }}
              type="text"
              placeholder={placeholderText}
              value={address2}
              onChange={e => { setAddress2(e.target.value); console.log('hello', e.target.value); }}>
            </input>
          </div>
        </>
        :
        <Button
          variant={ButtonType.Text}
          icon={<AddCircleIcon accessibility="decorative" className="addCircleIcon" />}
          style={{ padding: '16px 0px 0px 0px', fontSize: '16px', color: '#252525' }}
          data-testid="addAddress2Input"
          onClick={() => setEnabled(true)}>
          {placeholderText}
        </Button>}
    </div>
  );
};
