import React from 'react';

import Image from 'src/shared/components/common/Image';

import { resources } from 'config';

import { AppPromoData } from './useAppPromo';

type Props = {
  appPromoData: AppPromoData
}

export const AppPromo = (props: Props) => {
  if(!props.appPromoData.link) {
    return null;
  }

  return (
    <div className="appPromo">
      <h3>Order like a regular on the {props.appPromoData.appName} app!</h3>
      <div className="promoContent">
        <div className="leftContent">
          {props.appPromoData.appLogo ? <Image className="appIcon" src={props.appPromoData.appLogo} alt={`${props.appPromoData.appName} app logo`} cdnHost={resources.imagePrefix} /> : null}
          <p>{props.appPromoData.appName}</p>
        </div>
        <a className="downloadButton" href={props.appPromoData.link} target="_blank" rel="noopener noreferrer">Download</a>
      </div>
    </div>
  );
};
