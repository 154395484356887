// To work with a Consent Management Platform (e.g. OneTrust), the default consent states are set here.
// Consent is denied for visitors outside of the US, which will be shown the OneTrust banner.
// Set default consent states: https://developers.google.com/tag-platform/security/guides/consent
export const formatToGoogleAnalytics = (tagId: string) => {
  if(tagId === '') {
    return '';
  }

  return `
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=${tagId}"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${tagId}');
    
    gtag('consent', 'default', {
      wait_for_update: 500,
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied'
    });
  </script>
  `;
};

// The 'text/plain' type is set here to implement 'type re-writing' with the OneTrust cookie banner.
// See: https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?language=en_US#idm2623
// This blocks the script from running until the user's consent is received.
export const formatToFacebook = (pixelId: string) => {
  if(pixelId === '') {
    return '';
  }

  return `
  <!-- Facebook Pixel Code -->
  <script type="text/plain" class="optanon-category-C0002">
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('dataProcessingOptions', ['LDU']);
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');
  </script>
  <noscript>
    <img height="1" width="1" style="display:none" 
         src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
  </noscript>
    <!-- End Facebook Pixel Code -->
  `;
};
