import React from 'react';

import { useEditor } from '@toasttab/sites-components';

import { Block as BlockType, PaddingEnum } from 'src/apollo/sites';


import { DynamicSection } from 'public/components/default_template/dynamic_section/DynamicSection';
import { GRID_GAP } from 'public/components/default_template/dynamic_section/DynamicSectionUtils';

// 50px per cell, height and width
export const DYNAMIC_SECTION_CELL_SIZE = 50;

export const DynamicSectionPopup = ({ popupIndex, dynamicBlock }: { popupIndex?: number, dynamicBlock: BlockType }) => {
  const { isEditor } = useEditor();

  // subtract 1 so that the end of the block and the end of the section align
  const numCols = dynamicBlock.endX - 1;
  // subtract 1 to match the columns
  const numRows = dynamicBlock.endY - 1;

  // 48px from modalWrapper padding
  // 24px from popup padding
  // 50px from popupHeader height
  const maxModalContentWidth = `calc((100% ${isEditor ? '- (24px * 2) ' : ''}- (${numCols} * ${GRID_GAP}px)) / ${numCols}`;
  const maxModalContentHeight = `calc((100vh - (48px * 2) - 24px - 50px - (${numRows} * ${GRID_GAP}px)) / ${numRows}`;
  return (
    <DynamicSection
      isPopup
      blocks={[dynamicBlock]}
      numCols={numCols}
      mobileNumCols={(dynamicBlock.mobileEndX ?? dynamicBlock.endX) - 1}
      numRows={numRows}
      mobileNumRows={(dynamicBlock.mobileEndY ?? dynamicBlock.endY) - 1}
      editPath={`content.popups[${popupIndex}].dynamicSection`}
      padding={PaddingEnum.None}
      cellWidthCSS={`min(${DYNAMIC_SECTION_CELL_SIZE}px, ${maxModalContentWidth})`}
      cellHeightCSS={`min(${DYNAMIC_SECTION_CELL_SIZE}px, ${maxModalContentHeight})`} />
  );
};
