import React, { FC, useCallback, useState } from 'react';

import { SavedAddress, useDeleteAddressMutation } from 'src/apollo/onlineOrdering';
import { reportError } from 'src/lib/js/clientError';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import Image from 'shared/components/common/Image';
import DropDown from 'shared/components/common/dropdown';
import { alertError, alertSuccess } from 'shared/js/alertUtils';

import { EditAddress } from 'public/components/default_template/online_ordering/account/profile_addresses/edit_address/EditAddress';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

interface ProfileAddressesProps {
  selectedLocationLat?: number;
  selelctedLocationLong?: number;
  isLoggedOut?: boolean;
}

export const ProfileAddresses: FC<ProfileAddressesProps> = ({ selectedLocationLat, selelctedLocationLong, isLoggedOut }) => {
  const { customer } = useCustomer();
  const [editingAddress, setEditingAddress] = useState<SavedAddress>();
  const actionClicked = useCallback((addr: SavedAddress) => {
    setEditingAddress(addr);
  }, []);
  const [DeleteAddressMutation] = useDeleteAddressMutation();
  const ooClient = useOOClient();

  const deleteAddress = useCallback(async (addressGuid: string) => {
    try {
      const { data } = await DeleteAddressMutation({ variables: { input: { addressGuid } }, client: ooClient });
      if(!data) {
        throw new Error('Data object is undefined');
      } else if(data.deleteAddress.__typename === 'DeleteAddressError') {
        throw new Error(data.deleteAddress.message);
      } else if(data.deleteAddress.__typename === 'DeleteAddressResponse') {
        alertSuccess('Address removed successfully');
      }
    } catch(e) {
      alertError('Something went wrong, please try again later');
      reportError(e);
    }
  }, [DeleteAddressMutation, ooClient]);

  if(isLoggedOut) {
    return <div className="accountSubheadingDisabled">My Addresses</div>;
  }

  if(!customer || !customer.addresses || customer.addresses.length === 0) {
    return <p className="placeholderMsg">No saved addresses</p>;
  }

  return (
    <div className="profileAddresses" data-testid="ProfileAddresses" role="form" tabIndex={0}>
      <h2 className="accountSubheading">My Addresses</h2>

      {editingAddress && <EditAddress address={editingAddress} onSuccessfulUpdate={() =>
        setEditingAddress(undefined)} selectedLocationLat={selectedLocationLat} selelctedLocationLong={selelctedLocationLong} />}
      {!editingAddress && customer.addresses && customer.addresses.length > 0 &&
        <div className="addressItemsWrapper">
          {
            customer.addresses.map(addr => {
              const combinedAddress = addr.deliveryInfo.address2
                ? `${addr.deliveryInfo.address1}, ${addr.deliveryInfo.address2}`
                : `${addr.deliveryInfo.address1}`;
              return (
                <div key={addr.guid} className="addressItem">
                  <strong className="addressLabel">{addr.name}</strong>
                  <div className="addressLine" key={addr.guid}>
                    <p>{combinedAddress}<span className="addressDelimiter">,</span></p>
                    <p>{`${addr.deliveryInfo.city}, ${addr.deliveryInfo.state}  ${addr.deliveryInfo.zipCode}`}</p>
                  </div>
                  <div className="addressActions">
                    <DropDown label={<Image className="addressActionIcon" alt="Address actions" src="icons/kebab-menu.svg" />}>
                      {({ close }) =>
                        <>
                          <DropDownOption
                            testId="edit-address"
                            onSelect={() => {
                              actionClicked(addr);
                              close();
                            }}>
                            Edit
                          </DropDownOption>
                          <DropDownOption
                            testId="remove-address"
                            onSelect={async () => {
                              await deleteAddress(addr.guid);
                              close();
                            }}>
                            Remove
                          </DropDownOption>
                        </>}
                    </DropDown>
                  </div>
                </div>
              );
            })
          }
        </div>}
    </div>
  );
};

