import { DeliveryInfo } from 'src/apollo/onlineOrdering';

export type AddressType = DeliveryInfo & { name: string };

const getLocalStorageKey = (guid: string | null) => {
  return `toast-ecomm-delivery-location|${guid}`;
};

export const saveDeliveryAddress = (address: AddressType, guid: string) => {
  const key = getLocalStorageKey(guid);
  localStorage.setItem(key, JSON.stringify(address));
};

export const getDeliveryAddress = (guid: string | null): AddressType | undefined => {
  if(!guid) {
    return undefined;
  }

  const strInfo = localStorage.getItem(getLocalStorageKey(guid));
  return strInfo ? JSON.parse(strInfo) as AddressType : undefined;
};

export default { getDeliveryAddress, saveDeliveryAddress };
