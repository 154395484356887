import React, { Fragment } from 'react';

import { resources } from 'config';

import { RequestContext } from './context';


export const splitAtIndex = (value: string, index: number) => value ? [value.substring(0, index), value.substring(index)] : [];

export const slugify = (str: string) => str.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-')
  .toLowerCase();

export const getHostnameFromURL = (url: string) => {
  const matches = url ? url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i) : null;
  return matches && matches[1];
};

export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

/**
 * @deprecated Use FormattedPrice instead
 */
export const formatPrice = (price: number) => formatter.format(price);

export const splitTextWithNewlines = (id: string, text: string) => text ?
  text
    .split(/\\n|\\r/)
    .map((str: string, i: number) => str ? <Fragment key={`${id}-${i}`}> { str } <br /> </Fragment> : <></ >)
  : '';

export const getHost = (ctx?: RequestContext) => {
  if(ctx) {
    return ctx.host;
  }

  return getHostFromWindow();
};

export const getHostFromWindow = () => {
  if(typeof window !== 'undefined') {
    return window.location.host.split(':')[0] || '';
  }
  return '';
};

export const getPath = (ctx?: RequestContext) => {
  if(ctx) {
    return ctx.path;
  }

  if(typeof window !== 'undefined') {
    return window.location.pathname || '';
  }

  return '';
};

export const formatImageURL = (url: string): string => {
  return resources.ooAssetURL ? url.replace(/https:\/\/[^.]+\.cloudfront\.net\//, resources.ooAssetURL) : url;
};
