import isEmpty from 'lodash/isEmpty';

import { DeliveryInfoInput } from 'src/apollo/onlineOrdering';

import { Location, DeliveryInfo } from 'public/components/online_ordering/types';

export const ukiDummyAddressValue = 'XX';

export const normalizeGoogleAddress = (addressFromGoogle: google.maps.places.PlaceResult) => {
  const normalizedAddress = {} as DeliveryInfoInput;

  if(addressFromGoogle.address_components) {
    // prefer a route or street_address over a premise
    const hasStreetAddress = addressFromGoogle.address_components.some(comp => comp.types.includes('route') || comp.types.includes('street_address'));
    for(let component of addressFromGoogle.address_components) {
      for(let componentType of component.types) {
        if(normalizedAddress) {
          if(componentType === 'street_number') {
            normalizedAddress.address1 = normalizedAddress.address1 ? component.long_name + ' ' + normalizedAddress.address1 : component.long_name;
            break;
          } else if(componentType === 'premise' && !hasStreetAddress) {
            normalizedAddress.address1 = normalizedAddress.address1 ? normalizedAddress.address1 + ' ' + component.short_name : component.short_name;
            break;
          } else if(componentType === 'route' || componentType === 'street_address') {
            normalizedAddress.address1 = normalizedAddress.address1 ? normalizedAddress.address1 + ' ' + component.short_name : component.short_name;
            break;
          } else if(componentType === 'subpremise') {
            normalizedAddress.address2 = `#${component.long_name}`;
            break;
          } else if(
            componentType === 'locality'
            || componentType === 'neighborhood' && !normalizedAddress.city
            || componentType === 'sublocality_level_1' && !normalizedAddress.city
            || componentType === 'administrative_area_level_3' && !normalizedAddress.city
            || componentType === 'postal_town' && !normalizedAddress.city
          ) {
            // locality should be given priority, so always overwrite other data that may have been put in the city.
            // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
            // Brooklyn and other parts of New York City do not include the city as part of the address. Instead, they use sublocality_level_1.
            // Based on querying the Toast Local database, some Rxs dont have a locality or sublocality_level_1, but do have a city in the
            // administrative_area_level_3 field.
            normalizedAddress.city = component.long_name;
            break;
          } else if(componentType === 'administrative_area_level_1') {
            normalizedAddress.state = component.short_name;
            // normalises administrative area to be alligned with state, this addition allows us to enable OO delivery for Canadian customers
            // this will be updated with greater support for international in the coming weeks
            normalizedAddress.administrativeArea = component.short_name;
            break;
          } else if(componentType === 'postal_code') {
            normalizedAddress.zipCode = component.short_name;
            break;
          } else if(componentType === 'country') {
            normalizedAddress.country = component.short_name;
            break;
          }
        }
      }
    }
  }

  if(normalizedAddress && addressFromGoogle.geometry?.location) {
    normalizedAddress.latitude = addressFromGoogle.geometry.location.lat();
    normalizedAddress.longitude = addressFromGoogle.geometry.location.lng();
  }

  if(normalizedAddress?.country === 'IE' || normalizedAddress?.country === 'GB') {
    // state is not a relevant field for UKI, so we default to the dummy state value 'XX'
    normalizedAddress.state = ukiDummyAddressValue;
    if(isEmpty(normalizedAddress.zipCode)) {
      // zipcode is not always found for UKI addresses, so we default to a dummy value 'XX'
      normalizedAddress.zipCode = ukiDummyAddressValue;
    }
  }
  return isEmpty(normalizedAddress) ? null : normalizedAddress;
};

export const formatLocationAddress = (address: Location, includeZip: boolean = true) =>
  address ? `${address.address1}${address.address2 ? `, ${address.address2}` : ''}, ${address.city}, ${address.state}${includeZip ? ` ${address.zip}` : ''}` : '';

export const formatDeliveryAddress = (address: DeliveryInfo, full?: boolean, showAddress2: boolean = true) => {
  const address2 = address?.address2 ? `, ${address.address2}` : '';
  const zipcode = address?.zipCode == ukiDummyAddressValue ? '' : address?.zipCode;
  const fullAddress = full ? `, ${address?.city}, ${address?.administrativeArea || address?.state || ''} ${zipcode}` : '';

  return address ? `${address.address1}${showAddress2 && address2 ? address2 : ''}${fullAddress}` : '';
};

// For US, CA, IE, and UK addresses
export const normalizeAddressString = (address?: DeliveryInfoInput | null, showAddress2 = true) => {
  if(!address) {
    return '';
  }

  const { address1, address2, city, state, zipCode } = address ?? {};
  // Ireland and UK return 'XX' for state
  return [address1, showAddress2 ? address2 : '', city, state !== 'XX' ? state : '', zipCode]
    .filter(Boolean)
    .join(', ');
};
