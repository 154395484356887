import { NavType } from 'shared/components/common/nav/Nav';
import { SiteData } from 'shared/js/types';

/**
 *
 * @param restaurant
 * @returns A navType to use for online ordering that omits overlaid global nav behavior or undefined if no override is required.
 *
 * An "Overlaid" global nav setting will partially obscure page content on online ordering pages.
 * This function will provide an override nav type setting to avoid usage of the overlaid nav property while still respecting sticky configuration settings.
 */
export const getGlobalNavOverrideForOO = (restaurant: SiteData): NavType | undefined => {
  const navConfig = restaurant?.content?.navConfig;
  return navConfig?.overlaid ? navConfig?.sticky === false ? 'normal' : 'stickyNav' : undefined;
};
