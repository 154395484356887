import React from 'react';

import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';

import { useFlags } from 'shared/components/common/feature_flags/useFlags';

import ReorderItems from 'public/components/default_template/online_ordering/reorder_items/ReorderItems';

import { OrderAgain } from './OrderAgain';

const ContextualOrderAgain = () => {
  const { customer } = useCustomer();
  const { ooReorderItemsEnabled } = useFlags();

  return customer
    ? ooReorderItemsEnabled
      ? <ReorderItems />
      : <OrderAgain />
    : null;
};

export default ContextualOrderAgain;
