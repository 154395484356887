import React, { memo, useEffect } from 'react';

import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import Image from 'shared/components/common/Image';
import AdyenPaymentOverlay from 'shared/components/common/loading_spinner/AdyenPaymentOverlay';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutNote from 'public/components/default_template/online_ordering/checkout/CheckoutNote';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';

import AdyenPaymentForm from './AdyenPaymentForm';
import AdyenPaymentIFrame from './AdyenPaymentIFrame';

const InternationalPaymentContainer = memo(({ expanded, hideIntlPayment }: { expanded: boolean, hideIntlPayment: () => void }) => {
  const { ooRestaurant } = useRestaurant();
  const { orderError, showAdyenOverlay, setShowAdyenOverlay } = useCheckout();
  const { ooIntlPaymentsIframe } = useFlags();

  const onlineOrderingEnabled = ooRestaurant?.onlineOrderingEnabled;

  useEffect(() => {
    if(orderError) {
      setShowAdyenOverlay(false);
    }
  }, [orderError, setShowAdyenOverlay]);

  if(!onlineOrderingEnabled) {
    return <div data-testid="paymentUnavailable">Online ordering payments unavailable</div>;
  }

  return (
    <>
      {showAdyenOverlay && <AdyenPaymentOverlay fullScreen />}
      <AnimatedSection expanded={expanded}>
        <div className="internationalPaymentContainer">
          <div className={classnames('internationalPaymentHeader collapsable')} onClick={() => hideIntlPayment()}>
            Payment details
            <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
          </div>

          <div data-testid={'internationalPaymentContainer'}>
            {/* Rendering AdyenPaymentForm will create a payment intent. Only do that when the user is ready to pay. */}
            {expanded ?
              ooIntlPaymentsIframe ? <AdyenPaymentIFrame /> : <AdyenPaymentForm />
              : null}
            <p data-testid="intlPaymentLegalStatement" className="note checkoutNote" role="contentinfo">
              I understand my information will be used to process this payment and will also be disclosed to the merchant.
            </p>
          </div>
        </div>
        <CheckoutNote />
      </AnimatedSection>
    </>
  );
});
InternationalPaymentContainer.displayName = 'InternationalPaymentContainer';

export default InternationalPaymentContainer;
