/* eslint-disable import/order */
import React, { useState } from 'react';

import { useEditor } from '@toasttab/sites-components';

import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Link from 'shared/components/common/link';
import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { calculateDiscountsTotal, CartLineItems, useCartLineItems } from 'public/components/default_template/online_ordering/cart/cartUtils';
import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';
import OrderItems, { OrderItem, OrderPrices } from 'public/components/default_template/online_ordering/order/OrderItems';
import Upsell from 'public/components/default_template/online_ordering/upsell/Upsell';
import { useCart } from 'public/components/online_ordering/CartContext';
import { CartSelection } from 'public/components/online_ordering/types';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';
import CartOffer from './offers/CartOffer';
import { Channel } from 'public/js/siteUtilities';
import FreeShippingProgressBar from 'public/components/default_template/online_ordering/cart/FreeShippingProgressBar';
import { useGetShippingOptionsQuery } from 'src/apollo/onlineOrdering';

type SelectionProps = {
  selection: CartSelection;
  cartGuid: string;
  editable: boolean;
};

const CartItem = (props: SelectionProps) => {
  const { isOpen, onClose, onOpen } = useModal();
  const { selection, editable } = props;
  const { deleteFromCart, cart, restaurantGuid } = useCart();

  if(!selection?.guid || !cart?.restaurant || !selection?.itemGuid) {
    return null;
  }

  return (
    <>
      <ItemModal
        isOpen={isOpen}
        onClose={onClose}
        restaurantGuid={restaurantGuid}
        shortUrl={cart.restaurant.shortUrl}
        specialRequestsEnabled={cart.restaurant.specialRequestsConfig.enabled}
        specialRequestsPlaceholder={cart.restaurant.specialRequestsConfig.placeholderMessage}
        itemGuid={selection.itemGuid}
        itemGroupGuid={selection.itemGroupGuid}
        cartGuid={props.cartGuid}
        selectionGuid={props.selection?.guid} />
      <OrderItem
        selection={selection}
        itemActions={
          editable ?
            <div className="itemActions" aria-labelledby={`orderItemSelection-${normalizeHtmlId(selection.guid)}`}>
              <button type="button" className="itemAction" onClick={() => {
                onOpen();
              }}>Edit
              </button>
              <button type="button" className="itemAction"
                onClick={() => deleteFromCart(selection.guid!)}>Remove
              </button>
            </div> :
            undefined
        } />
    </>
  );
};

export const CartItems = ({ editable }: { editable?: boolean }) => {
  const { cart, cartGuid, loadingCart, error } = useCart();
  return (
    <div className="cartData">
      <OrderItems
        loading={loadingCart}
        error={error}
        selections={cart?.order?.selections}
        hasItems={Boolean(!!cart?.order?.selections?.length && cartGuid)}
        ItemComponent={CartItem}
        itemProps={{ cartGuid: cartGuid, editable }}
        noItemsComponent={
          <div className="emptyCart">
                        Add items to start your order.
          </div>
        } />
    </div>
  );
};

export const CartPrices = ({ giftCardAppliedAmount, tip, fundraisingAmount, shippingMessage }: {
  giftCardAppliedAmount?: number,
  tip?: number,
  fundraisingAmount?: number,
  shippingMessage?: string
}) => {
  const { cart, loadingCart, subtotal } = useCart();

  const cartLineItems: CartLineItems = useCartLineItems(cart as any)(tip, fundraisingAmount);

  return (
    <>
      {subtotal ?
        <OrderPrices
          shippingMessage={shippingMessage}
          loading={loadingCart}
          subtotal={subtotal}
          discountsTotal={calculateDiscountsTotal(cart?.order?.discounts?.globalReward?.amount || 0, cart?.order?.discountsTotal)}
          diningOptionBehavior={cart?.diningOptionBehavior}
          deliveryFee={cartLineItems.deliveryChargeTotalAmount || 0}
          gratuityServiceCharges={cartLineItems.gratuityServiceCharges || 0}
          processingServiceCharges={cartLineItems.processingServiceCharges || 0}
          nonDeliveryNonGratuityNonUbpServiceCharges={cartLineItems.nonDeliveryNonGratuityNonUbpServiceCharges || 0}
          tax={cartLineItems.tax || 0}
          tip={cartLineItems.tipAmount}
          fundraisingAmount={cartLineItems.fundraisingAmount}
          giftCardAppliedAmount={giftCardAppliedAmount} /> :
        null}
    </>
  );
};

const Cart = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { cart, channel, loadingCart } = useCart();
  const { isEditor } = useEditor();
  const { ooRestaurant, orderingDisabled, restaurant } = useRestaurant();
  const { checkoutPath, ecommCheckoutPath } = useRestaurantRoutes();
  const { data, loading: shippingOptionsLoading } = useGetShippingOptionsQuery({
    variables: { input: { siteGuid: restaurant.id || '' } },
    skip: channel !== Channel.ECOMM || !restaurant.id,
    fetchPolicy: 'no-cache'
  });
  const freeShippingThreshold = data?.getServiceGroups.find(group => group?.type === 'FREE_SHIPPING')?.freeShippingThresholdMin;
  const shippingMessage = freeShippingThreshold && cart?.order?.totalV2 ?
    cart.order.totalV2 >= parseFloat(freeShippingThreshold) ? 'FREE' : 'Calculated at checkout' :
    undefined;

  if(channel !== Channel.ECOMM && orderingDisabled || !ooRestaurant) {
    return null;
  }

  if(collapsed) {
    return (
      <div className="collapsedCart">
        <button type="button" className="checkoutButton" onClick={() => setCollapsed(false)}>
          <span>View Cart ({cart?.order?.numberOfSelections || 0})</span>
          <span>{<FormattedPrice value={cart?.order?.totalV2 || 0} />}</span>
        </button>
      </div>
    );
  }

  const hasItems = !!cart?.order?.selections?.length;

  return (
    <div id="cart-control" className="cart">
      <div className="body">
        <div className="paddedContent">
          <CartItems editable />
          {cart && channel !== Channel.ECOMM && <Upsell />}
        </div>
        <CartOffer />
        {cart && channel === Channel.ECOMM && freeShippingThreshold &&
            <FreeShippingProgressBar threshold={parseFloat(freeShippingThreshold)} loading={shippingOptionsLoading || loadingCart} />}
      </div>
      <div className="footer">
        <CartPrices shippingMessage={shippingMessage} />
        {hasItems && !isEditor &&
            <Link className="checkoutButtonAction" tabIndex={0} href={channel === Channel.ECOMM && ecommCheckoutPath ? ecommCheckoutPath : checkoutPath}>
              <div className="checkoutButton" data-testid="cart-checkout-cta">
                <span>Checkout</span>
                <span><FormattedPrice value={cart?.order?.totalV2 || 0} /></span>
              </div>
            </Link>}
      </div>
    </div>
  );
};

export default Cart;
