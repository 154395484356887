import { useCallback } from 'react';

import { GuestInput, useValidateCartPreCheckoutMutation } from 'src/apollo/onlineOrdering';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { OrderError } from 'public/components/online_ordering/CheckoutContext';

export const useIsCartValid = (onError: (err: OrderError | null) => void, refetchCart: () => void) => {
  const ooClient = useOOClient();
  const [validateCartPreCheckoutMutation] = useValidateCartPreCheckoutMutation({ client: ooClient });

  const isCartValid = useCallback(async (cartGuid: string, guestInfo: GuestInput): Promise<boolean> => {
    if(!cartGuid) return false;

    const { data } = await validateCartPreCheckoutMutation({
      variables: {
        cartGuid: cartGuid,
        validateOrderTime: true,
        customer: guestInfo
      }
    });

    const validateCartPreCheckout = data?.validateCartPreCheckout;
    if(!validateCartPreCheckout) return false;

    const { __typename } = validateCartPreCheckout;

    if(__typename === 'CartResponse' && validateCartPreCheckout.info.length === 0 && validateCartPreCheckout.warnings.length === 0) {
      return true;
    }

    if(__typename === 'CartResponse') {
      if(validateCartPreCheckout.warnings.length !== 0 && validateCartPreCheckout.warnings[0]) {
        onError({
          type: validateCartPreCheckout.warnings[0].code,
          message: validateCartPreCheckout.warnings[0].message
        });
      } else if(validateCartPreCheckout.info.length !== 0 && validateCartPreCheckout.info[0]) {
        // We ignore this info message to allow orders within a certain threshold to be placed.
        // If the fulfillment time is too high, it will be caught when the order is placed.
        if(validateCartPreCheckout.info[0].code === 'FULFILLMENT_DATE_CHANGED') {
          return true;
        }
        onError({
          type: validateCartPreCheckout.info[0].code,
          message: validateCartPreCheckout.info[0].message
        });
      }
    } else if(__typename === 'CartModificationError') {
      onError({
        type: validateCartPreCheckout.code,
        message: validateCartPreCheckout.message,
        refetchCart: true
      });
    } else if(__typename === 'CartOutOfStockError') {
      onError({
        type: 'OUT_OF_STOCK',
        message: validateCartPreCheckout.message,
        refetchCart: true
      });
    } else if(__typename === 'CartValidationError') {
      onError({
        type: validateCartPreCheckout.key,
        message: validateCartPreCheckout.message
      });
    } else {
      onError({
        type: 'unknown',
        message: 'Sorry, your order could not be sent.'
      });
    }

    // Don't refetch the cart until after the error modal is closed for errors that modify the cart.
    // This helps prevent page re-renders that could prevent the error modal from displaying.
    if(__typename && !['CartModificationError', 'CartOutOfStockError'].includes(__typename)) {
      refetchCart();
    }

    return false;
  }, [validateCartPreCheckoutMutation, onError, refetchCart]);

  return { isCartValid };
};
