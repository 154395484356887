import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { DisplayableMenuItemTag, HeroContentType, MenuFormatConfig } from 'src/apollo/sites';
import { useTracker } from 'src/lib/js/hooks/useTracker';
import { getReferrerProperties } from 'src/lib/js/trackerUtilities';
import { useSpotlightContext } from 'src/public/components/default_template/spotlight/SpotlightContext';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import { Channel, isWebsiteNonOrderRoute } from 'src/public/js/siteUtilities';
import { useNavHistory } from 'src/shared/components/common/history_context/NavHistoryContext';
import { useTrackOrderPage } from 'src/shared/js/hooks/useTrackOrderPage';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import GlobalNav from 'shared/components/common/nav/Nav';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import OrderPageSchema from 'public/components/default_template/OrderPageSchema';
import StickyFooter from 'public/components/default_template/footer/sticky_footer/StickyFooter';
import MenuPageHeader from 'public/components/default_template/menu_header/MenuPageHeader';
import MenuNav, { RefMenu, useMenuNav } from 'public/components/default_template/menu_nav/MenuNav';
import { scrollToRef } from 'public/components/default_template/menu_nav/menuNavScrollUtils';
import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';
import MenuSection, { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartModal';
import DiningOptionsModal from 'public/components/default_template/online_ordering/dining_options/DiningOptionsModal';
import DeeplinkItemModal from 'public/components/default_template/online_ordering/item_modal/DeeplinkItemModal';
import { ItemResolutionModal } from 'public/components/default_template/online_ordering/item_modal/ItemResolutionModal';
import { filterAndSortTags, OfferBadge } from 'public/components/default_template/online_ordering/item_tags/MenuItemTags';
import { useMenuSearchContext } from 'public/components/default_template/search';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment, useLocationSchedules } from 'public/components/online_ordering/FulfillmentContext';
import { useOffers } from 'public/components/online_ordering/OffersContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';

import { MenuHeader } from './MenuHeader';
import Offers from './offers/Offers';
import FulfillmentControl from './online_ordering/dining_behavior_toggle/FulfillmentControl';
import OrderAgain from './online_ordering/order_again/OrderAgain';
import OrderPageWrapper, { OrderPageContentProps } from './online_ordering/pages/OrderPageWrapper';
import { FilterNav } from './paginated_menu/FilterNav';
import { FilterTags } from './paginated_menu/FilterTags';
import { PaginatedMenu } from './paginated_menu/PaginatedMenu';
import { filterItems, filterMenus } from './search/searchFilterUtils';

export const OrderPageContent = ({ scrollNavRef, navOpen, hasHero, hideHero, usingSecondaryNav, menuGuid, groupGuid, itemGuid }: OrderPageContentProps) => {
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant, toastProduct } = useRestaurant();
  const { cart } = useCart();
  const { menuOffers } = useOffers();
  const [isMapOrModalOpen, setIsMapOrModalOpen] = useState(false);
  const hasScrolledToParam = useRef(false);
  const navRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const ooConfig = useMemo(() => siteRestaurant.config.ooConfig, [siteRestaurant.config.ooConfig]);
  const displayableMenuItemTags = ooConfig?.displayableMenuItemTags as DisplayableMenuItemTag[];
  const { isHidden } = useSpotlightContext();
  const { setFulfillmentModalOpen } = useFulfillment();
  const [fulfillmentModalLoading, setFulfillmentModalLoading] = useState(false);
  const isToastLocal = useMemo(() => toastProduct === ToastProduct.ToastLocal, [toastProduct]);

  const { searchString } = useMenuSearchContext();

  const { isEditor, useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'menu', displayName: 'Menu', actions: [], schema: { fields: [] }, skipRef: navRef });

  const restaurantGuid = selectedLocation.externalId;
  const {
    menus,
    popularItems: unfilteredPopularItems,
    menuItems,
    groupings,
    filters,
    setFilters,
    fetchMore,
    loadingNewFilterData,
    loading
  } = useOOMenus({ respectAvailability: !isEditor });
  const {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    selectedGuid,
    setSelectedGuid,
    scrolledGuid,
    setScrolledGuid,
    refMenuPages: unfilteredMenuPages,
    itemGuidsToItemsMap
  } = useMenuNav({ menus, menuItems, page: '/order' });

  const deeplinkItem = itemGuid ? itemGuidsToItemsMap.get(itemGuid) : null;

  const refMenuPages = useMemo<RefMenu[]>(() => {
    return filterMenus(unfilteredMenuPages, searchString);
  }, [unfilteredMenuPages, searchString]);

  const popularItems = useMemo<MenuItem[]>(() => {
    const itemsWithFilteredTags = unfilteredPopularItems?.map(item => ({ ...item, itemTags: filterAndSortTags(item?.itemTags ?? [], displayableMenuItemTags) })) ?? [];
    return filterItems(itemsWithFilteredTags, searchString);
  }, [unfilteredPopularItems, searchString, displayableMenuItemTags]);

  const shouldShowMenuTitles = unfilteredMenuPages.length > 1;

  const pageNode = useCallback((node: any) => {
    if(node && menuGuid && !hasScrolledToParam.current) {
      const menuIndex = refMenuPages?.findIndex(menu => menu.guid === menuGuid);
      const pageRef = refMenuPages[menuIndex]?.ref;
      if(groupGuid) {
        const groupRef = refMenuPages[menuIndex]?.groups.find(group => group.guid === groupGuid)?.ref;
        if(groupRef) {
          setSelectedMenuIndex(menuIndex);
          scrollToRef(groupRef);
          hasScrolledToParam.current = true;
        }
        return;
      }
      if(menuIndex && pageRef && menuIndex !== selectedMenuIndex) {
        setSelectedMenuIndex(menuIndex);
        scrollToRef(pageRef);
        hasScrolledToParam.current = true;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refMenuPages]);

  const ooFormatConfig: MenuFormatConfig | undefined | null = ooConfig?.format;

  const heading = useMemo(() => restaurant?.whiteLabelName || 'Menu', [restaurant]);
  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  const spotlight = siteRestaurant.content?.spotlightBanner;
  const spotlightBannerAppliedToOrderPage = (spotlight?.applicablePages?.filter(page => page === '/order' || page === '/online') ?? []).length > 0;
  const navSpotlightEnabled = !isHidden && !!spotlight?.enabled && spotlight?.fixed === true && spotlightBannerAppliedToOrderPage;

  const locationSchedules = useLocationSchedules();
  const locationSchedule = locationSchedules && locationSchedules[selectedLocation.externalId] || undefined;
  const showFeaturedItems = popularItems.length > 0 && (
    selectedLocation.featuredItems?.enabled || selectedLocation.featuredItems?.enabled === undefined && !ooConfig?.popularItemsDisabled
  );

  return (
    <>
      {isMobile &&
        <div className="paddedContentWrapper withBorder">
          <FulfillmentControl onClick={async () => {
            setFulfillmentModalLoading(true);
            await setFulfillmentModalOpen(true);
            setFulfillmentModalLoading(false);
          }} loading={fulfillmentModalLoading} isMobile={true} testId="fulfillment-control-mobile" />
        </div>}
      {!hideHero && <MenuPageHeader heading={heading} heroConfig={ooConfig as HeroContentType} allowFulfillmentChange />}
      <div className="pageContent" ref={scrollNavRef} data-testid="order-page" role="main" id="main" aria-label="menu" tabIndex={0}>
        {!restaurant?.hasOnlineOrderingModule ?
          <div className="onlineOrderingUnavailable">Online ordering is currently unavailable. Please contact the restaurant to place an order.</div> :
          <div ref={editableRef}>
            <div className={classnames('navAndMenu', { sideBySide })}>
              <GlobalNav navType="stickyNav"
                shouldShowPreviewBanner={false}
                className={classnames('secondaryNav', {
                  withoutHero: !hasHero,
                  withShadow: usingSecondaryNav,
                  globalNavOpen: navOpen || !usingSecondaryNav,
                  withNavSpotlight: navSpotlightEnabled,
                  sideBySide
                })}>
                {menus ?
                  <MenuNav
                    menus={refMenuPages}
                    selectedGroupGuid={selectedGuid}
                    setSelectedGroupGuid={setSelectedGuid}
                    selectedMenuIndex={selectedMenuIndex}
                    setSelectedMenuIndex={setSelectedMenuIndex}
                    menuConfig={ooFormatConfig}
                    navRef={navRef}
                    globalNavCollapsed={!navOpen && usingSecondaryNav} />
                  : null}
                {menuItems?.length && groupings ? <FilterNav groupings={groupings} filters={filters} setFilters={setFilters} /> : null}
              </GlobalNav>
              {!isMobile && isToastLocal &&
              <div className="paddedSection">
                <div className="fulfillmentContainer">
                  <FulfillmentControl onClick={async () => {
                    setFulfillmentModalLoading(true);
                    await setFulfillmentModalOpen(true);
                    setFulfillmentModalLoading(false);
                  }} loading={fulfillmentModalLoading} isMobile={false} testId="fulfillment-control-mobile" />
                </div>
              </div>}
              <div className="menuSections">
                {menuItems?.length && groupings ? <FilterTags groupings={groupings} filters={filters} setFilters={setFilters} /> : null}
                <div className="paddedContentWrapper">
                  <div className="paddedContent">
                    <Offers />
                  </div>
                </div>
                { isEditor ? null : <OrderAgain /> }
                {showFeaturedItems && !menuItems?.length ?
                  <div className={classnames('menuSectionWrapper', getTemplateClass(ooFormatConfig?.template), { columns: ooFormatConfig?.columns })}>
                    <div className={classnames('menuWrapper paddedContentWrapper', { sideBySide })}>
                      <div className="menu paddedContent">
                        <MenuSection
                          shortUrl={restaurant?.shortUrl}
                          restaurantGuid={restaurantGuid}
                          setIsMapOrModalOpen={setIsMapOrModalOpen}
                          isMapOrModalOpen={isMapOrModalOpen}
                          header="Featured Items"
                          orientation={ooConfig?.cardOrientation}
                          menuItems={popularItems}
                          menuConfig={{ format: ooFormatConfig, colors: ooConfig?.colors }} />
                      </div>
                    </div>
                  </div>
                  : null }
                <div className={classnames('menuSectionWrapper', getTemplateClass(ooFormatConfig?.template), { columns: ooFormatConfig?.columns })} ref={pageNode}>
                  {refMenuPages?.length
                    ? refMenuPages.map((page, pageIndex) =>
                      <div
                        role="tabpanel"
                        aria-labelledby={`${normalizeHtmlId(page.name)}-tab`}
                        className={classnames('menuWrapper paddedContentWrapper', { sideBySide })}
                        key={`${page.name}-${page.guid}`}
                        ref={page.ref}
                        tabIndex={-1}
                        id={`menu-${page.guid}`}>
                        <div className="menu paddedContent">
                          <div className="headerWrapper">
                            <MenuHeader
                              header={ shouldShowMenuTitles ? page.name : null }
                              badge={(menuOffers[page.guid]?.length ?? 0) > 0 ? <OfferBadge text={(menuOffers[page.guid]?.length ?? 0) > 1 ? 'OFFERS' : 'OFFER'} /> : null} />
                          </div>
                          {page.groups.map(group =>
                            <MenuSection
                              key={group.guid}
                              shortUrl={restaurant?.shortUrl}
                              specialRequestsEnabled={restaurant?.specialRequestsConfig?.enabled}
                              specialRequestsPlaceholder={restaurant?.specialRequestsConfig?.placeholderMessage}
                              restaurantGuid={restaurantGuid}
                              setIsMapOrModalOpen={setIsMapOrModalOpen}
                              isMapOrModalOpen={isMapOrModalOpen}
                              onVisible={() => {
                                const newSection = group.guid;
                                if(scrolledGuid === selectedGuid) {
                                  setSelectedGuid(newSection);
                                }
                                if(selectedMenuIndex === scrolledMenuIndex) {
                                  setSelectedMenuIndex(pageIndex);
                                  setSelectedGuid(newSection);
                                }
                                setScrolledGuid(newSection);
                                setScrolledMenuIndex(pageIndex);
                              }}
                              ref={group.ref}
                              groupGuid={group.guid}
                              menuGuid={page.guid}
                              header={page.groups.length < 2 && group.name.toLowerCase() === page.name.toLowerCase() ? undefined : group.name}
                              description={group.description}
                              orientation={ooConfig?.cardOrientation}
                              menuItems={group.items}
                              menuConfig={{ format: ooFormatConfig, colors: ooConfig?.colors }} />)}
                          {pageIndex !== refMenuPages.length - 1 ? <hr className="sectionSeparator" /> : null}
                        </div>
                      </div>) :
                    <div className="paddedSection">
                      <MenuSection onVisible={() => {}} />
                      {searchString &&
                        <div style={{ paddingTop: '40px', paddingBottom: '80px' }}>
                          <h2>No results found. Please adjust your search term and try again.</h2>
                        </div>}
                    </div>}
                  {menuItems?.length && groupings ?
                    <PaginatedMenu
                      menuItems={menuItems}
                      loading={loading || false}
                      fetchMore={fetchMore}
                      loadingNewFilterData={loadingNewFilterData || false}
                      menuConfig={{ format: ooConfig?.format, colors: ooConfig?.colors }}
                      hideOutOfStockItems={ooConfig?.hideOutOfStockItems} />
                    : null}
                </div>
              </div>
            </div>
            {cart?.order?.numberOfSelections ?
              <StickyFooter primaryCta={{ text: `View order (${cart.order.numberOfSelections})`, type: 'customAction', onClick: () => context?.open() }} /> :
              null}
          </div>}
      </div>
      <OrderPageSchema restaurant={restaurant} site={siteRestaurant} selectedLocation={selectedLocation} locationSchedule={locationSchedule} menus={menus} />
      {/* Only necessary when the currently set schedule time has expired or delivery is selected without an address */}
      <DiningOptionsModal />
      {/* Only necessary when the guest has attempted to add an item to the cart that conflicts with the cart's current selections */}
      <ItemResolutionModal />
      {deeplinkItem && <DeeplinkItemModal item={deeplinkItem} />}
    </>
  );
};

const TrackingOrderPage = (props: OrderPageContentProps) => {
  const { toastProduct } = useRestaurant();

  const { historySet } = useNavHistory();
  const tracker = useTracker();

  // update amplitude referrer to toast website for attribution
  useEffect(() => {
    if(typeof window !== 'undefined' && toastProduct === ToastProduct.Sites && historySet.find(isWebsiteNonOrderRoute)) {
      const [referrer, referringDomain] = getReferrerProperties(window.location.origin);
      referrer && referringDomain && tracker.register({ referrer, referring_domain: referringDomain });
    }
  }, [toastProduct, tracker, historySet]);

  useTrackOrderPage();

  return <OrderPageContent {...props} />;
};

const OrderPage = () => {
  return (
    <OrderPageWrapper channel={Channel.OO}>
      {props =>
        <TrackingOrderPage
          {...props} />}
    </OrderPageWrapper>);
};
export default React.memo(OrderPage);
