import React from 'react';

import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';

import { useRestaurant } from './RestaurantContext';

/**
 * This component ensures that Rx Data is consistently included on every analytic event
 */
const TrackRestaurant = () => {
  // https://github.com/toasttab/toast-sites-web/pull/605
  // Constructed this way to prevent a race condition between the LaunchDarkly context initialization
  // and the flag call, so that we don't create an anonymous session before creating one assigned to a restaurant GUID user

  const { restaurant: siteRestaurant, ooRestaurant, selectedLocation, toastProduct } = useRestaurant();
  const tracker = useTracker();

  tracker.register({
    restaurantGuid: selectedLocation?.externalId,
    managementGuid: siteRestaurant?.externalId,
    toastProduct,
    rxCountry: ooRestaurant?.i18n.country || null,
    isIntl: useIsIntlRestaurant(),
    // Will get set when a cart is loaded in CartContext, but ensure we clear it out in case
    // a user switches restaurants.
    // TODO Use unregister once https://github.toasttab.com/toasttab/toast-sites-web/pull/2624 is merged
    cartSource: null
  });

  return <React.Fragment />;
};

export default TrackRestaurant;
