import React from 'react';

import { Locale, checkLocale } from '@toasttab/buffet-pui-locale-utilities';
import { formatCurrency, Currency } from '@toasttab/buffet-pui-number-utilities';

import { Money } from 'src/apollo/onlineOrdering';
import { useOptionalRestaurant, useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

export type I18nType = {
  currency: Currency,
  locale: Locale,
  country?: string | null
}

export function useFormatPrice(price: number) {
  const { ooRestaurant } = useRestaurant();
  const restaurantI18n: I18nType | undefined = ooRestaurant?.i18n as I18nType | undefined;

  return formatCurrency({ amount: price, currency: restaurantI18n?.currency || Currency.USD }, checkLocale(restaurantI18n?.locale));
}

/**
 * Formats to a string from the "Money" type in defined on the Federated Gateway
 */
export function useFormatMoney(): (money: Money) => string {
  const ooRestaurant = useOptionalRestaurant()?.ooRestaurant;
  const restaurantI18n: I18nType | undefined = ooRestaurant?.i18n as I18nType | undefined;
  return React.useCallback((money: Pick<Money, 'amount' | 'currency'>) => {
    return formatCurrency(
      {
        amount: money.amount,
        currency: `${money.currency}` as Currency
      },
      checkLocale(restaurantI18n?.locale)
    );
  }, [restaurantI18n?.locale]);
}
