import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

import { useReorderTemplate } from './useReorderTemplate';

type Props = {
  onClick?: () => void;
  testId?: string;
}

const BaseCard = (props: PropsWithChildren<Props>) => {
  const { restaurant: { config } } = useRestaurant();
  const { templateClass, templateOverrides } = useReorderTemplate();
  const getColorFromTheme = useThemeColorScheme();

  const [backgroundColor, textColor, borderColor] = getColorFromTheme(theme => {
    return [
      theme.colorOverrides?.onlineOrderingMenu?.cardBackground ?? theme.colorScheme.surface.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.itemTitle ?? theme.colorScheme.text.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.cardBorder ?? theme.colorScheme.border.default
    ];
  },
  [
    config.ooConfig?.colors?.background ?? DEFAULT_COLORS.background ?? '#FFFFFF',
    config.ooConfig?.colors?.primaryText ?? DEFAULT_COLORS.text,
    templateOverrides?.borderColor
  ]);

  return (
    <button
      data-testid={props.testId}
      onClick={props.onClick}
      className={classnames('reorderItemCard',
        templateClass,
        templateOverrides.imageRatio,
        templateOverrides.borderStroke,
        templateOverrides.backgroundShade,
        templateOverrides.roundedCorner,
        templateOverrides.dropShadow)}
      style={{ borderColor, backgroundColor, color: textColor }}>
      {props.children}
    </button>
  );
};

export default BaseCard;
