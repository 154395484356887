import React, { useMemo } from 'react';

import classnames from 'classnames';

import { DoMenus_GetMenuItemsItem as DoMenusGetMenuItemsItem } from 'src/apollo/onlineOrdering';
import { formatImageURL } from 'src/lib/js/utilities';

import Image from 'shared/components/common/Image';
import { getItemImage } from 'shared/components/common/dynamic_menus/DynamicMenusUtils';
import { GroupItemBackupContent, MenuGroupEditableProps } from 'shared/components/common/dynamic_menus/menu_group/MenuGroup';
import FormattedPrice from 'shared/components/common/price/FormattedPrice';

type SideImageItemProps = {
  item: DoMenusGetMenuItemsItem | GroupItemBackupContent
} & MenuGroupEditableProps

// A menu item that is designed to live inside a LinkedItem inside of a MenuGroup block.
// This item is layed out with the image is to the side of the text.
export const SideImageItem = (props: SideImageItemProps) => {
  const { item, config } = props;
  const showDescription = useMemo(() => config?.appearance?.showDescription !== false && !!item.description,
    [config?.appearance?.showDescription, item.description]);
  const alignmentClass = useMemo(() => {
    if(config?.layout === 'textOnly') {
      return 'textOnly';
    }
    // Default to SideImage Layout.
    // When the image is on the left, the description alignment is on the right.
    if(config?.appearance?.imagePlacement === 'left') {
      return showDescription ? 'rightAligned' : 'rightAlignedNoDescr';
    } else {
      return showDescription ? 'leftAligned' : 'leftAlignedNoDescr';
    }
  }, [config?.layout, config?.appearance?.imagePlacement, showDescription]);
  const itemPrice = useMemo(() => typeof item.price === 'string' ? parseFloat(item.price) : item.price || 0,
    [item.price]);

  return (
    <div className={classnames('itemWrapper', alignmentClass, { priceHidden: config?.appearance?.showPrice === false }) }
      key={item.guid}>
      <div className="itemName">{item.name || 'Menu Item'}</div>
      <div className="itemDescription">{showDescription ? item.description : ''}</div>
      <div className="itemPrice">{config?.appearance?.showPrice !== false ? <FormattedPrice value={itemPrice} /> : ''}</div>
      <div className="itemImage"><Image alt={''} src={formatImageURL(getItemImage(item))} className={'itemImageWrapper'} /></div>
      <div className="itemDelimiter"></div>
    </div>
  );
};

export default SideImageItem;
