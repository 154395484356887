import { useCallback } from 'react';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useApplePayLineItems } from 'public/components/default_template/online_ordering/cart/cartUtils';
import { useCart } from 'public/components/online_ordering/CartContext';

declare global {
  interface Window {
    ApplePaySession?: any;
  }
}

const canMakeApplePayPayment = (atCheckoutOptionsLength: number) => {
  if(atCheckoutOptionsLength === 0) {
    return false;
  }
  try {
    if(window?.ApplePaySession) {
      const canMakePayments = window.ApplePaySession.canMakePayments();
      return canMakePayments;
    }
    return false;
  } catch(error) {
    return false;
  }
};

export const useApplePay = () => {
  const cartInfo = useCart();
  const restaurantInfo = useRestaurant()?.ooRestaurant as any;
  const getLineItems = useApplePayLineItems({
    cart: cartInfo.cart,
    showTip: true,
    showTotal: true
  });

  const getConfig = useCallback(
    (tip: number | undefined | null, fundraisingAmount: number | undefined | null, appliedGiftCardAmount: number | undefined | null, appliedToastCashAmount: number | undefined | null) => {
      const allItems = getLineItems(tip, fundraisingAmount, appliedGiftCardAmount, appliedToastCashAmount);
      const {
        whiteLabelName,
        creditCardConfig
      } = restaurantInfo;
      const supportedNetworks = ['discover', 'masterCard', 'visa'];
      if(creditCardConfig.amexAccepted) {
        supportedNetworks.push('amex');
      }
      const total = allItems.find(({ id }) => id === 'total-order-amount') || { amount: 0 };
      const lineItems = allItems.filter(({ id }) => id !== 'total-order-amount');

      return {
        currencyCode: 'USD',
        countryCode: 'US',
        total: {
          label: whiteLabelName,
          amount: total.amount
        },
        requiredBillingContactFields: ['postalAddress'],
        requiredShippingContactFields: ['name', 'phone', 'email'],
        lineItems,
        supportedNetworks,
        merchantCapabilities: ['supports3DS']
      };
    },
    [getLineItems, restaurantInfo]
  );

  return {
    canUseApplePay: canMakeApplePayPayment(cartInfo.cart?.paymentOptions?.atCheckout.length ?? 0),
    getConfig
  };
};
