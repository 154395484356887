import React from 'react';

import { PaginatedMenuItemsQuery } from 'src/apollo/onlineOrdering';

import { Filter } from './Filter';

type Props = {
  filters: Set<string>
  setFilters: React.Dispatch<React.SetStateAction<Set<string>>>
  groupings: NonNullable<PaginatedMenuItemsQuery['paginatedMenuItems']['groupings']>
}

export const FilterNav = ({ setFilters, filters, groupings }: Props) => {
  const availableFilters = groupings?.map(grouping => {
    const selected = grouping.children?.every(c => filters.has(`${grouping.guid}:${c.guid}`)) || filters.has(grouping.guid);

    return {
      name: grouping.name,
      value: grouping.guid,
      selected,
      children: grouping.children?.map((subGroup: any) => ({
        name: subGroup.name,
        value: `${grouping.guid}:${subGroup.guid}`,
        selected: selected || filters.has(`${grouping.guid}:${subGroup.guid}`)
      })) || []
    };
  });

  return (
    <div className="paddedContentWrapper filterNav">
      <div className="paddedContent filters">
        <Filter
          name="Category"
          options={availableFilters || []}
          onClear={() => setFilters(new Set())}
          onChange={(value: string | string[]) => {
            if(Array.isArray(value)) {
              const valueSet = new Set(value);
              const difference = valueSet.difference(filters);

              // If there are filters that haven't yet applied, add them all
              // Otherwise, clear out the changed filters
              if(difference.size > 0) {
                setFilters(new Set([...filters, ...value]));
              } else {
                setFilters(filters.difference(valueSet));
              }
            } else if(filters.has(value)) {
              setFilters(filters => { filters.delete(value); return new Set(filters); });
            } else {
              setFilters(filters => { filters.add(value); return new Set(filters); });
            }
          }} />
      </div>
    </div>
  );
};
