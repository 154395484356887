import React, { useContext } from 'react';

import * as ld from '@launchdarkly/node-server-sdk';
import { Request } from 'express';
import { camelCaseKeys } from 'launchdarkly-react-client-sdk';

const FlagContext = React.createContext<ld.LDFlagSet>({});

export const FlagContextProvider = ({ children, req }: React.PropsWithChildren<{ req: Request }>) => {
  // convert flag keys to camel case similar to
  // https://github.com/launchdarkly/react-client-sdk/blob/ca6ebcee17c0307286df5e4a9de91d76a8cf011c/src/provider.tsx#L45
  const flags = req?.flags ? camelCaseKeys(req.flags) : undefined;
  return (
    <FlagContext.Provider value={{ ...flags }}>{children}</FlagContext.Provider>
  );
};

export const useFlagContext = () => {
  return useContext(FlagContext);
};
