import React, { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import urlJoin from 'url-join';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout, OrderError } from 'public/components/online_ordering/CheckoutContext';
import { usePayment } from 'public/components/online_ordering/PaymentContext';

import { resources } from 'config';

import { AdyenMessage } from './types';
import useAdyenPayments from './useAdyenPayments';

const AdyenPaymentIFrame = () => {
  const [height, setHeight] = useState('100%');
  const { ooRestaurant } = useRestaurant();
  const { cartGuid, refetchCart } = useCart();
  const { orderTotal, setOrderError, setShowAdyenOverlay } = useCheckout();
  const { tipAmount } = usePayment();

  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const phone = useWatch({ name: 'yourInfoPhone' });

  const { placeAdyenOrder } = useAdyenPayments();

  useWindowMessage({
    origin: resources.sitesHost,
    sourceId: 'ADYEN_PAYMENT',
    messageHandlers: {
      [AdyenMessage.HEIGHT]: (data: { height: string }) => setHeight(data.height),
      [AdyenMessage.SHOW_OVERLAY]: () => setShowAdyenOverlay(true),
      [AdyenMessage.HIDE_OVERLAY]: () => setShowAdyenOverlay(false),
      [AdyenMessage.ERROR]: (data: { error: OrderError }) => setOrderError(data.error),
      [AdyenMessage.REFETCH_CART]: () => refetchCart(),
      [AdyenMessage.PLACE_ORDER]: (data: { paymentIntentID: string }) => placeAdyenOrder(data.paymentIntentID)
    }
  });

  const src = useMemo(() => {
    const params = new URLSearchParams({
      shortUrl: ooRestaurant?.shortUrl ?? '',
      cartGuid: cartGuid ?? '',
      checkAmount: (orderTotal - tipAmount).toString(),
      tipAmount: tipAmount.toString(),
      email,
      phone,
      firstName,
      lastName
    });

    return urlJoin(resources.sitesHost, 'sites-web/v1/adyen', '?' + params.toString());
  },
  [ooRestaurant, firstName, lastName, email, phone, cartGuid, orderTotal, tipAmount]);

  // allow list for GPay and ApplePay
  return <iframe title="Adyen payment" allow="payment; camera; microphone" width="100%" height={height} style={{ border: 0 }} src={src} />;
};

export default AdyenPaymentIFrame;
