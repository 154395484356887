import React from 'react';

import { NavType } from 'src/shared/components/common/nav/Nav';

import { RestaurantSiteContent, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';

import Footer from 'public/components/default_template/footer';
import Nav from 'public/components/default_template/nav/Nav';

type ComponentProps = {
  nav: React.ReactNode;
  footer?: React.ReactNode;
};

export const PageWrapper = ({ Component, shouldShowPreviewBanner, navTypeOverride }:{Component: React.ComponentType<ComponentProps>, shouldShowPreviewBanner: boolean, navTypeOverride?: NavType }) => {
  const { restaurant } = useRestaurant();

  const { meta, content, theme } = restaurant;
  if(!content) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const { primaryCta, nonPrimaryCtas } = content as RestaurantSiteContent;

  return <Component
    nav={<Nav logoSrc={meta.icon} logoObject={meta.iconObject} primaryCta={primaryCta} nonPrimaryCtas={nonPrimaryCtas} shouldShowPreviewBanner={shouldShowPreviewBanner} navType={navTypeOverride} />}
    footer={<Footer />} />;
};

export default PageWrapper;
