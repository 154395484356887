import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';

import EmptyCart from 'public/components/default_template/online_ordering/cart/EmptyCart';
import CheckoutErrorModal from 'public/components/default_template/online_ordering/checkout/CheckoutErrorModal';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import CheckoutSections from './CheckoutSections';
import { getCustomerInfo } from './checkoutUtils';
import { useSubmitOrder } from './useSubmitOrder';

const CheckoutForm = () => {
  const submitOrder = useSubmitOrder();
  const { customer } = useCustomer();
  const { loadingCart, cart, error: cartError } = useCart();
  const isCartLoading = !cart && loadingCart;
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: getCustomerInfo(customer)
  });

  const { reset } = formMethods;
  useEffect(() => {
    if(customer) {
      reset(getCustomerInfo(customer));
    }
  }, [reset, customer]);

  if(cartError) {
    return <UhOh {...getUhOhPropsForError(cartError)} />;
  }

  if(isCartLoading) {
    return (
      <div className="checkoutForm emptyCart">
        <section className="checkoutSection currentSection">
          <Skeleton width="100%" height="500px" />
        </section>
      </div>
    );
  }

  if((cart?.order?.selections?.length || 0) === 0) {
    return <EmptyCart />;
  }

  return (
    <div className="checkoutForm">
      <FormProvider {...formMethods}>
        <form className="checkoutFormContents" onSubmit={formMethods.handleSubmit(submitOrder)}>
          {formMethods.formState.isSubmitting && <LoadingSpinnerOverlay withBorderRadius={false} fullScreen={true} />}
          <CheckoutSections />
        </form>
        <CheckoutErrorModal onSubmit={formMethods.handleSubmit(submitOrder)} />
      </FormProvider>
    </div>
  );
};

export default CheckoutForm;
