import 'react-international-phone/style.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  defaultCountries,
  FlagImage,
  usePhoneInput
} from 'react-international-phone';

import { useClickOutside } from '@toasttab/use-click-outside';
import classnames from 'classnames';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { SUPPORTED_COUNTRIES } from 'src/shared/js/phoneUtils';

import DropdownArrow from 'shared/components/common/dropdown/DropdownArrow';
import { useOptionalRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import InputMessage from './InputMessage';

type InternationalPhoneInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  value?: string;
  id: string;
  label?: React.ReactNode | string;
  required?: boolean;
  warning?: string;
  unregisterOnUnmount?: boolean;
  disabled?: boolean;
  initialFocus?: boolean;
  focusMessage?: string;
  shouldUnFocus?: boolean;
  isSingletonField?: boolean;
  classNames?: string;
}

export const InternationalPhoneInput: React.FC<InternationalPhoneInputProps> = ({
  value,
  id,
  label,
  required,
  warning,
  unregisterOnUnmount = true,
  disabled = false,
  initialFocus = false,
  focusMessage,
  shouldUnFocus = true,
  isSingletonField = false,
  classNames,
  ...inputProps
}) => {
  const ooRestaurant = useOptionalRestaurant()?.ooRestaurant;
  const phoneNum = useMemo(() => {
    // we need to add + sign for guest phone numbers
    const num = value && !value.startsWith('+') ? `+${value}` : value;

    return num && isValidPhoneNumber(num) ? parsePhoneNumber(num).number : '';
  }, [value]);
  const { inputValue, phone, handlePhoneValueChange, country, setCountry } =
  usePhoneInput({
    defaultCountry: ooRestaurant?.i18n?.country?.toLowerCase() || 'us',
    value: phoneNum,
    countries: defaultCountries,
    forceDialCode: true,
    onChange: ({ phone, country }) => {
      if(isValidPhoneNumber(phone, country.iso2.toUpperCase() as CountryCode)) {
        setValue(id, phone, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
      }
    }
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [focus, setFocus] = useState(initialFocus);
  const { register, formState: { errors }, setValue, unregister, resetField } = useFormContext();
  const dropdownRef = useClickOutside<HTMLDivElement>(() => setShowDropdown(false));
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    // The timeout is necessary to prevent the onBlur from firing and putting us into an error state prematurely
    setTimeout(() => {
      if(initialFocus && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 100);
  }, [initialFocus]);

  const validate = useCallback(
    (value: string) => (value || required) && !isValidPhoneNumber(value) ? 'enter a valid phone number' : true,
    [required]
  );

  useEffect(() => {
    register(id, { required: required ? 'required' : false, validate });

    return unregisterOnUnmount
      ? () => {
        unregister(id);
      }
      : () => {};
  }, [register, id, required, unregister, unregisterOnUnmount, validate]);

  const onBlurInput = (phoneNumber: string) => () => {
    // This prevents us from showing an error state on blur when the form essentially wasn't touched,
    // e.g. in the case of the auth modal where the phone input is the only field and the guest clicks
    // the close button without attempting to enter phone number.
    // or when selecting different country
    if(isSingletonField && phoneNumber.replace(/\D/g, '') === country.dialCode) {
      return;
    }

    setValue(id, phone, { shouldDirty: true, shouldTouch: true, shouldValidate: true });

    if(shouldUnFocus) {
      setFocus(false);
    }
  };

  return (
    <div className={classnames('formInputContainer intlPhoneInput',
      errors[id] && 'inputError',
      (disabled || inputProps.readOnly) && 'inputDisabled',
      classNames)}>
      <div className="inputFieldInner">
        <div ref={dropdownRef} className="intlPhoneInputDropdown">
          <button
            onClick={() => setShowDropdown(prevState => !prevState)}
            role="combobox"
            type="button"
            value={country.name}
            aria-controls="listbox"
            aria-haspopup="listbox"
            tabIndex={0}
            disabled={disabled}
            aria-expanded={showDropdown}>
            <FlagImage iso2={country.iso2} />
            <DropdownArrow />
          </button>
          {showDropdown &&
        <ul role="listbox" className={showDropdown && 'active'} >
          {SUPPORTED_COUNTRIES.map(supportedCountry => {
            return (
              <li role="option"
                key={supportedCountry.iso2}
                onClick={() => {
                  setCountry(supportedCountry.iso2);
                  resetField(id);
                  setShowDropdown(false);
                }}>
                <FlagImage
                  iso2={supportedCountry.iso2} />
                {supportedCountry.name}
              </li>
            );
          })}
        </ul>}
        </div>
        <label>
          <input
            aria-required={required}
            type="tel"
            autoComplete="tel"
            value={inputValue}
            onChange={handlePhoneValueChange}
            placeholder=" "
            id={id}
            disabled={disabled}
            data-testid={`input-${id}`}
            name={id}
            onBlur={onBlurInput(phone)}
            ref={inputRef}
            onFocus={() => setFocus(true)}
            {...inputProps} />
          <span>{label}</span>
        </label>
      </div>
      <InputMessage id={id} warning={warning} focus={focus} focusMessage={focusMessage} />
    </div>
  );
};
