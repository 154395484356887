import React, { useContext, useState, createContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useEditor } from '@toasttab/sites-components';

type NavHistoryContextType = {
  historySet: string[],
}
export const NavHistoryContext = createContext<NavHistoryContextType | undefined>(undefined);

const dedupe = (addition: string) => (original: string[]) => {
  const deduped = new Set(original);
  deduped.add(addition);
  return Array.from(deduped);
};

/**
 * A context to hold a set of all previous routes or paths a user visited during in-app navigation.
 */
export const NavHistoryProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const history = useHistory();
  const location = useLocation();
  const [historySet, setHistorySet] = useState<string[]>([location.pathname]);

  useEffect(() => {
    const unlisten = history.listen(location => {
      setHistorySet(dedupe(location.pathname));
    });

    return unlisten;
  }, [history, location.pathname]);

  return (
    <NavHistoryContext.Provider value={{ historySet }}>
      {children}
    </NavHistoryContext.Provider>
  );
};

export const useNavHistory = () => {
  const context = useContext(NavHistoryContext);
  const { isEditor } = useEditor();

  // ssr and editor fallback
  if(typeof window === 'undefined' || isEditor) {
    return { historySet: [] };
  }

  if(!context) {
    throw new Error('useNavHistory must be used within a NavHistoryProvider');
  }
  return context;
};
